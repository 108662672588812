import {createSelector} from 'reselect';

import * as aggregatedSettingsTypes from '@/consts/tnc/aggregatedSettingTypes';

const selectConsumer = (state) => state.consumer;

const selectAggregatedSettings = createSelector(selectConsumer, (state) => state.aggregatedSettings);

const selectIsAggregatedSettingsFetched = createSelector(
    selectAggregatedSettings,
    (aggregatedSettings) => aggregatedSettings !== undefined
);

const selectConsumerAggregatedSettings = createSelector(
    selectAggregatedSettings,
    (aggregatedSettings) => aggregatedSettings?.consumerAggregatedSettings
);

const makeConsumerAggregatedSetting = (key) =>
    createSelector(selectConsumerAggregatedSettings, (cas) => cas?.find((item) => item.key === key)?.value);

const selectIsAmplitudeEnabled = makeConsumerAggregatedSetting(aggregatedSettingsTypes.ENABLE_AMPLITUDE);
const selectIsGaEnabled = makeConsumerAggregatedSetting(aggregatedSettingsTypes.ENABLE_GA);
const selectIsGaAnonymizationEnabled = makeConsumerAggregatedSetting(aggregatedSettingsTypes.ENABLE_GA_ANONYMIZATION);

const selectIsTncApproved = createSelector(
    selectAggregatedSettings,
    (aggregatedSettings) =>
        aggregatedSettings?.allNonMandatoryTermsReviewed && aggregatedSettings?.allMandatoryTermsApproved
);

const selectIsAllMandatoryTermsApproved = createSelector(
    selectAggregatedSettings,
    (aggregatedSettings) => aggregatedSettings?.allMandatoryTermsApproved
);

const selectIsAllNonMandatoryTermsReviewed = createSelector(
    selectAggregatedSettings,
    (aggregatedSettings) => aggregatedSettings?.allNonMandatoryTermsReviewed
);

export {
    selectAggregatedSettings,
    selectIsAggregatedSettingsFetched,
    selectIsAllMandatoryTermsApproved,
    selectIsAllNonMandatoryTermsReviewed,
    selectIsAmplitudeEnabled,
    selectIsGaAnonymizationEnabled,
    selectIsGaEnabled,
    selectIsTncApproved,
};
