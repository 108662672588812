import React from 'react';

import ContentList from '@/components/ContentList/ContentList';
import {MaxWidthContainer} from '@/components/Layout';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {twx} from '@/utils/tailwindUtils';

const QuickOrderSection = ({className, headerKey, contentList}) => {
    return (
        <article className={twx('hidden flex-1 px-4 py-8 has-[h2,li_*]:block', className)}>
            <QuickOrderSectionHeader titleKey={headerKey} />
            <MaxWidthContainer className='mt-4 flex-1 first:mt-0 ml:max-w-none'>
                <ContentList list={contentList} />
            </MaxWidthContainer>
        </article>
    );
};

const QuickOrderSectionHeader = ({titleKey}) => {
    const localizedStrings = getLocalizedStrings();
    const title = localizedStrings[titleKey];

    if (!title) return;

    return <h2 className='mb-0 text-24 leading-13'>{title}</h2>;
};

export default QuickOrderSection;
