import {AppButton} from '@/components/Button';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import {twx} from '@/utils/tailwindUtils';

import useHighlightsSlideText from '../hooks/useHighlightsSlideText';
import HighlightsContentContainer from './HighlightsContentContainer';

const HighlightsContentTextTop = ({
    isFullscreen,
    gradient,
    title,
    subTitle,
    description,
    firstButtonAction,
    firstButtonText,
    firstButtonAriaLabel,
    secondButtonAction,
    secondButtonText,
    secondButtonAriaLabel,
}) => {
    const {isTextLarger, TitleComponent} = useHighlightsSlideText(isFullscreen);

    return (
        <HighlightsContentContainer isFullscreen={isFullscreen} gradient={gradient}>
            <p
                className={twx(
                    'line-clamp-1 text-14 font-bold leading-[10px] md:leading-normal',
                    isFullscreen && 'pr-12 md:pr-0',
                    isTextLarger && 'text-24 leading-[18px]'
                )}
            >
                {subTitle}
            </p>
            <TitleComponent
                className={twx(
                    'mb-0 mt-14 text-36',
                    !isFullscreen && 'line-clamp-2',
                    isTextLarger && 'text-56 leading-[64px]'
                )}
            >
                {title}
            </TitleComponent>
            <p className={twx('mt-4 flex-1 md:mt-2 md:leading-[20px]', !isFullscreen && 'line-clamp-3')}>
                {description}
            </p>
            <div className='mt-4 flex w-full flex-col gap-4 md:mt-8'>
                <Buttons
                    firstButtonAction={firstButtonAction}
                    firstButtonText={firstButtonText}
                    firstButtonAriaLabel={firstButtonAriaLabel}
                    secondButtonAction={secondButtonAction}
                    secondButtonText={secondButtonText}
                    secondButtonAriaLabel={secondButtonAriaLabel}
                />
            </div>
        </HighlightsContentContainer>
    );
};

const Buttons = ({
    firstButtonAction,
    firstButtonText,
    firstButtonAriaLabel,
    secondButtonAction,
    secondButtonText,
    secondButtonAriaLabel,
}) => {
    return (
        <>
            {firstButtonText && firstButtonAction && (
                <Button
                    primary
                    dark
                    text={firstButtonText}
                    action={firstButtonAction}
                    aria-label={firstButtonAriaLabel}
                />
            )}
            {secondButtonText && secondButtonAction && (
                <Button
                    secondary
                    colored
                    text={secondButtonText}
                    action={secondButtonAction}
                    aria-label={secondButtonAriaLabel}
                />
            )}
        </>
    );
};

const Button = ({text, action, ...rest}) => {
    const redirectToExternalLink = useConfigExternalLink(action);
    const onClick = (e) => {
        e.stopPropagation();
        redirectToExternalLink();
    };

    return (
        <AppButton className='mt-0' {...rest} onClick={onClick}>
            {text}
        </AppButton>
    );
};

export default HighlightsContentTextTop;
