import {useEffect, useState} from 'react';

import useDidLayoutUpdate from '@/hooks/useDidLayoutUpdate';

const useAriaLiveMessage = ({children: propsContent, role, isConstant}) => {
    const shouldSetContentWithDelay = role === 'status';
    const SET_DELAY = 1;
    const shouldUpdateOnChange = !isConstant;

    const initialContent = shouldSetContentWithDelay ? undefined : propsContent;
    const [content, setContent] = useState(initialContent);

    // Unlike divs with role='alert', those with role='status'
    // are announced only when their content changes, not on initial render.
    // The workaround below fixes this behavior.
    useEffect(() => {
        if (shouldSetContentWithDelay && propsContent) {
            setTimeout(() => setContent(propsContent), SET_DELAY);
        }
    }, []);

    useDidLayoutUpdate(() => {
        if (shouldUpdateOnChange) {
            setContent(propsContent);
        }
    }, [propsContent]);

    return {content};
};

export default useAriaLiveMessage;
