import amplitudeEventNames from '@/consts/analytics/analyticsEventNames';
import analyticsEventProperties from '@/consts/analytics/analyticsEventProperties';
import {amplitude} from '@/services/analytics/amplitude/index';

const trackEnableNotificationScreen = () => {
    amplitude.trackWithSource(amplitudeEventNames.ENABLE_NOTIFICATION);
};

const trackEnableNotificationScreenNextButton = () => {
    amplitude.trackWithSource(amplitudeEventNames.NEXT_BUTTON_CLICKED);
};

const trackSubscribeToReceiveMessages = () => {
    amplitude.trackWithSource(amplitudeEventNames.NOTIFICATION_ALLOWED, {
        [analyticsEventProperties.SUCCESS]: true,
    });
};

const trackUnsubscribeToReceiveMessages = () => {
    amplitude.trackWithSource(amplitudeEventNames.NOTIFICATION_ALLOWED, {
        [analyticsEventProperties.SUCCESS]: false,
    });
};

export default {
    trackEnableNotificationScreen,
    trackEnableNotificationScreenNextButton,
    trackSubscribeToReceiveMessages,
    trackUnsubscribeToReceiveMessages,
};
