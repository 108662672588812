import appConfig from '@/config/appConfig';
import * as iccMethods from '@/consts/icc/iccMethods';
import authDataService from '@/services/auth/authDataService';
import iccResponseErrorCheckService from '@/services/icc/iccResponseErrorCheckService';
import {getMappedInitialLanguageCode} from '@/services/localization/localizationService';
import log from '@/services/logger/log';
import {
    aggregatedSettingsMapping,
    consumerMapping,
    consumerProductsInfoMapping,
    consumerProductsMapping,
    consumerProductsStatusMapping,
    deviceUnregistrationMapping,
    externalConsumerMapping,
    externalLinkMapping,
    mediaUrlsMapping,
    tncMapping,
} from '@/services/mapping/iccMarketMappings';
import server from '@/services/server/server';
import {
    setConsumerAcceptedTnc,
    setConsumerAggregatedSettings,
    setConsumerData,
    setConsumerProducts,
    setConsumerTnc,
    updateExternalLink,
} from '@/state/slices/consumerSlice';
import {dispatch} from '@/state/store';
import arrayUtils from '@/utils/arrayUtils';
import urlUtils from '@/utils/urlUtils';

const getIccMarketApiUrl = (methodName) => urlUtils.join(appConfig.getIccApiUrl(), methodName);

const getConsumerAggregatedSettings = (throwException = true) =>
    callGet({
        methodName: iccMethods.GET_CONSUMER_AGGREGATED_SETTINGS,
        mapper: aggregatedSettingsMapping,
        action: setConsumerAggregatedSettings,
        throwException,
    });

const getTnc = () =>
    callGet({
        methodName: iccMethods.GET_TNC,
        mapper: tncMapping,
        action: setConsumerTnc,
        throwException: true,
    });

const setTnc = ({data}) =>
    callPost({
        methodName: iccMethods.SET_CONSUMER_TNC,
        requestConfig: {data},
        throwException: true,
    });

const declineTnc = ({data, gigyaToken}) =>
    callDelete({
        methodName: iccMethods.SET_CONSUMER_TNC,
        requestConfig: {data, headers: {et: gigyaToken}},
        throwException: true,
    });

const getAcceptedTnc = () =>
    callGet({
        methodName: iccMethods.GET_ACCEPTED_TNC,
        mapper: tncMapping,
        action: setConsumerAcceptedTnc,
        params: {
            includeDeclined: true,
        },
        throwException: true,
    });

const getExternalLink = (linkCode) =>
    callGet({
        methodName: iccMethods.GET_EXTERNAL_LINK,
        mapper: externalLinkMapping,
        action: updateExternalLink,
        params: {
            linkCode,
        },
        isAnonymous: true,
    });

const getConsumerProfile = () =>
    callGet({
        methodName: iccMethods.GET_CONSUMER,
        mapper: consumerMapping,
        action: setConsumerData,
        throwException: true,
    });

const getExternalConsumerProfile = () =>
    callGet({
        methodName: iccMethods.GET_EXTERNAL_CONSUMER,
        mapper: externalConsumerMapping,
        action: setConsumerData,
        throwException: false,
    });

const getConsumerProducts = (gigyaToken) =>
    callGet({
        methodName: iccMethods.CONSUMER_PRODUCTS,
        headers: {et: gigyaToken},
        mapper: consumerProductsMapping,
        action: setConsumerProducts,
    });

const getConsumerProductsStatus = () =>
    callGet({
        methodName: iccMethods.GET_CONSUMER_PRODUCTS_STATUS,
        mapper: consumerProductsStatusMapping,
    });

const getMediaUrl = ({mediaNameList, throwException = false}) => {
    if (mediaNameList) {
        return callPost({
            methodName: iccMethods.GET_MEDIA_URL_V2,
            requestConfig: {data: {mediaName: arrayUtils.toArray(mediaNameList)}},
            mapper: mediaUrlsMapping,
            throwException,
            isAnonymous: true,
        });
    }
};

const getProductInfoList = ({serialNumbers, throwException}) =>
    callPost({
        methodName: iccMethods.GET_PRODUCT_INFO_LIST,
        requestConfig: {data: {serialNumbers}},
        mapper: consumerProductsInfoMapping,
        throwException,
    });

const getProductInfoListV2 = ({codentifyIds, throwException}) =>
    callPost({
        methodName: iccMethods.GET_PRODUCT_INFO_LIST_V2,
        requestConfig: {data: {CodentifyID: codentifyIds}},
        mapper: consumerProductsInfoMapping,
        throwException,
    });

const setConsumerProductName = ({productName, codentifyID, throwException}) =>
    callPost({
        methodName: iccMethods.SET_CONSUMER_PRODUCT_NAME,
        requestConfig: {
            data: {
                productName,
                codentifyID,
            },
        },
        throwException,
    });

const setConsumerLanguageCode = ({languageCode, throwException}) => {
    const languageCodeFormatted = languageCode?.toUpperCase();

    log.info(`iccMarketService: Change user language to ${languageCodeFormatted}`);

    return callPost({
        methodName: iccMethods.SET_CONSUMER,
        requestConfig: {
            data: {
                DefaultLocaleCode: languageCodeFormatted,
            },
        },
        throwException,
    });
};

const setConsumerProductV2 = (codentify) =>
    callPost({
        methodName: iccMethods.CONSUMER_PRODUCTS_V2,
        requestConfig: {params: {codentifyId: codentify}},
        throwException: true,
    });

const skipAutoDelivery = () =>
    callPost({
        methodName: iccMethods.AUTO_DELIVERY_SKIP_DELIVERY,
        throwException: true,
    });

const deliverAsap = (date) =>
    callPost({
        methodName: iccMethods.AUTO_DELIVERY_DELIVER_ASAP,
        requestConfig: {params: {date}},
        throwException: true,
    });

const removeConsumerProduct = ({data}) =>
    callDelete({
        methodName: iccMethods.CONSUMER_PRODUCTS,
        requestConfig: {params: {...data}},
        mapper: deviceUnregistrationMapping,
        throwException: true,
    });

const callGet = ({methodName, mapper, action, params = {}, headers = {}, throwException, isAnonymous}) => {
    return callRequest({
        httpMethod: server.get,
        methodUrl: getIccMarketApiUrl(methodName),
        requestConfig: {params, headers},
        mapper,
        action,
        throwException,
        isAnonymous,
    });
};

const callPost = ({methodName, requestConfig, mapper, throwException, isAnonymous}) => {
    return callRequest({
        httpMethod: server.post,
        methodUrl: getIccMarketApiUrl(methodName),
        requestConfig,
        mapper,
        throwException,
        isAnonymous,
    });
};

const callDelete = ({methodName, requestConfig, mapper, throwException, isAnonymous}) => {
    return callRequest({
        httpMethod: server.requestDelete,
        methodUrl: getIccMarketApiUrl(methodName),
        requestConfig,
        mapper,
        throwException,
        isAnonymous,
    });
};

const callRequest = async ({
    httpMethod,
    methodUrl,
    requestConfig,
    mapper,
    action,
    throwException,
    isAnonymous,
    ignoreHeaders,
}) => {
    try {
        const response = await callIccMethod({httpMethod, methodUrl, requestConfig, isAnonymous, ignoreHeaders});

        const {data} = response;

        if (mapper || action) {
            const mappedData = mapper ? mapper(data) : data;

            if (action) {
                dispatch(action(mappedData));
            }

            return mappedData;
        } else {
            return httpMethod === server.get ? data : response;
        }
    } catch (e) {
        if (throwException) {
            return Promise.reject(e);
        }
    }
};

const callIccMethod = async ({httpMethod, methodUrl, requestConfig = {}, isAnonymous, ignoreHeaders}) => {
    try {
        const config = await getIccRequestConfig(isAnonymous, requestConfig, ignoreHeaders);

        if (!config) {
            log.info(`iccMarketService: request ${methodUrl} will be ignored. Token is not provided`);

            return {};
        }

        const response = await httpMethod(methodUrl, config);

        return response;
    } catch (e) {
        await iccResponseErrorCheckService({isAnonymous, status: e?.response?.status, error: e});

        const config = await getIccRequestConfig(isAnonymous, requestConfig, ignoreHeaders);

        return await httpMethod(methodUrl, config);
    }
};

const getIccRequestConfig = async (isAnonymous, requestConfig, ignoreHeaders) => {
    if (ignoreHeaders) return requestConfig;

    const authToken = await authDataService.getAuthToken({isAnonymousUserAllowed: isAnonymous});

    if (!authToken) return;

    const appVersion = appConfig.getAppVersion();
    const languageCode = getMappedInitialLanguageCode()?.toUpperCase();
    const {headers, ...rest} = requestConfig;

    return {
        headers: {Authorization: 'Bearer ' + authToken, v: appVersion, ...headers, l: languageCode},
        ...rest,
    };
};

export default {
    getTnc,
    setTnc,
    declineTnc,
    getAcceptedTnc,
    getConsumerAggregatedSettings,
    getConsumerProducts,
    getConsumerProductsStatus,
    getConsumerProfile,
    getIccMarketApiUrl,
    getMediaUrl,
    getProductInfoList,
    getProductInfoListV2,
    setConsumerProductName,
    setConsumerLanguageCode,
    callRequest,
    getExternalConsumerProfile,
    removeConsumerProduct,
    setConsumerProductV2,
    skipAutoDelivery,
    deliverAsap,
    getExternalLink,
};
