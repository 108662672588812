import cn from 'classnames';
import React from 'react';

import appConfig from '@/config/appConfig';

import Icon from './Icon';

const SensitiveIcon = (props) => {
    const {className, isForceHide, children, ...restProps} = props;
    const isWarningIconDisabled = !appConfig.getIsWarningIconEnabled();
    const disabledClassName = isForceHide ? 'hidden' : 'invisible';

    return (
        <Icon {...restProps} className={cn(className, {[disabledClassName]: isWarningIconDisabled})}>
            {children}
        </Icon>
    );
};

export default SensitiveIcon;
