import storageService from '@/services/storage/storageService';
import {setDeviceCodentify} from '@/state/slices/deviceSlice';
import {dispatch} from '@/state/store';

const storeSelectedDeviceCodentify = (codentify) => {
    dispatch(setDeviceCodentify(codentify));
    storageService.setSelectedDeviceCodentifyToLocalStorage(codentify);
};

export default {
    storeSelectedDeviceCodentify,
};
