import * as advanceDiagnosticScreenTypes from '@/consts/advanceDiagnostic/advanceDiagnosticScreenTypes';
import queryParameters from '@/consts/route/queryParameters';
import ROUTE_PATHS from '@/consts/route/routePaths';
import {
    selectIsAem,
    selectIsRedirectionRequiredAfterNotificationClick,
    selectLanguageCode,
} from '@/state/selectors/global';
import {getState} from '@/state/store';
import urlUtils from '@/utils/urlUtils';

import routerService from './routerService';

const withLocale = (path) => {
    let result = path;

    try {
        const state = getState();
        const isAem = selectIsAem(state);

        if (!isAem) return path;

        const languageCode = selectLanguageCode(state);

        if (!languageCode) return path;

        result = urlUtils.join('/' + languageCode, path);
    } catch (e) {}

    return result;
};

const forwardToOrReplace = (path, replace, {state} = {}) => {
    const redirect = replace ? routerService.replace : routerService.forwardTo;

    redirect(path, state);
};

const forwardToRootPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.ROOT));
const forwardToErrorPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.ERROR_PAGE));
const replaceToErrorPage = () => routerService.replace(withLocale(ROUTE_PATHS.ERROR_PAGE));
const redirectToRootPage = () => routerService.redirect(withLocale(ROUTE_PATHS.ROOT));

const forwardToInstallPwaPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.INSTALL_PWA));
const forwardToLoginPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.LOGIN));
const replaceToLoginPage = () => routerService.replace(withLocale(ROUTE_PATHS.LOGIN));
const forwardToHomePage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.HOME_PAGE));
const forwardToHomePageIfNeed = () => {
    const isRedirectionRequiredAfterNotificationClick = selectIsRedirectionRequiredAfterNotificationClick(getState());

    // Redirection will be done from useSWMessageHandler hook
    if (isRedirectionRequiredAfterNotificationClick) return;

    forwardToHomePage();
};
const forwardToContactUsPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.CONTACT_US));
const replaceToContactUsPage = () => routerService.replace(withLocale(ROUTE_PATHS.CONTACT_US));
const forwardToShopPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.SHOP_PAGE));
const forwardToDevicesPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.DEVICES_PAGE));
const forwardToAutoDeliveryPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.AUTO_DELIVERY));
const forwardToAutoDeliveryAsapPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.AUTO_DELIVERY_ASAP));
const forwardToAutoDeliverySkipPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.AUTO_DELIVERY_SKIP));

const forwardToTncPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.TNC));
const forwardToTncDetailsPage = (type, replace) =>
    forwardToOrReplace(
        withLocale(ROUTE_PATHS.TNC_DETAILS) + '?' + urlUtils.stringify({[queryParameters.TNC_PAGE_TYPE]: type}),
        replace
    );
const forwardToProfilePreferenceCenterPage = () =>
    routerService.forwardTo(withLocale(ROUTE_PATHS.PROFILE_PREFERENCE_CENTER));
const replaceToProfilePreferenceCenterPage = () =>
    routerService.replace(withLocale(ROUTE_PATHS.PROFILE_PREFERENCE_CENTER));
const forwardToProfileTermsOfUse = () => routerService.forwardTo(withLocale(ROUTE_PATHS.PROFILE_TERMS_OF_USE));
const replaceToProfileTermsOfUse = () => routerService.replace(withLocale(ROUTE_PATHS.PROFILE_TERMS_OF_USE));
const replaceToProfileLanguageSelectPage = () => routerService.replace(withLocale(ROUTE_PATHS.PROFILE_LANGUAGE_SELECT));

// IA: this page is used to open all available popups in the app from AEM. it is not a part of user flow
const forwardToModalsPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.MODALS));
const isModalsPage = () => urlUtils.getCurrentPathname().includes(withLocale(ROUTE_PATHS.MODALS));

const forwardToQuickStartGuidePage = (deviceType = '') =>
    routerService.forwardTo(urlUtils.join(withLocale(ROUTE_PATHS.QUICK_START_GUIDE), deviceType));
const forwardToTipsAndTutorialsList = (deviceType = '') =>
    routerService.forwardTo(urlUtils.join(withLocale(ROUTE_PATHS.TIPS_AND_TUTORIALS), deviceType));
const forwardToADPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.ADVANCE_DIAGNOSTIC));
const forwardToAdvancedDiagnosticScreen = ({screenName}) =>
    routerService.forwardTo(
        withLocale(ROUTE_PATHS.ADVANCE_DIAGNOSTIC) + '?' + urlUtils.stringify({screen: screenName})
    );
const forwardToDamageCheckAD = () =>
    forwardToAdvancedDiagnosticScreen({screenName: advanceDiagnosticScreenTypes.DAMAGE_CHECK_SCREEN});
const forwardToSelectIssueAD = () =>
    forwardToAdvancedDiagnosticScreen({screenName: advanceDiagnosticScreenTypes.SELECT_ISSUE_SCREEN});
const forwardToCleaningContactsScreenAD = () =>
    forwardToAdvancedDiagnosticScreen({screenName: advanceDiagnosticScreenTypes.CLEANING_CONTACTS_SCREEN});
const forwardToFittingConsumablesResolvedAD = () =>
    forwardToAdvancedDiagnosticScreen({screenName: advanceDiagnosticScreenTypes.FITTING_CONSUMABLES_SOLVED});
const forwardToPostCleaningAD = () =>
    forwardToAdvancedDiagnosticScreen({screenName: advanceDiagnosticScreenTypes.POST_CLEANING_SCREEN});

const forwardToAboutDevicePage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.ABOUT_DEVICE));
const forwardToDeviceRenamePage = (replace) => forwardToOrReplace(withLocale(ROUTE_PATHS.DEVICE_RENAME), replace);

/* Device Settings */
const forwardToAemFlexBatteryPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.AEM_FLEX_BATTERY_SETTING));
const forwardToAutoStartPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.AUTO_START_SETTING));
const forwardToFlexPuffPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.FLEX_PUFF_SETTING));
const forwardToSmartGesturesPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.SMART_GESTURES_SETTING));
const forwardToIlluminationModePage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.ILLUMINATION_MODE_SETTING));
const forwardToVibrationsPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.VIBRATIONS_SETTING));

const forwardToCleaningGuide = ({isAdvancedDiagnostic, replace} = {}) =>
    forwardToOrReplace(
        withLocale(ROUTE_PATHS.CLEAN_TUTORIAL) + '?' + urlUtils.stringify({isAdvancedDiagnostic}),
        replace
    );

const forwardToInboxPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.INBOX_PAGE));
const forwardToConsumerProfilePage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.CONSUMER_PROFILE));

const forwardToNotificationDetailsPage = (notificationId, source) =>
    routerService.forwardTo(
        urlUtils.join(
            withLocale(ROUTE_PATHS.INBOX_PAGE),
            notificationId.toString() + '?' + urlUtils.stringify({source})
        )
    );

const forwardToSelectYourDevicePage = ({onBackRoute} = {}) =>
    routerService.forwardTo(withLocale(ROUTE_PATHS.SELECT_YOUR_DEVICE), {onBackRoute});
const forwardToUsbRequiredPage = (deviceType = '') =>
    routerService.forwardTo(
        urlUtils.join(withLocale(ROUTE_PATHS.USB_REQUIRED) + '?' + urlUtils.stringify({deviceType}))
    );

if (process.env.REACT_APP_ENV !== 'prod') {
    window.forwardTo = routerService.forwardTo;
}

export default {
    forwardToRootPage,
    forwardToErrorPage,
    replaceToErrorPage,
    forwardToInstallPwaPage,
    forwardToLoginPage,
    replaceToLoginPage,
    forwardToTncPage,
    forwardToHomePage,
    forwardToContactUsPage,
    replaceToContactUsPage,
    forwardToQuickStartGuidePage,
    forwardToTipsAndTutorialsList,
    forwardToADPage,
    forwardToAdvancedDiagnosticScreen,
    forwardToDamageCheckAD,
    forwardToSelectIssueAD,
    forwardToCleaningContactsScreenAD,
    forwardToFittingConsumablesResolvedAD,
    forwardToPostCleaningAD,
    forwardToAboutDevicePage,
    forwardToDeviceRenamePage,

    forwardToAemFlexBatteryPage,
    forwardToAutoStartPage,
    forwardToFlexPuffPage,
    forwardToSmartGesturesPage,
    forwardToIlluminationModePage,
    forwardToVibrationsPage,

    forwardToCleaningGuide,
    forwardToNotificationDetailsPage,
    forwardToInboxPage,
    forwardToModalsPage,
    isModalsPage,
    redirectToRootPage,
    forwardToConsumerProfilePage,
    forwardToShopPage,
    forwardToDevicesPage,
    forwardToSelectYourDevicePage,
    forwardToUsbRequiredPage,
    forwardToAutoDeliveryPage,
    forwardToAutoDeliveryAsapPage,
    forwardToAutoDeliverySkipPage,
    forwardToHomePageIfNeed,
    forwardToTncDetailsPage,
    forwardToProfilePreferenceCenterPage,
    replaceToProfilePreferenceCenterPage,
    forwardToProfileTermsOfUse,
    replaceToProfileTermsOfUse,
    replaceToProfileLanguageSelectPage,
};
