import React from 'react';
import {useSelector} from 'react-redux';

import {StickyHeader} from '@/components/Layout';
import appConfig from '@/config/appConfig';
import {AD_CLEANING_CONTACTS_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {selectDeviceType} from '@/state/selectors/device';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';
import AdvancedDiagnosticCleaningContactsCarousel from './AdvancedDiagnosticCleaningContactsCarousel';

const AdvancedDiagnosticCleaningContacts = () => {
    const isMobile = useIsMobileLayout();
    const deviceType = useSelector(selectDeviceType);
    const diagnosticData = appConfig.getAdConfig(deviceType, 'cleaningGuide');
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[AD_CLEANING_CONTACTS_HEADER_TITLE]);

    return (
        <div className='flex w-full flex-col overflow-hidden pt-0-hw-offset'>
            <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.AD} />
            <div className='mt-safe-or-sticky-header w-full pb-8'>
                {isMobile && <StickyHeader title={localizedStrings[AD_CLEANING_CONTACTS_HEADER_TITLE]} />}
                <AdvancedDiagnosticTitle itemName='cleaningcontactstitle' />
                <AdvancedDiagnosticCleaningContactsCarousel carouselData={diagnosticData} deviceType={deviceType} />
            </div>
            <WarningBanners className='-mt-4' source={WARNING_BANNER_SOURCE_TYPES.AD} />
        </div>
    );
};

export default AdvancedDiagnosticCleaningContacts;
