import cn from 'classnames';
import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useResizeObserver} from 'usehooks-ts';

import {selectIsMobileLayout} from '@/state/selectors/global.js';
import {twx} from '@/utils/tailwindUtils.js';

import StickyHeader from './StickyHeader';

const StickyPageLayout = forwardRef((props, ref) => {
    const isMobileLayout = useSelector(selectIsMobileLayout);

    return isMobileLayout ? <MobileStickyPage {...props} ref={ref} /> : <DesktopPage {...props} ref={ref} />;
});

const DesktopPage = forwardRef(
    ({children, desktopClassName, desktopFooterClassName, desktopContentClassName, footer}, ref) => {
        return (
            <section ref={ref} className={twx('flex flex-col', desktopClassName)}>
                <div className={twx('w-full', desktopContentClassName)}>{children}</div>
                <div className={twx('w-full', desktopFooterClassName)}>{footer}</div>
            </section>
        );
    }
);

const MobileStickyPage = forwardRef(
    (
        {
            children,
            className,
            headerOptions,
            noScrollFooterClassName,
            footerClassName,
            contentClassName,
            footer,
            isContentContainerFocusable,
        },
        ref
    ) => {
        const contentContainerRef = useRef();
        const contentRef = useRef();
        const {height} = useResizeObserver({
            ref: contentRef,
        });
        const [isScrollVisible, setIsScrollVisible] = useState(false);

        useEffect(() => {
            const containerHeight = contentContainerRef.current.clientHeight;
            const isScrollVisible = containerHeight < height;

            setIsScrollVisible(isScrollVisible);
        }, [height]);

        // If a scrollable container has no interactive elements inside, it should be made focusable (accessibility)
        const getContentContainerTabIndex = () => (isContentContainerFocusable ? 0 : undefined);

        return (
            <section ref={ref} className={cn('flex max-h-screen flex-1 flex-col', className)}>
                {headerOptions && <StickyHeader scrollElement={contentContainerRef.current} {...headerOptions} />}
                <div
                    ref={contentContainerRef}
                    className='w-full flex-1 overflow-y-auto overflow-x-hidden pt-0-hw-offset'
                    tabIndex={getContentContainerTabIndex()}
                >
                    <div ref={contentRef} className={cn('min-h-full', contentClassName)}>
                        {children}
                    </div>
                </div>
                {footer && (
                    <div
                        className={twx(
                            'w-full px-4 pt-4 pb-safe-offset-4 md:px-8 md:pb-safe-offset-8',
                            {'bg-secondary': isScrollVisible},
                            {[noScrollFooterClassName]: !isScrollVisible},
                            footerClassName
                        )}
                    >
                        {footer}
                    </div>
                )}
            </section>
        );
    }
);

export default StickyPageLayout;
