import React, {forwardRef} from 'react';

import {ButtonRaw} from '@/components/Button';
import PageHeaderMedia from '@/components/PageHeaderMedia/PageHeaderMedia';
import {twx} from '@/utils/tailwindUtils';

const Banner = forwardRef(function BannerRef(
    {
        className,
        'aria-label': ariaLabel,
        onClick,
        imageMedia,
        videoPath,
        isGradient,
        isDarkBannerText,
        isVideoAvailable,
        children,
        gradient,
        tabIndex,
        'aria-hidden': ariaHidden,
        ...rest
    },
    ref
) {
    const isButton = !!onClick;
    const Container = isButton ? ButtonRaw : 'article';

    return (
        <Container
            ref={ref}
            className={twx('relative w-full', className, {'text-primary': isDarkBannerText})}
            aria-label={ariaLabel}
            aria-hidden={ariaHidden}
            tabIndex={tabIndex}
            onClick={onClick}
        >
            <PageHeaderMedia
                isVideoAvailable={isVideoAvailable ?? !!videoPath}
                className='absolute h-full'
                videoSrc={videoPath}
                imageMedia={imageMedia}
                {...rest}
            />
            {isGradient && <div className='translate-z-1 absolute h-full w-full' style={{background: gradient}} />}
            {children}
        </Container>
    );
});

export default Banner;
