import React from 'react';

import RemoteIcon from '@/components/Icon/RemoteIcon';
import {getLocalizedStrings} from '@/services/localization/localizationService';

const LeviaLearnMoreItem = ({item}) => {
    const {titleKey} = item;
    const localizedStrings = getLocalizedStrings();

    if (!titleKey) return null;

    return (
        <li className='flex items-center gap-1 text-start ml:gap-2'>
            <div className='h-12 w-12 shrink-0'>
                <RemoteIcon icon={item} />
            </div>
            <p className='ml:text-16 ml:leading-15'>{localizedStrings[titleKey]}</p>
        </li>
    );
};

export default LeviaLearnMoreItem;
