import React from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import appConfig from '@/config/appConfig';
import {
    ADVANCED_DIAGNOSTICS_SUPPORT_CONTACT_US_BUTTON_ARIA_LABEL,
    ADVANCED_DIAGNOSTICS_SUPPORT_FIND_STORE_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import {getAriaLabel} from '@/services/accessibilityService';
import {twx} from '@/utils/tailwindUtils';

import {HelpBlueIcon, LocationBlueIcon} from '../Icons';
import {ContentWrapper, MaxWidthContainer} from '../Layout';

const SupportAndStoreTemplate = (props) => {
    const {className, isTitleVisible} = props;

    const supportExternalLink = appConfig.getSupportExternalLink();
    const redirectToSupport = useConfigExternalLink(supportExternalLink);

    const storeExternalLink = appConfig.getIqosStoreExternalLink();
    const redirectToStore = useConfigExternalLink(storeExternalLink);

    const isSupportTitleVisible = supportExternalLink || storeExternalLink;
    const isOtherWaysToSupportVisible = isSupportTitleVisible && isTitleVisible;
    const sectionHeading = isOtherWaysToSupportVisible ? 'h3' : 'h2';

    return (
        <div className='w-full'>
            <ContentWrapper>
                {isOtherWaysToSupportVisible && (
                    <h2 className='mb-10 mt-10 text-24 md:mt-16'>
                        <AemComponent
                            component={EditableText}
                            pageName={routePaths.ADVANCE_DIAGNOSTIC}
                            itemName='supportothertitle'
                        />
                    </h2>
                )}
                <div
                    className={twx(
                        'flex w-full flex-col items-center gap-10 md:w-auto md:flex-row md:gap-6',
                        {'mt-[60px]': !isSupportTitleVisible || !isTitleVisible},
                        className
                    )}
                >
                    {supportExternalLink && (
                        <Section
                            icon={<HelpBlueIcon />}
                            heading={sectionHeading}
                            titleKey='supportcontactustitle'
                            onClick={redirectToSupport}
                            buttonTextKey='supportcontactusbutton'
                            buttonAriaLabelKey={ADVANCED_DIAGNOSTICS_SUPPORT_CONTACT_US_BUTTON_ARIA_LABEL}
                        />
                    )}
                    {storeExternalLink && (
                        <Section
                            icon={<LocationBlueIcon />}
                            heading={sectionHeading}
                            titleKey='supportfindstoretitle'
                            onClick={redirectToStore}
                            buttonTextKey='supportfindstorebutton'
                            buttonAriaLabelKey={ADVANCED_DIAGNOSTICS_SUPPORT_FIND_STORE_BUTTON_ARIA_LABEL}
                        />
                    )}
                </div>
            </ContentWrapper>
        </div>
    );
};

const Section = (props) => {
    const {icon, heading: Heading, titleKey, buttonTextKey, buttonAriaLabelKey, onClick} = props;

    return (
        <MaxWidthContainer className='w-full md:self-stretch'>
            {icon && icon}
            <Heading className='mt-3 flex-1 text-18 md:mt-4'>
                <AemComponent component={EditableText} pageName={routePaths.ADVANCE_DIAGNOSTIC} itemName={titleKey} />
            </Heading>
            <AppButton
                secondary
                light
                onClick={onClick}
                className='mt-6 md:min-w-[312px]'
                aria-label={getAriaLabel(buttonAriaLabelKey)}
            >
                <AemComponent
                    component={EditableText}
                    pageName={routePaths.ADVANCE_DIAGNOSTIC}
                    itemName={buttonTextKey}
                />
            </AppButton>
        </MaxWidthContainer>
    );
};

export default SupportAndStoreTemplate;
