import cn from 'classnames';
import React, {useRef} from 'react';

import layoutSizes from '@/consts/layout/layoutSizes';

import Carousel from '../../../components/Carousel/Carousel';
import styles from './AdvancedDiagnosticCarousel.module.scss';

const AdvancedDiagnosticCarousel = (props) => {
    const {
        carouselData,
        onSlide,
        children,
        focusOnSelect = false,
        swipe = true,
        accessibility = true,
        arrows = true,
        appendDots = (dots) => <ul>{dots}</ul>,
        dotsClassName,
        onAfterChange,
    } = props;
    const isSingleSlide = carouselData.length === 1;
    const slider = useRef();
    const sliderOptions = {
        centerMode: true,
        variableWidth: true,
        focusOnSelect,
        centerPadding: '24px',
        responsive: [
            {
                breakpoint: layoutSizes.MD,
                settings: {
                    variableWidth: false,
                },
            },
        ],
        swipe,
        accessibility,
        arrows,
        appendDots,
    };

    return (
        <div
            className={cn(
                'md:-mx-10 ml:-mx-14 lg:-mx-16',
                {[styles.SingleSlide]: isSingleSlide},
                styles.AdvancedDiagnosticCarousel
            )}
        >
            <Carousel
                isControlArrowVisible
                dotsClassName={cn('mt-6', dotsClassName)}
                options={sliderOptions}
                onTransitionEnd={onSlide}
                onAfterChange={onAfterChange}
                ref={slider}
            >
                {children}
            </Carousel>
        </div>
    );
};

export default AdvancedDiagnosticCarousel;
