import appConfig from '@/config/appConfig';
import UWM_COMMAND_TYPES from '@/consts/uwm/uwmCommendTypes';
import {getMappedInitialLanguageCode} from '@/services/localization/localizationService';
import {selectDeviceType} from '@/state/selectors/device';
import {getState} from '@/state/store';

const postMessage = (detail) => {
    document.getElementById('uwmIframe').contentWindow.postMessage({detail}, '*');
};

const postTipsAndTutorialsInitMessage = ({isQuickStartGuide, isCleaningGuide, deviceType, skipFirstStep}) => {
    postMessage({
        command: UWM_COMMAND_TYPES.UWM_INIT_TIPS_TUTORIALS,
        payload: {
            deviceType: deviceType || selectDeviceType(getState()),
            materialColor: null,
            isQuickStartGuide,
            isCleaningGuide,
            skipFirstStep,
            platformCode: 'PWA',
        },
    });
};

const postMarketLocaleInitMessage = () => {
    const countryCode = appConfig.getIccMarketName();
    const languageCode = getMappedInitialLanguageCode()?.toUpperCase();

    postMessage({
        command: UWM_COMMAND_TYPES.UWM_INIT_MARKET_LOCALE,
        payload: {
            countryCode,
            languageCode,
        },
    });
};

export default {
    postTipsAndTutorialsInitMessage,
    postMarketLocaleInitMessage,
};
