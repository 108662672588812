import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import ContentList from '@/components/ContentList/ContentList';
import {MaxWidthContainer} from '@/components/Layout';
import routePaths from '@/consts/route/routePaths';
import {twx} from '@/utils/tailwindUtils';

const HowItWorksSection = ({className, howItWorksList, itemName}) => {
    return (
        <article className={twx('mx-4 bg-romance py-8', className)}>
            <AemEditableText
                pageName={routePaths.HOME_PAGE}
                itemName={itemName}
                render={(title) => (title ? <h2 className='mb-0 text-24'>{title}</h2> : null)}
            />
            <MaxWidthContainer className='mt-4 flex-1 first:mt-0'>
                <ContentList
                    list={howItWorksList}
                    itemProps={{
                        className: 'md:items-start md:grid-cols-[32px_1fr] md:gap-x-4',
                        titleClassName: 'md:text-18',
                        descriptionClassName: 'md:mt-2',
                    }}
                />
            </MaxWidthContainer>
        </article>
    );
};

export default HowItWorksSection;
