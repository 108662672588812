import LayoutTypes from '@/consts/layout/layoutTypes';

const isXsLayout = (layout) => layout === LayoutTypes.XS;
const isSmLayout = (layout) => layout === LayoutTypes.SM;
const isMdLayout = (layout) => layout === LayoutTypes.MD;
const isMlLayout = (layout) => layout === LayoutTypes.ML;
const isLgLayout = (layout) => layout === LayoutTypes.LG;
const isXlLayout = (layout) => layout === LayoutTypes.XL;
const is2XlLayout = (layout) => layout === LayoutTypes.XXL;

const isMobileLayout = (layout) => isXsLayout(layout) || isSmLayout(layout) || isMdLayout(layout);
const isTabletAndDesktopLayout = (layout) => isMdLayout(layout) || isDesktopLayout(layout);
const isDesktopOnlyLayout = (layout) => isLgLayout(layout) || isXlLayout(layout) || is2XlLayout(layout);
const isDesktopLayout = (layout) =>
    isMlLayout(layout) || isLgLayout(layout) || isXlLayout(layout) || is2XlLayout(layout);

export default {
    isXsLayout,
    isSmLayout,
    isMdLayout,
    isMlLayout,
    isLgLayout,
    isXlLayout,
    is2XlLayout,
    isDesktopLayout,
    isDesktopOnlyLayout,
    isMobileLayout,
    isTabletAndDesktopLayout,
};
