import {useSelector} from 'react-redux';

import {selectDeviceSerialNumber, selectDeviceType} from '@/state/selectors/device';

import {ICC_SSO_AD_DEEPLINK_TEMPLATE} from '../../../consts/icc/iccSsoDeeplinkTemplates';
import useExternalLink from '../../../hooks/useExternalLink';

const useRedirectToPhase2 = ({disabled} = {}) => {
    const deviceType = useSelector(selectDeviceType);
    const deviceSerialNumber = useSelector(selectDeviceSerialNumber);
    const {redirect: redirectToPhase2} = useExternalLink({
        ssoTemplate: ICC_SSO_AD_DEEPLINK_TEMPLATE,
        isConnectionLink: true,
        disabled,
        deviceType,
        deviceSerialNumber,
    });

    return redirectToPhase2;
};

export default useRedirectToPhase2;
