import cn from 'classnames';
import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {SelectedIcon} from '@/components/Icons';
import {LANGUAGE_SELECT_TITLE_ID} from '@/components/LanguageSelect/consts/languageSelectConsts';
import helpers from '@/utils/helpers';
import {twx} from '@/utils/tailwindUtils';

const LanguageSelectContentSelectList = ({className, data = [], selectedValue, onChange, isDesktopView}) => {
    const handleClick = (languageCode) => {
        helpers.runFunction(onChange, languageCode);
    };

    return (
        <div
            className={cn('flex w-full flex-col', className)}
            role='listbox'
            aria-labelledby={LANGUAGE_SELECT_TITLE_ID}
        >
            {data.map((item) => {
                const {title, value} = item;
                const isSelected = selectedValue === value;

                return (
                    <ButtonRaw
                        key={value}
                        className={twx(
                            'flex w-full items-center justify-between border-0 border-b border-solid border-border py-4 md:p-0',
                            {
                                'bg-secondary': isSelected && !isDesktopView,
                                'last:border-b-0': !isDesktopView,
                            }
                        )}
                        role='option'
                        aria-label={title}
                        aria-selected={isSelected}
                        onClick={() => handleClick(value)}
                    >
                        <div className='flex-1 px-4 text-left text-16 md:p-4'>{title}</div>
                        {isSelected && (
                            <div className='mr-5 md:mr-0 md:p-4'>
                                <SelectedIcon />
                            </div>
                        )}
                    </ButtonRaw>
                );
            })}
        </div>
    );
};

export default LanguageSelectContentSelectList;
