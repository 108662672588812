import React from 'react';

import BackButton from '@/components/BackButton/BackButton';
import ROUTE_PATHS from '@/consts/route/routePaths';
import HomePageTopNavigation from '@/containers/HomePageTopNavigation/HomePageTopNavigation';
import HomePageLayout from '@/views/Home/HomePageLayout';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

const AutoDeliveryPageDesktopLayout = ({children}) => {
    return (
        <HomePageLayout className='bg-secondary' activePath={ROUTE_PATHS.HOME_PAGE}>
            <WarningBannersTop
                source={WARNING_BANNER_SOURCE_TYPES.AUTO_DELIVERY}
                className='ml:pl-[var(--sidebar-width)]'
            />
            <HomePageTopNavigation profileControlsClassName='bg-romance' />
            <div className='flex min-h-full flex-col px-16 pt-24'>
                <div className='flex w-full flex-1 gap-8'>{children}</div>
            </div>
        </HomePageLayout>
    );
};

AutoDeliveryPageDesktopLayout.Start = ({children, onBack}) => {
    return (
        <div className='flex-1 pb-8'>
            <BackButton onClick={onBack} className='mb-4' />
            {children}
        </div>
    );
};
AutoDeliveryPageDesktopLayout.End = ({children}) => {
    return (
        <div className='flex w-[45%] flex-col rounded-t-[30px] bg-romance'>
            <div className='w-full flex-1 p-8'>{children}</div>
            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.AUTO_DELIVERY} />
        </div>
    );
};

export default AutoDeliveryPageDesktopLayout;
