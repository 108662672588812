import {createSlice} from '@reduxjs/toolkit';

import ModalTypes from '@/consts/app/modalTypes';

const initialState = {
    globalModals: [],
    renderedModals: [],
};

const DEFAULT_MODAL_ORDER = 1000;
const HIGHEST_MODAL_ORDER = 99999;

export const slice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        showModalAction: (state, action) => {
            const {modalType, modalProps, order = DEFAULT_MODAL_ORDER, alwaysDisplay = false} = action.payload;

            if (state.globalModals.some((modal) => modal.modalType === modalType)) {
                return state;
            }

            state.globalModals.push({
                modalType,
                modalProps,
                order,
                alwaysDisplay,
            });

            return state;
        },
        hideModalAction: (state, action) => {
            const modalType = action.payload;

            state.globalModals = state.globalModals.filter((modal) => modal.modalType !== modalType);

            return state;
        },
        hideAllModalsAction: (state, action) => {
            return {...state, globalModals: [], renderedModals: []};
        },
        addModalToRenderedModals: (state, action) => {
            const id = action.payload;

            if (state.renderedModals.some((modalId) => modalId === id)) {
                return state;
            }

            state.renderedModals.push(id);

            return state;
        },
        removeModalFromRenderedModals: (state, action) => {
            const id = action.payload;

            state.renderedModals = state.renderedModals.filter((modalId) => modalId !== id);

            return state;
        },
    },
});

export const {
    showModalAction,
    hideModalAction,
    hideAllModalsAction,
    addModalToRenderedModals,
    removeModalFromRenderedModals,
} = slice.actions;

export const showUWMUnavailabilityModalAction = (modalProps) =>
    showModalAction({modalType: ModalTypes.UWM_UNAVAILABILITY, modalProps});

export const showLeviaLearnMoreModalAction = (modalProps) =>
    showModalAction({modalType: ModalTypes.LEVIA_LEAR_MORE, modalProps, order: HIGHEST_MODAL_ORDER});

export const showFirmwareAvailableModalAction = (modalProps) =>
    showModalAction({modalType: ModalTypes.FIRMWARE_AVAILABLE, modalProps, order: HIGHEST_MODAL_ORDER});

export const showBrowserNotCompatibleModalAction = (modalProps) =>
    showModalAction({modalType: ModalTypes.BROWSER_NOT_COMPATIBLE, modalProps, order: HIGHEST_MODAL_ORDER});

export const showConsumerFeedbackGatheringModalAction = (modalProps) =>
    showModalAction({modalType: ModalTypes.CONSUMER_FEEDBACK_GATHERING, modalProps, order: HIGHEST_MODAL_ORDER});

export const showLanguageSelectModalAction = (modalProps) =>
    showModalAction({modalType: ModalTypes.LANGUAGE_SELECT, modalProps});

export const showMaintenancePopup = (modalProps) =>
    showModalAction({modalType: ModalTypes.MAINTENANCE, modalProps, order: HIGHEST_MODAL_ORDER});

export const showEnableNotificationModal = (modalProps) =>
    showModalAction({modalType: ModalTypes.ENABLE_NOTIFICATION, modalProps, order: HIGHEST_MODAL_ORDER});

export const showAutoDeliveryStatusNoneDetailsPopup = (modalProps) =>
    showModalAction({modalType: ModalTypes.AUTO_DELIVERY_STATUS_NONE_DETAILS, modalProps, order: DEFAULT_MODAL_ORDER});

export const showAutoDeliveryStatusInactiveDetailsPopup = (modalProps) =>
    showModalAction({
        modalType: ModalTypes.AUTO_DELIVERY_STATUS_INACTIVE_DETAILS,
        modalProps,
        order: DEFAULT_MODAL_ORDER,
    });

export const showQuickOrderPopup = (modalProps) =>
    showModalAction({
        modalType: ModalTypes.QUICK_ORDER,
        modalProps,
        order: DEFAULT_MODAL_ORDER,
    });

export const hideAutoDeliveryStatusNoneDetailsPopup = () =>
    hideModalAction(ModalTypes.AUTO_DELIVERY_STATUS_NONE_DETAILS);

export const hideAutoDeliveryStatusInactiveDetailsPopup = () =>
    hideModalAction(ModalTypes.AUTO_DELIVERY_STATUS_INACTIVE_DETAILS);

export const hideQuickOrderPopup = () => hideModalAction(ModalTypes.QUICK_ORDER);

export default slice.reducer;
