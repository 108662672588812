import React, {useId} from 'react';

import SwitchToggle from '@/components/SwitchToggle/SwitchToggle';

import ExpansionPanel from './ExpansionPanel';

const DCExpansionPanel = ({title, innerText, onChange, isActive, onTncLinkClick}) => {
    const titleId = useId();
    const innerTextId = useId();

    return (
        <ExpansionPanel
            className='w-full'
            innerTextId={innerTextId}
            innerText={innerText}
            titleId={titleId}
            title={title}
            onTncLinkClick={onTncLinkClick}
        >
            <SwitchToggle isActive={isActive} aria-labelledby={titleId} onChange={onChange} className='shrink-0' />
        </ExpansionPanel>
    );
};

export default DCExpansionPanel;
