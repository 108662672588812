import React from 'react';

import RemoteIcon from '@/components/Icon/RemoteIcon';
import {getLocalizedStrings} from '@/services/localization/localizationService';

const YourUsualSectionDescription = ({sectionTitleKey, descriptionKey, icons}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <div>
            <h2 className='mb-2 text-36'>{localizedStrings[sectionTitleKey]}</h2>
            <p className='text-18 leading-[28px] tracking-02'>{localizedStrings[descriptionKey]}</p>
            <ul className='mt-8 flex flex-col gap-4'>
                {icons?.map((icon, i) => {
                    const {titleKey} = icon;
                    const title = localizedStrings[titleKey];

                    if (!title) return null;

                    return (
                        <li key={i} className='flex items-center justify-start gap-2'>
                            <RemoteIcon icon={icon} className='w-8 shrink-0' />
                            <span className='text-start'>{title}</span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default YourUsualSectionDescription;
