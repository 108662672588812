import React from 'react';

import RemoteImage from '@/components/Image/RemoteImage';
import {ContentWrapper, MaxWidthContainer} from '@/components/Layout';
import appConfig from '@/config/appConfig';
import {NO_DEVICES_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import HomePageTopNavigation from '@/containers/HomePageTopNavigation/HomePageTopNavigation';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {HOME_PAGE_NAVIGATION_SOURCE_TYPES} from '@/services/homePageNavigationService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import HomePageLayout from '@/views/Home/HomePageLayout';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import DevicesPageButtons from './DevicesPageButtons';
import DevicesPageHeader from './DevicesPageHeader';

const NoDevicesView = () => {
    const localizedStrings = getLocalizedStrings();
    const imageMedia = appConfig.getSetUpYourIqosImageMedia();

    useDocumentTitle(localizedStrings[NO_DEVICES_PAGE_HEADER_TITLE]);

    return (
        <HomePageLayout className='bg-secondary'>
            <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.DEVICES} className='ml:pl-[var(--sidebar-width)]' />
            <div className='flex h-screen w-full flex-1 flex-col pb-8'>
                <ContentWrapper className='mx-4 h-full flex-1 items-start'>
                    <MaxWidthContainer className='mx-auto w-full flex-1' size='ml'>
                        <HomePageTopNavigation
                            title={localizedStrings[NO_DEVICES_PAGE_HEADER_TITLE]}
                            source={HOME_PAGE_NAVIGATION_SOURCE_TYPES.DEVICES}
                            profileControlsClassName='bg-romance'
                        />
                        <DevicesPageHeader />
                        <div className='relative min-h-[200px] w-full flex-1'>
                            <RemoteImage
                                className='md:right-none absolute left-0 right-0 m-auto h-full max-w-[327px] object-contain md:left-1/2 md:m-0 md:max-w-none md:-translate-x-1/2'
                                imageMedia={imageMedia}
                            />
                        </div>
                        <DevicesPageButtons />
                    </MaxWidthContainer>
                </ContentWrapper>
            </div>
            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.DEVICES} />
        </HomePageLayout>
    );
};

export default NoDevicesView;
