import React from 'react';

import {AUTO_DELIVERY_PAYMENT_PENDING_TITLE} from '@/consts/localization/localizationKeys';
import {getLocalizedStrings} from '@/services/localization/localizationService';

import AutoDeliveryStatusTitle from '../AutoDeliveryStatusTitle';

const AutoDeliveryPaymentPendingTitle = ({className}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <AutoDeliveryStatusTitle
            className={className}
            title={localizedStrings[AUTO_DELIVERY_PAYMENT_PENDING_TITLE]}
            renderAs='h3'
        />
    );
};

export default AutoDeliveryPaymentPendingTitle;
