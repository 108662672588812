import axios from 'axios';

import {GET_TOKEN} from '@/consts/icc/iccMethods';
import log from '@/services/logger/log';
import {logRequestError} from '@/services/logger/logService';

const PENDING_REQUEST_TIMEOUT = 60000;

const get = (url, requestConfig) => requestApi('get', url, requestConfig);
const post = (url, requestConfig = {}) => requestApi('post', url, requestConfig);
const put = (url, requestConfig = {}) => requestApi('put', url, requestConfig);
const requestDelete = (url, requestConfig = {}) => requestApi('delete', url, requestConfig);

const requestApi = async (method, url, requestConfig) => {
    const isGetToken = url.includes(GET_TOKEN);
    let pendingTimeout;

    try {
        const controller = new AbortController();

        pendingTimeout = setTimeout(() => controller.abort(), PENDING_REQUEST_TIMEOUT);

        log.debug(`Request has started. Url: ${url}`);

        const response = await request({
            method,
            url,
            ...requestConfig,
            signal: controller.signal,
            timeout: PENDING_REQUEST_TIMEOUT,
        });

        clearTimeout(pendingTimeout);

        //icc token should be already exists to post logs
        if (!isGetToken) {
            log.info(`Request has successful response. Url: ${url}`);
        }
        return response;
    } catch (e) {
        if (pendingTimeout) {
            clearTimeout(pendingTimeout);
        }

        const LOG_LENGTH_LIMIT = 10 * 1024;
        const requestConfigCopy = {...requestConfig};

        if (requestConfig?.headers) {
            const trimRequestConfigHeader = (headerName) => {
                const header = requestConfig.headers?.[headerName]?.toString();

                if (header) {
                    const NUMBER_OF_VISIBLE_CHARS = 10;
                    const headerTrimmed = `${header.substring(0, NUMBER_OF_VISIBLE_CHARS)}*****${header.substring(
                        header.length - NUMBER_OF_VISIBLE_CHARS
                    )}`;

                    requestConfigCopy.headers[headerName] = headerTrimmed;
                }
            };

            trimRequestConfigHeader('Authorization');
            trimRequestConfigHeader('Token');
            trimRequestConfigHeader('id_token');
            trimRequestConfigHeader('X-API-Key');
        }

        if (isGetToken) {
            requestConfigCopy.data = undefined;
        }

        const logMessage = `requestApi: url: ${url}; requestConfig: ${JSON.stringify(
            requestConfigCopy
        )}, method: ${method}; error: ${e}; response data: ${JSON.stringify(e.response?.data)?.substring(
            0,
            LOG_LENGTH_LIMIT
        )}`;
        const statusCode = e?.response?.status;

        logRequestError(statusCode, logMessage);

        throw e;
    }
};

const request = (requestConfig) => axios.request(requestConfig);

export default {
    get,
    post,
    put,
    requestDelete,
};
