import React from 'react';

import TipsAndTutorials from '@/views/TipsAndTutorials/TipsAndTutorials';

const TipsAndTutorialsPage = () => (
    <div className='flex w-full flex-col'>
        <TipsAndTutorials />
    </div>
);

export default TipsAndTutorialsPage;
