import React from 'react';

import {SupportServiceIcon} from '@/components/Icons';
import {CONTACT_US_CALL_US_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import {getAriaLabel} from '@/services/accessibilityService';

import ContactUsCallSchedule from './ContactUsCallSchedule';
import ContactUsSection from './ContactUsSection';

const ContactUsCallUs = ({className, callUs}) => {
    const isMobile = useIsMobileLayout();
    const {phoneNumber, workingDays} = callUs;

    if (!phoneNumber) return null;

    return (
        <ContactUsSection className={className}>
            <ContactUsSection.Icon>
                <SupportServiceIcon />
            </ContactUsSection.Icon>
            <ContactUsSection.Title itemName='callUsTitle' />
            <ContactUsSection.Description itemName='callUsDesc' contentList={[phoneNumber]} />
            <ContactUsCallSchedule workingDays={workingDays} />
            {isMobile && (
                <ContactUsSection.Button
                    itemName='callUsButton'
                    to={`tel:${phoneNumber}`}
                    aria-label={getAriaLabel(CONTACT_US_CALL_US_BUTTON_ARIA_LABEL)}
                    primary
                    light
                    isExternalLink
                />
            )}
        </ContactUsSection>
    );
};

export default ContactUsCallUs;
