import iccService from '@/services/icc/iccService';
import appRouterService from '@/services/route/appRouterService';
import storageService from '@/services/storage/storageService';
import {logoutClearData} from '@/state/slices/globalSlice';
import {dispatch} from '@/state/store';

import authDataStoreService from './authDataStoreService';

const login = async ({password, username}) => {
    authDataStoreService.setCredentials({username, password});

    try {
        await iccService.fetchIccToken();
    } catch (e) {
        throw e;
    }

    authDataStoreService.clearCredentials();
};

const logout = () => {
    storageService.removeAuthDataFromStorage();
    dispatch(logoutClearData());

    appRouterService.forwardToLoginPage();
};

export default {
    logout,
    login,
};
