import cn from 'classnames';
import React from 'react';

import {AUTO_DELIVERY_ACTIVE_SUBTITLE} from '@/consts/localization/localizationKeys';
import {getLocalizedStrings} from '@/services/localization/localizationService';

const AutoDeliveryActiveSubtitle = ({className, minDeliveryDaysCount, maxDeliveryDaysCount}) => {
    const localizedStrings = getLocalizedStrings();
    const text = localizedStrings.formatString(
        localizedStrings[AUTO_DELIVERY_ACTIVE_SUBTITLE],
        minDeliveryDaysCount,
        maxDeliveryDaysCount
    );

    return <div className={cn('text-jumbo md:text-20 md:font-bold md:tracking-02', className)}>{text}</div>;
};

export default AutoDeliveryActiveSubtitle;
