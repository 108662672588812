import {useEffect} from 'react';

import analyticsEventValues from '@/consts/analytics/analyticsEventValues';
import {AUTO_DELIVERY_ON_HOLD_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useIsDesktopLayout from '@/hooks/layout/useIsDesktopLayout';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import amplitudeAutodeliveryTracking from '@/services/analytics/amplitude/amplitudeAutoDeliveryTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';

import StatusOnHoldDesktop from './StatusOnHoldDesktop';
import StatusOnHoldMobile from './StatusOnHoldMobile';

const StatusOnHold = () => {
    const isDesktopLayout = useIsDesktopLayout();
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[AUTO_DELIVERY_ON_HOLD_HEADER_TITLE]);

    useEffect(() => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryOrderScreen(analyticsEventValues.ON_HOLD);
    }, []);

    const amplitudeEvent = (trackingValue) => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryOrderButtonClick(trackingValue, analyticsEventValues.ON_HOLD);
    };

    return isDesktopLayout ? (
        <StatusOnHoldDesktop amplitudeEvent={amplitudeEvent} />
    ) : (
        <StatusOnHoldMobile amplitudeEvent={amplitudeEvent} />
    );
};

export default StatusOnHold;
