import {useGetAutoDeliveryDetailsExtended} from '@/api/consumerApi';
import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import {StickyPageLayout} from '@/components/Layout';
import {
    AUTO_DELIVERY_DELIVER_ASAP_BUTTON_ARIA_LABEL,
    AUTO_DELIVERY_HEADER_TITLE,
    AUTO_DELIVERY_SKIP_DELIVERY_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import {getAriaLabel} from '@/services/accessibilityService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import routerService from '@/services/route/routerService';
import AutoDeliveryActiveProgress from '@/views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveProgress';
import AutoDeliveryActiveSubtitle from '@/views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveSubtitle';
import AutoDeliveryActiveTitle from '@/views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveTitle';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import AutoDeliveryDetailsFooter from '../components/AutoDeliveryDetailsFooter';
import AutoDeliveryOrder from '../components/AutoDeliveryOrder';
import AutoDeliveryOrderAddress from '../components/AutoDeliveryOrderAddress';
import AutoDeliveryOrderHeader from '../components/AutoDeliveryOrderHeader';
import AutoDeliveryOrderSummary from '../components/AutoDeliveryOrderSummary';

const StatusActiveMobile = () => {
    const {
        data: {
            autoDelivery: {minDeliveryDaysCount, maxDeliveryDaysCount, nextDeliveryDate, maxNextDeliveryDate, status},
            order: {status: orderStatus, price, deliveryAddress, products},
        },
    } = useGetAutoDeliveryDetailsExtended();
    const localizedStrings = getLocalizedStrings();

    return (
        <StickyPageLayout
            contentClassName='flex-1 text-start'
            headerOptions={{
                onBack: routerService.goBack,
                title: localizedStrings[AUTO_DELIVERY_HEADER_TITLE],
            }}
            footer={
                <AutoDeliveryDetailsFooter>
                    <AppButton
                        primary
                        light
                        className='mt-0'
                        onClick={appRouterService.forwardToAutoDeliveryAsapPage}
                        aria-label={getAriaLabel(AUTO_DELIVERY_DELIVER_ASAP_BUTTON_ARIA_LABEL)}
                    >
                        <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='deliverAsapButton' />
                    </AppButton>
                    <AppButton
                        tertiary
                        className='self-center'
                        onClick={appRouterService.forwardToAutoDeliverySkipPage}
                        aria-label={getAriaLabel(AUTO_DELIVERY_SKIP_DELIVERY_BUTTON_ARIA_LABEL)}
                    >
                        <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='skipDeliveryButton' />
                    </AppButton>
                </AutoDeliveryDetailsFooter>
            }
            noScrollFooterClassName='border-t border-mischka pt-4'
            footerClassName='bg-romance border-t-[1px] border-french-gray pb-safe-or-4'
        >
            <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.AUTO_DELIVERY} />
            <div className='pt-safe-offset-sticky-header px-4 pb-4 md:px-8'>
                <AutoDeliveryActiveSubtitle
                    minDeliveryDaysCount={minDeliveryDaysCount}
                    maxDeliveryDaysCount={maxDeliveryDaysCount}
                    className='text-20 font-bold tracking-02'
                />
                <AutoDeliveryActiveTitle
                    nextDeliveryDate={nextDeliveryDate}
                    maxNextDeliveryDate={maxNextDeliveryDate}
                    className='mt-2 text-28'
                />
                <AutoDeliveryActiveProgress status={status} orderStatus={orderStatus} className='mt-8 text-16' />
            </div>
            <AutoDeliveryOrder className='border-t-8 border-secondary md:px-8' products={products}>
                <AutoDeliveryOrderHeader isEditAllowed />
            </AutoDeliveryOrder>
            <AutoDeliveryOrderSummary className='border-t-8 border-secondary md:px-8' price={price} />
            <AutoDeliveryOrderAddress
                className='border-t-8 border-secondary md:px-8'
                address={deliveryAddress}
                isEditAllowed
            />
            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.AUTO_DELIVERY} />
        </StickyPageLayout>
    );
};

export default StatusActiveMobile;
