import React, {useState} from 'react';

import {PopupFullPage} from '@/components/Popup';
import {HIGHLIGHTS_FULLSCREEN_POPUP_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import useScrollToPreviousPosition from '@/hooks/useScrollToPreviousPosition';
import {getAriaLabel} from '@/services/accessibilityService';
import {WARNING_BANNER_SOURCE_TYPES, WarningBannersTop} from '@/views/WarningBanners';

import HighlightsCarouselList from './components/HighlightsCarouselList';
import HighlightsHeader from './components/HighlightsHeader';
import useHighlightsHeaderText from './hooks/useHighlightsHeaderText';

export const FullscreenDesktopHighlights = ({carouselsFromConfig, isAutoPlayEnabled, onClose}) => {
    const [popupEl, setPopupEl] = useState();
    const {headerText, handleSlideChange} = useHighlightsHeaderText(carouselsFromConfig);

    useScrollToPreviousPosition();

    return (
        <PopupFullPage
            contentRef={setPopupEl}
            className='z-10 bg-primary pt-0-hw-offset'
            isCloseVisible
            onClose={onClose}
            closeButtonClass='text-white top-7-hw-offset right-13'
            aria-label={getAriaLabel(HIGHLIGHTS_FULLSCREEN_POPUP_ARIA_LABEL)}
        >
            <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.HIGHLIGHTS_FULLSCREEN} containerEl={popupEl} />
            <div className='flex h-full flex-col pt-8'>
                <HighlightsHeader title={headerText} />
                <div className='min-h-0 flex-1 py-8'>
                    <HighlightsCarouselList
                        carouselsFromConfig={carouselsFromConfig}
                        isAutoPlayEnabled={isAutoPlayEnabled}
                        onCarouselChange={handleSlideChange}
                        onSlideChange={handleSlideChange}
                    />
                </div>
            </div>
        </PopupFullPage>
    );
};

export default FullscreenDesktopHighlights;
