import ROUTE_PARAMS from './routeParams';

const DEVICES = '/devices';
const CONSUMER_PROFILE = '/profile';
const QUICK_START_GUIDE = '/quick-start-guide';
const TIPS_AND_TUTORIALS = '/tips-and-tutorials';
const INBOX_PAGE = '/inbox';

const ROUTE_PATHS = {
    ROOT: '/',
    INSTALL_PWA: '/install-pwa',
    LOGIN: '/login',
    TNC: '/tnc',
    TNC_DETAILS: '/tnc-details',
    HOME_PAGE: '/home',
    SHOP_PAGE: '/shop',
    DEVICES_PAGE: DEVICES,
    QUICK_START_GUIDE,
    QUICK_START_GUIDE_PARAMETERIZED: `${QUICK_START_GUIDE}/:${ROUTE_PARAMS.DEVICE_TYPE}?`,
    TIPS_AND_TUTORIALS,
    TIPS_AND_TUTORIALS_PARAMETERIZED: `${TIPS_AND_TUTORIALS}/:${ROUTE_PARAMS.DEVICE_TYPE}?`,
    INBOX_PAGE,
    INBOX_PAGE_PARAMETERIZED: `${INBOX_PAGE}/:${ROUTE_PARAMS.MESSAGE_ID}?`,
    ADVANCE_DIAGNOSTIC: '/ad',
    AUTO_DELIVERY: '/auto-delivery',
    AUTO_DELIVERY_SKIP: '/auto-delivery/skip',
    AUTO_DELIVERY_ASAP: '/auto-delivery/asap',
    ABOUT_DEVICE: `${DEVICES}/about`,
    DEVICE_RENAME: `${DEVICES}/rename`,
    AEM_FLEX_BATTERY_SETTING: `${DEVICES}/aem-flex-battery`,
    AUTO_START_SETTING: `${DEVICES}/auto-start`,
    FLEX_PUFF_SETTING: `${DEVICES}/flex-puff`,
    SMART_GESTURES_SETTING: `${DEVICES}/smart-gestures`,
    ILLUMINATION_MODE_SETTING: `${DEVICES}/illumination-mode`,
    VIBRATIONS_SETTING: `${DEVICES}/vibrations`,
    CLEAN_TUTORIAL: '/clean-tutorial',
    MODALS: '/modals',
    CONSUMER_PROFILE,
    CONTACT_US: `${CONSUMER_PROFILE}/contact-us`,
    PROFILE_TERMS_OF_USE: `${CONSUMER_PROFILE}/terms-of-use`,
    PROFILE_PREFERENCE_CENTER: `${CONSUMER_PROFILE}/preference-center`,
    PROFILE_LANGUAGE_SELECT: `${CONSUMER_PROFILE}/language-select`,
    SELECT_YOUR_DEVICE: '/select-device',
    USB_REQUIRED: '/usb-required',
    ERROR_PAGE: '/error',
    WILDCARD: '*',
};

export default ROUTE_PATHS;
