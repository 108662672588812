import {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

import useIsWelcomeMode from '@/hooks/useIsWelcomeMode';
import {setIsWelcomeAnimationFinished, setIsWelcomeAnimationStarted} from '@/state/slices/globalSlice';
import {twx} from '@/utils/tailwindUtils';

const useWelcomeTopBannerAnimation = () => {
    const bannerGreetingRef = useRef();
    const welcomeGreetingRef = useRef();

    const dispatch = useDispatch();
    const {isWelcomeMode, isAnimationStarted, isAnimationFinished} = useIsWelcomeMode();

    const [translation, setTranslation] = useState({x: 0, y: 0});

    useEffect(() => {
        if (isAnimationStarted) {
            const WELCOME_PAGE_ANIMATION_DURATION = 500;
            const timer = setTimeout(() => {
                dispatch(setIsWelcomeAnimationFinished(true));
            }, WELCOME_PAGE_ANIMATION_DURATION);

            return () => clearTimeout(timer);
        }
    }, [isAnimationStarted]);

    useEffect(() => {
        if (isWelcomeMode && bannerGreetingRef.current && welcomeGreetingRef.current) {
            const startRect = bannerGreetingRef.current.getBoundingClientRect();
            const endRect = welcomeGreetingRef.current.getBoundingClientRect();

            if (startRect && endRect) {
                setTranslation({
                    x: endRect.left - startRect.left,
                    y: endRect.top - startRect.top,
                });
            }
        }
    }, [bannerGreetingRef, welcomeGreetingRef, isWelcomeMode]);

    const startAnimation = () => {
        dispatch(setIsWelcomeAnimationStarted(true));
    };

    const animationClassNames = {
        bannerAnimationClassName: twx({
            'absolute z-[112] w-full overflow-x-clip overflow-y-visible bg-black transition-[height,width,transform] translate-y-[calc(var(--hw-h)*-1)] ml:-translate-x-[var(--sidebar-width)] duration-0 md:w-screen':
                isWelcomeMode,
            'h-screen md:h-screen lg:h-screen xl:h-screen 2xl:h-screen': isWelcomeMode && !isAnimationStarted,
            'md:w-full ml:translate-x-0 translate-y-0 relative duration-500': isAnimationStarted,
            'z-0': isAnimationFinished,
        }),
        greetingAnimationClassName: twx({
            'transform opacity-0 transition-[opacity,transform] duration-500': isWelcomeMode,
            'opacity-1 translate-x-0 translate-y-0': isAnimationStarted,
        }),
        greetingAnimationStyle: {
            '--tw-translate-x': `${translation.x}px`,
            '--tw-translate-y': `${translation.y}px`,
        },
        bannerContentAnimationClassName: twx({
            'opacity-0 transition-opacity duration-500': isWelcomeMode,
            'opacity-1': isAnimationStarted,
        }),
        loyaltyButtonsBarAnimationClassName: twx({
            'translate-y-[100%] transition-transform duration-500': isWelcomeMode,
            'translate-y-0': isAnimationStarted,
        }),
    };

    return {
        bannerGreetingRef,
        welcomeGreetingRef,
        isAnimationStarted,
        startAnimation,
        isAnimationFinished,
        translation,
        isWelcomeModeInProgress: isWelcomeMode && !isAnimationFinished,
        ...animationClassNames,
    };
};

export default useWelcomeTopBannerAnimation;
