import appConfig from '@/config/appConfig';
import analyticsEventProperties from '@/consts/analytics/analyticsEventProperties';
import analyticsEventValues from '@/consts/analytics/analyticsEventValues';
import deviceTypesService from '@/services/device/deviceTypesService';
import marketService from '@/services/marketService';
import userBrowserService from '@/services/user/userBrowserService';
import {selectIsGaAnonymizationEnabled} from '@/state/selectors/consumerAggregatedSettings';
import {selectDeviceSerialNumber, selectDeviceType} from '@/state/selectors/device';
import {getState} from '@/state/store';
import cryptoUtils from '@/utils/cryptoUtils';
import objUtils from '@/utils/objUtils';
import stringUtils from '@/utils/stringUtils';
import uaUtils from '@/utils/uaUtils';

export const toggleAnalytics = (isEnabled) => {
    const googleTagManagerId = appConfig.getGoogleTagManagerId();

    window[`ga-disable-${googleTagManagerId}`] = !isEnabled;
};

const createProperty = (key, value) => ({[key]: value});

const getProperties = () => {
    const getEvent = (eventName) => createProperty(analyticsEventProperties.EVENT, eventName);

    const getSource = () => {
        const source = userBrowserService.isInStandaloneMode()
            ? analyticsEventValues.PWA
            : analyticsEventValues.BROWSER;

        return createProperty(analyticsEventProperties.SOURCE, source);
    };

    const getMarket = () => {
        const market = marketService.getMarketCode()?.toUpperCase();

        return createProperty(analyticsEventProperties.MARKET, market);
    };

    const getInteractionType = (interactionType) =>
        createProperty(analyticsEventProperties.INTERACTION_TYPE, interactionType);

    const getTrackingValue = (trackingValue) => createProperty(analyticsEventProperties.TRACKING_VALUE, trackingValue);

    const getSuccess = (isSuccess) => createProperty(analyticsEventProperties.SUCCESS, isSuccess);

    const getDeviceSerialNumber = ({deviceSerialNumber: paramsSerialNumber, fromCurrent}) => {
        const currentSerialNumber = selectDeviceSerialNumber(getState());
        const serialNumber = fromCurrent ? currentSerialNumber : paramsSerialNumber;
        const anonymizedSerialNumber = anonymize(serialNumber);

        return createProperty(analyticsEventProperties.IQOS_PRODUCT_DEVICE_ID, anonymizedSerialNumber);
    };

    const getDeviceModel = ({deviceType: paramsDeviceType, fromCurrent}) => {
        const currentDeviceType = selectDeviceType(getState());
        const deviceType = fromCurrent ? currentDeviceType : paramsDeviceType;
        const deviceData = deviceTypesService.getDeviceTypesDataByType(deviceType);
        const deviceModel = deviceData?.modelName;

        return createProperty(analyticsEventProperties.IQOS_PRODUCT_MODEL_TYPE, deviceModel);
    };

    const getPushSource = (pushSource) => createProperty(analyticsEventProperties.PUSH_SOURCE, pushSource);

    const getCampaignName = (campaignName) => createProperty(analyticsEventProperties.CAMPAIGN_NAME, campaignName);

    const getLegal = (legalName) => createProperty(analyticsEventProperties.LEGAL, legalName);

    const getDeviceType = () => {
        const deviceType = uaUtils.getDeviceType();
        const capitalized = stringUtils.capitalize(deviceType);

        return createProperty(analyticsEventProperties.DEVICE_TYPE, capitalized);
    };

    const getQuestionnairePage = (questionnairePage) =>
        createProperty(analyticsEventProperties.QUESTIONNAIRE_PAGE_LOWERCASE, questionnairePage);

    return {
        event: getEvent,
        source: getSource,
        market: getMarket,
        interactionType: getInteractionType,
        trackingValue: getTrackingValue,
        success: getSuccess,
        deviceSerialNumber: getDeviceSerialNumber,
        deviceModel: getDeviceModel,
        pushSource: getPushSource,
        campaignName: getCampaignName,
        legal: getLegal,
        deviceType: getDeviceType,
        questionnairePage: getQuestionnairePage,
    };
};

export const properties = getProperties();

export const track = ({event: eventName, properties: eventPropertiesArray = []}) => {
    if (!eventName) return;

    const eventProperties = convertToObj(eventPropertiesArray);
    const event = {
        ...properties.event(eventName),
        ...eventProperties,
    };

    window.dataLayer?.push(event);
};

const convertToObj = (arrayOfObjects) => {
    const result = {};

    for (const obj of arrayOfObjects) {
        for (const [key, value] of Object.entries(obj)) {
            if (objUtils.isNullOrUndefined(value)) continue;

            result[key] = value;
        }
    }

    return result;
};

const anonymize = (value) => {
    const isAnonymizationEnabled = selectIsGaAnonymizationEnabled(getState());

    return isAnonymizationEnabled ? cryptoUtils.hashMD5(value) : value;
};
