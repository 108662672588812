import React, {useEffect} from 'react';

import StickyHeader from '@/components/Layout/StickyHeader';
import {CONTACT_US_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import amplitudeProfileTracking from '@/services/analytics/amplitude/amplitudeProfileTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import routerService from '@/services/route/routerService';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import ContactUsCallUs from './components/ContactUsCallUs';
import ContactUsComeVisitUs from './components/ContactUsComeVisitUs';
import ContactUsHeader from './components/ContactUsHeader';
import ContactUsSendQuestion from './components/ContactUsSendQuestion';
import ContactUsSocialChannels from './components/ContactUsSocialChannels';
import useGetContactUs from './hooks/useGetContactUs';

const ContactUsPage = () => {
    const isMobile = useIsMobileLayout();
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[CONTACT_US_PAGE_HEADER_TITLE]);

    useEffect(() => {
        window.scrollTo(0, 0);
        amplitudeProfileTracking.trackContactUsScreen();
        googleAnalyticsService.trackContactUsScreenView();
    }, []);

    const {imageMedia, socialChannels, email, nearestStoreAction, callUs} = useGetContactUs();

    return (
        <div className='pt-safe-offset-sticky-header ml:pt-16'>
            <WarningBannersTop
                source={WARNING_BANNER_SOURCE_TYPES.CONTACT_US}
                className='ml:left-[var(--profile-sidebar-width)]'
            />
            <div className='flex flex-col gap-8 pb-8 pt-0-hw-offset ml:px-16'>
                {isMobile && (
                    <StickyHeader
                        onBack={routerService.goBack}
                        title={localizedStrings[CONTACT_US_PAGE_HEADER_TITLE]}
                    />
                )}
                <ContactUsHeader imageMedia={imageMedia} />
                <div className='grid grid-cols-1 gap-16 px-4 ml:grid-cols-2 ml:gap-8 ml:px-0'>
                    <ContactUsSocialChannels className='ml:col-span-2' socialChannels={socialChannels} />
                    <ContactUsSendQuestion email={email} />
                    <ContactUsComeVisitUs nearestStoreAction={nearestStoreAction} />
                    <ContactUsCallUs className='ml:col-span-2' callUs={callUs} />
                </div>
            </div>
            <WarningBanners className='-mt-4' source={WARNING_BANNER_SOURCE_TYPES.CONTACT_US} />
        </div>
    );
};

export default ContactUsPage;
