import {useEffect, useState} from 'react';

import useIsDesktopLayout from '@/hooks/layout/useIsDesktopLayout';

const useGreetingPortal = (isHomePage) => {
    const isDesktop = useIsDesktopLayout();
    const [greetingPortal, setGreetingPortal] = useState(null);

    useEffect(() => {
        if (!isDesktop) return;

        const greetingPortal = isHomePage ? document.getElementById('greetingPortal') : null;

        setGreetingPortal(greetingPortal);
    }, [isDesktop]);

    return greetingPortal;
};

export default useGreetingPortal;
