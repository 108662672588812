import React from 'react';

import DeviceIcon from '@/components/DeviceIcon/DeviceIcon';
import {RenameBlueIcon} from '@/components/Icons';
import RadioButtonChildComponent from '@/containers/DeviceRenamePage/components/RadioButtonChildComponent';

export const DeviceRenameItems = (props) => {
    const {
        isDefaultMode,
        isSuccess,
        resetToDefault,
        defaultText,
        defaultTextAriaLabel,
        deviceName,
        deviceNameText,
        defaultProductName,
        setPersonalizedMode,
        personalisedText,
        personalizedTextAriaLabel,
        charactersText,
        pleaseAddName,
        nameIsTooLong,
        onChange,
        clearInput,
        inputRef,
        'aria-labelledby': ariaLabelledBy,
    } = props;

    const getButtonAriaLabel = (optionLabel) => `${deviceNameText}, ${optionLabel}`;
    const options = [
        {
            // eslint-disable-next-line react/display-name
            svgIcon: () => <DeviceIcon mode='rename' />,
            isActive: isDefaultMode,
            onClick: resetToDefault,
            text: defaultText,
            'aria-label': getButtonAriaLabel(defaultTextAriaLabel),
            description: deviceNameText,
            isDefaultMode: true,
            defaultDeviceName: defaultProductName,
        },
        {
            svgIcon: RenameBlueIcon,
            isActive: !isDefaultMode,
            onClick: setPersonalizedMode,
            text: personalisedText,
            'aria-label': getButtonAriaLabel(personalizedTextAriaLabel),
            description: deviceNameText,
            isDefaultMode: false,
            charactersText,
            pleaseAddName,
            nameIsTooLong,
            deviceName,
            isSuccess,
            onChange,
            clearInput,
            inputRef,
        },
    ];

    return (
        <div className='flex flex-col' role='radiogroup' aria-labelledby={ariaLabelledBy}>
            {options.map((option, i) => (
                <DeviceRenameRadioButtonItem {...option} key={i} />
            ))}
        </div>
    );
};

const DeviceRenameRadioButtonItem = (props) => {
    const {
        text,
        'aria-label': ariaLabel,
        defaultDeviceName,
        description,
        deviceName,
        isActive,
        isDefaultMode,
        isSuccess,
        onClick,
        onChange,
        clearInput,
        inputRef,
        svgIcon: SvgIcon,
        charactersText,
        pleaseAddName,
        nameIsTooLong,
    } = props;

    return (
        <>
            <div className='radio flex min-h-[80px] w-full items-center gap-3 border-t border-alto-second px-8 py-2 last:border-b ml:min-h-0 ml:gap-2 ml:px-0 ml:py-4 ml:first:border-t-0 ml:first:pt-0'>
                <div className='flex h-12 w-12 items-center justify-center rounded-full'>
                    <SvgIcon width='48' height='48' />
                </div>
                <div className='flex flex-1 flex-col overflow-hidden text-start' aria-hidden='true'>
                    {description && (
                        <p className='overflow-hidden text-ellipsis ml:text-14 ml:leading-143 ml:tracking-04'>
                            {description}
                        </p>
                    )}
                    <p className='overflow-hidden text-ellipsis font-bold ml:text-16 ml:leading-15'>{text}</p>
                </div>
                <input
                    type='radio'
                    checked={!!isActive}
                    tabIndex={isActive ? 0 : -1}
                    aria-label={ariaLabel}
                    onChange={onClick}
                />
                <span className='checkmark' />
            </div>
            {isActive && (
                <RadioButtonChildComponent
                    isDefaultMode={isDefaultMode}
                    deviceNameText={description}
                    defaultDeviceName={defaultDeviceName}
                    charactersText={charactersText}
                    pleaseAddName={pleaseAddName}
                    nameIsTooLong={nameIsTooLong}
                    deviceName={deviceName}
                    isSuccess={isSuccess}
                    onChange={onChange}
                    clearInput={clearInput}
                    ref={inputRef}
                />
            )}
        </>
    );
};
