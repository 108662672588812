import React, {useRef, useState} from 'react';

import AriaLiveContainer from '@/components/AriaLiveMessage/AriaLiveContainer';
import {Popover, PopoverAnchor} from '@/components/Popover/Popover';
import * as localizationKeys from '@/consts/localization/localizationKeys';
import domService from '@/services/domService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {twx} from '@/utils/tailwindUtils';

import InboxPopoverContent from './components/InboxPopoverContent';
import InboxPopoverList from './components/InboxPopoverList';
import InboxPopoverNotificationDetails from './components/InboxPopoverNotificationDetails';
import useOpenInboxDetails from './useOpenInboxDetails';

const InboxPopover = ({isOpen, setIsOpen, children}) => {
    const ref = useRef(null);
    const backButtonRef = useRef(null);
    const [messageId, setMessageId] = useState(null);
    const [isMessageOpened, setIsMessageOpened] = useState(false);
    const [lastFocusedMessageId, setLastFocusedMessageId] = useState(null);
    const localizedStrings = getLocalizedStrings();

    useOpenInboxDetails({setMessageId, setIsOpen});

    const onMessageClick = (messageId) => {
        setLastFocusedMessageId(messageId);
        setMessageId(messageId);
        setIsMessageOpened(true);
    };
    const onOpenChange = (isOpen) => {
        setIsOpen(isOpen);

        if (!isOpen) {
            closeNotificationDetails();
            setMessageId();
        }
    };
    const closeNotificationDetails = () => {
        setIsMessageOpened();
    };

    const handleAnimationEnd = () => {
        if (isMessageOpened && backButtonRef.current) {
            backButtonRef.current.focus();
        } else if (lastFocusedMessageId) {
            setMessageId();
            const notificationEl = domService.getElementById(lastFocusedMessageId);

            if (notificationEl) {
                notificationEl.focus();
            }
        }
    };

    return (
        <Popover open={isOpen} onOpenChange={onOpenChange}>
            <PopoverAnchor>{children}</PopoverAnchor>
            <InboxPopoverContent ref={ref}>
                {isOpen && (
                    <div
                        className={twx(
                            'transition-height relative h-[480px] overflow-hidden rounded-xl duration-300 ease-in-out',
                            isMessageOpened && 'h-[556px]'
                        )}
                    >
                        <AriaLiveContainer>
                            {
                                localizedStrings[
                                    isMessageOpened
                                        ? localizationKeys.INBOX_DETAILS_VIEW_ARIA_LIVE_MESSAGE
                                        : localizationKeys.INBOX_LIST_VIEW_ARIA_LIVE_MESSAGE
                                ]
                            }
                        </AriaLiveContainer>
                        <AnimatedContainer
                            className={isMessageOpened ? '-translate-x-full' : 'translate-x-0'}
                            ariaHidden={isMessageOpened}
                            onTransitionEnd={handleAnimationEnd}
                        >
                            <InboxPopoverList onMessageClick={onMessageClick} containerRef={ref} />
                        </AnimatedContainer>
                        <AnimatedContainer
                            className={isMessageOpened ? 'translate-x-0' : 'translate-x-full'}
                            ariaHidden={!isMessageOpened}
                        >
                            {(messageId || isMessageOpened) && (
                                <InboxPopoverNotificationDetails
                                    messageId={messageId}
                                    closeNotificationDetails={closeNotificationDetails}
                                    containerRef={ref}
                                    backButtonRef={backButtonRef}
                                />
                            )}
                        </AnimatedContainer>
                    </div>
                )}
            </InboxPopoverContent>
        </Popover>
    );
};

export default InboxPopover;

const AnimatedContainer = ({className, children, inert, ...rest}) => {
    return (
        <div
            className={twx(
                'absolute inset-0 flex flex-col overflow-auto pb-6 transition-transform duration-300 ease-in-out',
                className
            )}
            inert={domService.getInertFromBool(inert)}
            {...rest}
        >
            {children}
        </div>
    );
};
