import {useSelector} from 'react-redux';

import layoutService from '@/services/layoutService';
import {selectLayoutType} from '@/state/selectors/global';

const useLayout = () => {
    const layout = useSelector(selectLayoutType);

    const isXs = layoutService.isXsLayout(layout);
    const isSm = layoutService.isSmLayout(layout);
    const isMd = layoutService.isMdLayout(layout);
    const isMl = layoutService.isMlLayout(layout);
    const isLg = layoutService.isLgLayout(layout);
    const isXl = layoutService.isXlLayout(layout);
    const is2Xl = layoutService.is2XlLayout(layout);
    const isMobile = layoutService.isMobileLayout(layout);
    const isTabletAndDesktop = layoutService.isTabletAndDesktopLayout(layout);
    const isDesktop = layoutService.isDesktopLayout(layout);

    return {isXs, isSm, isMd, isMl, isLg, isXl, is2Xl, isMobile, isTabletAndDesktop, isDesktop};
};

export default useLayout;
