import amplitudeEventNames from '@/consts/analytics/analyticsEventNames';
import analyticsEventProperties from '@/consts/analytics/analyticsEventProperties';

import amplitudeDeviceTracking from './amplitudeDeviceTracking';

const trackADEntryPointScreen = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(amplitudeEventNames.DIAGNOSE_SCREEN_VIEWED);
};

const trackADInitialQuestionnaireScreen = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(amplitudeEventNames.AD_INITIAL_QUESTIONNAIRE_VIEWED);
};

const trackADNoneOfTheAboveClick = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(amplitudeEventNames.NONE_OF_THE_ABOVE_BUTTON_CLICKED);
};

const trackADQuestionnaireSelectClick = (questionnairePage) => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(amplitudeEventNames.QUESTIONNAIRE_SELECT_BUTTON_CLICKED, {
        [analyticsEventProperties.QUESTIONNAIRE_PAGE]: questionnairePage,
    });
};

const trackADQuestionnaireScroll = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(amplitudeEventNames.QUESTIONNAIRE_SCROLLED);
};

const trackADCleanMyDeviceClick = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(amplitudeEventNames.CLEAN_MY_DEVICE_BUTTON_CLICKED);
};

const trackADRemoveStickInstructionScroll = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(amplitudeEventNames.REMOVE_STICK_INSTRUCTIONS_SCROLLED);
};

export default {
    trackADEntryPointScreen,
    trackADInitialQuestionnaireScreen,
    trackADNoneOfTheAboveClick,
    trackADQuestionnaireSelectClick,
    trackADQuestionnaireScroll,
    trackADCleanMyDeviceClick,
    trackADRemoveStickInstructionScroll,
};
