import React from 'react';

import queryParameters from '@/consts/route/queryParameters';
import urlUtils from '@/utils/urlUtils';
import TipsAndTutorials from '@/views/TipsAndTutorials/TipsAndTutorials';

const CleaningGuidePage = () => {
    const [isAdvancedDiagnostic] = urlUtils.getDataFromUrl(queryParameters.IS_ADVANCED_DIAGNOSTIC);

    return (
        <div className='flex w-full flex-col'>
            <TipsAndTutorials isCleaningGuide isAdvancedDiagnostic={isAdvancedDiagnostic} />
        </div>
    );
};

export default CleaningGuidePage;
