import {makeSelectVisibleGlobalModal} from '@/state/selectors/modal';
import {hideModalAction, hideQuickOrderPopup, showQuickOrderPopup} from '@/state/slices/modalSlice';
import {dispatch, getState} from '@/state/store';

const hideModal = (modalType) => {
    const modal = makeSelectVisibleGlobalModal(modalType)(getState());

    if (modal) {
        dispatch(hideModalAction(modalType));
    }
};

const togglePopup = (isOpen, openAction, closeAction) => {
    if (isOpen) {
        dispatch(openAction());
    } else {
        dispatch(closeAction());
    }
};

const toggleQuickOrderPopup = (isOpen) => togglePopup(isOpen, showQuickOrderPopup, hideQuickOrderPopup);

export default {hideModal, toggleQuickOrderPopup};
