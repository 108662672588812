import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import iccMarketService from '@/services/icc/iccMarketService';
import {selectConsumerFetchDate} from '@/state/selectors/consumer';

const REFETCH_TIME_MS = 30 * 60 * 1000;

const useRefetchExternalConsumerProfile = () => {
    const fetchDate = useSelector(selectConsumerFetchDate);

    useEffect(() => {
        const isDataOutdated = !fetchDate || new Date() - fetchDate > REFETCH_TIME_MS;

        if (!isDataOutdated) return;

        iccMarketService.getExternalConsumerProfile();
    }, [fetchDate]);
};

export default useRefetchExternalConsumerProfile;
