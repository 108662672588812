import {useEffect} from 'react';

import uamClientService from '@/services/uam/uamClientService';
import helpers from '@/utils/helpers';

const useUamAssets = ({isDisabled, setLoading, setIsChargerError, setIsHolderError, codentify, holderCodentify}) => {
    useEffect(() => {
        if (isDisabled || !codentify) return;

        const getUamInfo = async () => {
            helpers.runFunction(setLoading, true);
            helpers.runFunction(setIsChargerError, false);

            const promises = [
                uamClientService.getUamInfo({
                    codentify,
                    onError: () => {
                        helpers.runFunction(setIsChargerError, true);
                    },
                }),
            ];

            if (holderCodentify) {
                helpers.runFunction(setIsHolderError, false);
                promises.push(
                    uamClientService.getUamInfo({
                        codentify: holderCodentify,
                        isHolder: true,
                        onError: () => {
                            helpers.runFunction(setIsHolderError, true);
                        },
                    })
                );
            } else {
                helpers.runFunction(setIsHolderError, true);
            }

            await Promise.all(promises);
            helpers.runFunction(setLoading, false);
        };

        getUamInfo();
    }, [codentify, holderCodentify]);
};

export default useUamAssets;
