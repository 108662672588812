import React from 'react';
import {useSelector} from 'react-redux';

import {ContactUsIcon, GlobeIcon, PreferenceCenterIcon} from '@/components/Icons';
import {SidebarPageLayout} from '@/components/Layout';
import {
    CONSUMER_PROFILE_ATTRIBUTION_NOTICE_BUTTON_ARIA_LABEL,
    CONSUMER_PROFILE_CONTACT_US_BUTTON_ARIA_LABEL,
    CONSUMER_PROFILE_LANGUAGE_BUTTON_ARIA_LABEL,
    CONSUMER_PROFILE_PREFERENCE_CENTER_BUTTON_ARIA_LABEL,
    CONSUMER_PROFILE_PRIVACY_NOTICE_BUTTON_ARIA_LABEL,
    CONSUMER_PROFILE_TERMS_OF_USE_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import {TERMS_OF_USE} from '@/consts/tnc/tncTypes';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import {
    selectIsAllMandatoryTermsApproved,
    selectIsAllNonMandatoryTermsReviewed,
} from '@/state/selectors/consumerAggregatedSettings';
import {twx} from '@/utils/tailwindUtils';

import ConsumerProfileHeader from './components/ConsumerProfileHeader';
import ConsumerProfileLogoutButton from './components/ConsumerProfileLogoutButton';
import {PROFILE_SIDEBAR_MENU_ITEMS} from './consts/profileSidebarMenu';
import useProfilePageSidebar from './hooks/useProfilePageSidebar';

const ProfilePageContainer = ({
    className,
    children,
    activeMenuItem,
    isTncLayout,
    onPreferenceCenterClick,
    onTermsOfUseClick,
}) => {
    const isMobile = useIsMobileLayout();
    const {
        userName,
        handlePreferenceCenterClick,
        handleLanguageSelectClick,
        handleContactUsClick,
        handleTermsOfUseClick,
        handlePrivacyNoticeClick,
        handleAttributionNoticeClick,
    } = useProfilePageSidebar({onPreferenceCenterClick, onTermsOfUseClick});
    const isAllMandatoryTermsApproved = useSelector(selectIsAllMandatoryTermsApproved);
    const isAllNonMandatoryTermsReviewed = useSelector(selectIsAllNonMandatoryTermsReviewed);
    const hasBackButton = isAllMandatoryTermsApproved && isAllNonMandatoryTermsReviewed;

    if (isMobile) {
        return <MobileContainer className={className}>{children}</MobileContainer>;
    }

    const prefCenter = {
        icon: <PreferenceCenterIcon className='shrink-0' />,
        itemName: 'preferenceCentreButton',
        onClick: handlePreferenceCenterClick,
        itemKey: PROFILE_SIDEBAR_MENU_ITEMS.PREFERENCE_CENTER,
        ariaLabelKey: CONSUMER_PROFILE_PREFERENCE_CENTER_BUTTON_ARIA_LABEL,
        disabled: !isAllMandatoryTermsApproved,
    };
    const accountSettingsItems = isTncLayout
        ? [prefCenter]
        : [
              prefCenter,
              {
                  icon: <GlobeIcon className='shrink-0' />,
                  itemName: 'languageButton',
                  ariaLabelKey: CONSUMER_PROFILE_LANGUAGE_BUTTON_ARIA_LABEL,
                  onClick: handleLanguageSelectClick,
                  itemKey: PROFILE_SIDEBAR_MENU_ITEMS.LANGUAGE_SELECT,
              },
          ];

    return (
        <SidebarPageLayout>
            <SidebarPageLayout.Sidebar hasBackButton={hasBackButton}>
                {!isTncLayout && <ConsumerProfileHeader userName={userName} />}
                <SidebarPageLayout.SidebarMenuSection
                    headerItemName='accountSettingsHeader'
                    items={accountSettingsItems}
                    activeItemKey={activeMenuItem}
                />
                {!isTncLayout && (
                    <SidebarPageLayout.SidebarMenuSection
                        headerItemName='supportHeader'
                        items={[
                            {
                                icon: <ContactUsIcon className='shrink-0' />,
                                itemName: 'contactUsButton',
                                ariaLabelKey: CONSUMER_PROFILE_CONTACT_US_BUTTON_ARIA_LABEL,
                                onClick: handleContactUsClick,
                                itemKey: PROFILE_SIDEBAR_MENU_ITEMS.CONTACT_US,
                            },
                        ]}
                        activeItemKey={activeMenuItem}
                    />
                )}
                <SidebarPageLayout.SidebarMenuSection
                    headerItemName='legalHeader'
                    items={[
                        {
                            itemName: 'termsOfUseButton',
                            ariaLabelKey: CONSUMER_PROFILE_TERMS_OF_USE_BUTTON_ARIA_LABEL,
                            onClick: handleTermsOfUseClick,
                            itemKey: TERMS_OF_USE,
                        },
                        {
                            itemName: 'privacyNoticeButton',
                            ariaLabelKey: CONSUMER_PROFILE_PRIVACY_NOTICE_BUTTON_ARIA_LABEL,
                            onClick: handlePrivacyNoticeClick,
                        },
                        {
                            itemName: 'attributionNoticeButton',
                            ariaLabelKey: CONSUMER_PROFILE_ATTRIBUTION_NOTICE_BUTTON_ARIA_LABEL,
                            onClick: handleAttributionNoticeClick,
                        },
                    ]}
                    activeItemKey={activeMenuItem}
                    hasShorterGapBetweenItems
                />
                {!isTncLayout && (
                    <LogoutButtonContainer>
                        <ConsumerProfileLogoutButton className='tracking-05' />
                    </LogoutButtonContainer>
                )}
            </SidebarPageLayout.Sidebar>
            <SidebarPageLayout.Content className={className}>{children}</SidebarPageLayout.Content>
        </SidebarPageLayout>
    );
};

const MobileContainer = ({children, className}) => {
    return <div className={twx('flex w-full flex-col', className)}>{children}</div>;
};

const LogoutButtonContainer = ({children}) => {
    return <div className='mt-8 px-4'>{children}</div>;
};

export default ProfilePageContainer;
