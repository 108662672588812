import React from 'react';
import {useSelector} from 'react-redux';

import {MODES} from '@/components/DeviceIcon/DeviceIcon';
import {MyDevicesIcon, RenameBlueIcon} from '@/components/Icons';
import {
    MY_DEVICES_PAGE_ABOUT_DEVICE_BUTTON_ARIA_LABEL,
    MY_DEVICES_PAGE_DIAGNOSE_ISSUE_BUTTON_ARIA_LABEL,
    MY_DEVICES_PAGE_RENAME_DEVICE_BUTTON_ARIA_LABEL,
    MY_DEVICES_PAGE_TIPS_AND_TUTORIALS_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import advanceDiagnosticService from '@/containers/AdvancedDiagnosticPage/advanceDiagnosticService';
import DeviceSettings from '@/containers/DevicesPage/components/MyDevicesView/components/DeviceSettings/DeviceSettings';
import useOnRenameClick from '@/containers/DevicesPage/components/MyDevicesView/hooks/useOnRenameClick';
import useLayout from '@/hooks/layout/useLayout';
import amplitudeDevicesPageTracking from '@/services/analytics/amplitude/amplitudeDevicesPageTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import appRouterService from '@/services/route/appRouterService';
import {selectCurrentProductInfo} from '@/state/selectors/device';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners} from '@/views/WarningBanners';

import DevicesSettingsSection from '../../DevicesSettingsSection/DevicesSettingsSection';
import MyDevicesConnectButton from './MyDevicesConnectButton';

const MyDevicesManageDevice = () => {
    const {isMobile, isMl} = useLayout();
    const device = useSelector(selectCurrentProductInfo);
    const onRenameClick = useOnRenameClick();

    const {type, deviceSerialNumber} = device || {};
    const isDiagnosticEnabled = advanceDiagnosticService.isEnabled(type);

    const onAboutClick = () => {
        appRouterService.forwardToAboutDevicePage();
    };

    const onAdClick = () => {
        appRouterService.forwardToADPage();
    };

    const onTipsAndTutorialsClick = () => {
        amplitudeDevicesPageTracking.trackTipsAndTutorialsScreen();
        googleAnalyticsService.trackTipsAndTutorialsScreenView(type, deviceSerialNumber);
        appRouterService.forwardToTipsAndTutorialsList();
    };

    return (
        <>
            {(isMobile || isMl) && (
                <DevicesSettingsSection.Group className='ml:mt-8'>
                    <DevicesSettingsSection.Buttons>
                        <MyDevicesConnectButton device={device} />
                    </DevicesSettingsSection.Buttons>
                </DevicesSettingsSection.Group>
            )}
            <DeviceSettings />
            <DevicesSettingsSection.Group className='mt-4 ml:mt-8'>
                <DevicesSettingsSection.Title itemName='manageYourIqos' />
                <DevicesSettingsSection.Buttons>
                    {isMobile && (
                        <DevicesSettingsSection.Button
                            itemName='renamemydevice'
                            ariaLabelKey={MY_DEVICES_PAGE_RENAME_DEVICE_BUTTON_ARIA_LABEL}
                            icon={RenameBlueIcon}
                            onClick={onRenameClick}
                            hasSeparator
                        />
                    )}
                    <DevicesSettingsSection.Button
                        itemName='aboutmydevice'
                        ariaLabelKey={MY_DEVICES_PAGE_ABOUT_DEVICE_BUTTON_ARIA_LABEL}
                        mode={MODES.ABOUT}
                        onClick={onAboutClick}
                        hasSeparator
                    />
                    {isDiagnosticEnabled && (
                        <DevicesSettingsSection.Button
                            itemName='diagnoseissue'
                            ariaLabelKey={MY_DEVICES_PAGE_DIAGNOSE_ISSUE_BUTTON_ARIA_LABEL}
                            mode={MODES.DIAGNOSTICS}
                            onClick={onAdClick}
                            hasSeparator
                        />
                    )}
                    <DevicesSettingsSection.Button
                        itemName='tipsandtutorials'
                        ariaLabelKey={MY_DEVICES_PAGE_TIPS_AND_TUTORIALS_BUTTON_ARIA_LABEL}
                        icon={MyDevicesIcon}
                        onClick={onTipsAndTutorialsClick}
                    />
                </DevicesSettingsSection.Buttons>
            </DevicesSettingsSection.Group>
            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.DEVICES} />
        </>
    );
};

export default MyDevicesManageDevice;
