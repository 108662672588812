import React, {forwardRef} from 'react';

import {ArrowSmallIcon} from '@/components/Icons';
import {twx} from '@/utils/tailwindUtils';

const SwiperNavigationButton = forwardRef(({disabled, className, 'aria-label': ariaLabel}, ref) => {
    return (
        <button
            ref={ref}
            disabled={disabled}
            className={twx(
                'flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border border-black text-black',
                {
                    'cursor-default border-black/40 text-black/40': disabled,
                },
                className
            )}
            aria-label={ariaLabel}
        >
            <ArrowSmallIcon className='shrink-0' />
        </button>
    );
});

export default SwiperNavigationButton;
