import cn from 'classnames';
import React from 'react';

import LoaderCentered from '@/components/Loader/AppLoader/LoaderCentered';

import styles from '../Loader/Loader.module.scss';

const Loader = ({imgClassName, isLoader}) => (
    <div className={cn(styles.LoaderWrapper, imgClassName)}>{isLoader && <LoaderCentered />}</div>
);

export default Loader;
