import UAParser from 'ua-parser-js';

const getBrowserName = () => {
    const uaParser = new UAParser();

    return uaParser.getBrowser().name;
};

const getDeviceType = () => {
    const uaParser = new UAParser();
    const deviceType = uaParser.getDevice().type;

    // ua-parser-js can only detect non-desktop devices.
    // For desktops (and probably any unknown device), the device type is set to undefined.
    if (!deviceType) return 'desktop';

    return deviceType;
};

const getOS = () => {
    const uaParser = new UAParser();

    return uaParser.getOS();
};

const getOSShortName = () => {
    const {name} = getOS();

    return name?.toLowerCase().substr(0, 3);
};

const getIsIos = () => {
    const {name} = getOS();

    return name?.toLowerCase() === 'ios';
};

const isMobile = () => {
    const deviceType = getDeviceType();

    return deviceType === 'mobile';
};

export default {
    getBrowserName,
    getDeviceType,
    getOS,
    getOSShortName,
    getIsIos,
    isMobile,
};
