import cn from 'classnames';
import React, {forwardRef, useEffect, useState} from 'react';

import {INPUT_CLEAR_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import {getAriaLabel} from '@/services/accessibilityService';

import {ButtonRaw} from '../../../components/Button';
import {ErrorIcon, SuccessIcon} from '../../../components/Icons';
import RoundedCancelIcon from '../../../components/Icons/RoundedCancelIcon';
import Input from '../../../components/InputNBW/InputNBW';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {twx} from '../../../utils/tailwindUtils';

const MAX_DEVICE_NAME_CHARACTERS = 20;
const MIN_DEVICE_NAME_CHARACTERS = 3;

// eslint-disable-next-line react/display-name
const InputComponent = forwardRef((props, ref) => {
    const {id, deviceName, onChange, clearInput, isSuccess, charactersText, pleaseAddName, nameIsTooLong} = props;
    const localizedStrings = getLocalizedStrings();
    const [isInputError, setIsInputError] = useState({blankInputError: false, maxInputCharacterError: false});
    const [inputValidationMessage, setIsInputValidationMessage] = useState('');

    const inputCheck = (inputValue) => {
        const characterCount = inputValue.length || deviceName.length;

        if (inputValue === '') {
            setIsInputError(true);
            setIsInputValidationMessage(pleaseAddName);
        } else if (characterCount > MAX_DEVICE_NAME_CHARACTERS) {
            setIsInputError(true);
            setIsInputValidationMessage(nameIsTooLong);
        } else {
            setIsInputValidationMessage(
                localizedStrings.formatString(charactersText, characterCount, MAX_DEVICE_NAME_CHARACTERS)
            );
            setIsInputError(false);
        }
    };

    useEffect(() => {
        inputCheck(deviceName);
    }, [deviceName, charactersText, pleaseAddName, nameIsTooLong]);

    const iconClassName =
        'w-6 h-6 z-[11] top-1/2 -translate-y-1/2 absolute right-3 rtl:left-3 rtl:right-auto m-0 p-0' +
        'md:rtl:right-auto';

    return (
        <>
            <div className='relative mb-2 w-full ml:mb-1'>
                <Input
                    id={id}
                    className={twx(
                        'bg-romance pr-10 ml:border-2 ml:bg-secondary ml:focus:border-primary rtl:pl-10 rtl:pr-3',
                        {
                            'border-2 border-solid border-error ml:focus:border-error': isInputError,
                        },
                        {'bg-cararra': isSuccess}
                    )}
                    maxLength={MAX_DEVICE_NAME_CHARACTERS}
                    minLength={MIN_DEVICE_NAME_CHARACTERS}
                    onChange={onChange}
                    type='text'
                    value={deviceName}
                    ref={ref}
                />
                {!isInputError && !isSuccess && (
                    <ButtonRaw
                        onClick={clearInput}
                        className={iconClassName}
                        aria-label={getAriaLabel(INPUT_CLEAR_BUTTON_ARIA_LABEL)}
                    >
                        <RoundedCancelIcon className='h-full w-full cursor-pointer' />
                    </ButtonRaw>
                )}
                {isInputError && <ErrorIcon className={iconClassName} />}
                {isSuccess && <SuccessIcon className={iconClassName} />}
            </div>
            <p
                className={cn('text-12 ml:text-14 ml:leading-[24px]', {'text-error': isInputError})}
                role={isInputError ? 'alert' : undefined}
            >
                {inputValidationMessage}
            </p>
        </>
    );
});

export default InputComponent;
