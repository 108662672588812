import CloseButton from '@/components/CloseButton/CloseButton';
import {StickyPageLayout} from '@/components/Layout';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import AutoDeliveryDetailsFooter from '../components/AutoDeliveryDetailsFooter';
import AutoDeliveryOrder from '../components/AutoDeliveryOrder';
import AutoDeliveryOrderAddress from '../components/AutoDeliveryOrderAddress';
import AutoDeliveryOrderHeader from '../components/AutoDeliveryOrderHeader';
import AutoDeliveryOrderSummary from '../components/AutoDeliveryOrderSummary';
import ConfirmAsapButton from './ConfirmAsapButton';
import ConfirmSkipButton from './ConfirmSkipButton';
import DeliveryAsapSection from './DeliveryAsapSection';
import DeliverySkipSection from './DeliverySkipSection';

const EditDeliveryMobile = ({isAsap, isSkip, data, onClose, isShowSkipButton, title}) => {
    const {
        order: {price, deliveryAddress, products},
    } = data;

    return (
        <StickyPageLayout
            contentClassName='flex-1 text-start'
            headerOptions={{
                endIcon: <CloseButton onClick={onClose} />,
                title,
            }}
            footer={
                <AutoDeliveryDetailsFooter>
                    {isAsap && <ConfirmAsapButton closePopup={onClose} />}
                    {isShowSkipButton && <ConfirmSkipButton closePopup={onClose} />}
                </AutoDeliveryDetailsFooter>
            }
            noScrollFooterClassName='border-t border-mischka pt-4'
            footerClassName='bg-romance border-t-[1px] border-french-gray'
        >
            <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.AUTO_DELIVERY} />
            <div className='pt-safe-offset-sticky-header px-4 pb-4'>
                {isAsap && <DeliveryAsapSection />}
                {isSkip && <DeliverySkipSection />}
                <AutoDeliveryOrder className='border-t border-mischka px-0' products={products}>
                    <AutoDeliveryOrderHeader isEditAllowed renderAs={isAsap || isSkip ? 'h2' : 'h1'} />
                </AutoDeliveryOrder>
                <AutoDeliveryOrderSummary className='border-t border-mischka px-0' price={price} />
                <AutoDeliveryOrderAddress
                    className='border-t border-mischka px-0'
                    address={deliveryAddress}
                    isEditAllowed
                />
            </div>
            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.AUTO_DELIVERY} />
        </StickyPageLayout>
    );
};

export default EditDeliveryMobile;
