const LayoutTypes = {
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    ML: 'ml',
    LG: 'lg',
    XL: 'xl',
    XXL: '2xl',
};

export default LayoutTypes;
