import React from 'react';
import {useLocation} from 'react-router-dom';

import {SELECT_YOUR_DEVICE_PAGE_HEADER_TITLE, SHOP_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import ROUTE_PATHS from '@/consts/route/routePaths';
import HomePageTopNavigation from '@/containers/HomePageTopNavigation/HomePageTopNavigation';
import SelectYourDeviceContent from '@/containers/SelectYourDevicePage/components/SelectYourDeviceContent';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {getDefaultDeviceData} from '@/services/device/deviceData';
import {HOME_PAGE_NAVIGATION_SOURCE_TYPES} from '@/services/homePageNavigationService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import routerService from '@/services/route/routerService';
import arrayUtils from '@/utils/arrayUtils';
import HomePageLayout from '@/views/Home/HomePageLayout';

const SelectYourDevicePage = () => {
    const isMobileLayout = useIsMobileLayout();
    const location = useLocation();
    const {onBackRoute} = location.state || {};
    const localizedStrings = getLocalizedStrings();
    const selectDeviceData = getDefaultDeviceData();
    const supportedDeviceData = arrayUtils.sortArrayWithOrderField(
        selectDeviceData.filter((device) => device.isSupported)
    );
    const onBack =
        onBackRoute === ROUTE_PATHS.DEVICES_PAGE ? appRouterService.forwardToDevicesPage : routerService.goBack;

    useDocumentTitle(localizedStrings[SELECT_YOUR_DEVICE_PAGE_HEADER_TITLE]);

    return isMobileLayout ? (
        <SelectYourDeviceContent supportedDeviceData={supportedDeviceData} onBack={onBack} />
    ) : (
        <HomePageLayout className='bg-secondary' activePath={ROUTE_PATHS.DEVICES_PAGE}>
            <HomePageTopNavigation
                title={localizedStrings[SHOP_PAGE_HEADER_TITLE]}
                source={HOME_PAGE_NAVIGATION_SOURCE_TYPES.SHOP}
                profileControlsClassName='bg-romance'
            />
            <SelectYourDeviceContent supportedDeviceData={supportedDeviceData} onBack={onBack} />
        </HomePageLayout>
    );
};

export default SelectYourDevicePage;
