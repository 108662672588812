import React, {useId} from 'react';

import {AppButton} from '@/components/Button';
import RemoteIcon from '@/components/Icon/RemoteIcon';
import PopupSticked from '@/components/Popup/PopupSticked';
import appConfig from '@/config/appConfig';
import * as localizationKeys from '@/consts/localization/localizationKeys';
import {MAINTENANCE_POPUP_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import {getAriaLabel} from '@/services/accessibilityService';
import {getLocalizedStrings} from '@/services/localization/localizationService';

const MaintenancePopup = () => {
    const headerId = useId();
    const localizedStrings = getLocalizedStrings();
    const headerIcon = appConfig.getMaintenancePopupIcon();
    const {titleKey, descriptionKey} = headerIcon;

    const onClick = () => {
        window.location.reload();
    };

    return (
        <PopupSticked className='flex flex-col px-4 py-8 md:max-w-[458px] md:px-12 md:pt-16' aria-labelledby={headerId}>
            <div className='mx-auto h-12 w-12'>
                <RemoteIcon icon={headerIcon} />
            </div>
            <h1 id={headerId} className='mb-0 mt-4 text-24'>
                {localizedStrings[titleKey]}
            </h1>
            <p className='mt-4 text-start'>{localizedStrings[descriptionKey]}</p>
            <AppButton
                primary
                light
                className='mt-8 md:mt-16'
                aria-label={getAriaLabel(MAINTENANCE_POPUP_BUTTON_ARIA_LABEL)}
                onClick={onClick}
            >
                {localizedStrings[localizationKeys.MAINTENANCE_POPUP_BUTTON]}
            </AppButton>
        </PopupSticked>
    );
};

export default MaintenancePopup;
