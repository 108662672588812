import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import routePaths from '@/consts/route/routePaths';
import {getAriaLabel} from '@/services/accessibilityService';
import tncService from '@/services/tncService';
import {hideLoader, showLoader} from '@/state/slices/loadersSlice';
import {dispatch} from '@/state/store';
import {twx} from '@/utils/tailwindUtils';

const SaveContinueButton = ({
    className,
    legals,
    onApproved,
    legalsSettingsNotNull,
    acceptedSettingList,
    itemName,
    ariaLabelKey,
    isSecondary,
}) => {
    const onSaveButtonClick = async () => {
        dispatch(showLoader());

        await tncService.setPreferenceCenterSettings(legals, legalsSettingsNotNull, acceptedSettingList, onApproved);

        dispatch(hideLoader());
    };

    return (
        <AppButton
            className={twx('mt-0', className)}
            light
            primary={!isSecondary}
            secondary={isSecondary}
            onClick={onSaveButtonClick}
            aria-label={getAriaLabel(ariaLabelKey)}
        >
            <AemEditableText pageName={routePaths.TNC} itemName={itemName} />
        </AppButton>
    );
};

export default SaveContinueButton;
