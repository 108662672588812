import React, {useState} from 'react';

import {AppButton} from '@/components/Button';
import {RegularCleaningIcon} from '@/components/Icons';
import {StickyHeader} from '@/components/Layout';
import appConfig from '@/config/appConfig';
import * as advanceDiagnosticScreenTypes from '@/consts/advanceDiagnostic/advanceDiagnosticScreenTypes';
import {DEVICE_TYPES} from '@/consts/device/deviceTypes';
import {AD_FITTING_CONSUMABLES_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {getAriaLabel} from '@/services/accessibilityService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import routerService from '@/services/route/routerService';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners} from '@/views/WarningBanners';

import advanceDiagnosticService from '../advanceDiagnosticService';
import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';

const AdvancedDiagnosticFittingConsumables = () => {
    const isMobile = useIsMobileLayout();
    const localizedStrings = getLocalizedStrings();
    const diagnosticData = appConfig.getAdConfig(DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER, 'step3');
    const dataArrayCount = diagnosticData.length;
    const [dataIndex, setDataIndex] = useState(0);
    const isLastStep = dataArrayCount - 1 === dataIndex;
    const {
        title,
        description,
        acceptButtonText,
        acceptButtonAriaLabelKey,
        declineButtonText,
        declineButtonAriaLabelKey,
        errorCode,
        isHolder,
    } = diagnosticData[dataIndex] || {};

    const onBackButtonClick = () => {
        if (dataIndex > 0) {
            setDataIndex(dataIndex - 1);
        } else {
            routerService.goBack();
        }
    };

    const declineClick = () => {
        const screenName = advanceDiagnosticScreenTypes.QUESTIONNAIRE_MARKET_SCREEN;

        advanceDiagnosticService.setInferenceCodeAndRedirect(errorCode, isHolder, screenName, true);
    };

    const onNextClick = () => {
        if (isLastStep) {
            appRouterService.forwardToFittingConsumablesResolvedAD();
        } else {
            setDataIndex(dataIndex + 1);
        }
    };

    useDocumentTitle(localizedStrings[AD_FITTING_CONSUMABLES_HEADER_TITLE]);

    return (
        <div className='flex w-full flex-col'>
            <AdvancedDiagnosticContainer>
                {isMobile && (
                    <StickyHeader
                        onBack={onBackButtonClick}
                        title={localizedStrings[AD_FITTING_CONSUMABLES_HEADER_TITLE]}
                    />
                )}
                <RegularCleaningIcon className='mb-4 h-16 w-16' />
                <h1 className='mt-4 text-28 md:mt-2 ml:mt-3 ml:text-32'>{localizedStrings[title]}</h1>
                <p>
                    <span className='whitespace-pre-line'>{localizedStrings[description]}</span>
                </p>
                <div className='mt-10 w-full md:w-auto'>
                    <AppButton
                        secondary
                        light
                        className='mb-3 mt-0 md:min-w-[346px]'
                        onClick={onNextClick}
                        aria-label={getAriaLabel(acceptButtonAriaLabelKey)}
                    >
                        {localizedStrings[acceptButtonText]}
                    </AppButton>
                    <AppButton
                        secondary
                        light
                        className='mb-3 mt-0 md:min-w-[346px]'
                        onClick={declineClick}
                        aria-label={getAriaLabel(declineButtonAriaLabelKey)}
                    >
                        {localizedStrings[declineButtonText]}
                    </AppButton>
                </div>
            </AdvancedDiagnosticContainer>{' '}
            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.AD} />
        </div>
    );
};

export default AdvancedDiagnosticFittingConsumables;
