import React from 'react';

import {twx} from '@/utils/tailwindUtils';

import PopupRaw from '../PopupRaw';
import styles from './PopupSideBar.module.scss';

const PopupSideBar = ({children, className, overlayClassName, ...rest}) => {
    return (
        <PopupRaw
            className={twx(
                'left-auto right-0 top-0 flex h-full flex-col rounded-l-3xl p-0 ml:h-[calc(100%-var(--hw-h))] ml:top-0-hw-offset',
                className
            )}
            overlayClassName={twx(styles.PopupSideBar, overlayClassName)}
            closeTimeoutMS={300}
            {...rest}
        >
            {children}
        </PopupRaw>
    );
};

export default PopupSideBar;
