import {React, useEffect} from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {ClockIcon} from '@/components/Icons';
import InfoBox from '@/components/InfoBox/InfoBox';
import appConfig from '@/config/appConfig';
import {AUTO_DELIVERY_SKIP_CANT_BE_SKIPPER_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import amplitudeAutoDeliveryTracking from '@/services/analytics/amplitude/amplitudeAutoDeliveryTracking';
import AutoDeliveryButton from '@/views/AutoDelivery/AutoDeliveryButton';
import AutoDeliveryStatusTitle from '@/views/AutoDelivery/AutoDeliveryStatusTitle';

import useDeliveryDate from '../hooks/useDeliveryDate';
import useOrderSummaryTracking from '../hooks/useOrderSummaryTracking';
import EditDeliveryPopupTopSection from './EditDeliveryPopupTopSection';

const DeliverySkipSection = () => {
    const externalLinkConfig = appConfig.getAutoDeliverySkipGoToWebsiteExternalLink();
    const {skipDateShort, isSkipDeliveryAvailable} = useDeliveryDate();
    const orderSummaryTrackingValue = useOrderSummaryTracking();

    useEffect(() => {
        if (!isSkipDeliveryAvailable) {
            amplitudeAutoDeliveryTracking.trackAutoDeliverySkipDeliveryNotAvailable(orderSummaryTrackingValue);
        }
    }, [isSkipDeliveryAvailable]);

    return isSkipDeliveryAvailable ? (
        <EditDeliveryPopupTopSection
            header={<AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='skipDeliveryConfirmHeaderTitle' />}
            title={<AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='skipDeliveryConfirmTitle' />}
            subtitle={
                <AemEditableText
                    pageName={routePaths.AUTO_DELIVERY}
                    itemName='skipDeliveryConfirmSubTitle'
                    contentList={[skipDateShort]}
                />
            }
            infoText={<AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='skipDeliveryConfirmDescription' />}
            icon={<ClockIcon />}
        />
    ) : (
        <div className='pb-8 md:pb-0'>
            <AutoDeliveryStatusTitle
                title={
                    <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='skipDeliveryConfirmHeaderTitle' />
                }
                className='text-28'
            />
            <InfoBox className='mt-8 tracking-02 md:bg-romance'>
                <b className='md:text-18'>
                    <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='skipDeliveryCantBeSkippedTitle' />
                </b>
                <div className='mt-2'>
                    <AemEditableText
                        pageName={routePaths.AUTO_DELIVERY}
                        itemName='skipDeliveryCantBeSkippedDescription'
                    />
                </div>
                <AutoDeliveryButton
                    sandy
                    className='mt-4 md:w-full'
                    text={
                        <AemEditableText
                            pageName={routePaths.AUTO_DELIVERY}
                            itemName='skipDeliveryCantBeSkippedButton'
                        />
                    }
                    externalLinkConfig={externalLinkConfig}
                    ariaLabelKey={AUTO_DELIVERY_SKIP_CANT_BE_SKIPPER_BUTTON_ARIA_LABEL}
                />
            </InfoBox>
        </div>
    );
};

export default DeliverySkipSection;
