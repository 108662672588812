import amplitudeEventNames from '@/consts/analytics/analyticsEventNames';
import analyticsEventProperties from '@/consts/analytics/analyticsEventProperties';
import analyticsEventValues from '@/consts/analytics/analyticsEventValues';
import gaEventNames from '@/consts/analytics/gaEventNames';

import {properties, toggleAnalytics, track} from './googleAnalytics';

const trackEnableNotificationsScreenView = () => {
    track({
        event: gaEventNames.NOTIFICATION_RECEIVED,
        properties: [properties.interactionType(analyticsEventValues.SCREEN_VIEWED)],
    });
};

const trackEnableNotificationsNextButtonClick = () => {
    track({
        event: gaEventNames.NOTIFICATION_RECEIVED,
        properties: [properties.interactionType(analyticsEventValues.NEXT_BUTTON_CLICKED)],
    });
};

const trackAllowPushNotifications = () => {
    track({
        event: gaEventNames.NOTIFICATION_RECEIVED,
        properties: [properties.interactionType(analyticsEventValues.NOTIFICATION_ALLOWED), properties.success(true)],
    });
};

const trackDisallowPushNotifications = () => {
    track({
        event: gaEventNames.NOTIFICATION_RECEIVED,
        properties: [
            properties.interactionType(analyticsEventValues.NOTIFICATION_DISALLOWED),
            properties.success(false),
        ],
    });
};

const trackHomeScreenView = () => {
    track({
        event: gaEventNames.HOME_SCREEN_VIEWED,
    });
};

const trackHomeTopBannerClick = (trackingValue) => {
    if (!trackingValue) return;

    track({
        event: gaEventNames.TOP_SECTION,
        properties: [
            properties.interactionType(amplitudeEventNames.HOME_PAGE_TOP_BANNER_CLICKED),
            properties.trackingValue(trackingValue),
        ],
    });
};

const trackLoyaltyButtonClick = (trackingValue) => {
    if (!trackingValue) return;

    track({
        event: gaEventNames.LOYALTY_BUTTONS_BAR_CLICKED,
        properties: [properties.trackingValue(trackingValue)],
    });
};

const trackHighlightsBannerClick = (trackingValue) => {
    if (!trackingValue) return;

    track({
        event: gaEventNames.HIGHLIGHT_BANNER_CLICKED,
        properties: [properties.trackingValue(trackingValue)],
    });
};

const trackForYouTileClick = (trackingValue) => {
    if (!trackingValue) return;

    track({
        event: gaEventNames.FOR_YOU_TILE_OPENED,
        properties: [properties.trackingValue(trackingValue)],
    });
};

const trackShopScreenView = () => {
    track({
        event: gaEventNames.SHOP_SECTION_VIEWED,
    });
};

const trackQuickOrderBannerClick = () => {
    track({
        event: gaEventNames.QUICK_ORDER_BANNER_CLICKED,
    });
};

const trackQuickOrderButtonClick = () => {
    track({
        event: gaEventNames.QUICK_ORDER_BUTTON_CLICKED,
    });
};

const trackYourDevicesButtonClick = (buttonType, deviceType) => {
    if (!buttonType || !deviceType) return;

    track({
        event: gaEventNames.YOUR_DEVICES_BUTTON_CLICKED,
        properties: [
            properties.deviceModel({deviceType}),
            {
                [analyticsEventProperties.BUTTON_TYPE]: buttonType,
            },
        ],
    });
};

const trackShopByProductBannerClick = (trackingValue) => {
    if (!trackingValue) return;

    track({
        event: gaEventNames.SHOP_BY_PRODUCT_CLICKED,
        properties: [properties.trackingValue(trackingValue)],
    });
};

const trackDeviceScreenView = () => {
    track({
        event: gaEventNames.DEVICE_SECTION_VIEWED,
    });
};

const trackTryYourFirstIqosButtonClick = () => {
    track({
        event: gaEventNames.TRY_YOUR_FIRST_IQOS_BUTTON_CLICKED,
    });
};

const trackAboutMyDeviceScreenView = (deviceType, deviceSerialNumber) => {
    if (!deviceType || !deviceSerialNumber) return;

    track({
        event: gaEventNames.ABOUT_MY_DEVICE_SCREEN_VIEWED,
        properties: [properties.deviceModel({deviceType}), properties.deviceSerialNumber({deviceSerialNumber})],
    });
};

const trackTipsAndTutorialsScreenView = (deviceType, deviceSerialNumber) => {
    if (!deviceType || !deviceSerialNumber) return;

    track({
        event: gaEventNames.TIPS_AND_TUTORIALS_SCREEN_VIEWED,
        properties: [properties.deviceModel({deviceType}), properties.deviceSerialNumber({deviceSerialNumber})],
    });
};

const trackUnregisterDeviceClick = (deviceType, deviceSerialNumber, isSuccess) => {
    if (!deviceType || !deviceSerialNumber) return;

    track({
        event: gaEventNames.UNREGISTER_DEVICE_CLICKED,
        properties: [
            properties.deviceModel({deviceType}),
            properties.deviceSerialNumber({deviceSerialNumber}),
            properties.success(isSuccess),
        ],
    });
};

const trackInboxScreenView = () => {
    track({
        event: gaEventNames.INBOX_SCREEN_VIEWED,
    });
};

const trackInboxDetailsScreenView = (pushSource, campaignName) => {
    track({
        event: gaEventNames.INBOX_MESSAGE_OPENED,
        properties: [properties.pushSource(pushSource), properties.campaignName(campaignName)],
    });
};

const trackInboxMessageButtonClick = (pushSource, campaignName) => {
    track({
        event: gaEventNames.INBOX_ACTION_BUTTON_CLICKED,
        properties: [properties.pushSource(pushSource), properties.campaignName(campaignName)],
    });
};

const trackLeviaScreenView = (pushSource) => {
    track({
        event: gaEventNames.ACTION_SCREEN_VIEWED,
        properties: [properties.pushSource(pushSource)],
    });
};

const trackPreferenceCenterScreenView = () => {
    track({
        event: gaEventNames.PREFERENCE_CENTER_VIEWED,
    });
};

const trackUpdateYourDataScreenView = () => {
    track({
        event: gaEventNames.PREFERENCE_CENTER_UPDATE_VIEWED,
    });
};

const trackContactUsScreenView = () => {
    track({
        event: gaEventNames.CONTACT_US_VIEWED,
    });
};

const trackShowProfileLinkClick = () => {
    track({
        event: gaEventNames.SHOW_PROFILE_CLICKED,
    });
};

const trackTermsOfUseClick = () => {
    track({
        event: gaEventNames.LEGAL_VIEWED,
        properties: [properties.legal(analyticsEventValues.TERMS_OF_USE)],
    });
};

const trackPrivacyNoticeClick = () => {
    track({
        event: gaEventNames.LEGAL_VIEWED,
        properties: [properties.legal(analyticsEventValues.PRIVACY_NOTICE)],
    });
};

const trackAttributionNoticeClick = () => {
    track({
        event: gaEventNames.LEGAL_VIEWED,
        properties: [properties.legal(analyticsEventValues.ATTRIBUTION_NOTICE)],
    });
};

const trackAddNewDeviceClick = () => {
    track({
        event: gaEventNames.ADD_NEW_DEVICE_CLICKED,
    });
};

const trackPairDeviceClick = (deviceType, deviceSerialNumber) => {
    track({
        event: gaEventNames.PAIR_DEVICE_CLICKED,
        properties: [properties.deviceModel({deviceType}), properties.deviceSerialNumber({deviceSerialNumber})],
    });
};

const trackRenameDeviceScreenView = () => {
    track({
        event: gaEventNames.RENAME_MY_DEVICE_VIEWED,
    });
};

const trackRenameDevice = () => {
    track({
        event: gaEventNames.DEVICE_RENAMED,
    });
};

const trackAdQuestionnaireSelectClick = (questionnairePage) => {
    track({
        event: gaEventNames.QUESTIONNAIRE_SELECT,
        properties: [
            properties.deviceModel({fromCurrent: true}),
            properties.deviceSerialNumber({fromCurrent: true}),
            properties.questionnairePage(questionnairePage),
        ],
    });
};

const trackAdQuestionnaireScroll = (questionnairePage) => {
    track({
        event: gaEventNames.QUESTIONNAIRE_SCROLL,
        properties: [
            properties.deviceModel({fromCurrent: true}),
            properties.deviceSerialNumber({fromCurrent: true}),
            properties.questionnairePage(questionnairePage),
        ],
    });
};

const trackAdCleanMyDeviceClick = () => {
    track({
        event: gaEventNames.CLEAN_MY_DEVICE_BUTTON_CLICKED,
        properties: [properties.deviceModel({fromCurrent: true}), properties.deviceSerialNumber({fromCurrent: true})],
    });
};

const trackAdRemoveStickInstructionsScroll = () => {
    track({
        event: gaEventNames.REMOVE_STICK_INSTRUCTIONS_SCROLLED,
        properties: [properties.deviceModel({fromCurrent: true}), properties.deviceSerialNumber({fromCurrent: true})],
    });
};

const trackAdEntryPointScreenView = () => {
    track({
        event: gaEventNames.DEVICE_SCREEN_VIEWED,
        properties: [properties.deviceModel({fromCurrent: true}), properties.deviceSerialNumber({fromCurrent: true})],
    });
};

const trackAdInitialQuestionnaireScreenView = (isQureMarket = undefined) => {
    const qureMarketValue =
        isQureMarket === true ? 'qure_market' : isQureMarket === false ? 'not_qure_market' : undefined;

    track({
        event: gaEventNames.AD_INITIAL_QUESTIONNAIRE_VIEWED,
        properties: [
            properties.deviceModel({fromCurrent: true}),
            properties.deviceSerialNumber({fromCurrent: true}),
            {
                [analyticsEventProperties.TYPE]: qureMarketValue,
            },
        ],
    });
};

const trackAdFinishScreenView = () => {
    track({
        event: gaEventNames.AD_FINISH_SCREEN_VIEWED,
        properties: [properties.deviceModel({fromCurrent: true}), properties.deviceSerialNumber({fromCurrent: true})],
    });
};

const trackAdNoneOfTheAboveClick = () => {
    track({
        event: gaEventNames.AD_NONE_OF_THE_ABOVE_BUTTON_CLICKED,
        properties: [properties.deviceModel({fromCurrent: true}), properties.deviceSerialNumber({fromCurrent: true})],
    });
};

const trackHighlightsViewAllClick = () => {
    track({
        event: gaEventNames.HIGHLIGHT_VIEW_ALL_CLICKED,
    });
};

const trackConsumerProfileScreenView = () => {
    track({
        event: gaEventNames.PROFILE_SCREEN_VIEWED,
    });
};

const trackStartWithBluefyClick = (deviceType, deviceSerialNumber) => {
    track({
        event: gaEventNames.START_WITH_BLUEFY_CLICKED,
        properties: [properties.deviceModel({deviceType}), properties.deviceSerialNumber({deviceSerialNumber})],
    });
};

export default {
    toggleAnalytics,
    trackEnableNotificationsScreenView,
    trackEnableNotificationsNextButtonClick,
    trackAllowPushNotifications,
    trackDisallowPushNotifications,
    trackHomeScreenView,
    trackHomeTopBannerClick,
    trackLoyaltyButtonClick,
    trackHighlightsBannerClick,
    trackForYouTileClick,
    trackShopScreenView,
    trackQuickOrderBannerClick,
    trackQuickOrderButtonClick,
    trackYourDevicesButtonClick,
    trackShopByProductBannerClick,
    trackDeviceScreenView,
    trackTryYourFirstIqosButtonClick,
    trackAboutMyDeviceScreenView,
    trackTipsAndTutorialsScreenView,
    trackUnregisterDeviceClick,
    trackInboxScreenView,
    trackInboxDetailsScreenView,
    trackInboxMessageButtonClick,
    trackLeviaScreenView,
    trackPreferenceCenterScreenView,
    trackUpdateYourDataScreenView,
    trackContactUsScreenView,
    trackShowProfileLinkClick,
    trackTermsOfUseClick,
    trackPrivacyNoticeClick,
    trackAttributionNoticeClick,
    trackAddNewDeviceClick,
    trackPairDeviceClick,
    trackRenameDeviceScreenView,
    trackRenameDevice,
    trackAdQuestionnaireSelectClick,
    trackAdQuestionnaireScroll,
    trackAdCleanMyDeviceClick,
    trackAdRemoveStickInstructionsScroll,
    trackAdEntryPointScreenView,
    trackAdInitialQuestionnaireScreenView,
    trackAdFinishScreenView,
    trackAdNoneOfTheAboveClick,
    trackHighlightsViewAllClick,
    trackConsumerProfileScreenView,
    trackStartWithBluefyClick,
};
