import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {BinIcon} from '@/components/Icons';
import {StickyHeader} from '@/components/Layout';
import {
    INBOX_DELETE_MESSAGE_BUTTON_ARIA_LABEL,
    NOTIFICATION_DETAILS_PAGE_HEADER_TITLE,
} from '@/consts/localization/localizationKeys';
import useMessageDeletionWithUndo from '@/hooks/inbox/useMessageDeletionWithUndo';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import {getAriaLabel} from '@/services/accessibilityService';
import {getLocalizedStrings} from '@/services/localization/localizationService';

import NotificationDetailsMessage from './components/NotificationDetailsMessage';
import NotificationDetailsReadNext from './components/NotificationDetailsReadNext';

const NotificationDetails = ({message, nextMessage, onBackButtonClick}) => {
    const isMobile = useIsMobileLayout();
    const localizedStrings = getLocalizedStrings();

    const handleMessageDeleteClick = useMessageDeletionWithUndo({messageId: message?.messageId});
    const onDeleteClick = () => {
        handleMessageDeleteClick();
        onBackButtonClick();
    };

    return (
        <div className='flex flex-col bg-secondary text-start'>
            {isMobile && (
                <StickyHeader
                    onBack={onBackButtonClick}
                    endIcon={
                        <ButtonRaw
                            onClick={onDeleteClick}
                            aria-label={getAriaLabel(INBOX_DELETE_MESSAGE_BUTTON_ARIA_LABEL)}
                        >
                            <BinIcon />
                        </ButtonRaw>
                    }
                    title={localizedStrings.formatString(
                        localizedStrings[NOTIFICATION_DETAILS_PAGE_HEADER_TITLE],
                        message?.title
                    )}
                />
            )}
            <NotificationDetailsMessage
                className='px-layout pt-safe-offset-sticky-header grow bg-romance pb-2'
                {...message}
            />
            {nextMessage && <NotificationDetailsReadNext notificationItem={nextMessage} />}
        </div>
    );
};

export default NotificationDetails;
