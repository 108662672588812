import {useEffect, useId} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {makeSelectIsVisibleRenderedModal} from '@/state/selectors/modal';
import {addModalToRenderedModals, removeModalFromRenderedModals} from '@/state/slices/modalSlice';

const useOneModalOnPageAtATime = (props) => {
    const {allowDisplayWithOtherPopups} = props || {};
    const modalId = useId();
    const dispatch = useDispatch();
    const isVisibleModal = useSelector(makeSelectIsVisibleRenderedModal(modalId));

    useEffect(() => {
        if (allowDisplayWithOtherPopups) return;

        dispatch(addModalToRenderedModals(modalId));

        return () => {
            dispatch(removeModalFromRenderedModals(modalId));
        };
    }, []);

    return allowDisplayWithOtherPopups || isVisibleModal;
};

export default useOneModalOnPageAtATime;
