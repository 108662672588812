import React from 'react';

import {MailIcon} from '@/components/Icons';
import {CONTACT_US_SEND_QUESTION_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import {getAriaLabel} from '@/services/accessibilityService';

import ContactUsSection from './ContactUsSection';

const ContactUsSendQuestion = ({email}) => {
    if (!email) return null;

    return (
        <ContactUsSection>
            <ContactUsSection.Icon>
                <MailIcon />
            </ContactUsSection.Icon>
            <ContactUsSection.Title itemName='sendQuestionTitle' />
            <ContactUsSection.Description itemName='sendQuestionDesc' />
            <ContactUsSection.Button
                itemName='sendQuestionButton'
                to={`mailto:${email}`}
                aria-label={getAriaLabel(CONTACT_US_SEND_QUESTION_BUTTON_ARIA_LABEL)}
                secondary
                light
                hasThickerShadow
                isExternalLink
            />
        </ContactUsSection>
    );
};

export default ContactUsSendQuestion;
