import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import domService from '@/services/domService';

const useAdvancedDiagnosticCarousel = (currentSlideIndex) => {
    const isMobile = useIsMobileLayout();

    const getSlideInertValue = (slideIndex) => {
        const isCurrentSlide = slideIndex === currentSlideIndex;
        const isInert = isMobile && !isCurrentSlide;
        const inert = domService.getInertFromBool(isInert);

        return inert;
    };

    return {getSlideInertValue};
};

export default useAdvancedDiagnosticCarousel;
