import {VisuallyHidden} from '@radix-ui/react-visually-hidden';
import React, {Fragment} from 'react';

import useAriaLiveMessage from './useAriaLiveMessage';

const AriaLiveContainer = ({children, alert, visible, constant, ...props}) => {
    const role = alert ? 'alert' : 'status';
    const {content} = useAriaLiveMessage({children, role, isConstant: constant});
    const Container = visible ? Fragment : VisuallyHidden;

    return (
        <Container asChild>
            <div role={role} {...props}>
                {content}
            </div>
        </Container>
    );
};

export default AriaLiveContainer;
