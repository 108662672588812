import cn from 'classnames';
import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {ArrowSmallIcon} from '@/components/Icons';
import {twx} from '@/utils/tailwindUtils';

const HighlightsArrowButton = ({isFlipped, onClick, 'aria-label': ariaLabel}) => {
    return (
        <ButtonRaw
            className={twx(
                'flex-center absolute left-auto right-[calc(100%/3-56px)] top-1/2 z-10 flex h-10 w-10 shrink-0 -translate-y-1/2 rounded-full border border-solid border-black bg-romance text-primary',
                isFlipped && 'left-[calc(100%/3-56px)] right-auto'
            )}
            onClick={onClick}
            aria-label={ariaLabel}
        >
            <ArrowSmallIcon className={cn(isFlipped && 'rotate-y-180')} />
        </ButtonRaw>
    );
};

export default HighlightsArrowButton;
