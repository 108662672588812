import {DEVICE_TYPES} from '@/consts/device/deviceTypes';
import {getDeviceTypesData} from '@/consts/device/deviceTypesData';

const getDeviceTypesDataByType = (type) => {
    if (!type) return null;

    const result = getDeviceTypesData()[type];

    return result ? result : null;
};

const getIsHolderByType = (type) => getDeviceTypesDataByType(type)?.isHolder;
const getDeviceModelByType = (type) => getDeviceTypesDataByType(type)?.deviceModel;
const getDeviceModelNameByType = (type) => getDeviceTypesDataByType(type)?.modelName;
const getProductNameByType = (type) => (type ? getDeviceTypesDataByType(type)?.name : undefined);
const isCortez = (type) => getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER;
const isCosmos = (type) => getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER;
const isOrdinary = (type) => getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_V24P_CHARGER;
const isP1 = (type) => getDeviceTypesDataByType(type)?.type === DEVICE_TYPES.DEV_TYPE_P1;
const isP4 = (type) => getDeviceTypesDataByType(type)?.type === DEVICE_TYPES.DEV_TYPE_P4;
const isP1V4 = (type) => getDeviceTypesDataByType(type)?.type === DEVICE_TYPES.DEV_TYPE_P1_V4;
const isP1V41 = (type) => isHermes(type) || isHermesHolder(type) || isJuno(type) || isAlpha(type);
const isSoyuz = (type) => getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER;
const isAriane = (type) => getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1_ARIANE;
const isVespucci = (type) => getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V30M;
const isOriginalOne = (type) => getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V31M;
const isOriginalDuo = (type) =>
    getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V31_ORIGINALS_CHARGER;
const isVoyager = (type) => getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER;
const isDelta = (type) => getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL;
const isHermesPrime = (type) => getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V41P_CHARGER;
const isHermesMid = (type) => getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V41M_CHARGER;
const isJuno = (type) => getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V41J_CONTROL;
const isAlpha = (type) => getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V41A_CONTROL;
const isHermesPrimeHolder = (type) =>
    getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V41P_HOLDER_CONTROL;
const isHermesMidHolder = (type) =>
    getDeviceTypesDataByType(type)?.fullType === DEVICE_TYPES.DEV_TYPE_P1V41M_HOLDER_CONTROL;
const isHermes = (type) => isHermesMid(type) || isHermesPrime(type);
const isHermesHolder = (type) => isHermesMidHolder(type) || isHermesPrimeHolder(type);
const isMono = (type) => getDeviceTypesDataByType(type)?.isMono;
const isIluma = (type) => isCosmos(type) || isVoyager(type) || isDelta(type);
const isP1Charger = (type) => isP1(type) && !getDeviceTypesDataByType(type)?.isHolder;
const isP1V4Holder = (type) => isP1V4(type) && getDeviceTypesDataByType(type)?.isHolder;
const isP1V4HolderOrMono = (type) => isP1V4Holder(type) || (isP1V4(type) && isMono(type));
const isP1ChargerWithoutAriane = (type) => isP1Charger(type) && !isAriane(type);
const isOriginalsDuoDeviceByCodeOrMaterial = ({configurationCode, material, isHolder}) => {
    const comparedByte = isHolder ? 2 : 1; //regional configuration(1 - original one charger, 2 - original one holder)

    if (configurationCode) {
        const isOriginalsDuoByCode = configurationCode.configurationCodeByte1 === comparedByte;

        return isOriginalsDuoByCode;
    }

    const {materialBrandFamily} = material || {};

    if (materialBrandFamily) {
        const isOriginalsDuoByMaterial = materialBrandFamily === 'IO';

        return isOriginalsDuoByMaterial;
    }
};

const getDevicePlatform = (deviceType) => {
    const type = getDeviceTypesDataByType(deviceType)?.type;

    if (!type) return null;

    switch (type) {
        case DEVICE_TYPES.DEV_TYPE_P1:
            return DEVICE_TYPES.DEV_TYPE_P1;
        case DEVICE_TYPES.DEV_TYPE_P4:
            return DEVICE_TYPES.DEV_TYPE_P4;
        case DEVICE_TYPES.DEV_TYPE_P1_V4:
            return DEVICE_TYPES.DEV_TYPE_P1_V4;
        default:
            return null;
    }
};

const mapHolderTypeToChargerType = (holderType, deviceType) => {
    // RS: Cosmos & Voyager, Hermes Prime & Hermes Mid holders are same.
    // This logic is used to avoid mix between,
    // e.g., Cosmos charger asset & Voyager holder asset
    const isHolderP1V41 = isP1V41(holderType);

    if (isCosmos(deviceType) && !isHolderP1V41) {
        return DEVICE_TYPES.DEV_TYPE_P1V40C_HOLDER_CONTROL;
    }
    if (isVoyager(deviceType) && !isHolderP1V41) {
        return DEVICE_TYPES.DEV_TYPE_P1V40V_HOLDER_CONTROL;
    }
    if (isHermesPrime(deviceType) && isHolderP1V41) {
        return DEVICE_TYPES.DEV_TYPE_P1V41P_HOLDER_CONTROL;
    }
    if (isHermesMid(deviceType) && isHolderP1V41) {
        return DEVICE_TYPES.DEV_TYPE_P1V41M_HOLDER_CONTROL;
    }

    return holderType;
};

export default {
    getIsHolderByType,
    getDeviceModelByType,
    getDevicePlatform,
    getProductNameByType,
    isCortez,
    isCosmos,
    isDelta,
    isMono,
    isOrdinary,
    isOriginalOne,
    isOriginalDuo,
    isHermesPrime,
    isHermesMid,
    isHermes,
    isJuno,
    isAlpha,
    isP1,
    isP1Charger,
    isP1V4,
    isP1V41,
    isP1V4Holder,
    isP1V4HolderOrMono,
    isP4,
    isAriane,
    isSoyuz,
    isVespucci,
    isVoyager,
    getDeviceTypesDataByType,
    isIluma,
    isOriginalsDuoDeviceByCodeOrMaterial,
    isP1ChargerWithoutAriane,
    mapHolderTypeToChargerType,
    getDeviceModelNameByType,
};
