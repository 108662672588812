import {createSelector} from 'reselect';

const selectAuth = (state) => state.auth;

const selectAccessToken = createSelector(selectAuth, (authState) => authState.accessToken);

const selectAccessTokenData = createSelector(selectAuth, ({accessToken, expirationDate, isAnonymous}) => ({
    accessToken,
    expirationDate,
    isAnonymous,
}));

const selectRefreshToken = createSelector(selectAuth, (authState) => authState.refreshToken);

const selectGigyaToken = createSelector(selectAuth, (authState) => authState.gigyaToken);

const selectIsLoggedIn = createSelector(selectAuth, (authState) => !!authState.accessToken && !authState.isAnonymous);

export {selectAccessToken, selectAccessTokenData, selectGigyaToken, selectIsLoggedIn, selectRefreshToken};
