import React from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import appConfig from '@/config/appConfig';
import {
    LEVIA_POPUP_CHECK_COMPATIBILITY_BUTTON_ARIA_LABEL,
    LEVIA_POPUP_LEARN_MORE_BUTTON_ARIA_LABEL,
    LEVIA_POPUP_SKIP_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import {getAriaLabel} from '@/services/accessibilityService';

const LeviaLearnMoreButtons = ({onCancel}) => {
    const checkCompatibilityExternalLink = appConfig.getLeviaCheckCompatibilityExternalLink();
    const learnMoreExternalLink = appConfig.getLeviaLearnMoreExternalLink();
    const redirectToExternalLink = useConfigExternalLink(checkCompatibilityExternalLink);
    const redirectToLearnMore = useConfigExternalLink(learnMoreExternalLink);

    return (
        <div className='m-auto flex max-w-[312px] flex-col items-center gap-4 ml:mt-8 ml:max-w-none ml:items-stretch ml:gap-3'>
            {checkCompatibilityExternalLink && (
                <AppButton
                    className='mt-0'
                    primary
                    light
                    aria-label={getAriaLabel(LEVIA_POPUP_CHECK_COMPATIBILITY_BUTTON_ARIA_LABEL)}
                    onClick={redirectToExternalLink}
                >
                    <AemComponent
                        component={EditableText}
                        pageName={routePaths.MODALS}
                        itemName='leviaCompatibilityButton'
                    />
                </AppButton>
            )}
            {learnMoreExternalLink && (
                <AppButton
                    className='mt-0'
                    secondary
                    light
                    aria-label={getAriaLabel(LEVIA_POPUP_LEARN_MORE_BUTTON_ARIA_LABEL)}
                    onClick={redirectToLearnMore}
                >
                    <AemComponent
                        component={EditableText}
                        pageName={routePaths.MODALS}
                        itemName='leviaLearnMoreButton'
                    />
                </AppButton>
            )}
            <AppButton
                tertiary
                className='mt-0 text-center ml:self-center'
                aria-label={getAriaLabel(LEVIA_POPUP_SKIP_BUTTON_ARIA_LABEL)}
                onClick={onCancel}
            >
                <AemComponent component={EditableText} pageName={routePaths.MODALS} itemName='leviaSkipButton' />
            </AppButton>
        </div>
    );
};

export default LeviaLearnMoreButtons;
