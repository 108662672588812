import React from 'react';

import {LocationIcon} from '@/components/Icons';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import {getAriaLabel} from '@/services/accessibilityService';

import ContactUsSection from './ContactUsSection';

const ContactUsComeVisitUs = ({nearestStoreAction}) => {
    const {ariaLabelKey} = nearestStoreAction || {};
    const redirectToExternalLink = useConfigExternalLink(nearestStoreAction);

    if (!nearestStoreAction) return null;

    return (
        <ContactUsSection>
            <ContactUsSection.Icon>
                <LocationIcon />
            </ContactUsSection.Icon>
            <ContactUsSection.Title itemName='comeVisitUsTitle' />
            <ContactUsSection.Description itemName='comeVisitUsDesc' />
            <ContactUsSection.Button
                onClick={redirectToExternalLink}
                itemName='comeVisitUsButton'
                aria-label={getAriaLabel(ariaLabelKey)}
                secondary
                light
                hasThickerShadow
                isExternalLink
            />
        </ContactUsSection>
    );
};

export default ContactUsComeVisitUs;
