import {useEffect} from 'react';

import amplitudeHomePageTracking from '@/services/analytics/amplitude/amplitudeHomePageTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';

const useHomePageAnalytics = (isWelcomeVisible) => {
    useEffect(() => {
        if (isWelcomeVisible) return;

        amplitudeHomePageTracking.trackHomePageScreen();
        googleAnalyticsService.trackHomeScreenView();
    }, [isWelcomeVisible]);
};

export default useHomePageAnalytics;
