import {createSlice} from '@reduxjs/toolkit';

import * as notificationTypes from '@/consts/app/notificationTypes';
import helpers from '@/utils/helpers';

export const slice = createSlice({
    name: 'notifications',
    initialState: [],
    reducers: {
        setNotification: (state, action) => {
            const {type, text, customId} = action.payload;

            if (state.some((el) => el.text === text)) return state;

            state.push({
                notificationId: customId || helpers.guid(),
                type,
                text,
            });

            return state;
        },
        removeNotification: (state, action) => {
            const notificationId = action.payload;

            state = state.filter((notification) => notification.notificationId !== notificationId);

            return state;
        },
    },
});

export const {setNotification, removeNotification} = slice.actions;

export const setNotificationInfo = (text, customId) => setNotification({type: notificationTypes.INFO, text, customId});

export const setNotificationError = (text, customId) =>
    setNotification({type: notificationTypes.ERROR, text, customId});

export default slice.reducer;
