import domIdentifiers from '@/consts/app/domIdentifiers';

const getHtmlElement = () => document.documentElement;
const getElementById = (id) => document.getElementById(id);
const getElementBySelector = (selector) => document.querySelector(selector);
const getAllElementsBySelector = (selector) => document.querySelectorAll(selector);
const getRoot = () => getElementById('root');
const getComponentsWrapperElement = () => getElementBySelector(`.${domIdentifiers.COMPONENTS_CONTAINER_CLASS_NAME}`);
const getProfileSidebarContentElement = () => getElementById(domIdentifiers.PROFILE_SIDEBAR_CONTENT);
const setFocusToDomElement = (domElement) => domElement?.focus();

const getCssVariable = (name, {element = getRoot(), asInt, asFloat}) => {
    let value = getComputedStyle(element)?.getPropertyValue(name);

    if (asInt) {
        value = parseInt(value);
    } else if (asFloat) {
        value = parseFloat(value);
    }

    return value;
};

const setCssVariable = (name, value, {element = getRoot()}) => {
    element?.style?.setProperty(name, value);
};

const getStickyHeaderHeight = () => getCssVariable(domIdentifiers.STICKY_HEADER_HEIGHT_VARIABLE, {asFloat: true});

// React <19 does not support boolean inert attribute so it needs to be converted to a string
const getInertFromBool = (inert) => (inert ? '' : undefined);

export default {
    getAllElementsBySelector,
    getComponentsWrapperElement,
    getElementBySelector,
    getHtmlElement,
    getRoot,
    setFocusToDomElement,
    getProfileSidebarContentElement,
    getCssVariable,
    setCssVariable,
    getStickyHeaderHeight,
    getInertFromBool,
    getElementById,
};
