import React, {useEffect} from 'react';

import useInboxListView from '@/hooks/inbox/useInboxListView';
import amplitudeInboxTracking from '@/services/analytics/amplitude/amplitudeInboxTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import homePageNavigationService from '@/services/homePageNavigationService';
import inboxMessageService from '@/services/inboxMessageService';

import InboxView from './InboxView';

const InboxViewPage = ({className}) => {
    const {inboxMessages, sentryRef, hasNextPage} = useInboxListView();

    const onCloseClick = () => {
        homePageNavigationService.forwardToSourcePage();
        inboxMessageService.deleteInboxMessages();
    };

    useEffect(() => {
        amplitudeInboxTracking.trackInboxScreen();
        googleAnalyticsService.trackInboxScreenView();
    }, []);

    return (
        <InboxView
            className={className}
            notifications={inboxMessages}
            sentryRef={sentryRef}
            hasNextPage={hasNextPage}
            onCloseClick={onCloseClick}
        />
    );
};

export default InboxViewPage;
