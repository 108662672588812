import useLayout from '@/hooks/layout/useLayout';

import DesktopHighlights from './components/DesktopHighlights/DesktopHighlights';
import MobileHighlights from './components/MobileHighlights/MobileHighlights';
import {HighlightsSectionIdContext} from './contexts/HighlightsSectionIdContext';

const Highlights = ({id, ...props}) => {
    const {isTabletAndDesktop} = useLayout();

    return (
        <HighlightsSectionIdContext.Provider value={id}>
            {isTabletAndDesktop ? <DesktopHighlights {...props} /> : <MobileHighlights {...props} />}
        </HighlightsSectionIdContext.Provider>
    );
};

export default Highlights;
