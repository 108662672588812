import {createSelector} from 'reselect';

import {APP_SECTION_TYPES, EXTERNAL_LINK_TYPES} from '@/consts/externalLinkTypes';
import layoutService from '@/services/layoutService';

const selectGlobal = (state) => state.global;

const selectIsAem = createSelector(selectGlobal, (globalState) => globalState.isAem);

const selectIsAppLoading = createSelector(selectGlobal, (globalState) => globalState.isAppLoading);

const selectLayoutType = createSelector(selectGlobal, (globalState) => globalState.layout);

const selectIsXsLayout = createSelector(selectLayoutType, (layout) => layoutService.isXsLayout(layout));

const selectIsSmLayout = createSelector(selectLayoutType, (layout) => layoutService.isSmLayout(layout));

const selectIsMdLayout = createSelector(selectLayoutType, (layout) => layoutService.isMdLayout(layout));

const selectIsMlLayout = createSelector(selectLayoutType, (layout) => layoutService.isMlLayout(layout));

const selectIsLgLayout = createSelector(selectLayoutType, (layout) => layoutService.isLgLayout(layout));

const selectIsMobileLayout = createSelector(selectLayoutType, (layout) => layoutService.isMobileLayout(layout));

const selectIsDesktopLayout = createSelector(selectLayoutType, (layout) => layoutService.isDesktopLayout(layout));

const selectIsDesktopOnlyLayout = createSelector(selectLayoutType, (layout) =>
    layoutService.isDesktopOnlyLayout(layout)
);

const selectLanguageCode = createSelector(selectGlobal, (globalState) => globalState.languageCode);

const selectSwMessage = createSelector(selectGlobal, (globalState) => globalState.swMessage);

const selectIsRedirectionRequiredAfterNotificationClick = createSelector(
    selectSwMessage,
    (swMessage) =>
        swMessage?.actionTypeCode === EXTERNAL_LINK_TYPES.SECTION_LINK &&
        swMessage?.appSection === APP_SECTION_TYPES.DETAILS
);

const selectHwContainerIds = createSelector(selectGlobal, (globalState) => globalState.hwContainerIds);
const selectIsWelcomeMode = createSelector(selectGlobal, (globalState) => globalState.isWelcomeMode);
const selectIsWelcomeAnimationStarted = createSelector(
    selectGlobal,
    (globalState) => globalState.isWelcomeAnimationStarted
);
const selectIsWelcomeAnimationFinished = createSelector(
    selectGlobal,
    (globalState) => globalState.isWelcomeAnimationFinished
);

export {
    selectHwContainerIds,
    selectIsAem,
    selectIsAppLoading,
    selectIsDesktopLayout,
    selectIsDesktopOnlyLayout,
    selectIsLgLayout,
    selectIsMdLayout,
    selectIsMlLayout,
    selectIsMobileLayout,
    selectIsRedirectionRequiredAfterNotificationClick,
    selectIsSmLayout,
    selectIsWelcomeAnimationFinished,
    selectIsWelcomeAnimationStarted,
    selectIsWelcomeMode,
    selectIsXsLayout,
    selectLanguageCode,
    selectLayoutType,
    selectSwMessage,
};
