import {useEffect} from 'react';

import analyticsEventValues from '@/consts/analytics/analyticsEventValues';
import {AUTO_DELIVERY_PAYMENT_PENDING_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useIsDesktopLayout from '@/hooks/layout/useIsDesktopLayout';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import amplitudeAutodeliveryTracking from '@/services/analytics/amplitude/amplitudeAutoDeliveryTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';

import StatusPaymentPendingDesktop from './StatusPaymentPendingDesktop';
import StatusPaymentPendingMobile from './StatusPaymentPendingMobile';

const StatusPaymentPending = () => {
    const isDesktopLayout = useIsDesktopLayout();
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[AUTO_DELIVERY_PAYMENT_PENDING_HEADER_TITLE]);

    const amplitudeEvent = () => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryOrderButtonClick(
            analyticsEventValues.CHECK_ORDER_STATUS,
            analyticsEventValues.PAYMENT_PENDING
        );
    };

    useEffect(() => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryOrderScreen(analyticsEventValues.PAYMENT_PENDING);
    }, []);

    return isDesktopLayout ? (
        <StatusPaymentPendingDesktop amplitudeEvent={amplitudeEvent} />
    ) : (
        <StatusPaymentPendingMobile amplitudeEvent={amplitudeEvent} />
    );
};

export default StatusPaymentPending;
