import deepmerge from 'deepmerge';
import {v4 as uuidv4} from 'uuid';

import arrayUtils from '@/utils/arrayUtils';

const guid = () => uuidv4();

const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const mergeDeep = deepmerge;

const scrollTop = (yCoord = 0) => window.scrollTo({top: yCoord});

const getBodyTop = () => {
    return document.body.getBoundingClientRect().top;
};

const runFunction = (func, arg) => {
    const args = arrayUtils.toArray(arg);

    if (typeof func === 'function') {
        return args ? func(...args) : func();
    }
};

const deepClone = (data) => JSON.parse(JSON.stringify(data));

const debounce = (func, delay) => {
    let timerId;

    return (...args) => {
        clearTimeout(timerId);

        timerId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export default {
    guid,
    mergeDeep,
    scrollTop,
    runFunction,
    timeout,
    deepClone,
    debounce,
    getBodyTop,
};
