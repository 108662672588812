import React, {useEffect, useState} from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import LanguageSelectContentSelectList from '@/components/LanguageSelect/components/LanguageSelectContent/LanguageSelectContentSelectList';
import LanguageSelectContentTitle from '@/components/LanguageSelect/components/LanguageSelectContent/LanguageSelectContentTitle';
import useLanguageSelect from '@/components/LanguageSelect/hooks/useLanguageSelect';
import {LANGUAGE_SELECT_SAVE_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import {getAriaLabel} from '@/services/accessibilityService';
import appRouterService from '@/services/route/appRouterService';
import helpers from '@/utils/helpers';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

const ProfileLanguageSelectPage = () => {
    const isMobile = useIsMobileLayout();
    const {languageCode, selectListData, handleLanguageChange} = useLanguageSelect();
    const [selectedLanguage, setSelectedLanguage] = useState(languageCode);

    //language select page is absent for mobile design
    useEffect(() => {
        if (isMobile) {
            appRouterService.forwardToConsumerProfilePage();
        }
    }, [isMobile]);

    useEffect(() => {
        setSelectedLanguage(languageCode);
    }, [languageCode]);

    const saveLanguage = () => {
        if (selectedLanguage === languageCode) return;

        helpers.runFunction(handleLanguageChange, selectedLanguage);
    };

    return (
        <>
            <WarningBannersTop
                source={WARNING_BANNER_SOURCE_TYPES.LANGUAGE_SELECT}
                className='ml:left-[var(--profile-sidebar-width)]'
            />
            <div className='mx-16 pt-0-hw-offset'>
                <LanguageSelectContentTitle
                    pageName={routePaths.PROFILE_LANGUAGE_SELECT}
                    className='text-32 mt-safe-offset-20'
                    renderAs='h1'
                />
                <LanguageSelectContentSelectList
                    className='py-8'
                    isDesktopView
                    selectedValue={selectedLanguage}
                    data={selectListData}
                    onChange={setSelectedLanguage}
                />
                <AppButton
                    className='mt-0 w-78'
                    light
                    primary
                    onClick={saveLanguage}
                    aria-label={getAriaLabel(LANGUAGE_SELECT_SAVE_BUTTON_ARIA_LABEL)}
                >
                    <AemEditableText pageName={routePaths.PROFILE_LANGUAGE_SELECT} itemName='saveButton' />
                </AppButton>
            </div>
            <WarningBanners className='mt-8' source={WARNING_BANNER_SOURCE_TYPES.LANGUAGE_SELECT} />
        </>
    );
};

export default ProfileLanguageSelectPage;
