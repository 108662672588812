import appConfig from '@/config/appConfig';
import iccService from '@/services/icc/iccService';
import {getMappedInitialLanguageCode} from '@/services/localization/localizationService';
import log from '@/services/logger/log';
import {selectDeviceType} from '@/state/selectors/device';
import {makeSelectSsoLink} from '@/state/selectors/media';
import {addSsoLink} from '@/state/slices/mediaSlice';
import {dispatch, getState} from '@/state/store';
import stringUtils from '@/utils/stringUtils';

const SSO_LINK_STATUSES = {
    LOADING: 'loading',
    SUCCESS: 'success',
    FAILED: 'failed',
};

const fetchSsoLink = async ({ssoTemplate, linkProcessor}) => {
    try {
        dispatch(addSsoLink({ssoTemplate, status: SSO_LINK_STATUSES.LOADING}));

        const {url, expirationDate, errorStatus} = await iccService.fetchSSOLink(ssoTemplate);

        if (errorStatus || !url) {
            dispatch(addSsoLink({ssoTemplate, status: SSO_LINK_STATUSES.FAILED}));

            return null;
        } else {
            const linkProcessed = linkProcessor ? linkProcessor(url) : url;

            dispatch(
                addSsoLink({
                    ssoTemplate,
                    link: linkProcessed,
                    expirationDate,
                    status: SSO_LINK_STATUSES.SUCCESS,
                })
            );
        }

        return url;
    } catch (e) {
        log.info(`iccSsoService: fetchSsoLink error: ${e}`);
    }
};

const getSsoLink = async ({ssoTemplate, params, linkProcessor}) => {
    const formattedSsoTemplate = getFormattedSsoTemplate({ssoTemplate, params});

    if (!formattedSsoTemplate) return;

    const {link, expirationDate} = makeSelectSsoLink(formattedSsoTemplate)(getState());
    const isLinkFetchedAndActive = link && !getLinkExpiration(expirationDate).isExpired;

    if (isLinkFetchedAndActive) return link;

    const result = await fetchSsoLink({ssoTemplate: formattedSsoTemplate, linkProcessor});

    return result;
};

const getFormattedSsoTemplate = ({ssoTemplate, params}) => {
    if (!ssoTemplate) return null;

    const marketCode = appConfig.getIccMarketName().toUpperCase();
    const languageCode = getMappedInitialLanguageCode()?.toUpperCase();
    const deviceType = selectDeviceType(getState())?.toUpperCase();

    if (!languageCode || !marketCode) return null;

    const formattedSsoTemplate = stringUtils.formatByName(ssoTemplate, {
        marketCode,
        languageCode,
        deviceType,
        ...params,
    });

    return formattedSsoTemplate;
};

const getLinkExpiration = (expirationDate) => {
    const expiredInMs = expirationDate - new Date();

    return {
        expiredInMs,
        isExpired: expiredInMs < 0,
    };
};

export default {
    fetchSsoLink,
    getSsoLink,
    getFormattedSsoTemplate,
    getLinkExpiration,
};
