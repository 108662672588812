import appRouterService from '@/services/route/appRouterService';

let _source;

export const HOME_PAGE_NAVIGATION_SOURCE_TYPES = {
    HOME: 'home',
    SHOP: 'shop',
    DEVICES: 'devices',
};

const setSource = (source) => (_source = source);

const forwardToSourcePage = () => {
    switch (_source) {
        case HOME_PAGE_NAVIGATION_SOURCE_TYPES.HOME:
        default:
            appRouterService.forwardToHomePage();
            break;
        case HOME_PAGE_NAVIGATION_SOURCE_TYPES.SHOP:
            appRouterService.forwardToShopPage();
            break;
        case HOME_PAGE_NAVIGATION_SOURCE_TYPES.DEVICES:
            appRouterService.forwardToDevicesPage();
            break;
    }
};

export default {
    setSource,
    forwardToSourcePage,
};
