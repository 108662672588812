import React from 'react';

import {isInboxMessageStatusRead} from '../../../../../consts/icc/iccInboxMessageStatusTypes';

const InboxNotificationItem = ({subTitle, title, shortText, timeAgo, status}) => {
    const isRead = isInboxMessageStatusRead(status);

    return (
        <>
            <article className='my-4 text-start'>
                <div className='flex justify-between'>
                    <div className='flex items-center gap-2'>
                        {!isRead && <div className='h-2 w-2 rounded-full bg-amber' />}
                        <p className='text-14 font-bold leading-13 text-granite-gray'>{subTitle}</p>
                    </div>
                    <p className='text-14 font-bold text-granite-gray'>{timeAgo}</p>
                </div>
                <p className='text-18 font-bold leading-15'>{title}</p>
                <p className='mt-1 text-14'>{shortText}</p>
            </article>
        </>
    );
};

export default InboxNotificationItem;
