import helpers from '@/utils/helpers';

// If button component is not <button /> or <input type="button" />,
// additional attributes (role, tabIndex, onKeyDown, onKeyUp) must be
// set to make it accessible
export const makeButtonAccessible = ({
    Component,
    isExternalLink,
    role,
    tabIndex,
    href,
    type,
    disabled,
    onKeyDown,
    onKeyUp,
    onClick,
}) => {
    const isButton = Component === 'button' || (Component === 'input' && type === 'button');
    const isLink = Component === 'a' || isExternalLink;
    const isAccessible = (isLink && href) || isButton;

    const defaultTabIndex = disabled ? -1 : 0;
    const {onKeyDown: defaultOnKeyDown, onKeyUp: defaultOnKeyUp} = isAccessible
        ? getButtonAccessibilityEvents(isLink, onClick)
        : {};

    const getAccessibilityValue = (propsValue, accessibilityValue) =>
        propsValue || (isAccessible ? undefined : accessibilityValue);
    const buttonRole = getAccessibilityValue(role, 'button');
    const buttonTabIndex = getAccessibilityValue(tabIndex, defaultTabIndex);
    const buttonOnKeyDown = getAccessibilityValue(onKeyDown, defaultOnKeyDown);
    const buttonOnKeyUp = getAccessibilityValue(onKeyUp, defaultOnKeyUp);

    return {
        buttonRole,
        buttonTabIndex,
        buttonOnKeyDown,
        buttonOnKeyUp,
    };
};

const getButtonAccessibilityEvents = (isLink, onClick) => {
    const isEnterKey = (keyCode) => keyCode === 13;
    const isSpaceKey = (keyCode) => keyCode === 32;
    const runOnClick = () => helpers.runFunction(onClick);

    const onKeyDown = (e) => {
        const {keyCode} = e;

        // Links can't be activated by space
        if (!isLink && isSpaceKey(keyCode)) {
            // Prevent page scrolling when pressing space
            e.preventDefault();
        } else if (isEnterKey(keyCode)) {
            e.preventDefault();
            runOnClick();
        }
    };

    // Space should activate button on key up
    const onKeyUp = (e) => {
        if (!isLink && isSpaceKey(e.keyCode)) {
            e.preventDefault();
            runOnClick();
        }
    };

    return {onKeyDown, onKeyUp};
};
