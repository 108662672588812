import {useDispatch, useSelector} from 'react-redux';

import ROUTE_PATHS from '@/consts/route/routePaths';
import {TERMS_OF_USE} from '@/consts/tnc/tncTypes';
import useLocationPathname from '@/hooks/useLocationPathname';
import appRouterService from '@/services/route/appRouterService';
import {selectTermsOfUseLegalCode} from '@/state/selectors/consumer';
import {setTncPopup} from '@/state/slices/tncSlice';

const useTermsOfUsePopup = ({className, overlayClassName} = {}) => {
    const {pathname} = useLocationPathname();
    const termsOfUseLegalCode = useSelector(selectTermsOfUseLegalCode);
    const dispatch = useDispatch();

    const openPopup = (code) => {
        const isTncDetailsPage = pathname === ROUTE_PATHS.TNC_DETAILS;
        // If we are already on /tnc-details page we don't want to add new record to the browser history
        const isReplace = isTncDetailsPage;

        if (code === termsOfUseLegalCode) {
            appRouterService.forwardToTncDetailsPage(TERMS_OF_USE, isReplace);
        } else {
            dispatch(setTncPopup({code, className, overlayClassName}));
        }
    };

    return {
        openPopup,
    };
};

export default useTermsOfUsePopup;
