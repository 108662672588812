import React, {useId, useState} from 'react';

import Banner from '@/components/Banner/Banner';
import {AppButton} from '@/components/Button';
import CloseButton from '@/components/CloseButton/CloseButton';
import {StickyHeader} from '@/components/Layout';
import PopupResponsive from '@/components/Popup/PopupResponsive';
import PromotedBadge from '@/components/PromotedBadge/PromotedBadge';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {getAriaLabel} from '@/services/accessibilityService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import helpers from '@/utils/helpers';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import AutoDeliveryBenefits from './AutoDeliveryBenefits';
import HowItWorksSection from './HowItWorksSection';

const AutoDeliveryDetailsStatusNoneTemplate = ({
    banner,
    headerKey,
    benefitsList,
    howItWorksList,
    amplitudeEvent,
    benefitsItemName,
    howItWorksItemName,
    onClose,
}) => {
    const isMobile = useIsMobileLayout();
    const [popupEl, setPopupEl] = useState();
    const headerId = useId();

    const {
        imageMedia,
        videoPath,
        isGradient,
        titleKey,
        subTitleKey,
        descriptionKey,
        buttonActionKey,
        action,
        actionAriaLabelKey,
    } = banner || {};
    const localizedStrings = getLocalizedStrings();
    const redirectToExternalLink = useConfigExternalLink(action);
    const isShowButton = buttonActionKey && localizedStrings[buttonActionKey] && redirectToExternalLink;

    useDocumentTitle(localizedStrings[headerKey]);

    const onButtonClick = () => {
        helpers.runFunction(amplitudeEvent);
        redirectToExternalLink();
    };

    return (
        <PopupResponsive
            contentRef={setPopupEl}
            className='overflow-auto text-start pt-0-hw-offset ml:ml-[calc(var(--sidebar-width)/2)] ml:max-h-[calc(100%-92px-var(--hw-h))] ml:w-[calc(100%-var(--sidebar-width)*1.5)] ml:max-w-[1016px] ml:rounded-xl ml:p-0'
            closeButtonClass='ml:text-romance'
            onClose={onClose}
            aria-labelledby={headerId}
        >
            {isMobile && <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.AUTO_DELIVERY} containerEl={popupEl} />}
            {isMobile && (
                <StickyHeader
                    scrollElement={popupEl}
                    title={localizedStrings[headerKey]}
                    endIcon={<CloseButton onClick={onClose} />}
                />
            )}
            <Banner
                className='h-[422px] bg-tertiary ml:h-[308px]'
                imageMedia={imageMedia}
                videoPath={videoPath}
                isGradient={isGradient}
                gradient='linear-gradient(180deg, rgba(52, 48, 61, 0) 39.99%, #1C1A21 100%)'
            >
                <div className='absolute bottom-8 w-full px-4 text-start text-white ml:px-12'>
                    <div className='mb-2 flex ml:mb-4'>
                        <PromotedBadge
                            className='max-w-full whitespace-nowrap [&>*]:overflow-hidden [&>*]:text-ellipsis'
                            badgeText={localizedStrings[subTitleKey]}
                        />
                    </div>
                    <h1
                        id={headerId}
                        className='mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-24 leading-[40px] ml:mb-4 ml:text-28'
                    >
                        {localizedStrings[titleKey]}
                    </h1>
                    <p
                        className='line-clamp-3 whitespace-pre-line text-14 ml:max-w-96 ml:tracking-04'
                        dangerouslySetInnerHTML={{__html: localizedStrings[descriptionKey]}}
                    />
                    {isShowButton && (
                        <AppButton
                            primary
                            dark
                            className='mt-4 whitespace-nowrap [&>*]:overflow-hidden [&>*]:text-ellipsis'
                            onClick={onButtonClick}
                            aria-label={getAriaLabel(actionAriaLabelKey)}
                        >
                            {localizedStrings[buttonActionKey]}
                        </AppButton>
                    )}
                </div>
            </Banner>
            <div className='ml:grid ml:grid-cols-2 ml:gap-8 ml:p-8'>
                <AutoDeliveryBenefits
                    benefitsList={benefitsList}
                    itemName={benefitsItemName}
                    className='hidden px-4 py-8 has-[h2,li_*]:block ml:m-0 ml:p-0'
                />
                <HowItWorksSection
                    howItWorksList={howItWorksList}
                    itemName={howItWorksItemName}
                    className='hidden border-t border-mischka first:border-0 has-[h2,li_*]:block ml:m-0 ml:border-0 ml:p-0'
                />
            </div>
            {isMobile && <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.AUTO_DELIVERY} />}
        </PopupResponsive>
    );
};

export default AutoDeliveryDetailsStatusNoneTemplate;
