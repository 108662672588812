import {useState} from 'react';

import {updateAutoDeliveryDetails} from '@/api/consumerApi';
import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import {ConfirmationIcon, ErrorBigIcon} from '@/components/Icons';
import AppLoader from '@/components/Loader/AppLoader/AppLoader';
import {
    AUTO_DELIVERY_ASAP_POPUP_ERROR_BUTTON_ARIA_LABEL,
    AUTO_DELIVERY_ASAP_POPUP_SUCCESS_BUTTON_ARIA_LABEL,
    AUTO_DELIVERY_DELIVER_ASAP_CONFIRM_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeAutoDeliveryTracking from '@/services/analytics/amplitude/amplitudeAutoDeliveryTracking';
import iccAutoDeliveryService from '@/services/icc/iccAutoDeliveryService';
import routerService from '@/services/route/routerService';
import helpers from '@/utils/helpers';
import stringUtils from '@/utils/stringUtils';

import useDeliveryDate from '../hooks/useDeliveryDate';
import useOrderSummaryTracking from '../hooks/useOrderSummaryTracking';
import DeliveryAsapConfirmPopup from './DeliveryAsapConfirmPopup';
import EditDeliveryResultPopup from './EditDeliveryResultPopup';

const ConfirmAsapButton = ({closePopup}) => {
    const {days, asapDeliveryDay, asapDeliveryMonth, asapDeliveryDateRaw} = useDeliveryDate();
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmPopupVisible, setIsConfirmPopupVisible] = useState(false);
    const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
    const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
    const orderSummaryTrackingValue = useOrderSummaryTracking();

    const onConfirmClick = () => {
        amplitudeAutoDeliveryTracking.trackAutoDeliveryDeliverAsapStarted(orderSummaryTrackingValue);
        setIsConfirmPopupVisible(true);
    };
    const onClose = () => setIsConfirmPopupVisible(false);
    const onConfirmSubmit = () => {
        setIsConfirmPopupVisible(false);
        setIsLoading(true);

        iccAutoDeliveryService.deliverAsap({
            asapDeliveryDate: asapDeliveryDateRaw,
            onSuccess: (responseData) => {
                setIsLoading(false);
                setIsSuccessPopupVisible(true);
                updateAutoDeliveryDetails(responseData);
                amplitudeAutoDeliveryTracking.trackAutoDeliveryDeliverAsapCompleted({
                    success: true,
                    orderSummary: orderSummaryTrackingValue,
                });
            },
            onError: (errorMessage) => {
                setIsLoading(false);
                setIsErrorPopupVisible(true);
                amplitudeAutoDeliveryTracking.trackAutoDeliveryDeliverAsapCompleted({
                    success: false,
                    message: errorMessage,
                    orderSummary: orderSummaryTrackingValue,
                });
            },
        });
    };
    const onResultPopupClose = () => {
        setIsErrorPopupVisible(false);
        setIsSuccessPopupVisible(false);
        helpers.runFunction(closePopup);
    };
    const onBackButtonClick = () => {
        routerService.go(-2);
    };

    return (
        <>
            <AppButton
                primary
                light
                className='mt-0'
                onClick={onConfirmClick}
                aria-label={getAriaLabel(AUTO_DELIVERY_DELIVER_ASAP_CONFIRM_BUTTON_ARIA_LABEL)}
            >
                <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='deliverAsapConfirmButton' />
            </AppButton>
            {isLoading && <AppLoader />}
            {isConfirmPopupVisible && <DeliveryAsapConfirmPopup onConfirm={onConfirmSubmit} onClose={onClose} />}
            {isSuccessPopupVisible && (
                <EditDeliveryResultPopup
                    icon={<ConfirmationIcon />}
                    title={
                        <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='deliverAsapPopupSuccessTitle' />
                    }
                    description={
                        <AemEditableText
                            pageName={routePaths.AUTO_DELIVERY}
                            itemName='deliverAsapPopupSuccessDescription'
                            render={(text) => {
                                const textFormatted = stringUtils.formatString(
                                    text,
                                    days,
                                    asapDeliveryDay,
                                    asapDeliveryMonth
                                );

                                return textFormatted ? (
                                    <span dangerouslySetInnerHTML={{__html: textFormatted}} />
                                ) : null;
                            }}
                        />
                    }
                    button={
                        <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='deliverAsapPopupSuccessButton' />
                    }
                    buttonAriaLabel={getAriaLabel(AUTO_DELIVERY_ASAP_POPUP_SUCCESS_BUTTON_ARIA_LABEL)}
                    onButtonClick={onBackButtonClick}
                    onClose={onResultPopupClose}
                />
            )}
            {isErrorPopupVisible && (
                <EditDeliveryResultPopup
                    icon={<ErrorBigIcon />}
                    title={
                        <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='deliverAsapPopupErrorTitle' />
                    }
                    description={
                        <AemEditableText
                            pageName={routePaths.AUTO_DELIVERY}
                            itemName='deliverAsapPopupErrorDescription'
                        />
                    }
                    button={
                        <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='deliverAsapPopupErrorButton' />
                    }
                    buttonAriaLabel={getAriaLabel(AUTO_DELIVERY_ASAP_POPUP_ERROR_BUTTON_ARIA_LABEL)}
                    onButtonClick={onBackButtonClick}
                    onClose={onResultPopupClose}
                />
            )}
        </>
    );
};

export default ConfirmAsapButton;
