import {useSelector} from 'react-redux';

import {selectIsAem, selectLanguageCode} from '@/state/selectors/global';

const useWithLocale = () => {
    const languageCode = useSelector(selectLanguageCode);
    const isAem = useSelector(selectIsAem);

    const withLocale = (path) => {
        if (isAem) return `/${languageCode}${path}`;

        return `${path}`;
    };

    return withLocale;
};

export default useWithLocale;
