import React from 'react';
import {useSelector} from 'react-redux';

import DeviceSettingItem from '@/containers/DevicesPage/components/MyDevicesView/components/DeviceSettings/DeviceSettingItem';
import useDeviceSettings from '@/containers/DevicesPage/components/MyDevicesView/hooks/DeviceSettings/useDeviceSettings';
import useIsDesktopLayout from '@/hooks/layout/useIsDesktopLayout';
import useUamAssets from '@/hooks/uam/useUamAssets';
import {selectCurrentProductInfo} from '@/state/selectors/device';
import {hideLoader, showLoader} from '@/state/slices/loadersSlice';
import {dispatch} from '@/state/store';

import DevicesSettingsSection from '../../../DevicesSettingsSection/DevicesSettingsSection';

const DeviceSettings = () => {
    const isDesktopLayout = useIsDesktopLayout();
    const device = useSelector(selectCurrentProductInfo);
    const {codentify, holder} = device || {};
    const {isAnySettingEnabled, settings} = useDeviceSettings();

    const setLoading = (isLoading) => {
        if (isLoading) {
            dispatch(showLoader());
        } else {
            dispatch(hideLoader());
        }
    };

    useUamAssets({isDisabled: !isAnySettingEnabled, setLoading, codentify, holderCodentify: holder?.codentify});

    if (!settings?.length) return null;

    return (
        <>
            <DevicesSettingsSection.Group className='mt-4 ml:mt-8'>
                <DevicesSettingsSection.Title itemName='personaliseyouriqos' />
                {isDesktopLayout ? (
                    <div className='ml:grid ml:grid-cols-2 ml:gap-2'>
                        <SettingItems settings={settings} />
                    </div>
                ) : (
                    <DevicesSettingsSection>
                        <SettingItems settings={settings} />
                    </DevicesSettingsSection>
                )}
            </DevicesSettingsSection.Group>
        </>
    );
};

const SettingItems = ({settings}) => {
    return settings.map((setting, i) => <DeviceSettingItem key={i} {...setting} />);
};

export default DeviceSettings;
