import React from 'react';

import {ButtonRaw} from '@/components/Button';
import RemoteIcon from '@/components/Icon/RemoteIcon';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeHomePageTracking from '@/services/analytics/amplitude/amplitudeHomePageTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {twx} from '@/utils/tailwindUtils';

const HomePageLoyaltyButtonsBar = ({buttons, className}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <div
            className={twx(
                'flex w-full flex-row items-baseline justify-between rounded-xl bg-secondary px-4 py-2 md:gap-2 md:bg-romance md:px-8 md:py-4',
                className
            )}
        >
            {buttons.map((icon, index) => {
                const {titleKey, action, amplitudeTrackingValue, altKey} = icon;

                return (
                    <LoyaltyButton
                        key={index}
                        title={localizedStrings[titleKey]}
                        icon={icon}
                        action={action}
                        trackingValue={amplitudeTrackingValue}
                        aria-label={getAriaLabel(altKey)}
                    />
                );
            })}
        </div>
    );
};

const LoyaltyButton = ({title, icon, action, trackingValue, 'aria-label': ariaLabel}) => {
    const redirectToExternalLink = useConfigExternalLink(action);

    const onButtonClick = () => {
        if (!action) return;

        amplitudeHomePageTracking.trackLoyaltyButtonsBarClick(trackingValue);
        googleAnalyticsService.trackLoyaltyButtonClick(trackingValue);
        redirectToExternalLink();
    };

    return (
        <ButtonRaw
            className='flex w-full min-w-18 flex-col justify-start gap-2 p-0 md:flex-row md:justify-center'
            onClick={onButtonClick}
            aria-label={ariaLabel}
        >
            <div className='h-12 w-12 md:h-16 md:w-16'>
                <RemoteIcon className='h-full object-contain' icon={icon} />
            </div>
            <span className='text-xs max-w-full break-words text-center font-normal text-primary'>{title}</span>
        </ButtonRaw>
    );
};

export default HomePageLoyaltyButtonsBar;
