import {WARNING_BANNER_SOURCE_TYPES} from '@/consts/aem/warningBannerSourceTypes';
import {WarningBannersTop} from '@/views/WarningBanners';

import {twx} from '../../utils/tailwindUtils';

const TncPageContainer = ({className, ...rest}) => {
    // It is needed only for setting bg-color on desktop
    return (
        <>
            <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.TNC} />
            <div
                className={twx('flex w-full flex-1 flex-col items-center justify-center md:bg-romance', className)}
                {...rest}
            />
        </>
    );
};

export default TncPageContainer;
