import React, {useId} from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import PopupCentered from '@/components/Popup/PopupCentered';
import {
    UNREGISTER_YOUR_DEVICE_POPUP_CANCEL_BUTTON_ARIA_LABEL,
    UNREGISTER_YOUR_DEVICE_POPUP_UNREGISTER_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import {getAriaLabel} from '@/services/accessibilityService';

const UnregisterDevicePopup = ({deviceName, onUnregisterClick, onCancelClick}) => {
    const headerId = useId();

    return (
        <PopupCentered
            className='right-0 z-[102] mx-8 rounded-2xl p-6 ml:m-0 ml:w-[calc(100%-128px)] ml:max-w-[624px]'
            overlayClassName='ml:before:bg-transparent ml:w-[calc(100vw-var(--sidebar-width)-var(--devices-popup-sidebar-width))] ml:left-[var(--sidebar-width)]'
            aria-labelledby={headerId}
        >
            <div className='flex flex-col text-justify text-18'>
                <h1 id={headerId} className='mb-0 flex flex-col text-18'>
                    <b id={headerId} className='ml:text-18 ml:leading-13 ml:tracking-02'>
                        <AemEditableText pageName={routePaths.DEVICES_PAGE} itemName='unregisterYourDevicePopupTitle' />
                    </b>
                    <b className='break-words text-left ml:text-18 ml:leading-13 ml:tracking-02'>{deviceName}</b>
                </h1>
                <span className='mt-2 text-14 ml:leading-143 ml:tracking-02'>
                    <AemEditableText
                        pageName={routePaths.DEVICES_PAGE}
                        itemName='unregisterYourDevicePopupDescription'
                    />
                </span>
            </div>
            <div className='mt-4 flex justify-end text-14 ml:text-14 ml:leading-143'>
                <AppButton
                    className='mr-10 mt-0 w-auto p-0 text-end ml:mr-8 ml:tracking-04'
                    onClick={onCancelClick}
                    aria-label={getAriaLabel(UNREGISTER_YOUR_DEVICE_POPUP_CANCEL_BUTTON_ARIA_LABEL)}
                >
                    <AemEditableText pageName={routePaths.DEVICES_PAGE} itemName='unregisterYourDevicePopupCancel' />
                </AppButton>
                <AppButton
                    className='mt-0 w-auto p-0 text-end font-bold ml:tracking-02'
                    onClick={onUnregisterClick}
                    aria-label={getAriaLabel(UNREGISTER_YOUR_DEVICE_POPUP_UNREGISTER_BUTTON_ARIA_LABEL)}
                >
                    <AemEditableText
                        pageName={routePaths.DEVICES_PAGE}
                        itemName='unregisterYourDevicePopupUnregister'
                    />
                </AppButton>
            </div>
        </PopupCentered>
    );
};

export default UnregisterDevicePopup;
