import marketService from '@/services/marketService';
import {selectLanguageCode} from '@/state/selectors/global';
import {getState} from '@/state/store';
import urlUtils from '@/utils/urlUtils';

export const isAemPath = () => {
    const path = urlUtils.getCurrentPathname();

    if (path.includes(getAemPath())) return true;

    return false;
};

export const getFullAemPath = (marketCode, languageCode) => {
    if (marketCode && languageCode) {
        return `${getAemPath()}/${marketCode}/${languageCode.toLowerCase()}`;
    }

    const selectedMarketCode = marketService.getMarketCode();
    const selectedLanguageCode = selectLanguageCode(getState());

    return `${getAemPath()}/${selectedMarketCode}/${selectedLanguageCode.toLowerCase()}`;
};

const getAemPath = () => {
    return '/content/pwa-app';
};
