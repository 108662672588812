import React from 'react';

import {AppButton} from '@/components/Button';
import AppImage from '@/components/Image/AppImage';
import GuideVideo from '@/components/Video/GuideVideo/GuideVideo';
import queryParameters from '@/consts/route/queryParameters';
import * as sourceTypes from '@/consts/sourceTypes';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import amplitudeInboxTracking from '@/services/analytics/amplitude/amplitudeInboxTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import urlUtils from '@/utils/urlUtils';

import NotificationDetailsNewFeatures from './NotificationDetailsNewFeatures';

const NotificationDetailsMessage = (props) => {
    const {
        className,
        subTitle,
        title,
        campaignName,
        detailedText,
        detailedImage,
        detailedVideo,
        newFeatures,
        newFeaturesTitle,
        actionButton,
    } = props;
    const isMobile = useIsMobileLayout();
    const {actionLink, buttonLabel, linkType} = actionButton || {};
    const isShowButton = actionLink && buttonLabel && linkType;
    const isShowFeatures = !!newFeatures?.length;
    const Heading = isMobile ? 'h1' : 'h2';

    const redirectToExternalLink = useConfigExternalLink({
        url: actionLink,
        ssoTemplate: actionLink,
        linkType,
        source: sourceTypes.INBOX,
    });

    const onClick = () => {
        const [source] = urlUtils.getDataFromUrl(queryParameters.SOURCE);

        amplitudeInboxTracking.trackInboxMessageButtonClicked(source, campaignName);
        googleAnalyticsService.trackInboxMessageButtonClick(source, campaignName);
        redirectToExternalLink();
    };

    return (
        <div className={className}>
            <p className='primary-tins-slate text-20 font-bold leading-15 text-jumbo ml:text-14'>{subTitle}</p>
            <Heading className='mb-8 text-32 leading-[40px] ml:mb-4 ml:text-18 ml:leading-15'>{title}</Heading>

            {detailedImage && (
                <AppImage src={detailedImage} className='mb-4 w-full shrink-0 rounded-[12px] object-cover ml:mb-2' />
            )}

            {detailedVideo && (
                <GuideVideo src={detailedVideo} containerClassName='w-full h-full mb-4 ml:mb-2' className='w-full' />
            )}

            {detailedText && <div className='whitespace-pre-line' dangerouslySetInnerHTML={{__html: detailedText}} />}

            {isShowFeatures && (
                <NotificationDetailsNewFeatures newFeatures={newFeatures} newFeaturesTitle={newFeaturesTitle} />
            )}

            {isShowButton && (
                <AppButton
                    onClick={onClick}
                    primary
                    light
                    className='my-8 min-h-12 w-full border ml:mb-0 ml:mt-4'
                    aria-label={buttonLabel}
                >
                    {buttonLabel}
                </AppButton>
            )}
        </div>
    );
};

export default NotificationDetailsMessage;
