import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {GlobeIcon} from '@/components/Icons';
import {LANGUAGE_SELECT_TRIGGER_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import {getFormattedAriaLabel} from '@/services/accessibilityService';
import {twx} from '@/utils/tailwindUtils';

const LanguageSelectTrigger = ({languageName, isDark, onClick}) => {
    const ariaLabel = getFormattedAriaLabel(LANGUAGE_SELECT_TRIGGER_BUTTON_ARIA_LABEL, languageName);

    return (
        <ButtonRaw
            className={twx('flex gap-2 self-end font-bold leading-[24px] text-romance underline', {
                'text-primary': isDark,
            })}
            onClick={onClick}
            aria-label={ariaLabel}
        >
            <div className='flex self-end text-14'>{languageName}</div>
            <GlobeIcon className='h-6 w-6 stroke-2' />
        </ButtonRaw>
    );
};

export default LanguageSelectTrigger;
