import {ENABLE_NOTIFICATION_POPUP_NEXT_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import {getAriaLabel} from '@/services/accessibilityService';

import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../../components/Button';

const EnableNotificationModalButton = ({onClick}) => {
    return (
        <AppButton
            className='m-auto ml:w-full'
            primary
            light
            aria-label={getAriaLabel(ENABLE_NOTIFICATION_POPUP_NEXT_BUTTON_ARIA_LABEL)}
            onClick={onClick}
        >
            <AemComponent component={EditableText} pageName='enable-notification' itemName='enableNotificationButton' />
        </AppButton>
    );
};

export default EnableNotificationModalButton;
