import appConfig from '@/config/appConfig';
import signedMediaService from '@/services/signedMediaService';
import {selectSignedMediaList} from '@/state/selectors/media';
import {getState} from '@/state/store';
import arrayUtils from '@/utils/arrayUtils';
import stringUtils from '@/utils/stringUtils';

const getThumbnailNameList = (mediaData) => {
    const mediaIdList = arrayUtils.toArray(mediaData);
    const thumbnailNameList = mediaIdList.map((mediaId) => getThumbnailName(mediaId));

    return thumbnailNameList;
};

const getThumbnailName = (mediaId) => {
    const THUMBNAIL_NAME_PREFIX = '_50x50';
    const thumbnailName = mediaId ? mediaId.replace(/(\.[\w\d_-]+)$/i, `${THUMBNAIL_NAME_PREFIX}$1`) : undefined;

    return thumbnailName;
};

const getSignedMediaUrl = async (mediaIds) => {
    return signedMediaService.getSignedMediaUrl(mediaIds);
};

const getSignedMediaList = () => selectSignedMediaList(getState());

const assetMapping = (mediaName) => mediaIdMapping(mediaName, appConfig.getAssetsPath());
const mediaIdMapping = (mediaName, assetsPath) => stringUtils.formatString(assetsPath, mediaName);

export default {
    getSignedMediaUrl,
    getThumbnailNameList,
    getSignedMediaList,
    getThumbnailName,
    assetMapping,
};
