import {getLocalizedStrings} from '@/services/localization/localizationService';
import stringUtils from '@/utils/stringUtils';

export const getAriaLabel = (defaultKey, overrideKey = undefined) => {
    const key = overrideKey || defaultKey;
    const localizedStrings = getLocalizedStrings();
    const value = localizedStrings[key] || '';

    return value;
};

export const getFormattedAriaLabel = (localizationKey, ...values) => {
    let ariaLabel = getAriaLabel(localizationKey);

    if (ariaLabel) {
        ariaLabel = stringUtils.formatString(ariaLabel, ...values);
    }

    return ariaLabel;
};

export const getImageAlt = getAriaLabel;

export default {
    getAriaLabel,
    getImageAlt,
};
