import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import loaderService from '@/services/loaderService';
import {selectIsConsumerProductsFetched} from '@/state/selectors/consumer';
import {showLoader} from '@/state/slices/loadersSlice';

const LOADER_NAME = 'DEVICE_LIST_LOADER';

const useConsumerProductsLoader = () => {
    const dispatch = useDispatch();
    const isConsumerProductsFetched = useSelector(selectIsConsumerProductsFetched);

    useEffect(() => {
        if (isConsumerProductsFetched) {
            loaderService.hideLoaderIfNeeded(LOADER_NAME);
        } else {
            dispatch(showLoader(LOADER_NAME));
        }

        return () => {
            loaderService.hideLoaderIfNeeded(LOADER_NAME);
        };
    }, [isConsumerProductsFetched]);
};

export default useConsumerProductsLoader;
