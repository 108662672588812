import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';

import useExternalLinkRedirectToAutoDelivery from './useExternalLinkRedirectToAutoDelivery';

const usePageSectionLinkData = () => {
    const isMobile = useIsMobileLayout();
    const redirectToAutoDelivery = useExternalLinkRedirectToAutoDelivery();

    return {isMobile, redirectToAutoDelivery};
};

export default usePageSectionLinkData;
