import React, {forwardRef} from 'react';

import {PopoverArrow, PopoverContent} from '@/components/Popover/Popover';
import {INBOX_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import {getAriaLabel} from '@/services/accessibilityService';

const InboxPopoverContent = forwardRef(({children, ...rest}, ref) => {
    return (
        <PopoverContent align='end' aria-label={getAriaLabel(INBOX_ARIA_LABEL)} arrowPadding={40} ref={ref} {...rest}>
            {children}
            <PopoverArrow />
        </PopoverContent>
    );
});

export default InboxPopoverContent;
