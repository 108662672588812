import {useId, useState} from 'react';

import {AppButton} from '@/components/Button';
import CloseButton from '@/components/CloseButton/CloseButton';
import {StickyPageLayout} from '@/components/Layout';
import PopupResponsive from '@/components/Popup/PopupResponsive';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

const EditDeliveryResultPopup = ({icon, title, description, button, buttonAriaLabel, onClose, onButtonClick}) => {
    const headerId = useId();
    const [popupEl, setPopupEl] = useState();
    const isMobile = useIsMobileLayout();

    return (
        <PopupResponsive
            overlayRef={setPopupEl}
            onClose={onClose}
            className='z-[21] flex flex-col bg-romance ml:ml-[calc(var(--sidebar-width)/2)] ml:w-full ml:max-w-[494px] ml:rounded-xl ml:p-8 ml:pt-19 ml:text-center'
            aria-labelledby={headerId}
        >
            {isMobile && <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.AUTO_DELIVERY} containerEl={popupEl} />}
            <StickyPageLayout
                contentClassName='flex flex-1 flex-col'
                desktopContentClassName='flex flex-col items-center'
                desktopFooterClassName='flex flex-col items-center'
                headerOptions={{
                    endIcon: <CloseButton onClick={onClose} />,
                }}
                footer={
                    <AppButton
                        primary
                        light
                        className='mt-0 ml:mt-10 ml:min-w-78'
                        onClick={onButtonClick}
                        aria-label={buttonAriaLabel}
                    >
                        {button}
                    </AppButton>
                }
            >
                <div className='flex flex-1 flex-col items-center justify-center p-8'>
                    {icon}
                    <h1 id={headerId} className='mb-0 mt-8 text-32 ml:mt-6'>
                        {title}
                    </h1>
                    <p className='text-20 tracking-02 ml:mt-6'>{description}</p>
                </div>
                {isMobile && <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.AUTO_DELIVERY} />}
            </StickyPageLayout>
        </PopupResponsive>
    );
};

export default EditDeliveryResultPopup;
