import React from 'react';

import {twx} from '@/utils/tailwindUtils.js';

const PromotedBadge = ({className, badgeText}) => {
    if (!badgeText) return null;

    return (
        <div
            className={twx(
                'color flex justify-center self-start rounded bg-tertiary px-2 py-1 text-primary',
                className
            )}
        >
            <span className='text-12 leading-[16px] md:tracking-02'>{badgeText}</span>
        </div>
    );
};

export default PromotedBadge;
