import React from 'react';

import {getLocalizedStrings} from '@/services/localization/localizationService';
import stylesUtils from '@/utils/stylesUtils';
import {twx} from '@/utils/tailwindUtils';

import HwContainer from './HwContainer';

const HealthWarning = ({titleKey, className, isSeparatorVisible, fontSize, fontFamily}) => {
    const localizedStrings = getLocalizedStrings();
    const styles = stylesUtils.getFontSizeStyles(fontSize);
    const fontFamilyClassName = stylesUtils.getFontFamilyClassName(fontFamily);

    return (
        <HwContainer className={className} isSeparatorVisible={isSeparatorVisible}>
            <div className={twx('m-8 bg-romance px-8 py-6 text-14 font-bold', fontFamilyClassName)}>
                <p style={styles}>{localizedStrings[titleKey]}</p>
            </div>
        </HwContainer>
    );
};

export default HealthWarning;
