import React, {useId} from 'react';

import {AppButton} from '@/components/Button';
import {FirmwareUpdateIcon} from '@/components/Icons';
import PopupSticked from '@/components/Popup/PopupSticked';
import * as localizationKeys from '@/consts/localization/localizationKeys';
import {APP_UPDATE_POPUP_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import {getAriaLabel} from '@/services/accessibilityService';
import {getLocalizedStrings} from '@/services/localization/localizationService';

import useAppUpdate from './useAppUpdate';

const AppUpdatePopup = () => {
    const headerId = useId();
    const isNewVersionAvailable = useAppUpdate();

    if (!isNewVersionAvailable) return null;

    const localizedStrings = getLocalizedStrings();

    const onClick = () => {
        if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({type: 'SKIP_WAITING'});
        }
        window.location.reload();
    };

    return (
        <PopupSticked className='flex flex-col px-4 py-8 ml:max-w-[458px] ml:px-12 ml:pt-16' aria-labelledby={headerId}>
            <FirmwareUpdateIcon className='mx-auto h-16 w-16' />
            <h1 id={headerId} className='mb-0 mt-4 text-24'>
                {localizedStrings[localizationKeys.APP_UPDATE_POPUP_TITLE]}
            </h1>
            <p className='mt-4 text-start'>{localizedStrings[localizationKeys.APP_UPDATE_POPUP_DESCRIPTION]}</p>
            <AppButton
                primary
                light
                className='mt-8 ml:mt-16'
                aria-label={getAriaLabel(APP_UPDATE_POPUP_BUTTON_ARIA_LABEL)}
                onClick={onClick}
            >
                {localizedStrings[localizationKeys.APP_UPDATE_POPUP_BUTTON]}
            </AppButton>
        </PopupSticked>
    );
};

export default AppUpdatePopup;
