import {CloudWatchLogsClient, PutLogEventsCommand} from '@aws-sdk/client-cloudwatch-logs';

import appConfig from '@/config/appConfig';
import consoleLog from '@/services/logger/consoleLogger';

import awsAuthenticationService from './awsAuthenticationService';

let AWSClient;
let cloudWatchConfig;

const putEvents = async ({eventMessages, onError, onComplete}) => {
    try {
        const cloudWatchClient = await getCloudWatchClient();
        const requestData = prepareEvent(eventMessages);

        await exposeEvents({cloudWatchClient, requestData, onError, onComplete});
    } catch {
        onError();
    }
};

const EXPIRED_TOKEN_EXCEPTION = 'ExpiredTokenException';

const exposeEvents = async ({cloudWatchClient, requestData, onError, onComplete}) => {
    try {
        const command = new PutLogEventsCommand(requestData);
        const data = await cloudWatchClient.send(command);

        cloudWatchConfig.sequenceToken = data ? data.nextSequenceToken : null;
        onComplete();
    } catch (err) {
        consoleLog.error(`cloudWatchProvider: putLogEvents error: ${err.toString()}`);
        onError();

        switch (err.name) {
            case EXPIRED_TOKEN_EXCEPTION:
                cloudWatchConfig = null;
                await awsAuthenticationService.getCredentials(true);
                break;
            default:
                break;
        }
    }
};

const getCloudWatchClient = async () => {
    if (!AWSClient || !cloudWatchConfig) {
        const credentials = await awsAuthenticationService.getCredentials();

        createCloudWatchClient(credentials);
    }

    return AWSClient;
};

const createCloudWatchClient = (credentials) => {
    if (credentials) {
        cloudWatchConfig = credentials.cloudWatchSettings;

        AWSClient = new CloudWatchLogsClient({
            region: appConfig.getAwsRegion(),
            credentials,
            maxAttempts: 0,
        });
    }
};

const updateCloudWatchClient = (credentials) => {
    if (AWSClient) {
        createCloudWatchClient(credentials);
    }
};

const prepareEvent = (logEvents) => {
    const {groupName, streamName, sequenceToken} = cloudWatchConfig;

    const data = {
        logEvents: [...logEvents],
        logGroupName: groupName,
        logStreamName: streamName,
    };

    if (sequenceToken) {
        data.sequenceToken = sequenceToken;
    }

    return data;
};

export default {
    putEvents,
    updateCloudWatchClient,
};
