import {useId} from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import {ArrowLeftIcon, CalendarIcon} from '@/components/Icons';
import PopupResponsive from '@/components/Popup/PopupResponsive';
import PopupStickedWithSwipeClose from '@/components/Popup/PopupStickedWithSwipeClose';
import {AUTO_DELIVERY_ASAP_POPUP_CONFIRM_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import {getAriaLabel} from '@/services/accessibilityService';

import AutoDeliveryDetailsFooter from '../components/AutoDeliveryDetailsFooter';
import useDeliveryDate from '../hooks/useDeliveryDate';
import DeliveryAsapInfo from './DeliveryAsapInfo';

const DeliveryAsapConfirmPopup = ({onClose, onConfirm}) => {
    const headerId = useId();
    const isMobile = useIsMobileLayout();
    const {startDeliveryDay, asapDeliveryDay} = useDeliveryDate();
    const Popup = isMobile ? PopupStickedWithSwipeClose : PopupResponsive;

    return (
        <Popup
            className='ml:ml-[calc(var(--sidebar-width)/2)] ml:w-full ml:max-w-[494px] ml:rounded-xl ml:p-8 ml:pt-19'
            onClose={onClose}
            aria-labelledby={headerId}
        >
            <div className='text-start'>
                <h1 id={headerId} className='mb-0 text-28'>
                    <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='deliveryAsapConfirmPopupTitle' />
                </h1>
                <DeliveryAsapInfo className='mt-8' />
                <div className='mt-8 flex items-center justify-between md:justify-center'>
                    <div className='flex items-center gap-2'>
                        <CalendarIcon className='text-french-gray' />
                        <p>
                            <AemEditableText
                                pageName={routePaths.AUTO_DELIVERY}
                                itemName='deliveryAsapConfirmPopupStartDate'
                                contentList={[startDeliveryDay]}
                            />
                        </p>
                    </div>
                    <ArrowLeftIcon className='mx-2 rotate-180' />
                    <div className='flex items-center gap-2'>
                        <CalendarIcon className='text-tertiary' />
                        <p>
                            <AemEditableText
                                pageName={routePaths.AUTO_DELIVERY}
                                itemName='deliveryAsapConfirmPopupEndDate'
                                contentList={[asapDeliveryDay]}
                            />
                        </p>
                    </div>
                </div>
                <AutoDeliveryDetailsFooter className='mt-8 ml:border-none ml:pt-0'>
                    <AppButton
                        primary
                        light
                        className='mt-0'
                        onClick={onConfirm}
                        aria-label={getAriaLabel(AUTO_DELIVERY_ASAP_POPUP_CONFIRM_BUTTON_ARIA_LABEL)}
                    >
                        <AemEditableText
                            pageName={routePaths.AUTO_DELIVERY}
                            itemName='deliveryAsapConfirmPopupButton'
                        />
                    </AppButton>
                </AutoDeliveryDetailsFooter>
            </div>
        </Popup>
    );
};

export default DeliveryAsapConfirmPopup;
