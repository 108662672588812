import React, {useId, useState} from 'react';
import {useSelector} from 'react-redux';

import PopupMobileSticked from '@/components/Popup/PopupMobileSticked';
import appErrorService from '@/services/app/appErrorService';
import feedbackPopupService from '@/services/feedbackPopupService';
import modalService from '@/services/modalService';
import {selectConsumerId} from '@/state/selectors/consumer';

import FeedbackPopupContent from './components/FeedbackPopupContent';
import ThanksPopupContent from './components/ThanksPopupContent';
import consumerFeedbackGatheringService from './consumerFeedbackGatheringService';

const ConsumerFeedbackGatheringPopup = ({modalType}) => {
    const headerId = useId();
    const consumerId = useSelector(selectConsumerId);
    const [isThanksPopupVisible, setIsThanksPopupVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onClose = () => {
        modalService.hideModal(modalType);
        if (!isThanksPopupVisible) {
            feedbackPopupService.handleFeedbackClosing();
        }
    };

    const onSubmit = async (data) => {
        setIsLoading(true);
        await consumerFeedbackGatheringService.uploadFeedbackData({data, consumerId, onSuccess, onError});
        setIsLoading(false);
    };

    const onSuccess = () => {
        setIsThanksPopupVisible(true);
        feedbackPopupService.setDoNotShowAnymore();
    };

    const onError = () => {
        appErrorService.showGlobalError();
    };

    const onBackButtonClick = () => {
        onClose();
    };

    return (
        <PopupMobileSticked
            className='flex flex-col items-center px-8 ml:max-w-[568px] ml:pb-8'
            onClose={onClose}
            isCloseVisible
            aria-labelledby={headerId}
        >
            {isThanksPopupVisible ? (
                <ThanksPopupContent headerId={headerId} onBackButtonClick={onBackButtonClick} />
            ) : (
                <FeedbackPopupContent headerId={headerId} onSubmit={onSubmit} isLoading={isLoading} />
            )}
        </PopupMobileSticked>
    );
};

export default ConsumerFeedbackGatheringPopup;
