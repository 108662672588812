import {GLOBAL_GOOD_AFTERNOON, GLOBAL_GOOD_EVENING, GLOBAL_GOOD_MORNING} from '@/consts/localization/localizationKeys';
import {getLocalizedStrings} from '@/services/localization/localizationService';

const getLocalizedTimeOfDayGreeting = () => {
    const localizedStrings = getLocalizedStrings();
    const hours = new Date().getHours();

    if (hours >= 6 && hours < 12) return localizedStrings[GLOBAL_GOOD_MORNING];

    if (hours >= 12 && hours < 18) return localizedStrings[GLOBAL_GOOD_AFTERNOON];

    return localizedStrings[GLOBAL_GOOD_EVENING];
};

export default {
    getLocalizedTimeOfDayGreeting,
};
