import {useSelector} from 'react-redux';

import {selectIsDesktopOnlyLayout} from '@/state/selectors/global';

const useIsDesktopOnlyLayout = () => {
    const isDesktopOnlyLayout = useSelector(selectIsDesktopOnlyLayout);

    return isDesktopOnlyLayout;
};

export default useIsDesktopOnlyLayout;
