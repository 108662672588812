import React, {useEffect} from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import DeviceIcon from '@/components/DeviceIcon/DeviceIcon';
import {StickyPageLayout} from '@/components/Layout';
import {
    AD_ENTRY_POINT_HEADER_TITLE,
    ADVANCED_DIAGNOSTICS_ENTRY_POINT_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeADTracking from '@/services/analytics/amplitude/amplitudeADTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import routerService from '@/services/route/routerService';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners} from '@/views/WarningBanners';

import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticDescription from '../components/AdvancedDiagnosticDescription';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';

const AdvancedDiagnosticEntryPoint = () => {
    const localizedStrings = getLocalizedStrings();

    const onDiagnosticStart = () => {
        appRouterService.forwardToDamageCheckAD();
    };

    useDocumentTitle(localizedStrings[AD_ENTRY_POINT_HEADER_TITLE]);

    useEffect(() => {
        amplitudeADTracking.trackADEntryPointScreen();
        googleAnalyticsService.trackAdEntryPointScreenView();
    }, []);

    return (
        <StickyPageLayout
            className='items-center'
            headerOptions={{
                onBack: routerService.goBack,
                title: localizedStrings[AD_ENTRY_POINT_HEADER_TITLE],
            }}
            contentClassName='flex flex-1 flex-col items-center'
            footer={
                <AppButton
                    primary
                    light
                    onClick={onDiagnosticStart}
                    className='m-auto'
                    aria-label={getAriaLabel(ADVANCED_DIAGNOSTICS_ENTRY_POINT_BUTTON_ARIA_LABEL)}
                >
                    <AemComponent
                        component={EditableText}
                        pageName={routePaths.ADVANCE_DIAGNOSTIC}
                        itemName='entrypointbutton'
                    />
                </AppButton>
            }
        >
            <AdvancedDiagnosticContainer className='pt-0 ml:pt-0-hw-offset'>
                <DeviceIcon mode='diagnostics' className='h-16 w-16' />
                <AdvancedDiagnosticTitle itemName='entrypointtitle' />
                <AdvancedDiagnosticDescription itemName='entrypointdesc' />
            </AdvancedDiagnosticContainer>
            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.AD} />
        </StickyPageLayout>
    );
};

export default AdvancedDiagnosticEntryPoint;
