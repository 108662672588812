import * as localizationKeys from '@/consts/localization/localizationKeys';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {setNotificationError} from '@/state/slices/notificationsSlice';
import {dispatch} from '@/state/store';

const showError = (errorKey) => dispatch(setNotificationError(getLocalizedStrings()[errorKey]));

const showGlobalError = () => {
    showError(localizationKeys.GLOBAL_ERROR_NOTIFICATION_TEXT);
};

export default {
    showGlobalError,
};
