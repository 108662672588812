import {useEffect} from 'react';

import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import useIsWelcomeMode from '@/hooks/useIsWelcomeMode';
import pushNotificationService from '@/services/pushNotificationService';
import storageService from '@/services/storage/storageService';
import {showEnableNotificationModal} from '@/state/slices/modalSlice';
import {dispatch} from '@/state/store';

const useShowEnableNotificationModal = () => {
    const {isWelcomeMode, isAnimationFinished} = useIsWelcomeMode();
    const isMobileLayout = useIsMobileLayout();
    const isPermissionDenied = storageService.getPermissionDeniedLocalStorage();

    useEffect(() => {
        const run = async () => {
            const shouldNotificationScreenBeDisplayed =
                await pushNotificationService.checkIfNotificationScreenShouldBeDisplayed();

            if (isPermissionDenied || !shouldNotificationScreenBeDisplayed) {
                return;
            }

            if (isWelcomeMode && !isMobileLayout) {
                if (isAnimationFinished) {
                    dispatch(showEnableNotificationModal());
                }
            } else {
                dispatch(showEnableNotificationModal());
            }
        };

        run();
    }, [isWelcomeMode, isAnimationFinished, isPermissionDenied]);
};

export default useShowEnableNotificationModal;
