import authService from '@/services/auth/authService';
import iccMarketService from '@/services/icc/iccMarketService';
import languageDataService from '@/services/localization/languageDataService';
import log from '@/services/logger/log';
import marketConfigService from '@/services/marketConfigService';
import appRouterService from '@/services/route/appRouterService';
import {selectIsLoggedIn} from '@/state/selectors/auth';
import {selectIsConsumerDataFetched} from '@/state/selectors/consumer';
import {selectIsAem} from '@/state/selectors/global';
import {getState} from '@/state/store';

const onAppStart = async () => {
    try {
        const state = getState();
        const isLoggedIn = selectIsLoggedIn(state);

        if (isLoggedIn) {
            await fetchConsumerData();

            if (appRouterService.isModalsPage()) {
                appRouterService.forwardToModalsPage();
            } else {
                const isAem = selectIsAem(state);

                if (isAem) {
                    appRouterService.forwardToTncPage();
                }
            }

            return;
        }

        if (marketConfigService.isInstallPwaEnabled()) {
            appRouterService.forwardToInstallPwaPage();

            return;
        }
    } catch (e) {
        log.info(`onAppStart: error: : ${JSON.stringify(e)}`);
    }

    appRouterService.forwardToLoginPage();
};

const fetchConsumerData = async () => {
    try {
        const isConsumerDataFetched = selectIsConsumerDataFetched(getState());

        if (isConsumerDataFetched) return;

        await iccMarketService.getExternalConsumerProfile();
        await iccMarketService.getConsumerProfile();
        await iccMarketService.getConsumerAggregatedSettings();

        await languageDataService.checkAndSetConsumerLanguageCode();
    } catch (e) {
        authService.logout();
        throw e;
    }
};

export default {
    onAppStart,
    fetchConsumerData,
};
