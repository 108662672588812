import {useState} from 'react';

import {updateAutoDeliveryDetails} from '@/api/consumerApi';
import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import {ErrorBigIcon, PlusOneDayIcon} from '@/components/Icons';
import AppLoader from '@/components/Loader/AppLoader/AppLoader';
import {
    AUTO_DELIVERY_SKIP_DELIVERY_CONFIRM_BUTTON_ARIA_LABEL,
    AUTO_DELIVERY_SKIP_POPUP_ERROR_BUTTON_ARIA_LABEL,
    AUTO_DELIVERY_SKIP_POPUP_SUCCESS_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeAutoDeliveryTracking from '@/services/analytics/amplitude/amplitudeAutoDeliveryTracking';
import iccAutoDeliveryService from '@/services/icc/iccAutoDeliveryService';
import routerService from '@/services/route/routerService';
import helpers from '@/utils/helpers';
import stringUtils from '@/utils/stringUtils';

import useDeliveryDate from '../hooks/useDeliveryDate';
import useOrderSummaryTracking from '../hooks/useOrderSummaryTracking';
import EditDeliveryResultPopup from './EditDeliveryResultPopup';

const ConfirmSkipButton = ({closePopup}) => {
    const {startDeliveryDate} = useDeliveryDate();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessPopupVisible, setIsSuccessPopupVisible] = useState(false);
    const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
    const orderSummaryTrackingValue = useOrderSummaryTracking();

    const onConfirmSubmit = () => {
        amplitudeAutoDeliveryTracking.trackAutoDeliverySkipDeliveryStarted(orderSummaryTrackingValue);
        setIsLoading(true);
        iccAutoDeliveryService.skipAutoDelivery({
            onSuccess: (responseData) => {
                setIsLoading(false);
                setIsSuccessPopupVisible(true);
                updateAutoDeliveryDetails(responseData);
                amplitudeAutoDeliveryTracking.trackAutoDeliverySkipDeliveryCompleted({
                    success: true,
                    orderSummary: orderSummaryTrackingValue,
                });
            },
            onError: (errorMessage) => {
                setIsLoading(false);
                setIsErrorPopupVisible(true);
                amplitudeAutoDeliveryTracking.trackAutoDeliverySkipDeliveryCompleted({
                    success: false,
                    message: errorMessage,
                    orderSummary: orderSummaryTrackingValue,
                });
            },
        });
    };
    const onResultPopupClose = () => {
        setIsErrorPopupVisible(false);
        setIsSuccessPopupVisible(false);
        helpers.runFunction(closePopup);
    };
    const onBackButtonClick = () => {
        routerService.go(-2);
    };

    return (
        <>
            <AppButton
                primary
                light
                className='mt-0'
                onClick={onConfirmSubmit}
                aria-label={getAriaLabel(AUTO_DELIVERY_SKIP_DELIVERY_CONFIRM_BUTTON_ARIA_LABEL)}
            >
                <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='skipDeliveryConfirmButton' />
            </AppButton>
            {isLoading && <AppLoader />}
            {isSuccessPopupVisible && (
                <EditDeliveryResultPopup
                    icon={<PlusOneDayIcon />}
                    title={
                        <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='skipDeliveryPopupSuccessTitle' />
                    }
                    description={
                        <AemEditableText
                            pageName={routePaths.AUTO_DELIVERY}
                            itemName='skipDeliveryPopupSuccessDescription'
                            render={(text) => {
                                const textFormatted = stringUtils.formatString(text, startDeliveryDate);

                                return textFormatted ? (
                                    <span dangerouslySetInnerHTML={{__html: textFormatted}} />
                                ) : null;
                            }}
                        />
                    }
                    button={
                        <AemEditableText
                            pageName={routePaths.AUTO_DELIVERY}
                            itemName='skipDeliveryPopupSuccessButton'
                        />
                    }
                    buttonAriaLabel={getAriaLabel(AUTO_DELIVERY_SKIP_POPUP_SUCCESS_BUTTON_ARIA_LABEL)}
                    onButtonClick={onBackButtonClick}
                    onClose={onResultPopupClose}
                />
            )}
            {isErrorPopupVisible && (
                <EditDeliveryResultPopup
                    icon={<ErrorBigIcon />}
                    title={
                        <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='skipDeliveryPopupErrorTitle' />
                    }
                    description={
                        <AemEditableText
                            pageName={routePaths.AUTO_DELIVERY}
                            itemName='skipDeliveryPopupErrorDescription'
                        />
                    }
                    button={
                        <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='skipDeliveryPopupErrorButton' />
                    }
                    buttonAriaLabel={getAriaLabel(AUTO_DELIVERY_SKIP_POPUP_ERROR_BUTTON_ARIA_LABEL)}
                    onButtonClick={onBackButtonClick}
                    onClose={onResultPopupClose}
                />
            )}
        </>
    );
};

export default ConfirmSkipButton;
