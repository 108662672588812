import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {ArrowSmallIcon} from '@/components/Icons';
import PageSection from '@/components/Layout/PageSection';
import * as localizationKeys from '@/consts/localization/localizationKeys';
import {HIGHLIGHTS_VIEW_ALL_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import useLayout from '@/hooks/layout/useLayout';
import usePageSectionTitle from '@/hooks/usePageSectionTitle';
import {getAriaLabel} from '@/services/accessibilityService';
import {getLocalizedStrings} from '@/services/localization/localizationService';

import {useHighlightsSectionId} from '../../../contexts/HighlightsSectionIdContext';

const HighlightsHeader = ({onViewAllClick}) => {
    const id = useHighlightsSectionId();
    const {isTabletAndDesktop} = useLayout();
    const localizedStrings = getLocalizedStrings();

    const titleText = usePageSectionTitle(id);
    const buttonText = localizedStrings[localizationKeys.HIGHLIGHTS_VIEW_ALL_BUTTON];

    if (!titleText && !buttonText) return null;

    return (
        <header className='flex items-center justify-between gap-2'>
            <PageSection.Title
                className='overflow-hidden text-ellipsis whitespace-nowrap tracking-04 md:tracking-normal'
                text={titleText}
            />
            {isTabletAndDesktop && buttonText && (
                <ButtonRaw
                    className='flex shrink-0 gap-2 tracking-05 text-scarpa-flow md:py-3'
                    onClick={onViewAllClick}
                    aria-label={getAriaLabel(HIGHLIGHTS_VIEW_ALL_BUTTON_ARIA_LABEL)}
                >
                    <span className='flex-1'>{buttonText}</span>
                    <ArrowSmallIcon />
                </ButtonRaw>
            )}
        </header>
    );
};

export default HighlightsHeader;
