import React from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import RemoteIcon from '@/components/Icon/RemoteIcon';
import {MaxWidthContainer} from '@/components/Layout';
import appConfig from '@/config/appConfig';
import routePaths from '@/consts/route/routePaths';

const FirmwareAvailableArticle = ({headerId}) => {
    const headerIcon = appConfig.getFirmwareAvailablePopupHeaderIcon();

    return (
        <MaxWidthContainer className='flex-1 px-4 pb-4 pt-safe-offset-[calc(var(--sticky-header-height)+16px)] ml:w-full ml:max-w-[358px] ml:p-0 ml:pt-safe-offset-14'>
            <RemoteIcon icon={headerIcon} className='h-16 w-16 p-2' />
            <h1 id={headerId} className='mt-4 text-28 ml:mb-0 ml:leading-143'>
                <AemComponent component={EditableText} pageName={routePaths.MODALS} itemName='fwAvailableTitle' />
            </h1>
            <p className='ml:mt-4 ml:text-16 ml:leading-15'>
                <AemComponent component={EditableText} pageName={routePaths.MODALS} itemName='fwAvailableDesc' />
            </p>
            <p className='mt-4 text-12 leading-13 tracking-02 ml:mt-6'>
                <AemComponent component={EditableText} pageName={routePaths.MODALS} itemName='fwAvailableFootnote' />
            </p>
        </MaxWidthContainer>
    );
};

export default FirmwareAvailableArticle;
