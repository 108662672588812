import React from 'react';

import appConfig from '@/config/appConfig';
import {
    AUTO_DELIVERY_PAYMENT_PENDING_BUTTON,
    AUTO_DELIVERY_PAYMENT_PENDING_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';

import AutoDeliveryButton from '../AutoDeliveryButton';

const AutoDeliveryPaymentPendingButton = ({className, amplitudeEvent}) => {
    return (
        <AutoDeliveryButton
            primary
            light
            className={className}
            localizationKey={AUTO_DELIVERY_PAYMENT_PENDING_BUTTON}
            amplitudeEvent={amplitudeEvent}
            externalLinkConfig={appConfig.getAutoDeliveryPaymentPendingExternalLink()}
            ariaLabelKey={AUTO_DELIVERY_PAYMENT_PENDING_BUTTON_ARIA_LABEL}
        />
    );
};

export default AutoDeliveryPaymentPendingButton;
