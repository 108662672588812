import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import externalLinkService from '@/services/app/externalLinkService';
import deviceConnectionService from '@/services/device/deviceConnectionService';
import iccMarketService from '@/services/icc/iccMarketService';
import iccSsoService from '@/services/icc/iccSsoService';
import appRouterService from '@/services/route/appRouterService';
import {makeSelectExternalLink} from '@/state/selectors/consumer';
import {selectLanguageCode} from '@/state/selectors/global';
import {makeSelectSsoLink} from '@/state/selectors/media';
import {addSsoLink} from '@/state/slices/mediaSlice';
import uaUtils from '@/utils/uaUtils';

const useExternalLink = ({
    url,
    externalLinkCode,
    ssoTemplate,
    openInBluefy,
    isConnectionLink,
    ssoParams,
    linkProcessor,
    disabled,
    deviceType,
    deviceSerialNumber,
}) => {
    openInBluefy = openInBluefy || isConnectionLink;

    const languageCode = useSelector(selectLanguageCode);
    const formattedSsoTemplate = iccSsoService.getFormattedSsoTemplate({ssoTemplate, params: ssoParams});
    const isIos = uaUtils.getIsIos();
    const {link, status, expirationDate} = useSelector(makeSelectSsoLink(formattedSsoTemplate));
    const {value: externalLink = url} = useSelector(makeSelectExternalLink(externalLinkCode, languageCode));

    useSsoLinkFetch({ssoTemplate, ssoParams, status, linkProcessor, disabled});
    useSsoLinkRefresh({expirationDate, formattedSsoTemplate, disabled});
    useExternalLinkFetch(externalLink, externalLinkCode);

    const canLinkBeOpenedInNewTab = ssoTemplate && isIos && !openInBluefy && link;
    let redirect;

    if (canLinkBeOpenedInNewTab) {
        redirect = () => externalLinkService.openLinkInNewTab(link);
    } else if (isConnectionLink && !deviceConnectionService.isBleConnectionSupported(deviceType)) {
        redirect = () => appRouterService.forwardToUsbRequiredPage(deviceType);
    } else {
        redirect = () =>
            externalLinkService.openExternalLink({
                ssoTemplate,
                externalLink,
                externalLinkCode,
                openInBluefy: openInBluefy && isIos,
                linkProcessor,
                ssoParams,
                link,
                deviceType,
                deviceSerialNumber,
            });
    }

    return {redirect, link, externalLink};
};

const useExternalLinkFetch = (externalLink, externalLinkCode) => {
    useEffect(() => {
        if (externalLink) return;
        if (!externalLinkCode) return;

        iccMarketService.getExternalLink(externalLinkCode);
    }, [externalLink, externalLinkCode]);
};

const useSsoLinkFetch = ({ssoTemplate, ssoParams, status, linkProcessor, disabled}) => {
    useEffect(() => {
        if (disabled) return;
        if (status) return;

        iccSsoService.getSsoLink({
            ssoTemplate,
            params: ssoParams,
            linkProcessor,
        });
    }, [ssoTemplate, status, disabled]);
};

const useSsoLinkRefresh = ({expirationDate, formattedSsoTemplate, disabled}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (disabled) return;
        if (!expirationDate) return;

        const {expiredInMs, isExpired} = iccSsoService.getLinkExpiration(expirationDate);
        const timeoutMs = isExpired ? 0 : expiredInMs;

        const timeout = setTimeout(() => {
            // Clear stored ssoLink data, so it will be fetched again in useSsoLinkFetch hook
            dispatch(addSsoLink({ssoTemplate: formattedSsoTemplate}));
        }, timeoutMs);

        return () => clearTimeout(timeout);
    }, [expirationDate, disabled]);
};

export default useExternalLink;
