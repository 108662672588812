import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {ButtonRaw} from '@/components/Button';
import {UserIcon} from '@/components/Icons';
import {StickyHeader} from '@/components/Layout';
import DesktopStickyHeader from '@/components/Layout/DesktopStickyHeader';
import {CONSUMER_PROFILE_OPEN_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import useIsDesktopLayout from '@/hooks/layout/useIsDesktopLayout';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import {getAriaLabel} from '@/services/accessibilityService';
import appErrorService from '@/services/app/appErrorService';
import homePageNavigationService from '@/services/homePageNavigationService';
import appRouterService from '@/services/route/appRouterService';
import tncService from '@/services/tncService';
import {hideLoader, showLoader} from '@/state/slices/loadersSlice';
import {twx} from '@/utils/tailwindUtils';

import InboxIcon from './InboxIcon/InboxIcon';

const HomePageTopNavigation = ({source, title, childRenderer, className, profileControlsClassName}) => {
    const isDesktop = useIsDesktopLayout();

    useEffect(() => {
        if (isDesktop) return;
        homePageNavigationService.setSource(source);
    }, [source, isDesktop]);

    const endIcon = ({isSticked}) => <ProfileControls className={isSticked ? '' : profileControlsClassName} />;

    return isDesktop ? (
        <DesktopStickyHeader
            endIcon={endIcon}
            className={className}
            fixedClassName='w-[calc(100%-var(--sidebar-width))]'
            childRenderer={childRenderer}
        />
    ) : (
        <StickyHeader title={title} endIcon={endIcon} className={className} />
    );
};

const ProfileControls = ({className}) => {
    const dispatch = useDispatch();
    const isMobile = useIsMobileLayout();

    const onConsumerProfileClick = async () => {
        if (isMobile) {
            appRouterService.forwardToConsumerProfilePage();
        } else {
            const onTncLoaded = appRouterService.forwardToConsumerProfilePage;
            const onError = () => {
                appErrorService.showGlobalError();
                dispatch(hideLoader());
            };

            // Loader should be controlled manually because otherwise there is a flickering effect when page changes.
            // It gets shown before request for tnc is made and gets hidden after ProfilePreferenceCenterPage is loaded,
            // inside its useEffect
            dispatch(showLoader());
            await tncService.getTncData(onTncLoaded, onError, {disableLoader: true});
        }
    };

    return (
        <div className={twx('flex gap-2 rounded-full bg-secondary p-1', className)}>
            <InboxIcon />
            <ButtonRaw
                onClick={onConsumerProfileClick}
                aria-label={getAriaLabel(CONSUMER_PROFILE_OPEN_BUTTON_ARIA_LABEL)}
            >
                <UserIcon className='h-6 w-6' />
            </ButtonRaw>
        </div>
    );
};

export default HomePageTopNavigation;
