import cn from 'classnames';
import React from 'react';
import {SwiperSlide} from 'swiper/react';

import PageSection from '@/components/Layout/PageSection';
import SwiperCarousel from '@/components/Swiper/SwiperCarousel';
import SwiperNavigation from '@/components/Swiper/SwiperNavigation';
import useSwiperNavigation from '@/components/Swiper/useSwiperNavigation';
import useConsumerProductsLoader from '@/hooks/useConsumerProductsLoader';
import usePageSectionTitle from '@/hooks/usePageSectionTitle';
import useRefetchConsumerProducts from '@/hooks/useRefetchConsumerProducts';

import useGetDeviceSectionList from './hooks/useGetDeviceSectionList';

const DevicesSection = ({id, className}) => {
    const titleText = usePageSectionTitle(id);
    const devices = useGetDeviceSectionList();

    const {prevRef, nextRef, isBeginning, isEnd, swiperOptions} = useSwiperNavigation();

    useRefetchConsumerProducts();
    useConsumerProductsLoader();

    if (!devices?.length) return null;

    return (
        <PageSection className={cn('overflow-hidden px-0', className)}>
            <div className='mb-4 px-4 text-start md:mb-6 ml:mb-8 lg:flex lg:justify-between'>
                <PageSection.Title text={titleText} />
                <SwiperNavigation prevRef={prevRef} nextRef={nextRef} isBeginning={isBeginning} isEnd={isEnd} />
            </div>
            <SwiperCarousel options={swiperOptions}>
                {devices.map((slide, index) => (
                    <SwiperSlide key={index}>{slide}</SwiperSlide>
                ))}
            </SwiperCarousel>
        </PageSection>
    );
};

export default DevicesSection;
