import formatTokenKeys from '@/consts/app/formatTokenKeys';
import {getMappedInitialLanguageCode} from '@/services/localization/localizationService';
import stringUtils from '@/utils/stringUtils';

const getLanguageFormattedString = (str) => {
    const language = getMappedInitialLanguageCode();

    return formatStringWithLanguage(str, language?.toLowerCase());
};

const formatStringWithLanguage = (str, language) =>
    stringUtils.formatByName(str, {[formatTokenKeys.CULTURE_CODE]: language});

export default {getLanguageFormattedString, formatStringWithLanguage};
