import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {twx} from '@/utils/tailwindUtils';

import {LANGUAGE_SELECT_TITLE_ID} from '../../consts/languageSelectConsts';

const LanguageSelectContentTitle = ({className, pageName, renderAs: Heading = 'h2'}) => {
    return (
        <Heading id={LANGUAGE_SELECT_TITLE_ID} className={twx('m-0 p-0 text-left ml:pr-8', className)}>
            <AemEditableText pageName={pageName || 'modals'} itemName='languageselecttitle' />
        </Heading>
    );
};

export default LanguageSelectContentTitle;
