import React from 'react';
import {useSelector} from 'react-redux';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {TncIcon} from '@/components/Icons';
import {StickyPageLayout} from '@/components/Layout';
import * as localizationKeys from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import {TERMS_OF_USE} from '@/consts/tnc/tncTypes';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import useTermsOfUsePopup from '@/hooks/useTermsOfUsePopup';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {makeSelectAcceptedTncByType, makeSelectTncByType} from '@/state/selectors/consumer';
import TermsOfUseButtons from '@/views/Tnc/TermsOfUseButtons';
import TermsOfUseDescription from '@/views/Tnc/TermsOfUseDescription/TermsOfUseDescription';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners} from '@/views/WarningBanners';

import TncPageContainer from '../TncPageContainer';

const TermsOfUsePage = () => {
    const isMobileLayout = useIsMobileLayout();
    const termsOfUse = useSelector(makeSelectTncByType(TERMS_OF_USE));
    const acceptedTermsOfUse = useSelector(makeSelectAcceptedTncByType(TERMS_OF_USE));
    const {openPopup} = useTermsOfUsePopup();
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[localizationKeys.TERMS_OF_USE_HEADER_TITLE]);

    if (!termsOfUse) return null;

    const {description, legals, updateDescription} = termsOfUse;

    if (!legals) return null;

    const isUpdatedTnc = !!acceptedTermsOfUse;
    const descriptionText = isUpdatedTnc ? updateDescription : description;

    return (
        <TncPageContainer>
            <StickyPageLayout
                className='items-center'
                desktopClassName='w-[506px] justify-center py-5 flex-1 pt-5-hw-offset md:pb-8'
                desktopContentClassName='flex flex-col items-center'
                desktopFooterClassName='mt-6 md:mt-8'
                headerOptions={{
                    title: localizedStrings[localizationKeys.TERMS_OF_USE_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center pt-safe-offset-sticky-header'
                footer={<TermsOfUseButtons className='flex flex-col gap-3' />}
            >
                <div className='flex flex-1 flex-col items-center'>
                    <TncIcon />
                    <h1 className='mt-9 px-4 text-28 md:mb-0 md:mt-4'>
                        {isUpdatedTnc && <AemEditableText pageName={routePaths.TNC} itemName='termsOfUseTitleUpdate' />}
                        {!isUpdatedTnc && <AemEditableText pageName={routePaths.TNC} itemName='termsOfUseTitle' />}
                    </h1>
                    <TermsOfUseDescription
                        className='mt-4 px-4'
                        innerHtml={descriptionText}
                        tncType={TERMS_OF_USE}
                        onTncLinkClick={openPopup}
                    />

                    {isMobileLayout && (
                        <div className='mt-auto pt-4'>
                            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.TNC} />
                        </div>
                    )}
                </div>
            </StickyPageLayout>
            {!isMobileLayout && <WarningBanners className='-mt-4' source={WARNING_BANNER_SOURCE_TYPES.TNC} />}
        </TncPageContainer>
    );
};

export default TermsOfUsePage;
