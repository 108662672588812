import appConfig from '@/config/appConfig';
import {
    CONTACT_US_PAGE_FACEBOOK_BUTTON_ARIA_LABEL,
    CONTACT_US_PAGE_INSTAGRAM_BUTTON_ARIA_LABEL,
    CONTACT_US_PAGE_TELEGRAM_BUTTON_ARIA_LABEL,
    CONTACT_US_PAGE_TWITTER_BUTTON_ARIA_LABEL,
    CONTACT_US_PAGE_YOUTUBE_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import {getLocalizedStrings} from '@/services/localization/localizationService';

import {SOCIAL_CHANNEL_ICON_IDS} from '../consts/socialChannels';

const useGetContactUs = () => {
    const contactUs = appConfig.getContactUs();
    const localizedStrings = getLocalizedStrings();

    if (!contactUs) return null;

    const {
        imageMedia,
        socialChannelsTwitterAction,
        socialChannelsFacebookAction,
        socialChannelsYoutubeAction,
        socialChannelsTelegramAction,
        socialChannelsInstagramAction,
        sendQuestionEmail,
        comeVisitUsNearestStoreAction,
        callUsPhoneNumber,
        weekdaysTitle,
        weekdaysHours,
        saturdayTitle,
        saturdayHours,
        sundayTitle,
        sundayHours,
    } = contactUs;

    const socialChannels = [
        {
            id: SOCIAL_CHANNEL_ICON_IDS.TWITTER,
            action: socialChannelsTwitterAction,
            ariaLabelKey: CONTACT_US_PAGE_TWITTER_BUTTON_ARIA_LABEL,
        },
        {
            id: SOCIAL_CHANNEL_ICON_IDS.FACEBOOK,
            action: socialChannelsFacebookAction,
            ariaLabelKey: CONTACT_US_PAGE_FACEBOOK_BUTTON_ARIA_LABEL,
        },
        {
            id: SOCIAL_CHANNEL_ICON_IDS.YOUTUBE,
            action: socialChannelsYoutubeAction,
            ariaLabelKey: CONTACT_US_PAGE_YOUTUBE_BUTTON_ARIA_LABEL,
        },
        {
            id: SOCIAL_CHANNEL_ICON_IDS.TELEGRAM,
            action: socialChannelsTelegramAction,
            ariaLabelKey: CONTACT_US_PAGE_TELEGRAM_BUTTON_ARIA_LABEL,
        },
        {
            id: SOCIAL_CHANNEL_ICON_IDS.INSTAGRAM,
            action: socialChannelsInstagramAction,
            ariaLabelKey: CONTACT_US_PAGE_INSTAGRAM_BUTTON_ARIA_LABEL,
        },
    ];

    const callUs = {
        phoneNumber: callUsPhoneNumber,
        workingDays: [
            {
                title: localizedStrings[weekdaysTitle],
                hours: localizedStrings[weekdaysHours],
            },
            {
                title: localizedStrings[saturdayTitle],
                hours: localizedStrings[saturdayHours],
            },
            {
                title: localizedStrings[sundayTitle],
                hours: localizedStrings[sundayHours],
            },
        ],
    };

    return {
        imageMedia,
        socialChannels: socialChannels.filter((c) => c.action),
        email: sendQuestionEmail,
        nearestStoreAction: comeVisitUsNearestStoreAction,
        callUs,
    };
};

export default useGetContactUs;
