import React from 'react';
import {useSelector} from 'react-redux';
import {format} from 'react-string-format';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import BackButton from '@/components/BackButton/BackButton';
import {ButtonRaw} from '@/components/Button';
import AppButton from '@/components/Button/AppButton';
import {ComputerBlueIcon} from '@/components/Icons';
import appConfig from '@/config/appConfig';
import queryParameters from '@/consts/route/queryParameters';
import routePaths from '@/consts/route/routePaths';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import deviceTypesService from '@/services/device/deviceTypesService';
import appRouterService from '@/services/route/appRouterService';
import {selectDeviceType} from '@/state/selectors/device';
import urlUtils from '@/utils/urlUtils';

const UsbRequiredPage = () => {
    const iqosAppLink = appConfig.getIqosAppsLandingPageLink();
    const {redirectToExternalLink, externalLink} = useConfigExternalLink({...iqosAppLink, returnUrl: true}) || {};
    const [deviceTypeFromQuery] = urlUtils.getDataFromUrl(queryParameters.DEVICE_TYPE);
    const deviceTypeFromState = useSelector(selectDeviceType);
    const deviceType = deviceTypeFromQuery || deviceTypeFromState;
    const deviceName = deviceTypesService.getDeviceModelNameByType(deviceType);

    const redirectToSelectYourDevice = () => {
        const onBackRoute = routePaths.DEVICES_PAGE;

        appRouterService.forwardToSelectYourDevicePage({onBackRoute});
    };

    return (
        <section className='flex h-screen flex-1 flex-col gap-4 pt-safe-offset-4'>
            <BackButton className='ml-2' onClick={redirectToSelectYourDevice} />
            <div className='flex flex-1 flex-col items-center gap-4 px-4 pb-8'>
                <ComputerBlueIcon className='h-16 w-16' />
                <h1 className='mb-0 px-6 leading-[40px]'>
                    <AemEditableText
                        pageName={routePaths.USB_REQUIRED}
                        itemName='usbRequiredTitle'
                        contentList={deviceName ? [deviceName] : undefined}
                    />
                </h1>
                <p>
                    <AemEditableText
                        pageName={routePaths.USB_REQUIRED}
                        itemName='usbRequiredDescription'
                        render={(text) =>
                            text &&
                            format(
                                text,
                                externalLink ? (
                                    <ButtonRaw renderAs='a' className='inline' onClick={redirectToExternalLink}>
                                        {externalLink}
                                    </ButtonRaw>
                                ) : undefined
                            )
                        }
                    />
                </p>
            </div>
            <div className='w-full px-4 pt-4 pb-safe-offset-8'>
                <AppButton tertiary onClick={redirectToSelectYourDevice}>
                    <AemEditableText pageName={routePaths.USB_REQUIRED} itemName='usbRequiredConnectButton' />
                </AppButton>
            </div>
        </section>
    );
};

export default UsbRequiredPage;
