import cn from 'classnames';
import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {ButtonRaw} from '@/components/Button';
import appConfig from '@/config/appConfig';
import {AUTO_DELIVERY_FOOTER_TNC_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import {getAriaLabel} from '@/services/accessibilityService';

const AutoDeliveryDetailsFooter = ({className, children}) => {
    const autoDeliveryTncExternalLink = appConfig.getAutoDeliveryTncExternalLink();
    const redirectToExternalLink = useConfigExternalLink(autoDeliveryTncExternalLink);

    return (
        <div className={cn('flex flex-col gap-4 md:mt-8 md:border-t md:border-mischka md:pt-4', className)}>
            <div className='text-start text-12 tracking-02'>
                <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='footerMoreInfoText' />
                <span> </span>
                {redirectToExternalLink && (
                    <ButtonRaw
                        renderAs='a'
                        className='inline underline'
                        onClick={redirectToExternalLink}
                        aria-label={getAriaLabel(AUTO_DELIVERY_FOOTER_TNC_BUTTON_ARIA_LABEL)}
                    >
                        <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='footerTncText' />
                    </ButtonRaw>
                )}
            </div>
            {children}
        </div>
    );
};

export default AutoDeliveryDetailsFooter;
