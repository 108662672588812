import {VisuallyHidden} from '@radix-ui/react-visually-hidden';
import cn from 'classnames';
import React from 'react';

import Image from '@/components/Image/Image';
import {getImageByViewType, IMAGE_VIEW_TYPES} from '@/consts/icc/imageViewTypes';
import {AUTO_DELIVERY_ITEM_QUANTITY_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import {getFormattedAriaLabel} from '@/services/accessibilityService';
import {twx} from '@/utils/tailwindUtils';

const AutoDeliveryOrder = ({className, products, children}) => {
    if (!products?.length) return;

    return (
        <div className={twx('px-4 pb-4 pt-10', className)}>
            {children}
            <ul>
                {products.map((product, i) => {
                    return <ProductListItem product={product} key={i} />;
                })}
            </ul>
        </div>
    );
};

export default AutoDeliveryOrder;

const ProductListItem = ({product}) => {
    const {name, images, quantity, isOutOfStock} = product;
    const {link} = getImageByViewType(images, IMAGE_VIEW_TYPES.SMALL) || {};
    const quantityAriaLabel = getFormattedAriaLabel(AUTO_DELIVERY_ITEM_QUANTITY_ARIA_LABEL, name, quantity);

    return (
        <li className='flex items-center gap-2 border-b border-mischka py-4 text-black last:border-none md:py-2'>
            <Image
                src={link}
                className={cn('h-16 w-16 rounded-md bg-secondary object-contain', isOutOfStock && 'opacity-40')}
            />
            <div className={cn('flex-1', isOutOfStock && 'text-granite-gray')}>
                {name}
                <VisuallyHidden>, {quantityAriaLabel}</VisuallyHidden>
            </div>
            <div
                className={cn('rounded bg-secondary px-2 py-1 font-bold', isOutOfStock && 'text-granite-gray')}
                aria-hidden='true'
            >
                x{quantity}
            </div>
        </li>
    );
};
