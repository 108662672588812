import React from 'react';

import {ADVANCED_DIAGNOSTICS_REMOVE_STICK_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import {getAriaLabel} from '@/services/accessibilityService';
import {getLocalizedStrings} from '@/services/localization/localizationService';

import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../../components/Button';
import appRouterService from '../../../services/route/appRouterService';
import rtlService from '../../../services/rtlService';

const AdvancedDiagnosticRemoveStickSlide = (props) => {
    const {title, description, isLastSlide} = props;
    const localizedStrings = getLocalizedStrings();

    return (
        <div
            dir={rtlService.getDir()}
            className='flex h-full rounded bg-secondary text-primary md:w-[436px] md:items-center ml:w-[597px]'
        >
            <div className='flex w-full flex-col justify-between px-4 py-9 md:px-16 md:py-16 ml:px-32'>
                <div className='flex flex-col gap-3'>
                    <h2 className='mb-0 text-20'>{localizedStrings[title]}</h2>
                    <p>{localizedStrings[description]}</p>
                </div>
                {isLastSlide && (
                    <AppButton
                        secondary
                        light
                        onClick={appRouterService.forwardToFittingConsumablesResolvedAD}
                        className='mx-auto mt-6 w-full max-w-[312px]'
                        aria-label={getAriaLabel(ADVANCED_DIAGNOSTICS_REMOVE_STICK_BUTTON_ARIA_LABEL)}
                    >
                        <AemComponent
                            component={EditableText}
                            pageName={routePaths.ADVANCE_DIAGNOSTIC}
                            itemName='removestickokbutton'
                        />
                    </AppButton>
                )}
            </div>
        </div>
    );
};

export default AdvancedDiagnosticRemoveStickSlide;
