import {createSelector} from 'reselect';

import {selectConsumerProductsMergedInfo} from '@/state/selectors/consumer';

const selectDevice = (state) => state.device;

const selectDeviceData = createSelector(selectDevice, (globalState) => globalState.data);

const selectDeviceFirmwareVersion = createSelector(selectDeviceData, (deviceData) => deviceData?.firmwareVersion);

const selectHolderData = createSelector(selectDevice, (globalState) => globalState.holderData);

const selectHolderFirmwareVersion = createSelector(selectHolderData, (holderData) => holderData?.firmwareVersion);

const selectDeviceSettings = createSelector(selectDeviceData, selectHolderData, (deviceData, holderData) => {
    const deviceSettings = deviceData?.settings;
    const holderSettings = holderData?.settings;

    //holder settings should be first so device settings can redefine holder settings
    if (deviceSettings && holderSettings) return {...holderSettings, ...deviceSettings};

    if (deviceSettings) return deviceSettings;

    return holderSettings;
});

const selectDeviceCodentify = createSelector(selectDevice, (globalState) => globalState.codentify);

const selectDeviceSerialNumber = createSelector(selectDeviceSettings, (deviceSettings) => deviceSettings?.serialNumber);

const selectCurrentProductInfo = createSelector(
    selectDeviceCodentify,
    selectConsumerProductsMergedInfo,
    (codentify, products) => {
        if (!codentify) return;
        if (!products) return;

        const product = products.find((product) => product.codentify === codentify);

        if (!product) return;

        return {
            ...product,
        };
    }
);

const selectDeviceType = createSelector(selectCurrentProductInfo, (product) => product?.type);

export {
    selectCurrentProductInfo,
    selectDeviceCodentify,
    selectDeviceData,
    selectDeviceFirmwareVersion,
    selectDeviceSerialNumber,
    selectDeviceSettings,
    selectDeviceType,
    selectHolderData,
    selectHolderFirmwareVersion,
};
