import React, {useState} from 'react';

import Container from '@/components/Layout/Container/Container';
import appService from '@/services/appService';
import authService from '@/services/auth/authService';
import log from '@/services/logger/log';
import pushNotificationService from '@/services/pushNotificationService';
import {hideLoader, showLoader} from '@/state/slices/loadersSlice';
import {dispatch} from '@/state/store';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import LoginForm from './components/LoginForm/LoginForm';
import LoginHeader from './components/LoginHeader';

const LoginPage = () => {
    const [isLoginError, setIsLoginError] = useState(false);

    const onLogin = async (credentials) => {
        setIsLoginError(false);
        dispatch(showLoader());

        try {
            await authService.login(credentials);
            await appService.fetchConsumerData();
            await pushNotificationService.checkSubscriptionInfoForNewUser();
        } catch (e) {
            setIsLoginError(true);
            log.error(`LoginPage: standaloneLogin, ${e}`);
        }

        dispatch(hideLoader());
    };

    return (
        <div className='flex w-full pt-0-hw-offset'>
            <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.LOGIN} />
            <Container className='bg-romance ml:mb-auto ml:mt-10 ml:h-auto'>
                <LoginHeader />
                <LoginForm onLogin={onLogin} isLoginError={isLoginError} setIsLoginError={setIsLoginError} />
                <WarningBanners
                    source={WARNING_BANNER_SOURCE_TYPES.LOGIN}
                    className='md:p-0'
                    containerClassName='-mt-4 md:p-8'
                    contentClassName='md:p-6 ml:p-6 md:bg-secondary'
                />
            </Container>
        </div>
    );
};

export default LoginPage;
