import React from 'react';

import {getLocalizedStrings} from '@/services/localization/localizationService';

import HomePageForYouContainer from './HomePageForYouContainer/HomePageForYouContainer';

const HomePageCampaignItem = (props) => {
    const {banner, promotedBadgeType} = props;
    const {
        titleKey,
        promotionKey,
        imageMedia,
        videoPath,
        isGradient,
        isDarkBannerText,
        amplitudeTrackingValue,
        action,
        actionAriaLabelKey,
    } = banner;
    const localizedStrings = getLocalizedStrings();
    const PROMOTED_BADGE_ROMANCE = 'romance';

    return (
        <HomePageForYouContainer
            className='bg-tertiary'
            videoClassName='absolute bottom-0 h-auto'
            imageMedia={imageMedia}
            videoPath={videoPath}
            externalLink={action}
            isDarkText={isDarkBannerText}
            isSmallTitle
            titleText={localizedStrings[titleKey]}
            badgeText={localizedStrings[promotionKey]}
            badgeClassName={promotedBadgeType === PROMOTED_BADGE_ROMANCE && 'bg-romance'}
            amplitudeTrackingValue={amplitudeTrackingValue}
            linearGradient={isGradient}
            ariaLabelKey={actionAriaLabelKey}
        />
    );
};

export default HomePageCampaignItem;
