import React, {useId, useState} from 'react';

import {CONSUMER_FEEDBACK_POPUP_FEEDBACK_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import {getAriaLabel} from '@/services/accessibilityService';

import AemComponent from '../../../../components/Aem/AemComponent/AemComponent';
import EditableDynamicTextarea from '../../../../components/Aem/EditableDynamicTextarea/EditableDynamicTextarea';
import EditableText from '../../../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../../../components/Button';
import EstimationRadioGroup from './EstimationRadioGroup';

const MAX_LENGTH_TEXTAREA = 4096;

const FeedbackPopupContent = ({headerId, onSubmit, isLoading}) => {
    const descriptionId = useId();
    const [messageValue, setMessageValue] = useState();
    const [selectedValue, setSelectedValue] = useState();

    const onChange = (value) => {
        setSelectedValue(value);
    };

    const onMessageValueChange = (event) => {
        setMessageValue(event.target.value);
    };

    const onSubmitEvent = () => {
        const feedbackData = {
            rate: selectedValue,
            feedbackText: messageValue,
        };

        onSubmit(feedbackData);
    };

    return (
        <div className='flex w-full flex-col items-center justify-between'>
            <h1 id={headerId} className='w-full break-words text-24'>
                <AemComponent component={EditableText} pageName={routePaths.MODALS} itemName='feedbacktitle' />
            </h1>
            <p id={descriptionId} className='w-full break-words text-14'>
                <AemComponent component={EditableText} pageName={routePaths.MODALS} itemName='feedbacksubtitle' />
            </p>
            <EstimationRadioGroup selectedValue={selectedValue} aria-labelledby={descriptionId} onChange={onChange} />
            <AemComponent
                className='w-full text-16'
                component={EditableDynamicTextarea}
                pageName={routePaths.MODALS}
                maxLength={MAX_LENGTH_TEXTAREA}
                itemName='feedbackplaceholder'
                onChange={onMessageValueChange}
                value={messageValue}
            />
            <AppButton
                className='mt-8 min-h-12 max-w-[312px] break-words'
                onClick={onSubmitEvent}
                disabled={!selectedValue}
                primary
                light
                loading={isLoading}
                aria-label={getAriaLabel(CONSUMER_FEEDBACK_POPUP_FEEDBACK_BUTTON_ARIA_LABEL)}
            >
                <AemComponent component={EditableText} pageName={routePaths.MODALS} itemName='feedbackbuttonlabel' />
            </AppButton>
        </div>
    );
};

export default FeedbackPopupContent;
