import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import ModalTypes from '@/consts/app/modalTypes';
import UWM_COMMAND_TYPES from '@/consts/uwm/uwmCommendTypes';
import UWM_EVENT_TYPES from '@/consts/uwm/uwmEventTypes';
import useEventListener from '@/hooks/useEventListener';
import {useUWMUnavailabilityHandler} from '@/hooks/useUWMUnavailabilityHandler';
import log from '@/services/logger/log';
import {makeSelectVisibleGlobalModal} from '@/state/selectors/modal';
import {hideLoader, showLoader} from '@/state/slices/loadersSlice';
import {hideModalAction} from '@/state/slices/modalSlice';
import {dispatch} from '@/state/store';
import helpers from '@/utils/helpers';

const UwmModule = (props) => {
    const {src, onModuleLoaded, onBackButtonClick, onCompleteCleaningGuide, onSupportButtonClick} = props;
    const [moduleHeight, setModuleHeight] = useState();
    const [isModuleDataLoaded, setIsModuleDataLoaded] = useState(false);
    const [isModuleDataLoadedError, setIsModuleDataLoadedError] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(false);
    const isUnavailablePopupVisible = useSelector(makeSelectVisibleGlobalModal(ModalTypes.UWM_UNAVAILABILITY));
    const isShowLoader = !isModuleDataLoaded && !isModuleDataLoadedError && !isUnavailablePopupVisible;

    useEffect(() => {
        if (isShowLoader) {
            dispatch(showLoader());
        } else {
            dispatch(hideLoader());
        }
    }, [isShowLoader]);

    const refreshAction = () => {
        dispatch(hideModalAction(ModalTypes.UWM_UNAVAILABILITY));
        setIsModuleDataLoaded(false);
        setIsModuleDataLoadedError(false);
        setForceRefresh(!forceRefresh);
    };

    useUWMUnavailabilityHandler({
        forceRefresh,
        isModuleDataLoaded,
        isError: isModuleDataLoadedError,
        refreshAction,
    });

    useEventListener(
        'message',
        (event) => {
            const {detail} = event?.data || {};

            if (detail?.command === UWM_COMMAND_TYPES.UWM_EVENT) {
                log.debug(`UwmModule: detail: ${JSON.stringify(detail)}`);

                switch (detail.payload?.type) {
                    case UWM_EVENT_TYPES.UWM_MODULE_LOADED: {
                        onModuleLoaded();
                        break;
                    }
                    case UWM_EVENT_TYPES.UWM_MODULE_DATA_RECEIVED_SUCCESS: {
                        setIsModuleDataLoaded(true);
                        break;
                    }
                    case UWM_EVENT_TYPES.UWM_MODULE_RESIZE: {
                        setModuleHeight(detail.payload.height);
                        break;
                    }
                    case UWM_EVENT_TYPES.UWM_MODULE_BACK_BUTTON_CLICK: {
                        helpers.runFunction(onBackButtonClick);
                        break;
                    }
                    case UWM_EVENT_TYPES.UWM_MODULE_INITIALIZATION_ERROR: {
                        log.info(`UwmModule: UWM_MODULE_INITIALIZATION_ERROR: ${JSON.stringify(detail)}`);
                        setIsModuleDataLoadedError(true);
                        break;
                    }
                    case UWM_EVENT_TYPES.UWM_MODULE_COMPLETE_GUIDE: {
                        helpers.runFunction(onBackButtonClick);
                        break;
                    }
                    case UWM_EVENT_TYPES.UWM_MODULE_COMPLETE_CLEANING_GUIDE: {
                        helpers.runFunction(onCompleteCleaningGuide);
                        break;
                    }
                    case UWM_EVENT_TYPES.UWM_MODULE_SUPPORT_BTN_CLICK: {
                        helpers.runFunction(onSupportButtonClick);
                        break;
                    }
                    default:
                        break;
                }
            }
        },
        window
    );

    return isUnavailablePopupVisible ? null : (
        <iframe title='uwmIframe' id='uwmIframe' src={src} className='min-h-screen w-full' height={moduleHeight} />
    );
};

export default UwmModule;
