import React, {useEffect, useId, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableDeviceRenameItems from '@/components/Aem/EditableDeviceRenameItems/EditableDeviceRenameItems';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import {RenameBlueIcon} from '@/components/Icons';
import {
    DEVICE_RENAME_PAGE_DEFAULT_NAME_BUTTON_ARIA_LABEL,
    DEVICE_RENAME_PAGE_HEADER_TITLE,
    DEVICE_RENAME_PAGE_PERSONALIZED_NAME_BUTTON_ARIA_LABEL,
    DEVICE_RENAME_PAGE_SAVE_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import DevicesSettingsSection from '@/containers/DevicesPage/components/DevicesSettingsSection/DevicesSettingsSection';
import useLayout from '@/hooks/layout/useLayout';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeDeviceTracking from '@/services/analytics/amplitude/amplitudeDeviceTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import domService from '@/services/domService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {selectCurrentProductInfo} from '@/state/selectors/device';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import useOnRenameClick from './hooks/useOnRenameClick';

const DEFAULT_NAME_OPTION = 'defaultNameOption';
const PERSONALIZED_NAME_OPTION = 'personalizedNameOption';

const DeviceRenamePage = () => {
    const {isMobile, isDesktop} = useLayout();
    const headerId = useId();
    const ref = useRef();
    const device = useSelector(selectCurrentProductInfo);
    const {codentify, defaultProductName, productName} = device;

    const currentDeviceName = productName || '';
    const isDefaultName = !productName;
    const actualMode = isDefaultName ? DEFAULT_NAME_OPTION : PERSONALIZED_NAME_OPTION;

    const inputRef = useRef();
    const [currentMode, setCurrentMode] = useState(actualMode);
    const [deviceName, setDeviceName] = useState(currentDeviceName);

    const isDefaultMode = currentMode === DEFAULT_NAME_OPTION;
    const isSuccess = deviceName && deviceName === currentDeviceName;
    const deviceNameToSave = deviceName === '' ? null : deviceName;
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[DEVICE_RENAME_PAGE_HEADER_TITLE]);

    useEffect(() => {
        window.scrollTo(0, 0);
        amplitudeDeviceTracking.trackRenameDeviceScreen();
        googleAnalyticsService.trackRenameDeviceScreenView();
    }, []);

    useEffect(() => {
        setCurrentMode(actualMode);
        setDeviceName(currentDeviceName);
    }, [device]);

    const {onRenameClick} = useOnRenameClick();

    const onSaveClick = () => {
        amplitudeDeviceTracking.trackRenameDeviceSave();
        googleAnalyticsService.trackRenameDevice();
        onRenameClick(deviceNameToSave, isDefaultMode, codentify);
    };

    const clearInput = () => {
        const emptyDeviceName = '';

        setDeviceName(emptyDeviceName);
        setCurrentMode(PERSONALIZED_NAME_OPTION);
        domService.setFocusToDomElement(inputRef.current);
    };
    const resetToDefault = () => {
        setDeviceName('');
        setCurrentMode(DEFAULT_NAME_OPTION);
    };

    const onChange = ({target}) => {
        const name = target.value;

        setDeviceName(name);
        setCurrentMode(PERSONALIZED_NAME_OPTION);
    };

    const setPersonalizedMode = () => {
        setDeviceName(defaultProductName);
        setCurrentMode(PERSONALIZED_NAME_OPTION);
    };

    return (
        <DevicesSettingsSection.Container
            className='flex h-full w-full flex-col'
            title={localizedStrings[DEVICE_RENAME_PAGE_HEADER_TITLE]}
            onClick={onSaveClick}
            pageName={routePaths.DEVICE_RENAME}
            itemName='devicerenamesavebutton'
            ref={ref}
        >
            {isMobile && (
                <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.DEVICE_RENAME} containerEl={ref.current} />
            )}
            <div className='pt-safe-offset-sticky-header mb-9 flex flex-col items-center px-8 ml:my-8 ml:items-start ml:p-0'>
                {isMobile && <RenameBlueIcon className='h-16 w-16' />}
                <RenameText isMobile={isMobile} headerId={headerId} />
            </div>
            <AemComponent
                component={EditableDeviceRenameItems}
                pageName={routePaths.DEVICE_RENAME}
                itemName='devicerenameitems'
                defaultTextAriaLabel={getAriaLabel(DEVICE_RENAME_PAGE_DEFAULT_NAME_BUTTON_ARIA_LABEL)}
                personalizedTextAriaLabel={getAriaLabel(DEVICE_RENAME_PAGE_PERSONALIZED_NAME_BUTTON_ARIA_LABEL)}
                defaultProductName={defaultProductName}
                isDefaultMode={isDefaultMode}
                resetToDefault={resetToDefault}
                setPersonalizedMode={setPersonalizedMode}
                deviceName={deviceName}
                isSuccess={isSuccess}
                onChange={onChange}
                clearInput={clearInput}
                inputRef={inputRef}
                aria-labelledby={headerId}
            />
            <WarningBanners
                source={isMobile ? WARNING_BANNER_SOURCE_TYPES.DEVICE_RENAME : WARNING_BANNER_SOURCE_TYPES.DEVICES}
                containerClassName='md:-mb-8'
                contentClassName='ml:px-8'
            />
            {isDesktop && (
                <AppButton
                    className='my-8'
                    primary
                    light
                    onClick={onSaveClick}
                    aria-label={getAriaLabel(DEVICE_RENAME_PAGE_SAVE_BUTTON_ARIA_LABEL)}
                >
                    <AemComponent
                        component={EditableText}
                        pageName={routePaths.DEVICE_RENAME}
                        itemName='devicerenamesavebutton'
                    />
                </AppButton>
            )}
        </DevicesSettingsSection.Container>
    );
};

const RenameText = ({isMobile, headerId}) => {
    const Heading = isMobile ? 'h1' : 'h2';

    return (
        <div className='mt-4 flex max-w-[492px] flex-col text-center md:mt-2 ml:mt-0 ml:gap-2 ml:text-start'>
            <Heading id={headerId} className='text-28 ml:mb-0 ml:text-32 ml:leading-13125'>
                <AemComponent
                    component={EditableText}
                    pageName={routePaths.DEVICE_RENAME}
                    itemName='devicerenametitle'
                />
            </Heading>
            <p className='ml:text-16 ml:leading-15'>
                <span className='whitespace-pre-line'>
                    <AemComponent
                        component={EditableText}
                        pageName={routePaths.DEVICE_RENAME}
                        itemName='devicerenamedesc'
                    />
                </span>
            </p>
        </div>
    );
};

export default DeviceRenamePage;
