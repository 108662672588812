export const WARNING_BANNER_SOURCE_TYPES = {
    ABOUT_DEVICE: '/about-device',
    AD: '/ad',
    AUTO_DELIVERY: '/auto-delivery',
    BROWSER_NOT_COMPATIBLE_POPUP: '/browser-not-compatible-popup',
    CONTACT_US: '/contact-us',
    DEVICE_RENAME: '/device-rename',
    DEVICES: '/devices',
    ENABLE_NOTIFICATION_POPUP: '/enable-notification-popup',
    FIRMWARE_AVAILABLE_POPUP: '/firmware-available-popup',
    HIGHLIGHTS_FULLSCREEN: '/highlights-fullscreen',
    HOME: '/home',
    INBOX: '/inbox',
    INSTALL_PWA: '/install-pwa',
    LANGUAGE_SELECT: '/language-select',
    LEVIA_POPUP: '/levia-popup',
    LOGIN: '/login',
    PREFERENCE_CENTER: '/preference-center',
    PROFILE: '/profile',
    QUICK_ORDER_POPUP: '/quick-order-popup',
    SELECT_DEVICE: '/select-device',
    SHOP: '/shop',
    TNC_DETAILS: '/tnc-details',
    TNC: '/tnc',
};
