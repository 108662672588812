import React, {useEffect, useId, useState} from 'react';

import {StickyPageLayout} from '@/components/Layout';
import PageHeaderMedia from '@/components/PageHeaderMedia/PageHeaderMedia';
import {PopupFullPage} from '@/components/Popup';
import PopupOutline from '@/components/Popup/PopupOutline';
import useOneModalOnPageAtATime from '@/components/Popup/useOneModalOnPageAtATime';
import appConfig from '@/config/appConfig';
import ModalTypes from '@/consts/app/modalTypes';
import useLayout from '@/hooks/layout/useLayout';
import amplitudeNotificationTracking from '@/services/analytics/amplitude/amplitudeNotificationTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import pushNotificationService from '@/services/pushNotificationService';
import storageService from '@/services/storage/storageService';
import {hideModalAction} from '@/state/slices/modalSlice';
import {dispatch} from '@/state/store';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import EnableNotificationModalButton from './EnableNotificationModalButton';
import EnableNotificationModalContent from './EnableNotificationModalContent';

const EnableNotificationModal = () => {
    const [popupEl, setPopupEl] = useState();
    const headerId = useId();
    const {isMobile, isMd} = useLayout();
    const imageMedia = appConfig.getEnableNotificationPageHeaderImageMedia();
    const videoSrc = appConfig.getEnableNotificationPageHeaderVideoSrc();
    const videoThumbnailSrc = appConfig.getEnableNotificationPageHeaderVideoThumbnailSrc();
    const isMobileOnly = isMobile && !isMd;
    const PopupComponent = isMobileOnly ? PopupFullPage : PopupOutline;

    useOneModalOnPageAtATime();

    const onClick = async () => {
        amplitudeNotificationTracking.trackEnableNotificationScreenNextButton();
        googleAnalyticsService.trackEnableNotificationsNextButtonClick();
        await pushNotificationService.subscribe();
        storageService.setPermissionDeniedLocalStorage(false);

        dispatch(hideModalAction(ModalTypes.ENABLE_NOTIFICATION));
    };

    const onClose = () => {
        storageService.setPermissionDeniedLocalStorage(true);
        dispatch(hideModalAction(ModalTypes.ENABLE_NOTIFICATION));
    };

    useEffect(() => {
        amplitudeNotificationTracking.trackEnableNotificationScreen();
        googleAnalyticsService.trackEnableNotificationsScreenView();
    }, []);

    return (
        <PopupComponent
            className='flex md:left-1/2 md:-translate-x-1/2 md:rounded-3xl md:p-8 md:text-center ml:max-w-[458px]'
            overlayRef={setPopupEl}
            onClose={onClose}
            aria-labelledby={headerId}
        >
            {isMobileOnly && (
                <WarningBannersTop
                    source={WARNING_BANNER_SOURCE_TYPES.ENABLE_NOTIFICATION_POPUP}
                    containerEl={popupEl}
                />
            )}
            {isMobileOnly ? (
                <StickyPageLayout
                    className='items-center pt-safe'
                    contentClassName='flex flex-1 flex-col items-center'
                    footer={<EnableNotificationModalButton onClick={onClick} />}
                >
                    <PageHeaderMedia
                        isVideoAvailable={!!videoThumbnailSrc}
                        videoThumbnailSrc={videoThumbnailSrc}
                        videoSrc={videoSrc}
                        imageMedia={imageMedia}
                    />
                    <EnableNotificationModalContent headerId={headerId} onBtnClick={onClick} isMobile={isMobile} />
                    <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.ENABLE_NOTIFICATION_POPUP} />
                </StickyPageLayout>
            ) : (
                <EnableNotificationModalContent headerId={headerId}>
                    <EnableNotificationModalButton headerId={headerId} onClick={onClick} />
                </EnableNotificationModalContent>
            )}
        </PopupComponent>
    );
};

export default EnableNotificationModal;
