import React from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import EditableNotificationsInbox from '@/components/Aem/EditableNotificationsInbox/EditableNotificationsInbox';
import {StickyHeader} from '@/components/Layout';
import {INBOX_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {HOME_PAGE_NAVIGATION_SOURCE_TYPES} from '@/services/homePageNavigationService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import {twx} from '@/utils/tailwindUtils';

const InboxView = ({notifications, sentryRef, hasNextPage, onCloseClick, className}) => {
    const isMobile = useIsMobileLayout();
    const localizedStrings = getLocalizedStrings();

    const onMessageClick = (messageId) => {
        appRouterService.forwardToNotificationDetailsPage(messageId, HOME_PAGE_NAVIGATION_SOURCE_TYPES.INBOX);
    };

    useDocumentTitle(localizedStrings[INBOX_PAGE_HEADER_TITLE]);

    return (
        <div className={twx('px-layout pb-2 text-start', className)}>
            {isMobile && <StickyHeader title={localizedStrings[INBOX_PAGE_HEADER_TITLE]} onBack={onCloseClick} />}
            <h1 className='mt-safe-offset-sticky-header text-32'>
                <AemEditableText pageName={routePaths.HOME_PAGE} itemName='notificationsinboxtitle' />
            </h1>
            <AemComponent
                component={EditableNotificationsInbox}
                pageName={routePaths.HOME_PAGE}
                itemName='notificationsinbox'
                notifications={notifications}
                sentryRef={sentryRef}
                hasNextPage={hasNextPage}
                onMessageClick={onMessageClick}
            />
        </div>
    );
};

export default InboxView;
