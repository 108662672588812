import useInfiniteScroll from 'react-infinite-scroll-hook';

import useInboxMessages from './useInboxMessages';

const useInboxListView = () => {
    const {inboxMessages, loadMore, isLoading, hasNextPage} = useInboxMessages();

    const [sentryRef, {rootRef}] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage,
        onLoadMore: loadMore,
        rootMargin: '0px 0px 150px 0px',
    });

    return {inboxMessages, sentryRef, rootRef, hasNextPage};
};

export default useInboxListView;
