import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import iccConsumerProducts from '@/services/icc/iccConsumerProducts';
import {selectConsumerProductsFetchDate} from '@/state/selectors/consumer';

const REFETCH_TIME_MS = 30 * 60 * 1000;

const useRefetchConsumerProducts = () => {
    const consumerProductsFetchDate = useSelector(selectConsumerProductsFetchDate);

    useEffect(() => {
        const isDataOutdated = !consumerProductsFetchDate || new Date() - consumerProductsFetchDate > REFETCH_TIME_MS;

        if (!isDataOutdated) return;

        iccConsumerProducts.getDetailedProducts();
    }, [consumerProductsFetchDate]);
};

export default useRefetchConsumerProducts;
