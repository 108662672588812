import React, {useId, useState} from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import CloseButton from '@/components/CloseButton/CloseButton';
import {TickCircle, WarningIcon} from '@/components/Icons';
import {StickyPageLayout} from '@/components/Layout';
import {PopupFullPage} from '@/components/Popup';
import {
    BROWSER_NOT_COMPATIBLE_PAGE_HEADER_TITLE,
    BROWSER_NOT_COMPATIBLE_START_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useExternalLink from '@/hooks/useExternalLink';
import useModalRoute from '@/hooks/useModalRoute';
import useScrollToPreviousPosition from '@/hooks/useScrollToPreviousPosition';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeDeviceTracking from '@/services/analytics/amplitude/amplitudeDeviceTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import modalService from '@/services/modalService';
import navigatorService from '@/services/user/navigatorService';
import userBrowserService from '@/services/user/userBrowserService';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

const withBluefyLink = (link) => `https://bluefy.app/pwa?site=${link}`;

const BrowserNotCompatiblePopup = ({
    modalType,
    link,
    externalLink,
    ssoTemplate,
    ssoParams,
    linkProcessor,
    deviceType,
    deviceSerialNumber,
}) => {
    const [ref, setRef] = useState();
    const headerId = useId();
    const localizedStrings = getLocalizedStrings();
    const closeModal = () => modalService.hideModal(modalType);
    const {onClose} = useModalRoute({modalType, closeModal});

    // Use useExternalLink to get new link if it was refreshed after expiration
    const {link: linkFromState} = useExternalLink({
        externalLink,
        ssoTemplate,
        ssoParams,
        linkProcessor,
        disabled: true,
    });
    const linkToRedirect = ssoTemplate ? linkFromState || link : link;
    const finalLink = userBrowserService.isBluefyBrowser() ? linkToRedirect : withBluefyLink(linkToRedirect);

    const onClick = () => {
        amplitudeDeviceTracking.trackStartWithBluefyClick(deviceType, deviceSerialNumber);
        googleAnalyticsService.trackStartWithBluefyClick(deviceType, deviceSerialNumber);
        navigatorService.copyToClipboard(finalLink);
    };

    useScrollToPreviousPosition();

    // shouldReturnFocusAfterClose is set to false because after closing of the popup it breaks Home page layout
    // if click was done on the NOT first carousel item
    return (
        <PopupFullPage
            shouldReturnFocusAfterClose={false}
            className='z-[102] flex flex-col bg-romance'
            overlayRef={setRef}
            aria-labelledby={headerId}
        >
            <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.BROWSER_NOT_COMPATIBLE_POPUP} containerEl={ref} />
            <StickyPageLayout
                className='items-center'
                desktopContentClassName='flex flex-col items-center pt-14-hw-offset'
                headerOptions={{
                    endIcon: <CloseButton onClick={onClose} />,
                    title: localizedStrings[BROWSER_NOT_COMPATIBLE_PAGE_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center pt-safe-offset-sticky-header'
            >
                <WarningIcon className='h-9 w-9' />
                <h1 id={headerId} className='mt-4 px-8 text-28'>
                    <AemEditableText pageName={routePaths.MODALS} itemName='browserNotCompatibleTitle' />
                </h1>
                <div className='mt-8 bg-secondary px-8 py-6'>
                    <h2 className='text-24'>
                        <AemEditableText pageName={routePaths.MODALS} itemName='browserNotCompatibleSubtitle' />
                    </h2>
                    <div className='mt-8 flex flex-col gap-6'>
                        {['browserNotCompatibleStep1', 'browserNotCompatibleStep2', 'browserNotCompatibleStep3'].map(
                            (itemName) => (
                                <AemEditableText
                                    key={itemName}
                                    pageName={routePaths.MODALS}
                                    itemName={itemName}
                                    render={(text) => {
                                        return text ? <InstructionStep text={text} /> : null;
                                    }}
                                />
                            )
                        )}
                    </div>
                </div>
                <AppButton
                    className='mb-4 mt-25 max-w-[312px]'
                    primary
                    light
                    isExternalLink
                    to={finalLink}
                    aria-label={getAriaLabel(BROWSER_NOT_COMPATIBLE_START_BUTTON_ARIA_LABEL)}
                    onClick={onClick}
                    isTargetBlank
                >
                    <AemEditableText pageName={routePaths.MODALS} itemName='browserNotCompatibleButton' />
                </AppButton>
                <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.BROWSER_NOT_COMPATIBLE_POPUP} />
            </StickyPageLayout>
        </PopupFullPage>
    );
};

const InstructionStep = ({text}) => {
    return (
        <div className='flex items-center justify-start gap-2'>
            <TickCircle className='flex-shrink-0' />
            <span className='text-start'>{text}</span>
        </div>
    );
};

export default BrowserNotCompatiblePopup;
