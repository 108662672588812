import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';

const useHighlightsSlideText = (isFullscreen) => {
    const isMobile = useIsMobileLayout();
    const isTextLarger = isMobile && isFullscreen;
    const TitleComponent = isFullscreen ? 'h2' : 'h3';

    return {isTextLarger, TitleComponent};
};

export default useHighlightsSlideText;
