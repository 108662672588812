export const CLOSE_BUTTON_ARIA_LABEL = 'CLOSE_BUTTON_ARIA_LABEL';
export const BACK_BUTTON_ARIA_LABEL = 'BACK_BUTTON_ARIA_LABEL';
export const PREVIOUS_BUTTON_ARIA_LABEL = 'PREVIOUS_BUTTON_ARIA_LABEL';
export const NEXT_BUTTON_ARIA_LABEL = 'NEXT_BUTTON_ARIA_LABEL';
export const INPUT_CLEAR_BUTTON_ARIA_LABEL = 'INPUT_CLEAR_BUTTON_ARIA_LABEL';
export const INPUT_PASSWORD_SHOW_BUTTON_ARIA_LABEL = 'INPUT_PASSWORD_SHOW_BUTTON_ARIA_LABEL';
export const INPUT_PASSWORD_HIDE_BUTTON_ARIA_LABEL = 'INPUT_PASSWORD_HIDE_BUTTON_ARIA_LABEL';
export const ABOUT_DEVICE_CORTEZ_HOLDER_NAME = 'ABOUT_DEVICE_CORTEZ_HOLDER_NAME';
export const ABOUT_DEVICE_ORDINARY_HOLDER_NAME = 'ABOUT_DEVICE_ORDINARY_HOLDER_NAME';
export const ABOUT_DEVICE_ORIGINALS_DUO_HOLDER_NAME = 'ABOUT_DEVICE_ORIGINALS_DUO_HOLDER_NAME';
export const ABOUT_DEVICE_P1V40_HOLDER_NAME = 'ABOUT_DEVICE_P1V40_HOLDER_NAME';
export const ABOUT_DEVICE_P1V40M_HOLDER_NAME = 'ABOUT_DEVICE_P1V40M_HOLDER_NAME';
export const ABOUT_DEVICE_P1V41M_HOLDER_NAME = 'ABOUT_DEVICE_P1V41M_HOLDER_NAME';
export const ABOUT_DEVICE_P1V41P_HOLDER_NAME = 'ABOUT_DEVICE_P1V41P_HOLDER_NAME';
export const ABOUT_DEVICE_SOYUZ_HOLDER_NAME = 'ABOUT_DEVICE_SOYUZ_HOLDER_NAME';
export const DEVICE_CAROUSEL_ARIANE_NAME = 'DEVICE_CAROUSEL_ARIANE_NAME';
export const DEVICE_CAROUSEL_CORTEZ_NAME = 'DEVICE_CAROUSEL_CORTEZ_NAME';
export const DEVICE_CAROUSEL_ORDINARY_NAME = 'DEVICE_CAROUSEL_ORDINARY_NAME';
export const DEVICE_CAROUSEL_ORIGINALS_DUO_NAME = 'DEVICE_CAROUSEL_ORIGINALS_DUO_NAME';
export const DEVICE_CAROUSEL_ORIGINALS_ONE_NAME = 'DEVICE_CAROUSEL_ORIGINALS_ONE_NAME';
export const DEVICE_CAROUSEL_P1V40_NAME = 'DEVICE_CAROUSEL_P1V40_NAME';
export const DEVICE_CAROUSEL_P1V40D_NAME = 'DEVICE_CAROUSEL_P1V40D_NAME';
export const DEVICE_CAROUSEL_P1V40M_NAME = 'DEVICE_CAROUSEL_P1V40M_NAME';
export const DEVICE_CAROUSEL_P1V41J_NAME = 'DEVICE_CAROUSEL_P1V41J_NAME';
export const DEVICE_CAROUSEL_P1V41A_NAME = 'DEVICE_CAROUSEL_P1V41A_NAME';
export const DEVICE_CAROUSEL_P1V41M_NAME = 'DEVICE_CAROUSEL_P1V41M_NAME';
export const DEVICE_CAROUSEL_P1V41P_NAME = 'DEVICE_CAROUSEL_P1V41P_NAME';
export const DEVICE_CAROUSEL_SOYUZ_NAME = 'DEVICE_CAROUSEL_SOYUZ_NAME';
export const DEVICE_CAROUSEL_TITAN_NAME = 'DEVICE_CAROUSEL_TITAN_NAME';
export const DEVICE_CAROUSEL_VESPUCCI_NAME = 'DEVICE_CAROUSEL_VESPUCCI_NAME';
export const DEVICE_RENAME_NOTIFICATION_SUCCESS_TEXT = 'DEVICE_RENAME_NOTIFICATION_SUCCESS_TEXT';
export const DEVICE_RENAME_NOTIFICATION_FAILED_TEXT = 'DEVICE_RENAME_NOTIFICATION_FAILED_TEXT';
export const GLOBAL_ERROR_NOTIFICATION_TEXT = 'GLOBAL_ERROR_NOTIFICATION_TEXT';
export const TERMS_OF_USE_DECLINED_ERROR = 'TERMS_OF_USE_DECLINED_ERROR';
export const GLOBAL_ERROR_TITLE_TEXT = 'GLOBAL_ERROR_TITLE_TEXT';
export const GLOBAL_ERROR_WITH_RETRY_DESCRIPTION_TEXT = 'GLOBAL_ERROR_WITH_RETRY_DESCRIPTION_TEXT';
export const GLOBAL_ERROR_TRY_AGAIN_BUTTON_TEXT = 'GLOBAL_ERROR_TRY_AGAIN_BUTTON_TEXT';
export const GLOBAL_ERROR_CONTACT_US_BUTTON_TEXT = 'GLOBAL_ERROR_CONTACT_US_BUTTON_TEXT';
export const MY_DEVICES_LIST_TITLE = 'MY_DEVICES_LIST_TITLE';
export const MY_DEVICES_LIST_MISSING_DEVICE_TITLE = 'MY_DEVICES_LIST_MISSING_DEVICE_TITLE';
export const MY_DEVICES_LIST_MISSING_DEVICE_DESCRIPTION_TEXT = 'MY_DEVICES_LIST_MISSING_DEVICE_DESCRIPTION_TEXT';
export const MY_DEVICES_LIST_ADD_NEW_BUTTON_TEXT = 'MY_DEVICES_LIST_ADD_NEW_BUTTON_TEXT';
export const SHOP_YOUR_DEVICES_SHOP_DEVICES = 'SHOP_YOUR_DEVICES_SHOP_DEVICES';
export const SHOP_YOUR_DEVICES_TRADE_IN = 'SHOP_YOUR_DEVICES_TRADE_IN';
export const SHOP_YOUR_DEVICES_SHOP_ACCESSORIES = 'SHOP_YOUR_DEVICES_SHOP_ACCESSORIES';
export const AD_DAMAGE_HEADER_TITLE = 'AD_DAMAGE_HEADER_TITLE';
export const AD_ENTRY_POINT_HEADER_TITLE = 'AD_ENTRY_POINT_HEADER_TITLE';
export const AD_FITTING_CONSUMABLES_HEADER_TITLE = 'AD_FITTING_CONSUMABLES_HEADER_TITLE';
export const AD_POST_CLEANING_HEADER_TITLE = 'AD_POST_CLEANING_HEADER_TITLE';
export const AD_REMOVE_STICK_HEADER_TITLE = 'AD_REMOVE_STICK_HEADER_TITLE';
export const AD_SELECT_ISSUE_HEADER_TITLE = 'AD_SELECT_ISSUE_HEADER_TITLE';
export const CONSUMER_PROFILE_OPEN_BUTTON_ARIA_LABEL = 'CONSUMER_PROFILE_OPEN_BUTTON_ARIA_LABEL';
export const CONSUMER_PROFILE_PAGE_HEADER_TITLE = 'CONSUMER_PROFILE_PAGE_HEADER_TITLE';
export const CONTACT_US_PAGE_HEADER_TITLE = 'CONTACT_US_PAGE_HEADER_TITLE';
export const DEVICE_RENAME_PAGE_HEADER_TITLE = 'DEVICE_RENAME_PAGE_HEADER_TITLE';
export const MY_DEVICES_PAGE_HEADER_TITLE = 'MY_DEVICES_PAGE_HEADER_TITLE';
export const NO_DEVICES_PAGE_HEADER_TITLE = 'NO_DEVICES_PAGE_HEADER_TITLE';
export const HOME_PAGE_HEADER_TITLE = 'HOME_PAGE_HEADER_TITLE';
export const SHOP_PAGE_HEADER_TITLE = 'SHOP_PAGE_HEADER_TITLE';
export const INBOX_OPEN_BUTTON_ARIA_LABEL = 'INBOX_OPEN_BUTTON_ARIA_LABEL';
export const INBOX_PAGE_HEADER_TITLE = 'INBOX_PAGE_HEADER_TITLE';
export const NOTIFICATION_DETAILS_PAGE_HEADER_TITLE = 'NOTIFICATION_DETAILS_PAGE_HEADER_TITLE';
export const BROWSER_NOT_COMPATIBLE_PAGE_HEADER_TITLE = 'BROWSER_NOT_COMPATIBLE_PAGE_HEADER_TITLE';
export const LANGUAGE_SELECT_TRIGGER_BUTTON_ARIA_LABEL = 'LANGUAGE_SELECT_TRIGGER_BUTTON_ARIA_LABEL';
export const LANGUAGE_SELECT_PAGE_HEADER_TITLE = 'LANGUAGE_SELECT_PAGE_HEADER_TITLE';
export const SELECT_YOUR_DEVICE_PAGE_HEADER_TITLE = 'SELECT_YOUR_DEVICE_PAGE_HEADER_TITLE';
export const PREFERENCE_CENTER_PAGE_HEADER_TITLE = 'PREFERENCE_CENTER_PAGE_HEADER_TITLE';
export const TNC_PAGE_HEADER_TITLE = 'TNC_PAGE_HEADER_TITLE';
export const LEVIA_POPUP_HEADER_TITLE = 'LEVIA_POPUP_HEADER_TITLE';
export const LOGIN_PAGE_HEADER_TITLE = 'LOGIN_PAGE_HEADER_TITLE';
export const LOGIN_PAGE_LOGIN_BUTTON_ARIA_LABEL = 'LOGIN_PAGE_LOGIN_BUTTON_ARIA_LABEL';
export const LOGIN_PAGE_FORGOT_PASSWORD_BUTTON_ARIA_LABEL = 'LOGIN_PAGE_FORGOT_PASSWORD_BUTTON_ARIA_LABEL';
export const LOGIN_PAGE_REGISTER_BUTTON_ARIA_LABEL = 'LOGIN_PAGE_REGISTER_BUTTON_ARIA_LABEL';
export const FIRMWARE_AVAILABLE_POPUP_HEADER_TITLE = 'FIRMWARE_AVAILABLE_POPUP_HEADER_TITLE';
export const QUICK_ORDER_HEADER_TITLE = 'QUICK_ORDER_HEADER_TITLE';
export const DEVICE_UNREGISTERED_NOTIFICATION_TEXT = 'DEVICE_UNREGISTERED_NOTIFICATION_TEXT';
export const DEVICE_UNREGISTER_BUTTON_TEXT = 'DEVICE_UNREGISTER_BUTTON_TEXT';
export const ABOUT_YOUR_DEVICE_TITLE = 'ABOUT_YOUR_DEVICE_TITLE';
export const TERMS_OF_USE_HEADER_TITLE = 'TERMS_OF_USE_HEADER_TITLE';
export const AD_CLEANING_CONTACTS_HEADER_TITLE = 'AD_CLEANING_CONTACTS_HEADER_TITLE';
export const DATA_COLLECTION_HEADER_TITLE = 'DATA_COLLECTION_HEADER_TITLE';
export const AD_POST_CLEANING_DEVICE_HEADER_TITLE = 'AD_POST_CLEANING_DEVICE_HEADER_TITLE';
export const GLOBAL_GOOD_MORNING = 'GLOBAL_GOOD_MORNING';
export const GLOBAL_GOOD_AFTERNOON = 'GLOBAL_GOOD_AFTERNOON';
export const GLOBAL_GOOD_EVENING = 'GLOBAL_GOOD_EVENING';
export const AUTO_DELIVERY_PAYMENT_PENDING_HEADER_TITLE = 'AUTO_DELIVERY_PAYMENT_PENDING_HEADER_TITLE';
export const AUTO_DELIVERY_PAYMENT_PENDING_TITLE = 'AUTO_DELIVERY_PAYMENT_PENDING_TITLE';
export const AUTO_DELIVERY_ACTIVE_TITLE = 'AUTO_DELIVERY_ACTIVE_TITLE';
export const AUTO_DELIVERY_ACTIVE_SUBTITLE = 'AUTO_DELIVERY_ACTIVE_SUBTITLE';
export const AUTO_DELIVERY_PROGRESS_PROCESSED = 'AUTO_DELIVERY_PROGRESS_PROCESSED';
export const AUTO_DELIVERY_PROGRESS_ON_ITS_WAY = 'AUTO_DELIVERY_PROGRESS_ON_ITS_WAY';
export const AUTO_DELIVERY_PROGRESS_DELIVERED = 'AUTO_DELIVERY_PROGRESS_DELIVERED';
export const AUTO_DELIVERY_PROGRESS_ON_HOLD = 'AUTO_DELIVERY_PROGRESS_ON_HOLD';
export const AUTO_DELIVERY_HEADER_TITLE = 'AUTO_DELIVERY_HEADER_TITLE';
export const AUTO_DELIVERY_TITLE = 'AUTO_DELIVERY_TITLE';
export const AUTO_DELIVERY_VIEW_ORDER_LINK = 'AUTO_DELIVERY_VIEW_ORDER_LINK';
export const AUTO_DELIVERY_VIEW_ORDER_LINK_ARIA_LABEL = 'AUTO_DELIVERY_VIEW_ORDER_LINK_ARIA_LABEL';
export const AUTO_DELIVERY_ON_HOLD_HEADER_TITLE = 'AUTO_DELIVERY_ON_HOLD_HEADER_TITLE';
export const AUTO_DELIVERY_ON_HOLD_TITLE = 'AUTO_DELIVERY_ON_HOLD_TITLE';
export const AUTO_DELIVERY_ON_HOLD_PAY_FAILED_SUBTITLE = 'AUTO_DELIVERY_ON_HOLD_PAY_FAILED_SUBTITLE';
export const AUTO_DELIVERY_ON_HOLD_PAY_FAILED_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_ON_HOLD_PAY_FAILED_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_ON_HOLD_OUT_OF_STOCK_SUBTITLE = 'AUTO_DELIVERY_ON_HOLD_OUT_OF_STOCK_SUBTITLE';
export const AUTO_DELIVERY_ON_HOLD_DELIVERY_FAIL_SUBTITLE = 'AUTO_DELIVERY_ON_HOLD_DELIVERY_FAIL_SUBTITLE';
export const AUTO_DELIVERY_ON_HOLD_NONE_SUBTITLE = 'AUTO_DELIVERY_ON_HOLD_NONE_SUBTITLE';
export const AUTO_DELIVERY_ON_HOLD_PAY_FAILED_DESCRIPTION = 'AUTO_DELIVERY_ON_HOLD_PAY_FAILED_DESCRIPTION';
export const AUTO_DELIVERY_ON_HOLD_OUT_OF_STOCK_DESCRIPTION = 'AUTO_DELIVERY_ON_HOLD_OUT_OF_STOCK_DESCRIPTION';
export const AUTO_DELIVERY_ON_HOLD_DELIVERY_FAIL_DESCRIPTION = 'AUTO_DELIVERY_ON_HOLD_DELIVERY_FAIL_DESCRIPTION';
export const AUTO_DELIVERY_ON_HOLD_NONE_DESCRIPTION = 'AUTO_DELIVERY_ON_HOLD_NONE_DESCRIPTION';
export const AUTO_DELIVERY_LEARN_MORE_LINK = 'AUTO_DELIVERY_LEARN_MORE_LINK';
export const AUTO_DELIVERY_LEARN_MORE_LINK_ARIA_LABEL = 'AUTO_DELIVERY_LEARN_MORE_LINK_ARIA_LABEL';
export const AUTO_DELIVERY_INACTIVE_TITLE = 'AUTO_DELIVERY_INACTIVE_TITLE';
export const AUTO_DELIVERY_INACTIVE_DESCRIPTION = 'AUTO_DELIVERY_INACTIVE_DESCRIPTION';
export const AUTO_DELIVERY_ON_HOLD_PAY_FAILED_BUTTON = 'AUTO_DELIVERY_ON_HOLD_PAY_FAILED_BUTTON';
export const AUTO_DELIVERY_ON_HOLD_OUT_OF_STOCK_BUTTON = 'AUTO_DELIVERY_ON_HOLD_OUT_OF_STOCK_BUTTON';
export const AUTO_DELIVERY_ON_HOLD_OUT_OF_STOCK_BUTTON_ARIA_LABEL =
    'AUTO_DELIVERY_ON_HOLD_OUT_OF_STOCK_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_ON_HOLD_DELIVERY_FAIL_BUTTON = 'AUTO_DELIVERY_ON_HOLD_DELIVERY_FAIL_BUTTON';
export const AUTO_DELIVERY_ON_HOLD_DELIVERY_FAIL_BUTTON_ARIA_LABEL =
    'AUTO_DELIVERY_ON_HOLD_DELIVERY_FAIL_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_ON_HOLD_NONE_BUTTON = 'AUTO_DELIVERY_ON_HOLD_NONE_BUTTON';
export const AUTO_DELIVERY_ON_HOLD_NONE_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_ON_HOLD_NONE_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_INACTIVE_BUTTON = 'AUTO_DELIVERY_INACTIVE_BUTTON';
export const AUTO_DELIVERY_INACTIVE_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_INACTIVE_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_PAYMENT_PENDING_BUTTON = 'AUTO_DELIVERY_PAYMENT_PENDING_BUTTON';
export const AUTO_DELIVERY_INACTIVE_BENEFITS_LIST_TITLE = 'AUTO_DELIVERY_INACTIVE_BENEFITS_LIST_TITLE';
export const AUTO_DELIVERY_DETAILS_NONE_HEADER_TITLE = 'AUTO_DELIVERY_DETAILS_NONE_HEADER_TITLE';
export const AUTO_DELIVERY_DETAILS_INACTIVE_HEADER_TITLE = 'AUTO_DELIVERY_DETAILS_INACTIVE_HEADER_TITLE';
export const AUTO_DELIVERY_DELIVERY_ASAP_HEADER_TITLE = 'AUTO_DELIVERY_DELIVERY_ASAP_HEADER_TITLE';
export const AUTO_DELIVERY_SKIP_DELIVERY_CONFIRM_HEADER_TITLE = 'AUTO_DELIVERY_SKIP_DELIVERY_CONFIRM_HEADER_TITLE';
export const AUTO_DELIVERY_FOOTER_TNC_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_FOOTER_TNC_BUTTON_ARIA_LABEL';
export const APP_UPDATE_POPUP_TITLE = 'APP_UPDATE_POPUP_TITLE';
export const APP_UPDATE_POPUP_DESCRIPTION = 'APP_UPDATE_POPUP_DESCRIPTION';
export const APP_UPDATE_POPUP_BUTTON = 'APP_UPDATE_POPUP_BUTTON';
export const MAINTENANCE_POPUP_BUTTON = 'MAINTENANCE_POPUP_BUTTON';
export const AUTO_DELIVERY_VIEW_ORDER_SUMMARY_TEXT = 'AUTO_DELIVERY_VIEW_ORDER_SUMMARY_TEXT';
export const AUTO_DELIVERY_VIEW_ORDER_SUMMARY_LINK = 'AUTO_DELIVERY_VIEW_ORDER_SUMMARY_LINK';
export const WELCOME_VIEW_SUBTITLE = 'WELCOME_VIEW_SUBTITLE';
export const WELCOME_VIEW_BUTTON_TEXT = 'WELCOME_VIEW_BUTTON_TEXT';
export const WELCOME_VIEW_BUTTON_ARIA_LABEL = 'WELCOME_VIEW_BUTTON_ARIA_LABEL';
export const HIGHLIGHTS_VIEW_ALL_BUTTON = 'HIGHLIGHTS_VIEW_ALL_BUTTON';
export const HIGHLIGHTS_VIEW_ALL_BUTTON_ARIA_LABEL = 'HIGHLIGHTS_VIEW_ALL_BUTTON_ARIA_LABEL';
export const MY_DEVICES_PAGE_CONNECT_BROWSER_NOT_TRUSTED_TEXT_1 = 'MY_DEVICES_PAGE_CONNECT_BROWSER_NOT_TRUSTED_TEXT_1';
export const MY_DEVICES_PAGE_CONNECT_BROWSER_NOT_TRUSTED_TEXT_2 = 'MY_DEVICES_PAGE_CONNECT_BROWSER_NOT_TRUSTED_TEXT_2';
export const DELETE_INBOX_MESSAGE_TOAST_UNDO_BUTTON = 'DELETE_INBOX_MESSAGE_TOAST_UNDO_BUTTON';
export const DELETE_INBOX_MESSAGE_TOAST_TITLE = 'DELETE_INBOX_MESSAGE_TOAST_TITLE';
export const CHARGER_UNREGISTRATION_FAILED_NOTIFICATION_TEXT = 'CHARGER_UNREGISTRATION_FAILED_NOTIFICATION_TEXT';
export const HOLDER_UNREGISTRATION_FAILED_NOTIFICATION_TEXT = 'HOLDER_UNREGISTRATION_FAILED_NOTIFICATION_TEXT';
export const QUICK_ORDER_BENEFITS_HEADER_TITLE = 'QUICK_ORDER_BENEFITS_HEADER_TITLE';
export const QUICK_ORDER_HOW_IT_WORKS_HEADER_TITLE = 'QUICK_ORDER_HOW_IT_WORKS_HEADER_TITLE';
export const GET_STARTED_PAGE_DISMISS_BUTTON_ARIA_LABEL = 'GET_STARTED_PAGE_DISMISS_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_EDIT_ORDER_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_EDIT_ORDER_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_VIEW_ORDER_SUMMARY_LINK_ARIA_LABEL = 'AUTO_DELIVERY_VIEW_ORDER_SUMMARY_LINK_ARIA_LABEL';
export const AUTO_DELIVERY_CHANGE_ADDRESS_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_CHANGE_ADDRESS_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_DELIVER_ASAP_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_DELIVER_ASAP_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_SKIP_DELIVERY_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_SKIP_DELIVERY_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_DELIVER_ASAP_CONFIRM_BUTTON_ARIA_LABEL =
    'AUTO_DELIVERY_DELIVER_ASAP_CONFIRM_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_ASAP_INFO_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_ASAP_INFO_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_ASAP_POPUP_CONFIRM_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_ASAP_POPUP_CONFIRM_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_ASAP_POPUP_SUCCESS_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_ASAP_POPUP_SUCCESS_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_ASAP_POPUP_ERROR_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_ASAP_POPUP_ERROR_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_SKIP_DELIVERY_CONFIRM_BUTTON_ARIA_LABEL =
    'AUTO_DELIVERY_SKIP_DELIVERY_CONFIRM_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_SKIP_POPUP_SUCCESS_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_SKIP_POPUP_SUCCESS_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_SKIP_POPUP_ERROR_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_SKIP_POPUP_ERROR_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_SKIP_CANT_BE_SKIPPER_BUTTON_ARIA_LABEL =
    'AUTO_DELIVERY_SKIP_CANT_BE_SKIPPER_BUTTON_ARIA_LABEL';
export const NAVIGATION_BAR_LOGO_BUTTON_ARIA_LABEL = 'NAVIGATION_BAR_LOGO_BUTTON_ARIA_LABEL';
export const NAVIGATION_BAR_FOR_YOU_BUTTON_ARIA_LABEL = 'NAVIGATION_BAR_FOR_YOU_BUTTON_ARIA_LABEL';
export const NAVIGATION_BAR_SHOP_BUTTON_ARIA_LABEL = 'NAVIGATION_BAR_SHOP_BUTTON_ARIA_LABEL';
export const NAVIGATION_BAR_DEVICE_BUTTON_ARIA_LABEL = 'NAVIGATION_BAR_DEVICE_BUTTON_ARIA_LABEL';
export const SHOP_YOUR_DEVICES_SHOP_ACCESSORIES_ARIA_LABEL = 'SHOP_YOUR_DEVICES_SHOP_ACCESSORIES_ARIA_LABEL';
export const SHOP_YOUR_DEVICES_SHOP_DEVICES_ARIA_LABEL = 'SHOP_YOUR_DEVICES_SHOP_DEVICES_ARIA_LABEL';
export const SHOP_YOUR_DEVICES_TRADE_IN_ARIA_LABEL = 'SHOP_YOUR_DEVICES_TRADE_IN_ARIA_LABEL';
export const NO_DEVICES_PAGE_TRY_YOUR_FIRST_IQOS_BUTTON_ARIA_LABEL =
    'NO_DEVICES_PAGE_TRY_YOUR_FIRST_IQOS_BUTTON_ARIA_LABEL';
export const NO_DEVICES_PAGE_ADD_IQOS_BUTTON_ARIA_LABEL = 'NO_DEVICES_PAGE_ADD_IQOS_BUTTON_ARIA_LABEL';
export const MY_DEVICES_PAGE_RENAME_DEVICE_BUTTON_ARIA_LABEL = 'MY_DEVICES_PAGE_RENAME_DEVICE_BUTTON_ARIA_LABEL';
export const MY_DEVICES_PAGE_DEVICE_LIST_BUTTON_ARIA_LABEL = 'MY_DEVICES_PAGE_DEVICE_LIST_BUTTON_ARIA_LABEL';
export const MY_DEVICES_PAGE_CONNECT_DEVICE_BUTTON_ARIA_LABEL = 'MY_DEVICES_PAGE_CONNECT_DEVICE_BUTTON_ARIA_LABEL';
export const MY_DEVICES_PAGE_FLEX_BATTERY_BUTTON_ARIA_LABEL = 'MY_DEVICES_PAGE_FLEX_BATTERY_BUTTON_ARIA_LABEL';
export const MY_DEVICES_PAGE_FLEX_PUFF_BUTTON_ARIA_LABEL = 'MY_DEVICES_PAGE_FLEX_PUFF_BUTTON_ARIA_LABEL';
export const MY_DEVICES_PAGE_AUTO_START_BUTTON_ARIA_LABEL = 'MY_DEVICES_PAGE_AUTO_START_BUTTON_ARIA_LABEL';
export const MY_DEVICES_PAGE_SMART_GESTURES_BUTTON_ARIA_LABEL = 'MY_DEVICES_PAGE_SMART_GESTURES_BUTTON_ARIA_LABEL';
export const MY_DEVICES_PAGE_VIBRATIONS_BUTTON_ARIA_LABEL = 'MY_DEVICES_PAGE_VIBRATIONS_BUTTON_ARIA_LABEL';
export const MY_DEVICES_PAGE_ILLUMINATION_MODE_BUTTON_ARIA_LABEL =
    'MY_DEVICES_PAGE_ILLUMINATION_MODE_BUTTON_ARIA_LABEL';
export const MY_DEVICES_PAGE_ABOUT_DEVICE_BUTTON_ARIA_LABEL = 'MY_DEVICES_PAGE_ABOUT_DEVICE_BUTTON_ARIA_LABEL';
export const MY_DEVICES_PAGE_DIAGNOSE_ISSUE_BUTTON_ARIA_LABEL = 'MY_DEVICES_PAGE_DIAGNOSE_ISSUE_BUTTON_ARIA_LABEL';
export const MY_DEVICES_PAGE_TIPS_AND_TUTORIALS_BUTTON_ARIA_LABEL =
    'MY_DEVICES_PAGE_TIPS_AND_TUTORIALS_BUTTON_ARIA_LABEL';
export const DEVICE_RENAME_PAGE_DEFAULT_NAME_BUTTON_ARIA_LABEL = 'DEVICE_RENAME_PAGE_DEFAULT_NAME_BUTTON_ARIA_LABEL';
export const DEVICE_RENAME_PAGE_PERSONALIZED_NAME_BUTTON_ARIA_LABEL =
    'DEVICE_RENAME_PAGE_PERSONALIZED_NAME_BUTTON_ARIA_LABEL';
export const DEVICE_RENAME_PAGE_SAVE_BUTTON_ARIA_LABEL = 'DEVICE_RENAME_PAGE_SAVE_BUTTON_ARIA_LABEL';
export const DEVICE_UNREGISTER_BUTTON_TEXT_ARIA_LABEL = 'DEVICE_UNREGISTER_BUTTON_TEXT_ARIA_LABEL';
export const MY_DEVICES_LIST_ADD_NEW_BUTTON_TEXT_ARIA_LABEL = 'MY_DEVICES_LIST_ADD_NEW_BUTTON_TEXT_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_ENTRY_POINT_BUTTON_ARIA_LABEL = 'ADVANCED_DIAGNOSTICS_ENTRY_POINT_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_DAMAGE_SELECT_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_DAMAGE_SELECT_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_DAMAGE_NONE_BUTTON_ARIA_LABEL = 'ADVANCED_DIAGNOSTICS_DAMAGE_NONE_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_SELECT_ISSUE_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_SELECT_ISSUE_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_CURE_MARKET_BUTTON_ARIA_LABEL = 'ADVANCED_DIAGNOSTICS_CURE_MARKET_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_NOT_CURE_MARKET_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_NOT_CURE_MARKET_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_ACCEPT_THIRD_STEP_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_ACCEPT_THIRD_STEP_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_ELIGIBILITY_CHECK_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_ELIGIBILITY_CHECK_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_SUPPORT_CONTACT_US_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_SUPPORT_CONTACT_US_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_SUPPORT_FIND_STORE_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_SUPPORT_FIND_STORE_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_CHARGING_ISSUE_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_CHARGING_ISSUE_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_CHARGING_ISSUE_NOT_NOW_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_CHARGING_ISSUE_NOT_NOW_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_CLEANING_CONTACTS_GOT_IT_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_CLEANING_CONTACTS_GOT_IT_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_CLEANING_CONTACTS_TRY_AGAIN_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_CLEANING_CONTACTS_TRY_AGAIN_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_CLEANING_CONTACTS_NOT_NOW_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_CLEANING_CONTACTS_NOT_NOW_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_REMOVE_STICK_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_REMOVE_STICK_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_CONSUMABLES_SOLVED_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_CONSUMABLES_SOLVED_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_POST_CLEANING_REPEAT_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_POST_CLEANING_REPEAT_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_POST_CLEANING_YES_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_POST_CLEANING_YES_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_POST_CLEANING_NO_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_POST_CLEANING_NO_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_POST_CLEANING_DEVICE_YES_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_POST_CLEANING_DEVICE_YES_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_POST_CLEANING_DEVICE_NO_BUTTON_ARIA_LABEL =
    'ADVANCED_DIAGNOSTICS_POST_CLEANING_DEVICE_NO_BUTTON_ARIA_LABEL';
export const ADVANCED_DIAGNOSTICS_FINISH_BUTTON_ARIA_LABEL = 'ADVANCED_DIAGNOSTICS_FINISH_BUTTON_ARIA_LABEL';
export const CAROUSEL_NAVIGATION_SLIDE_ARIA_LABEL = 'CAROUSEL_NAVIGATION_SLIDE_ARIA_LABEL';
export const UNREGISTER_YOUR_DEVICE_POPUP_CANCEL_BUTTON_ARIA_LABEL =
    'UNREGISTER_YOUR_DEVICE_POPUP_CANCEL_BUTTON_ARIA_LABEL';
export const UNREGISTER_YOUR_DEVICE_POPUP_UNREGISTER_BUTTON_ARIA_LABEL =
    'UNREGISTER_YOUR_DEVICE_POPUP_UNREGISTER_BUTTON_ARIA_LABEL';
export const INBOX_DELETE_MESSAGE_BUTTON_ARIA_LABEL = 'INBOX_DELETE_MESSAGE_BUTTON_ARIA_LABEL';
export const CONSUMER_PROFILE_PREFERENCE_CENTER_BUTTON_ARIA_LABEL =
    'CONSUMER_PROFILE_PREFERENCE_CENTER_BUTTON_ARIA_LABEL';
export const CONSUMER_PROFILE_LANGUAGE_BUTTON_ARIA_LABEL = 'CONSUMER_PROFILE_LANGUAGE_BUTTON_ARIA_LABEL';
export const CONSUMER_PROFILE_CONTACT_US_BUTTON_ARIA_LABEL = 'CONSUMER_PROFILE_CONTACT_US_BUTTON_ARIA_LABEL';
export const CONSUMER_PROFILE_TERMS_OF_USE_BUTTON_ARIA_LABEL = 'CONSUMER_PROFILE_TERMS_OF_USE_BUTTON_ARIA_LABEL';
export const CONSUMER_PROFILE_PRIVACY_NOTICE_BUTTON_ARIA_LABEL = 'CONSUMER_PROFILE_PRIVACY_NOTICE_BUTTON_ARIA_LABEL';
export const CONSUMER_PROFILE_ATTRIBUTION_NOTICE_BUTTON_ARIA_LABEL =
    'CONSUMER_PROFILE_ATTRIBUTION_NOTICE_BUTTON_ARIA_LABEL';
export const CONSUMER_PROFILE_LOGOUT_BUTTON_ARIA_LABEL = 'CONSUMER_PROFILE_LOGOUT_BUTTON_ARIA_LABEL';
export const TNC_PREFERENCE_CENTER_ENABLE_ALL_BUTTON_ARIA_LABEL = 'TNC_PREFERENCE_CENTER_ENABLE_ALL_BUTTON_ARIA_LABEL';
export const TNC_PREFERENCE_CENTER_SAVE_BUTTON_ARIA_LABEL = 'TNC_PREFERENCE_CENTER_SAVE_BUTTON_ARIA_LABEL';
export const TNC_PREFERENCE_CENTER_CONTINUE_BUTTON_ARIA_LABEL = 'TNC_PREFERENCE_CENTER_CONTINUE_BUTTON_ARIA_LABEL';
export const LANGUAGE_SELECT_SAVE_BUTTON_ARIA_LABEL = 'LANGUAGE_SELECT_SAVE_BUTTON_ARIA_LABEL';
export const CONTACT_US_SEND_QUESTION_BUTTON_ARIA_LABEL = 'CONTACT_US_SEND_QUESTION_BUTTON_ARIA_LABEL';
export const CONTACT_US_CALL_US_BUTTON_ARIA_LABEL = 'CONTACT_US_CALL_US_BUTTON_ARIA_LABEL';
export const ENABLE_NOTIFICATION_POPUP_NEXT_BUTTON_ARIA_LABEL = 'ENABLE_NOTIFICATION_POPUP_NEXT_BUTTON_ARIA_LABEL';
export const LEVIA_POPUP_SKIP_BUTTON_ARIA_LABEL = 'LEVIA_POPUP_SKIP_BUTTON_ARIA_LABEL';
export const FIRMWARE_AVAILABLE_POPUP_SKIP_BUTTON_ARIA_LABEL = 'FIRMWARE_AVAILABLE_POPUP_SKIP_BUTTON_ARIA_LABEL';
export const BROWSER_NOT_COMPATIBLE_START_BUTTON_ARIA_LABEL = 'BROWSER_NOT_COMPATIBLE_START_BUTTON_ARIA_LABEL';
export const MAINTENANCE_POPUP_BUTTON_ARIA_LABEL = 'MAINTENANCE_POPUP_BUTTON_ARIA_LABEL';
export const APP_UPDATE_POPUP_BUTTON_ARIA_LABEL = 'APP_UPDATE_POPUP_BUTTON_ARIA_LABEL';
export const CONSUMER_FEEDBACK_POPUP_GO_BACK_BUTTON_ARIA_LABEL = 'CONSUMER_FEEDBACK_POPUP_GO_BACK_BUTTON_ARIA_LABEL';
export const CONSUMER_FEEDBACK_POPUP_RATE_BUTTON_ARIA_LABEL = 'CONSUMER_FEEDBACK_POPUP_RATE_BUTTON_ARIA_LABEL';
export const CONSUMER_FEEDBACK_POPUP_FEEDBACK_BUTTON_ARIA_LABEL = 'CONSUMER_FEEDBACK_POPUP_FEEDBACK_BUTTON_ARIA_LABEL';
export const CONSUMER_PROFILE_SHOW_PROFILE_BUTTON_ARIA_LABEL = 'CONSUMER_PROFILE_SHOW_PROFILE_BUTTON_ARIA_LABEL';
export const GLOBAL_ERROR_CONTACT_US_BUTTON_ARIA_LABEL = 'GLOBAL_ERROR_CONTACT_US_BUTTON_ARIA_LABEL';
export const CONTACT_US_PAGE_TWITTER_BUTTON_ARIA_LABEL = 'CONTACT_US_PAGE_TWITTER_BUTTON_ARIA_LABEL';
export const CONTACT_US_PAGE_FACEBOOK_BUTTON_ARIA_LABEL = 'CONTACT_US_PAGE_FACEBOOK_BUTTON_ARIA_LABEL';
export const CONTACT_US_PAGE_YOUTUBE_BUTTON_ARIA_LABEL = 'CONTACT_US_PAGE_YOUTUBE_BUTTON_ARIA_LABEL';
export const CONTACT_US_PAGE_TELEGRAM_BUTTON_ARIA_LABEL = 'CONTACT_US_PAGE_TELEGRAM_BUTTON_ARIA_LABEL';
export const CONTACT_US_PAGE_INSTAGRAM_BUTTON_ARIA_LABEL = 'CONTACT_US_PAGE_INSTAGRAM_BUTTON_ARIA_LABEL';
export const FIRMWARE_AVAILABLE_POPUP_CHECK_DEVICE_BUTTON_ARIA_LABEL =
    'FIRMWARE_AVAILABLE_POPUP_CHECK_DEVICE_BUTTON_ARIA_LABEL';
export const FIRMWARE_AVAILABLE_POPUP_LEARN_MORE_BUTTON_ARIA_LABEL =
    'FIRMWARE_AVAILABLE_POPUP_LEARN_MORE_BUTTON_ARIA_LABEL';
export const LEVIA_POPUP_CHECK_COMPATIBILITY_BUTTON_ARIA_LABEL = 'LEVIA_POPUP_CHECK_COMPATIBILITY_BUTTON_ARIA_LABEL';
export const LEVIA_POPUP_LEARN_MORE_BUTTON_ARIA_LABEL = 'LEVIA_POPUP_LEARN_MORE_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_PAYMENT_PENDING_BUTTON_ARIA_LABEL = 'AUTO_DELIVERY_PAYMENT_PENDING_BUTTON_ARIA_LABEL';
export const TNC_PAGE_AGREE_BUTTON_ARIA_LABEL = 'TNC_PAGE_AGREE_BUTTON_ARIA_LABEL';
export const TNC_PAGE_DECLINE_BUTTON_ARIA_LABEL = 'TNC_PAGE_DECLINE_BUTTON_ARIA_LABEL';
export const TNC_DATA_COLLECTION_PAGE_AGREE_BUTTON_ARIA_LABEL = 'TNC_DATA_COLLECTION_PAGE_AGREE_BUTTON_ARIA_LABEL';
export const TNC_DATA_COLLECTION_PAGE_DECLINE_BUTTON_ARIA_LABEL = 'TNC_DATA_COLLECTION_PAGE_DECLINE_BUTTON_ARIA_LABEL';
export const TNC_DATA_COLLECTION_PAGE_PREFERENCE_CENTER_BUTTON_ARIA_LABEL =
    'TNC_DATA_COLLECTION_PAGE_PREFERENCE_CENTER_BUTTON_ARIA_LABEL';
export const AUTO_DELIVERY_PROGRESS_ARIA_LABEL = 'AUTO_DELIVERY_PROGRESS_ARIA_LABEL';
export const HIGHLIGHTS_FULLSCREEN_POPUP_ARIA_LABEL = 'HIGHLIGHTS_FULLSCREEN_POPUP_ARIA_LABEL';
export const AUTO_DELIVERY_ITEM_QUANTITY_ARIA_LABEL = 'AUTO_DELIVERY_ITEM_QUANTITY_ARIA_LABEL';
export const CAROUSEL_NAVIGATION_CURRENT_SLIDE_ARIA_LABEL = 'CAROUSEL_NAVIGATION_CURRENT_SLIDE_ARIA_LABEL';
export const NEW_NOTIFICATION_ARIA_LABEL = 'NEW_NOTIFICATION_ARIA_LABEL';
export const UWM_UNAVAILABLE_POPUP_REFRESH_BUTTON_ARIA_LABEL = 'UWM_UNAVAILABLE_POPUP_REFRESH_BUTTON_ARIA_LABEL';
export const UWM_UNAVAILABLE_POPUP_NOT_NOW_BUTTON_ARIA_LABEL = 'UWM_UNAVAILABLE_POPUP_NOT_NOW_BUTTON_ARIA_LABEL';
export const INBOX_LIST_VIEW_ARIA_LIVE_MESSAGE = 'INBOX_LIST_VIEW_ARIA_LIVE_MESSAGE';
export const INBOX_DETAILS_VIEW_ARIA_LIVE_MESSAGE = 'INBOX_DETAILS_VIEW_ARIA_LIVE_MESSAGE';
export const MY_DEVICES_PAGE_SETTING_BUTTON_WITH_STATE_ARIA_LABEL =
    'MY_DEVICES_PAGE_SETTING_BUTTON_WITH_STATE_ARIA_LABEL';
export const INBOX_ARIA_LABEL = 'INBOX_ARIA_LABEL';
