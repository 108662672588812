import {useLocation} from 'react-router-dom';

import urlUtils from '@/utils/urlUtils';

const useSearchParams = () => {
    const {search} = useLocation();
    const searchParams = urlUtils.parseQueryString(search);

    return [searchParams];
};

export default useSearchParams;
