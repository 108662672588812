import {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';

import ROUTE_PATHS from '@/consts/route/routePaths';
import ContactUsPage from '@/containers/ContactUsPage/ContactUsPage';
import ProfileLanguageSelectPage from '@/containers/ProfileLanguageSelectPage/ProfileLanguageSelectPage';
import ProfilePreferenceCenterPage from '@/containers/ProfilePreferenceCenterPage/ProfilePreferenceCenterPage';
import ProfileTermsOfUsePage from '@/containers/ProfileTermsOfUsePage/ProfileTermsOfUsePage';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import useLocationPathname from '@/hooks/useLocationPathname';
import useWithLocale from '@/hooks/useWithLocale';
import domService from '@/services/domService';

import {PROFILE_SIDEBAR_MENU_ITEMS} from './consts/profileSidebarMenu';
import MobileConsumerProfilePage from './MobileConsumerProfilePage';
import ProfilePageContainer from './ProfilePageContainer';

const ConsumerProfilePage = () => {
    const {pathname} = useLocationPathname();
    const withLocale = useWithLocale();
    const isMobile = useIsMobileLayout();
    const activeMenuItem = useActiveMenuItem();
    const profileDefaultPage = isMobile ? MobileConsumerProfilePage : ProfilePreferenceCenterPage;

    useEffect(() => {
        const contentElement = domService.getProfileSidebarContentElement();

        contentElement?.scrollTo(0, 0);
    }, [pathname]);

    return (
        <ProfilePageContainer activeMenuItem={activeMenuItem}>
            <Switch>
                <Route
                    path={withLocale(ROUTE_PATHS.PROFILE_PREFERENCE_CENTER)}
                    component={ProfilePreferenceCenterPage}
                />
                <Route path={withLocale(ROUTE_PATHS.PROFILE_LANGUAGE_SELECT)} component={ProfileLanguageSelectPage} />
                <Route path={withLocale(ROUTE_PATHS.CONTACT_US)} component={ContactUsPage} />
                <Route path={withLocale(ROUTE_PATHS.PROFILE_TERMS_OF_USE)} component={ProfileTermsOfUsePage} />
                <Route component={profileDefaultPage} />
            </Switch>
        </ProfilePageContainer>
    );
};

const useActiveMenuItem = () => {
    const {pathname} = useLocationPathname();
    const defaultMenuId = PROFILE_SIDEBAR_MENU_ITEMS.PREFERENCE_CENTER;
    const menuIds = {
        [ROUTE_PATHS.PROFILE_PREFERENCE_CENTER]: PROFILE_SIDEBAR_MENU_ITEMS.PREFERENCE_CENTER,
        [ROUTE_PATHS.PROFILE_LANGUAGE_SELECT]: PROFILE_SIDEBAR_MENU_ITEMS.LANGUAGE_SELECT,
        [ROUTE_PATHS.CONTACT_US]: PROFILE_SIDEBAR_MENU_ITEMS.CONTACT_US,
        [ROUTE_PATHS.PROFILE_TERMS_OF_USE]: PROFILE_SIDEBAR_MENU_ITEMS.TERMS_OF_USE,
    };

    const activeMenuItem = menuIds[pathname] || defaultMenuId;

    return activeMenuItem;
};

export default ConsumerProfilePage;
