import React from 'react';

import AppButton from '../../../components/Button/AppButton/AppButton';
import Image from '../../../components/Image/Image';
import useOnConnectClick from '../../DevicesPage/hooks/useOnConnectClick';

const SelectDeviceItem = (props) => {
    const {image, name, type} = props;
    const {onConnectClick} = useOnConnectClick(type);

    return (
        <li className='flex w-full flex-col items-center gap-4 bg-secondary px-8 py-4 ml:rounded-[24px] ml:bg-white ml:py-8'>
            <div>
                <Image className='h-auto w-full' src={image} alt={name} aria-hidden />
            </div>
            <AppButton secondary light onClick={onConnectClick} className='mt-0 w-full ml:max-w-66'>
                {name}
            </AppButton>
        </li>
    );
};

export default SelectDeviceItem;
