import React, {useEffect} from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import SupportAndStoreTemplate from '@/components/SupportAndStoreTemplate/SupportAndStoreTemplate';
import appConfig from '@/config/appConfig';
import * as advanceDiagnosticIconTypes from '@/consts/advanceDiagnostic/advanceDiagnosticIconTypes';
import * as advanceDiagnosticScreenTypes from '@/consts/advanceDiagnostic/advanceDiagnosticScreenTypes';
import {
    ADVANCED_DIAGNOSTICS_ACCEPT_THIRD_STEP_BUTTON_ARIA_LABEL,
    ADVANCED_DIAGNOSTICS_CURE_MARKET_BUTTON_ARIA_LABEL,
    ADVANCED_DIAGNOSTICS_ELIGIBILITY_CHECK_BUTTON_ARIA_LABEL,
    ADVANCED_DIAGNOSTICS_NOT_CURE_MARKET_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeADTracking from '@/services/analytics/amplitude/amplitudeADTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners} from '@/views/WarningBanners';

import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticDescription from '../components/AdvancedDiagnosticDescription';
import AdvancedDiagnosticIcon from '../components/AdvancedDiagnosticIcon';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';
import NotNowButtonAD from '../components/NotNowButtonAD';
import useADMarketScreen from './useADMarketScreen';

export const AdvancedDiagnosticQuestionnaireMarketScreen = () => {
    const {inferenceCode, redirectToSupport, isHolder, isQuestionnaireThirdStep} = useADMarketScreen();
    const isQureMarket = appConfig.getIsQureMarket();

    const itemNameTitle = isQureMarket
        ? isQuestionnaireThirdStep
            ? 'quremarketthirdsteptitle'
            : 'quremarkettitle'
        : 'notquremarkettitle';

    const itemNameDescription = isQureMarket
        ? isQuestionnaireThirdStep
            ? 'quremarketthirdstepdesc'
            : 'quremarketdesc'
        : 'notquremarketdesc';

    const itemNameAcceptButtonText = isQuestionnaireThirdStep ? 'acceptthirdstepbutton' : 'acceptbutton';
    const buttonAriaLabel = isQuestionnaireThirdStep
        ? ADVANCED_DIAGNOSTICS_ACCEPT_THIRD_STEP_BUTTON_ARIA_LABEL
        : ADVANCED_DIAGNOSTICS_ELIGIBILITY_CHECK_BUTTON_ARIA_LABEL;

    useEffect(() => {
        amplitudeADTracking.trackADInitialQuestionnaireScreen();
        googleAnalyticsService.trackAdInitialQuestionnaireScreenView(isQureMarket);
    }, []);

    const NotNowButton = ({itemName, isTertiary, ariaLabelKey}) => (
        <NotNowButtonAD
            isHolder={isHolder}
            inferenceCode={inferenceCode}
            screenName={advanceDiagnosticScreenTypes.QUESTIONNAIRE_MARKET_SCREEN}
            isQuestionnaireThirdStep={isQuestionnaireThirdStep}
            isTertiary={isTertiary}
            aria-label={getAriaLabel(ariaLabelKey)}
        >
            <AemComponent component={EditableText} pageName={routePaths.ADVANCE_DIAGNOSTIC} itemName={itemName} />
        </NotNowButtonAD>
    );

    return (
        <div className='flex w-full flex-col'>
            <AdvancedDiagnosticContainer isDeviceHero>
                <AdvancedDiagnosticIcon type={advanceDiagnosticIconTypes.ERROR} />
                <div className='px-8'>
                    <AdvancedDiagnosticTitle itemName={itemNameTitle} />
                    <AdvancedDiagnosticDescription itemName={itemNameDescription} />
                </div>
                <div className='mt-10 w-full md:w-auto'>
                    {isQureMarket ? (
                        <div className='px-8'>
                            <AppButton
                                primary
                                light
                                className='mb-4 mt-0 min-w-[312px]'
                                aria-label={getAriaLabel(buttonAriaLabel)}
                                onClick={redirectToSupport}
                            >
                                <AemComponent
                                    component={EditableText}
                                    pageName={routePaths.ADVANCE_DIAGNOSTIC}
                                    itemName={itemNameAcceptButtonText}
                                />
                            </AppButton>
                            <NotNowButton
                                itemName='quremarketbutton'
                                ariaLabelKey={ADVANCED_DIAGNOSTICS_CURE_MARKET_BUTTON_ARIA_LABEL}
                            />
                        </div>
                    ) : (
                        <>
                            <SupportAndStoreTemplate className='mb-4 mt-0 md:mb-6 md:flex-col ml:flex-row' />
                            <NotNowButton
                                isTertiary
                                itemName='notquremarketbutton'
                                ariaLabelKey={ADVANCED_DIAGNOSTICS_NOT_CURE_MARKET_BUTTON_ARIA_LABEL}
                            />
                        </>
                    )}
                </div>
            </AdvancedDiagnosticContainer>
            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.AD} />
        </div>
    );
};

export default AdvancedDiagnosticQuestionnaireMarketScreen;
