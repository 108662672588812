import React from 'react';
import {useSelector} from 'react-redux';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import DeviceIcon from '@/components/DeviceIcon/DeviceIcon';
import {StickyHeader} from '@/components/Layout';
import appConfig from '@/config/appConfig';
import {
    AD_SELECT_ISSUE_HEADER_TITLE,
    ADVANCED_DIAGNOSTICS_SELECT_ISSUE_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeADTracking from '@/services/analytics/amplitude/amplitudeADTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import routerService from '@/services/route/routerService';
import {selectDeviceType} from '@/state/selectors/device';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners} from '@/views/WarningBanners';

import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticDescription from '../components/AdvancedDiagnosticDescription';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';
import useRedirectToPhase2 from '../hooks/useRedirectToPhase2';
import AdvancedDiagnosticIssueList from './components/AdvancedDiagnosticIssueList';

const AdvancedDiagnosticSelectIssue = () => {
    const isMobile = useIsMobileLayout();
    const deviceType = useSelector(selectDeviceType);
    const diagnosticData = appConfig.getAdConfig(deviceType, 'step2');
    const redirectToPhase2 = useRedirectToPhase2();
    const localizedStrings = getLocalizedStrings();

    const onNextButtonClick = async () => {
        amplitudeADTracking.trackADNoneOfTheAboveClick();
        googleAnalyticsService.trackAdNoneOfTheAboveClick();
        redirectToPhase2();
    };

    useDocumentTitle(localizedStrings[AD_SELECT_ISSUE_HEADER_TITLE]);

    return (
        <div className='flex w-full flex-col'>
            <AdvancedDiagnosticContainer>
                {isMobile && (
                    <StickyHeader
                        onBack={routerService.goBack}
                        title={localizedStrings[AD_SELECT_ISSUE_HEADER_TITLE]}
                    />
                )}
                <DeviceIcon mode='diagnostics' className='h-16 w-16' />
                <AdvancedDiagnosticTitle itemName='selectissuetitle' />
                <AdvancedDiagnosticDescription itemName='selectissuedesc' />
                <AdvancedDiagnosticIssueList issuesData={diagnosticData} />
                <AppButton
                    secondary
                    light
                    onClick={onNextButtonClick}
                    className='mx-auto mt-6'
                    aria-label={getAriaLabel(ADVANCED_DIAGNOSTICS_SELECT_ISSUE_BUTTON_ARIA_LABEL)}
                >
                    <AemComponent
                        component={EditableText}
                        pageName={routePaths.ADVANCE_DIAGNOSTIC}
                        itemName='selectissuebutton'
                    />
                </AppButton>
            </AdvancedDiagnosticContainer>
            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.AD} />
        </div>
    );
};

export default AdvancedDiagnosticSelectIssue;
