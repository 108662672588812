import React, {forwardRef} from 'react';

import Link from '@/components/Link/Link';
import {twx} from '@/utils/tailwindUtils';

import styles from './Button.module.scss';

const ButtonRaw = forwardRef((props, ref) => {
    const {className, disabled, to, onClick, onDisableClick, children, ...restProps} = props;
    const btnClassName = twx(styles.ButtonRaw, className, {
        'cursor-default': disabled && !onDisableClick,
    });

    const eventProps = disabled
        ? {
              onClick: onDisableClick,
          }
        : {
              to,
              onClick,
          };

    return (
        <Link ref={ref} {...restProps} {...eventProps} disabled={disabled} className={btnClassName}>
            {children}
        </Link>
    );
});

ButtonRaw.displayName = 'ButtonRaw';

export default ButtonRaw;
