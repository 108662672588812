import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import RemoteIcon from '@/components/Icon/RemoteIcon';
import {BellIcon} from '@/components/Icons';
import {MaxWidthContainer} from '@/components/Layout';
import appConfig from '@/config/appConfig';
import {getLocalizedStrings} from '@/services/localization/localizationService';

const EnableNotificationModalContent = ({children, headerId}) => {
    const icons = appConfig.getEnableNotificationPageIcons();
    const localizedStrings = getLocalizedStrings();

    return (
        <MaxWidthContainer className='flex-1 px-4 md:mt-4'>
            <BellIcon className='mt-8 h-16 w-16' />
            <h1 id={headerId} className='mb-0 mt-4 text-28'>
                <AemEditableText pageName='enable-notification' itemName='enableNotificationTitle' />
            </h1>
            <ul className='mb-3 mt-16 flex w-full flex-col gap-4 px-4 md:mb-14 md:mt-4'>
                {icons?.map((icon, i) => {
                    const {titleKey} = icon;
                    const title = localizedStrings[titleKey];

                    if (!title) return null;

                    return (
                        <li key={i} className='flex items-center justify-start gap-2'>
                            <div className='min-w-8'>
                                <RemoteIcon icon={icon} />
                            </div>
                            <span className='text-start'>{title}</span>
                        </li>
                    );
                })}
            </ul>
            {children}
        </MaxWidthContainer>
    );
};

export default EnableNotificationModalContent;
