const GA_EVENT_NAMES = {
    NOTIFICATION_RECEIVED: 'notification_received',
    HOME_SCREEN_VIEWED: 'home_screen_viewed',
    TOP_SECTION: 'top_section',
    LOYALTY_BUTTONS_BAR_CLICKED: 'loyalty_buttons_bar_clicked',
    HIGHLIGHT_BANNER_CLICKED: 'highlight_banner_clicked',
    FOR_YOU_TILE_OPENED: 'for_you_tile_opened',
    SUGGEST_FOR_YOU_LINK_OPENED: 'suggest_for_you_link_opened',
    SHOP_SECTION_VIEWED: 'shop_section_viewed',
    QUICK_ORDER_BANNER_CLICKED: 'quick_order_banner_clicked',
    QUICK_ORDER_BUTTON_CLICKED: 'quick_order_button_clicked',
    YOUR_DEVICES_BUTTON_CLICKED: 'your_devices_button_clicked',
    SHOP_BY_PRODUCT_CLICKED: 'shop_by_product_clicked',
    DEVICE_SECTION_VIEWED: 'device_section_viewed',
    TRY_YOUR_FIRST_IQOS_BUTTON_CLICKED: 'try_your_first_iqos_button_clicked',
    ABOUT_MY_DEVICE_SCREEN_VIEWED: 'about_my_device_screen_viewed',
    TIPS_AND_TUTORIALS_SCREEN_VIEWED: 'tips_and_tutorial_screen_viewed',
    UNREGISTER_DEVICE_CLICKED: 'unregister_device_clicked',
    INBOX_SCREEN_VIEWED: 'inbox_screen_viewed',
    INBOX_MESSAGE_OPENED: 'inbox_message_opened',
    INBOX_ACTION_BUTTON_CLICKED: 'inbox_action_button_clicked',
    ACTION_SCREEN_VIEWED: 'action_screen_viewed',
    PREFERENCE_CENTER_VIEWED: 'preference_center_viewed',
    PREFERENCE_CENTER_UPDATE_VIEWED: 'preference_center_update_viewed',
    CONTACT_US_VIEWED: 'contact_us_viewed',
    SHOW_PROFILE_CLICKED: 'show_profile_clicked',
    LEGAL_VIEWED: 'legal_viewed',
    ADD_NEW_DEVICE_CLICKED: 'add_new_device_clicked',
    PAIR_DEVICE_CLICKED: 'pair_device_clicked',
    RENAME_MY_DEVICE_VIEWED: 'rename_my_device_viewed',
    DEVICE_RENAMED: 'device_renamed',
    QUESTIONNAIRE_SELECT: 'questionnaire_select',
    QUESTIONNAIRE_SCROLL: 'questionnaire_scroll',
    CLEAN_MY_DEVICE_BUTTON_CLICKED: 'clean_my_device_button_clicked',
    REMOVE_STICK_INSTRUCTIONS_SCROLLED: 'remove_stick_instructions_scrolled',
    DEVICE_SCREEN_VIEWED: 'diagnose_screen_viewed',
    AD_INITIAL_QUESTIONNAIRE_VIEWED: 'advanced_diagnostics_initial_questionnaire_viewed',
    AD_FINISH_SCREEN_VIEWED: 'advanced_diagnostics_finish_screen_viewed',
    AD_NONE_OF_THE_ABOVE_BUTTON_CLICKED: 'none_of_the_above_button_clicked',
    HIGHLIGHT_VIEW_ALL_CLICKED: 'highlight_view_all_clicked',
    PROFILE_SCREEN_VIEWED: 'profile_screen_viewed',
    START_WITH_BLUEFY_CLICKED: 'start_with_bluefy_clicked',
};

export default GA_EVENT_NAMES;
