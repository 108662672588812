import {useEffect, useRef} from 'react';

import helpers from '@/utils/helpers';

export const useScrollToPreviousPosition = () => {
    const topRef = useRef();

    if (!topRef.current) {
        topRef.current = helpers.getBodyTop();
    }

    useEffect(() => {
        return () => {
            helpers.scrollTop(-topRef.current);
        };
    }, []);
};

export default useScrollToPreviousPosition;
