import React, {useId, useState} from 'react';

import Banner from '@/components/Banner/Banner';
import {AppButton} from '@/components/Button';
import CloseButton from '@/components/CloseButton/CloseButton';
import {StickyHeader} from '@/components/Layout';
import PopupResponsive from '@/components/Popup/PopupResponsive';
import appConfig from '@/config/appConfig';
import {
    QUICK_ORDER_BENEFITS_HEADER_TITLE,
    QUICK_ORDER_HEADER_TITLE,
    QUICK_ORDER_HOW_IT_WORKS_HEADER_TITLE,
} from '@/consts/localization/localizationKeys';
import QuickOrderSection from '@/containers/Modals/QuickOrderPopup/components/QuickOrderSection';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeShopPageTracking from '@/services/analytics/amplitude/amplitudeShopPageTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import modalService from '@/services/modalService';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

const QuickOrderPopup = () => {
    const [popupEl, setPopupEl] = useState();
    const isMobile = useIsMobileLayout();
    const headerId = useId();
    const banner = appConfig.getQuickOrderBanner();
    const {imageMedia, videoPath, isGradient, titleKey, descriptionKey, buttonActionKey, action, actionAriaLabelKey} =
        banner || {};
    const localizedStrings = getLocalizedStrings();
    const redirectToExternalLink = useConfigExternalLink(action);
    const isShowButton = buttonActionKey && localizedStrings[buttonActionKey] && redirectToExternalLink;
    const handleClose = () => modalService.toggleQuickOrderPopup(false);

    const onButtonClick = () => {
        amplitudeShopPageTracking.trackQuickOrderButtonClick();
        googleAnalyticsService.trackQuickOrderButtonClick();
        redirectToExternalLink();
    };

    useDocumentTitle(localizedStrings[QUICK_ORDER_HEADER_TITLE]);

    return (
        <PopupResponsive
            contentRef={setPopupEl}
            className='overflow-auto text-start pt-0-hw-offset ml:max-h-[calc(100%-92px-var(--hw-h))] ml:w-[calc(100%-128px)] ml:max-w-[1016px] ml:rounded-xl ml:p-0'
            hasNavbarOffset
            aria-labelledby={headerId}
            onClose={handleClose}
        >
            {isMobile && (
                <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.QUICK_ORDER_POPUP} containerEl={popupEl} />
            )}
            {isMobile && (
                <StickyHeader
                    scrollElement={popupEl}
                    title={localizedStrings[QUICK_ORDER_HEADER_TITLE]}
                    endIcon={<CloseButton onClick={handleClose} />}
                />
            )}
            <Banner
                className='h-[422px] bg-tertiary ml:h-[308px]'
                imageMedia={imageMedia}
                videoPath={videoPath}
                isGradient={isGradient}
                gradient='linear-gradient(180deg, rgba(52, 48, 61, 0) 39.99%, #1C1A21 100%)'
            >
                <div className='absolute bottom-4 w-full px-4 text-start text-white ml:bottom-8 ml:pl-12 ml:pr-8'>
                    <h1
                        id={headerId}
                        className='mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-24 ml:mb-4 ml:text-28 ml:leading-143'
                    >
                        {localizedStrings[titleKey]}
                    </h1>
                    <p
                        className='line-clamp-2 whitespace-pre-line text-14 leading-143 tracking-04 ml:max-w-[387px]'
                        dangerouslySetInnerHTML={{__html: localizedStrings[descriptionKey]}}
                    />
                    {isShowButton && (
                        <AppButton
                            primary
                            dark
                            className='mt-2 whitespace-nowrap ml:mt-4 [&>*]:overflow-hidden [&>*]:text-ellipsis'
                            onClick={onButtonClick}
                            aria-label={getAriaLabel(actionAriaLabelKey)}
                        >
                            {localizedStrings[buttonActionKey]}
                        </AppButton>
                    )}
                </div>
            </Banner>
            <div className='flex flex-col gap-[19px] ml:flex-row ml:gap-8'>
                <BenefitsSection />
                <HowItWorksSection />
            </div>
            {isMobile && <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.QUICK_ORDER_POPUP} />}
        </PopupResponsive>
    );
};

const BenefitsSection = () => {
    const list = appConfig.getQuickOrderBenefitsIcons();

    return (
        <QuickOrderSection
            className='ml:pl-8 ml:pr-0'
            headerKey={QUICK_ORDER_BENEFITS_HEADER_TITLE}
            contentList={list}
        />
    );
};

const HowItWorksSection = () => {
    const list = appConfig.getQuickOrderHowItWorksIcons();

    return (
        <QuickOrderSection
            className='border-t border-mischka bg-romance first:border-0 ml:pl-0 ml:pr-8'
            headerKey={QUICK_ORDER_HOW_IT_WORKS_HEADER_TITLE}
            contentList={list}
        />
    );
};

export default QuickOrderPopup;
