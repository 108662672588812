import React from 'react';

import appConfig from '@/config/appConfig';

import HealthWarningAlternativeTop from './components/HealthWarningAlternativeTop';
import {WARNING_BANNERS_TYPES} from './hwConsts';
import {setHwHeightCssVariable} from './hwUtils';

const getBanner = (source) => {
    const warningBanners = appConfig.getWarningBanners();
    const banners = warningBanners?.filter(
        (b) =>
            b.type === WARNING_BANNERS_TYPES.HEALTH_WARNING_ALTERNATIVE_TOP && b.pages?.some((page) => page === source)
    );
    const banner = banners.length ? banners[0] : null;

    return banner;
};

const WarningBannersTop = ({source, className, containerEl}) => {
    const banner = getBanner(source);

    if (!banner) {
        if (containerEl) setHwHeightCssVariable(containerEl, 0);

        return;
    }

    return <HealthWarningAlternativeTop className={className} {...banner} containerEl={containerEl} />;
};

export default WarningBannersTop;
