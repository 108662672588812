import cn from 'classnames';
import React from 'react';

import {HOME_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import HomePageTopNavigation from '@/containers/HomePageTopNavigation/HomePageTopNavigation';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import useIsTopBannerEnabled from '@/hooks/useIsTopBannerEnabled';
import useIsWelcomeMode from '@/hooks/useIsWelcomeMode';
import {HOME_PAGE_NAVIGATION_SOURCE_TYPES} from '@/services/homePageNavigationService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {twx} from '@/utils/tailwindUtils';
import DynamicPage from '@/views/DynamicPage/DynamicPage';
import HomePageLayout from '@/views/Home/HomePageLayout';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import useHomePageAnalytics from './components/useHomePageAnalytics';
import useShowEnableNotificationModal from './components/useShowEnableNotificationModal';

const HomePage = () => {
    const {isWelcomeVisible, pageAnimationClassName, topNavigationAnimationClassName} = useWelcomeAnimation();
    const localizedStrings = getLocalizedStrings();
    const isTopBannerEnabled = useIsTopBannerEnabled();

    useDocumentTitle(localizedStrings[HOME_PAGE_HEADER_TITLE]);
    useShowEnableNotificationModal();
    useHomePageAnalytics(isWelcomeVisible);

    return (
        <HomePageLayout className={cn('bg-secondary md:pb-2', pageAnimationClassName)}>
            <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.HOME} className='ml:pl-[var(--sidebar-width)]' />
            <HomePageTopNavigation
                title={localizedStrings[HOME_PAGE_HEADER_TITLE]}
                source={HOME_PAGE_NAVIGATION_SOURCE_TYPES.HOME}
                className={topNavigationAnimationClassName}
                childRenderer={(isBgVisible) => (
                    <div
                        className={cn(
                            'transition-colors duration-300 ease-in-out',
                            isBgVisible ? 'text-primary' : isTopBannerEnabled ? 'text-romance' : 'text-transparent'
                        )}
                        id='greetingPortal'
                    />
                )}
            />
            <DynamicPage />
            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.HOME} className='md:-mb-2 md:mt-4 lg:mt-2' />
        </HomePageLayout>
    );
};

export default HomePage;

const useWelcomeAnimation = () => {
    const {isWelcomeMode, isAnimationStarted, isAnimationFinished} = useIsWelcomeMode();
    const isWelcomeVisible = isWelcomeMode && !isAnimationFinished;

    return {
        isWelcomeVisible,
        pageAnimationClassName: {'animate-fade-in': isWelcomeMode && !isAnimationFinished},
        topNavigationAnimationClassName: twx({
            'opacity-0 transition-opacity duration-500 md:z-[111]': isWelcomeMode && !isAnimationFinished,
            'opacity-100': isAnimationStarted,
        }),
    };
};
