import React from 'react';

import {AUTO_DELIVERY_ON_HOLD_TITLE} from '@/consts/localization/localizationKeys';
import {getLocalizedStrings} from '@/services/localization/localizationService';

import AutoDeliveryStatusTitle from '../AutoDeliveryStatusTitle';

const AutoDeliveryOnHoldTitle = ({className}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <AutoDeliveryStatusTitle
            className={className}
            title={localizedStrings[AUTO_DELIVERY_ON_HOLD_TITLE]}
            renderAs='h3'
        />
    );
};

export default AutoDeliveryOnHoldTitle;
