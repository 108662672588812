import ButtonRaw from '@/components/Button/ButtonRaw';
import {ArrowIcon, ArrowSmallIcon} from '@/components/Icons';
import {AUTO_DELIVERY_TITLE} from '@/consts/localization/localizationKeys';
import useRedirectToAutoDeliveryDetails from '@/containers/HomePage/components/HomePageAutoDeliverySection/HomePageAutoDeliveryWidgets/useRedirectToAutoDeliveryDetails';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import {getAriaLabel} from '@/services/accessibilityService';
import {getLocalizedStrings} from '@/services/localization/localizationService';

const AutoDeliveryWidgetHeader = ({btnLocalizationKey, btnAriaLabelKey, redirect}) => {
    const localizedStrings = getLocalizedStrings();
    const redirectToDetailsPage = useRedirectToAutoDeliveryDetails({disabled: redirect});
    const redirectToNextPage = redirect || redirectToDetailsPage;
    const isMobile = useIsMobileLayout();
    const Icon = isMobile ? ArrowIcon : ArrowSmallIcon;

    const onClick = () => {
        redirectToNextPage();
    };

    return (
        <div className='flex items-center justify-between gap-2'>
            <h2 className='mb-0 overflow-hidden text-ellipsis whitespace-nowrap text-24 md:text-32'>
                {localizedStrings[AUTO_DELIVERY_TITLE]}
            </h2>
            {btnLocalizationKey && (
                <ButtonRaw
                    className='flex shrink-0 gap-2 tracking-05 text-scarpa-flow'
                    onClick={onClick}
                    aria-label={getAriaLabel(btnAriaLabelKey)}
                >
                    <span className='flex-1 md:pb-0.5'>{localizedStrings[btnLocalizationKey]}</span>
                    <Icon />
                </ButtonRaw>
            )}
        </div>
    );
};

export default AutoDeliveryWidgetHeader;
