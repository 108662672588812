import React, {useEffect, useId, useState} from 'react';

import CloseButton from '@/components/CloseButton/CloseButton';
import {StickyPageLayout} from '@/components/Layout';
import {PopupFullPage} from '@/components/Popup';
import PopupOutline from '@/components/Popup/PopupOutline';
import {LEVIA_POPUP_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import amplitudeInboxTracking from '@/services/analytics/amplitude/amplitudeInboxTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import modalService from '@/services/modalService';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import LeviaLearnMoreArticle from './LeviaLearnMoreArticle/LeviaLearnMoreArticle';
import LeviaLearnMoreButtons from './LeviaLearnMoreArticle/LeviaLearnMoreButtons';

const LeviaLearnMorePopup = ({modalType, source}) => {
    const isMobile = useIsMobileLayout();
    const onClose = () => modalService.hideModal(modalType);

    useEffect(() => {
        amplitudeInboxTracking.trackActionScreen(source);
        googleAnalyticsService.trackLeviaScreenView(source);
    }, []);

    return isMobile ? <MobileLeviaLearnMore onClose={onClose} /> : <DesktopLeviaLearnMore onClose={onClose} />;
};

const MobileLeviaLearnMore = ({onClose}) => {
    const [ref, setRef] = useState();
    const headerId = useId();
    const localizedStrings = getLocalizedStrings();

    return (
        <PopupFullPage
            className='z-20 flex flex-col bg-romance'
            overlayRef={setRef}
            aria-labelledby={headerId}
            disablePageScrolling
        >
            <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.LEVIA_POPUP} containerEl={ref} />
            <StickyPageLayout
                className='items-center'
                headerOptions={{
                    endIcon: <CloseButton onClick={onClose} />,
                    title: localizedStrings[LEVIA_POPUP_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center'
                footer={<LeviaLearnMoreButtons onCancel={onClose} />}
            >
                <LeviaLearnMoreArticle headerId={headerId} />
                <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.LEVIA_POPUP} />
            </StickyPageLayout>
        </PopupFullPage>
    );
};

const DesktopLeviaLearnMore = ({onClose}) => {
    const headerId = useId();

    return (
        <PopupOutline
            className='w-full max-w-[457px] rounded-3xl p-8 ml:max-h-[calc(100%-92px-var(--hw-h))]'
            hasNavbarOffset
            aria-labelledby={headerId}
            onClose={onClose}
        >
            <LeviaLearnMoreArticle headerId={headerId} />
            <LeviaLearnMoreButtons onCancel={onClose} />
        </PopupOutline>
    );
};

export default LeviaLearnMorePopup;
