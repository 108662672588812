import React, {useState} from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import RemoteImage from '@/components/Image/RemoteImage';
import {
    ADVANCED_DIAGNOSTICS_DAMAGE_NONE_BUTTON_ARIA_LABEL,
    ADVANCED_DIAGNOSTICS_DAMAGE_SELECT_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeADTracking from '@/services/analytics/amplitude/amplitudeADTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import deviceTypesService from '@/services/device/deviceTypesService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import rtlService from '@/services/rtlService';

import AdvancedDiagnosticCarousel from '../AdvancedDiagnosticCarousel/AdvancedDiagnosticCarousel';
import useAdvancedDiagnosticCarousel from '../AdvancedDiagnosticCarousel/useAdvancedDiagnosticCarousel';
import advanceDiagnosticService from '../advanceDiagnosticService';
import useRedirectToPhase2 from '../hooks/useRedirectToPhase2';

const AdvancedDiagnosticDamageCarousel = ({carouselData, deviceType}) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const {getSlideInertValue} = useAdvancedDiagnosticCarousel(currentSlideIndex);
    const localizedStrings = getLocalizedStrings();
    const isP1V4 = deviceTypesService.isP1V4(deviceType);
    const redirectToPhase2 = useRedirectToPhase2({disabled: !isP1V4});

    const onNextButtonClick = async () => {
        amplitudeADTracking.trackADNoneOfTheAboveClick();
        googleAnalyticsService.trackAdNoneOfTheAboveClick();

        if (isP1V4) {
            redirectToPhase2();
        } else {
            appRouterService.forwardToSelectIssueAD();
        }
    };

    const onOptionButtonClick = (screenName, errorCode, isHolder, amplitudeQuestionnairePage) => {
        amplitudeADTracking.trackADQuestionnaireSelectClick(amplitudeQuestionnairePage);
        googleAnalyticsService.trackAdQuestionnaireSelectClick(amplitudeQuestionnairePage);
        advanceDiagnosticService.setInferenceCodeAndRedirect(errorCode, isHolder, screenName);
    };

    const onAfterChange = (newIndex) => {
        setCurrentSlideIndex(newIndex);

        const {amplitudeQuestionnairePage} = carouselData[newIndex];

        amplitudeADTracking.trackADQuestionnaireScroll();
        googleAnalyticsService.trackAdQuestionnaireScroll(amplitudeQuestionnairePage);
    };

    return (
        <>
            <AdvancedDiagnosticCarousel carouselData={carouselData} onAfterChange={onAfterChange}>
                {carouselData.map((option, i) => {
                    const {amplitudeQuestionnairePage, imageMedia, title, description, nextStep, errorCode, isHolder} =
                        option;

                    return (
                        <div
                            key={i}
                            dir={rtlService.getDir()}
                            className='flex h-full flex-col rounded bg-secondary text-primary md:w-[436px] ml:w-[597px]'
                            inert={getSlideInertValue(i)}
                        >
                            <RemoteImage
                                className='h-[176px] w-full bg-center object-cover md:h-[244px] ml:h-[335px]'
                                imageMedia={imageMedia}
                            />

                            <div className='mx-auto mt-4 flex h-full max-w-[328px] flex-col justify-between px-6 py-7'>
                                <div>
                                    <h2 className='mb-3 text-20'>{localizedStrings[title]}</h2>
                                    <p>{localizedStrings[description]}</p>
                                </div>
                                <AppButton
                                    secondary
                                    light
                                    onClick={() =>
                                        onOptionButtonClick(nextStep, errorCode, isHolder, amplitudeQuestionnairePage)
                                    }
                                    className='mt-6 md:min-w-[220px] ml:mt-8'
                                    aria-label={getAriaLabel(ADVANCED_DIAGNOSTICS_DAMAGE_SELECT_BUTTON_ARIA_LABEL)}
                                >
                                    <AemEditableText
                                        pageName={routePaths.ADVANCE_DIAGNOSTIC}
                                        itemName='damageselectbutton'
                                    />
                                </AppButton>
                            </div>
                        </div>
                    );
                })}
            </AdvancedDiagnosticCarousel>
            <div className='px-8'>
                <AppButton
                    secondary
                    light
                    onClick={onNextButtonClick}
                    className='mx-auto mt-6'
                    aria-label={getAriaLabel(ADVANCED_DIAGNOSTICS_DAMAGE_NONE_BUTTON_ARIA_LABEL)}
                >
                    <AemEditableText pageName={routePaths.ADVANCE_DIAGNOSTIC} itemName='damagenonebutton' />
                </AppButton>
            </div>
        </>
    );
};

export default AdvancedDiagnosticDamageCarousel;
