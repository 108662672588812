import cn from 'classnames';
import React from 'react';

import PageSection from '@/components/Layout/PageSection';
import appConfig from '@/config/appConfig';
import useLayout from '@/hooks/layout/useLayout';
import usePageSectionTitle from '@/hooks/usePageSectionTitle';
import amplitudeShopPageTracking from '@/services/analytics/amplitude/amplitudeShopPageTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import modalService from '@/services/modalService';
import {filterSectionsById} from '@/services/pageSectionService';

import YourUsualSectionBanner from './components/YourUsualSectionBanner';
import YourUsualSectionDescription from './components/YourUsualSectionDescription';

const YourUsualSection = ({id, className}) => {
    const titleText = usePageSectionTitle(id);
    const {isMobile, isMl} = useLayout();
    const banners = appConfig.getYourUsualBanners();
    const yourUsualBanner = filterSectionsById({list: banners, id})[0];

    const {banner, descriptionKey: sectionDescriptionKey, icons, titleKey: sectionTitleKey} = yourUsualBanner || {};
    const {imageMedia, videoPath, titleKey, descriptionKey} = banner || {};

    const isBannerDataExist = imageMedia || videoPath || titleKey || descriptionKey;
    const isAdditionalInfoVisible =
        !isMobile && !isMl && (sectionDescriptionKey || sectionTitleKey || icons?.length > 0);

    const handleBannerClick = () => {
        amplitudeShopPageTracking.trackQuickOrderBannerClick();
        googleAnalyticsService.trackQuickOrderBannerClick();
        modalService.toggleQuickOrderPopup(true);
    };

    if (!isBannerDataExist && !isAdditionalInfoVisible) return;

    return (
        <PageSection className={className}>
            {isMobile && <PageSection.Title className='mb-8' text={titleText} />}
            <div
                className={cn({
                    'grid grid-cols-2 items-center gap-8': isAdditionalInfoVisible && isBannerDataExist,
                })}
            >
                {isAdditionalInfoVisible && (
                    <YourUsualSectionDescription
                        sectionTitleKey={sectionTitleKey}
                        descriptionKey={sectionDescriptionKey}
                        icons={icons}
                    />
                )}
                {isBannerDataExist && (
                    <YourUsualSectionBanner
                        isAdditionalInfoVisible={isAdditionalInfoVisible}
                        banner={banner}
                        onBannerClick={handleBannerClick}
                    />
                )}
            </div>
        </PageSection>
    );
};

export default YourUsualSection;
