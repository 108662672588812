import React from 'react';

import RemoteIcon from '@/components/Icon/RemoteIcon';
import {getLocalizedStrings} from '@/services/localization/localizationService';

const InstallPwaPageContent = ({title, descriptionKey, icons}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <>
            <h1 className='whitespace-pre-line text-28 ml:mb-2 ml:text-36'>{title}</h1>
            <p
                className='whitespace-pre-line text-16 ml:text-start'
                dangerouslySetInnerHTML={{__html: localizedStrings[descriptionKey]}}
            />
            <ul className='mt-8 flex flex-col items-start gap-4 text-16 ml:max-w-[330px] ml:gap-6'>
                {icons?.map((icon, i) => {
                    const {titleKey} = icon;
                    const title = localizedStrings.formatString(
                        localizedStrings[titleKey],
                        <div className='inline'>
                            <RemoteIcon className='inline fill-primary' icon={icon} />
                        </div>
                    );

                    if (!title) return null;

                    return (
                        <li key={i} className='flex w-full items-center gap-2'>
                            <div className='h-6 w-6 flex-shrink-0 rounded-full bg-tertiary'>{i + 1}</div>
                            <span className='w-full flex-1 text-start'>{title}</span>
                        </li>
                    );
                })}
            </ul>
        </>
    );
};

export default InstallPwaPageContent;
