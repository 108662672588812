import React from 'react';

import InboxEmpty from '@/containers/Inbox/InboxView/components/InboxEmpty';
import InboxNotificationsList from '@/containers/Inbox/InboxView/components/InboxNotificationsList';

export const NotificationsInbox = (props) => {
    const {notifications, emptyInboxText} = props;

    return notifications === undefined || notifications.length ? (
        <InboxNotificationsList {...props} />
    ) : (
        <InboxEmpty emptyInboxText={emptyInboxText} />
    );
};
