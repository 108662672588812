import React from 'react';

import CloseButton from '@/components/CloseButton/CloseButton';
import {twx} from '@/utils/tailwindUtils';

const PopupCloseButton = (props) => {
    const {onClick, className} = props;

    return <CloseButton className={twx('absolute right-3.5 top-3.5 z-[1]', className)} onClick={onClick} />;
};

export default PopupCloseButton;
