export const CONSUMER_PRODUCTS = '/consumer/consumerProductsAsync';
export const GET_EXTERNAL_CONSUMER = '/consumer/ExternalConsumerProfile';
export const GET_CONSUMER = '/consumer/consumerProfile';
export const SET_CONSUMER = '/consumer/consumerProfile';
export const GET_CONSUMER_AGGREGATED_SETTINGS = '/consumer/consumerAggregatedSettings';
export const GET_MEDIA_URL_V2 = '/media/media/v2/SignMediaUrlList';
export const GET_PRODUCT_INFO_LIST = '/consumer/v3/ProductInfoList';
export const GET_PRODUCT_INFO_LIST_V2 = '/consumer/v2/ProductInfoList';
export const GET_TOKEN = '/auth/connect/token';
export const SET_CONSUMER_PRODUCT_NAME = '/consumer/ConsumerProductsName';
export const GET_CONSUMER_LOGIN_TOKEN = '/consumer/CreateLoginToken';
export const INBOX_MESSAGES = '/inbox/messages';
export const INBOX_MESSAGES_EXTENDED = '/inbox/messages/extended';
export const INBOX_UPDATE_MESSAGE_STATUS = '/inbox/message/{messageId}/status';
export const INBOX_STATUS = '/inbox/messages/unread';
export const SUBSCRIPTION_PUSH_DATA = '/subscription/push';
export const FEEDBACK_PRE_SIGNED_URL = '/consumption/ConsumerFeedback/UploadUrl';
export const GET_TNC = '/consumer/consumerTermAndConditions/grouped';
export const GET_ACCEPTED_TNC = '/consumer/acceptedConsumerTermAndConditions/grouped';
export const SET_CONSUMER_TNC = '/consumer/consumerTermAndConditions';
export const GET_UAM_TOKEN_V5 = '/consumer/v5/UamToken';
export const GET_CONSUMER_PRODUCTS_STATUS = '/consumer/ConsumerProductsStatus';
export const CONSUMER_PRODUCTS_V2 = '/consumer/v2/consumerProductsAsync';
export const AUTO_DELIVERY_DETAILS = '/consumer/autodelivery/details';
export const AUTO_DELIVERY_DETAILS_EXTENDED = '/consumer/autodelivery/details/extended';
export const AUTO_DELIVERY_SKIP_DELIVERY = '/consumer/autodelivery/skipDelivery';
export const AUTO_DELIVERY_DELIVER_ASAP = '/consumer/autodelivery/deliverAsap';
export const GET_EXTERNAL_LINK = '/consumer/ExternalLink';
