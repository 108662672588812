import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import AriaLiveContainer from '@/components/AriaLiveMessage/AriaLiveContainer';
import CloseButton from '@/components/CloseButton/CloseButton';
import {ErrorIcon} from '@/components/Icons';
import {removeNotification} from '@/state/slices/notificationsSlice';
import helpers from '@/utils/helpers';
import {twx} from '@/utils/tailwindUtils';

import {getNotificationAriaMessage} from './notificationUtils';
import useHideNotification from './useHideNotification';

const NotificationTop = (props) => {
    const {notification, onClose} = props;
    const {notificationId, text} = notification;
    const [isActive, setIsActive] = useState(false);
    const dispatch = useDispatch();
    const ariaMessage = getNotificationAriaMessage(text);
    const DISPLAY_TIME = 5000;

    const onNotificationClick = () => {
        if (!isActive) {
            setIsActive(true);

            setTimeout(() => {
                hideNotification();
                helpers.runFunction(onClose);
            }, 500);
        }
    };

    const hideNotification = () => {
        dispatch(removeNotification(notificationId));
    };

    useHideNotification({
        displayTime: DISPLAY_TIME,
        hideNotification,
    });

    return (
        <div
            className={twx(
                'relative flex w-full items-center gap-4 rounded bg-secondary py-5 pl-6 pr-11 shadow-[0_4px_100px_0_rgba(0,0,0,0.25)] ml:gap-6 ml:pr-[42px]',
                {
                    'animate-hide': isActive,
                }
            )}
        >
            <ErrorIcon className='h-6 w-6 shrink-0' />
            <div className='flex-1 text-start text-14 leading-143 tracking-04 text-primary'>
                <AriaLiveContainer alert constant>
                    {ariaMessage}
                </AriaLiveContainer>
                {text}
            </div>
            <CloseButton onClick={onNotificationClick} className='absolute right-2 top-2' size='small' />
        </div>
    );
};

export default NotificationTop;
