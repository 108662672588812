import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';

const useHighlightsCarouselFlags = () => {
    const isMobile = useIsMobileLayout();

    const canSwipe = isMobile;
    const slideChangeAnimationSpeed = isMobile ? 300 : 0;

    return {canSwipe, slideChangeAnimationSpeed};
};

export default useHighlightsCarouselFlags;
