import React, {useEffect} from 'react';

import BackButton from '@/components/BackButton/BackButton';
import {ButtonRaw} from '@/components/Button';
import {TrashIcon} from '@/components/Icons';
import AppLoaderContent from '@/components/Loader/AppLoader/AppLoaderContent';
import {PopoverStickyTitle} from '@/components/Popover/Popover';
import {ScrollArea} from '@/components/ScrollArea/ScrollArea';
import {INBOX_DELETE_MESSAGE_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import * as sourceTypes from '@/consts/sourceTypes';
import NotificationDetailsMessage from '@/containers/Inbox/NotificationDetails/components/NotificationDetailsMessage';
import useMessageDeletionWithUndo from '@/hooks/inbox/useMessageDeletionWithUndo';
import useNotificationDetails from '@/hooks/inbox/useNotificationDetails';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeInboxTracking from '@/services/analytics/amplitude/amplitudeInboxTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';

const InboxPopoverNotificationDetails = ({
    messageId,
    closeNotificationDetails,
    containerRef,
    className,
    backButtonRef,
}) => {
    const {message, isLoaderActive} = useNotificationDetails({
        messageId,
    });

    useEffect(() => {
        if (message) {
            amplitudeInboxTracking.trackNotificationDetailedScreen(sourceTypes.INBOX, message.campaignName);
            googleAnalyticsService.trackInboxDetailsScreenView(sourceTypes.INBOX, message.campaignName);
        }
    }, [message]);

    const handleMessageDeleteClick = useMessageDeletionWithUndo({messageId});
    const onDeleteClick = () => {
        handleMessageDeleteClick();
        closeNotificationDetails();
    };

    return (
        <>
            <PopoverStickyTitle contentElement={containerRef.current} className='flex items-center justify-between'>
                <BackButton onClick={closeNotificationDetails} ref={backButtonRef} />
                <ButtonRaw onClick={onDeleteClick} aria-label={getAriaLabel(INBOX_DELETE_MESSAGE_BUTTON_ARIA_LABEL)}>
                    <TrashIcon />
                </ButtonRaw>
            </PopoverStickyTitle>
            <ScrollArea className='flex-1 p-6 py-0'>
                {isLoaderActive ? (
                    <div className='flex h-full items-center justify-center'>
                        <AppLoaderContent />
                    </div>
                ) : (
                    <div className='flex flex-col pt-2 text-start'>
                        <NotificationDetailsMessage {...message} />
                    </div>
                )}
            </ScrollArea>
        </>
    );
};

export default InboxPopoverNotificationDetails;
