import React from 'react';

import {getLocalizedStrings} from '@/services/localization/localizationService';
import stylesUtils from '@/utils/stylesUtils';
import {twx} from '@/utils/tailwindUtils';

import HwContainer from './HwContainer';

const HealthWarningExtended = ({
    titleKey,
    descriptionKey,
    className,
    contentClassName,
    isSeparatorVisible,
    fontSize,
    fontFamily,
}) => {
    const localizedStrings = getLocalizedStrings();
    const title = localizedStrings[titleKey];
    const description = localizedStrings[descriptionKey];

    if (!title && !description) return;

    const styles = stylesUtils.getFontSizeStyles(fontSize);
    const fontFamilyClassName = stylesUtils.getFontFamilyClassName(fontFamily);

    return (
        <HwContainer className={className} isSeparatorVisible={isSeparatorVisible}>
            <div
                className={twx(
                    'flex max-w-2xl flex-col gap-4 p-4 pb-8 text-16 md:p-8 ml:p-16 xl:mx-auto xl:box-content',
                    contentClassName,
                    fontFamilyClassName
                )}
                style={styles}
            >
                {title && <p className='leading-[24px]'>{localizedStrings[titleKey]}</p>}
                {description && (
                    <div className='whitespace-pre-line border-2 border-black bg-romance px-4 py-6 font-bold text-primary md:p-12'>
                        {description}
                    </div>
                )}
            </div>
        </HwContainer>
    );
};

export default HealthWarningExtended;
