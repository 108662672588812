import React from 'react';

import {ButtonRaw} from '@/components/Button';
import appConfig from '@/config/appConfig';
import analyticsEventValues from '@/consts/analytics/analyticsEventValues';
import {
    AUTO_DELIVERY_VIEW_ORDER_SUMMARY_LINK,
    AUTO_DELIVERY_VIEW_ORDER_SUMMARY_LINK_ARIA_LABEL,
    AUTO_DELIVERY_VIEW_ORDER_SUMMARY_TEXT,
} from '@/consts/localization/localizationKeys';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeAutoDeliveryTracking from '@/services/analytics/amplitude/amplitudeAutoDeliveryTracking';
import {getLocalizedStrings} from '@/services/localization/localizationService';

const AutoDeliveryOrderSummaryMessage = () => {
    const autoDeliveryOrderSummaryExternalLink = appConfig.getAutoDeliveryOrderSummaryExternalLink();
    const redirectToExternalLink = useConfigExternalLink(autoDeliveryOrderSummaryExternalLink);

    if (!redirectToExternalLink) return;

    const onLinkClick = () => {
        amplitudeAutoDeliveryTracking.trackAutoDeliveryOrderSummaryLinkClick(analyticsEventValues.ACTIVE);
        redirectToExternalLink();
    };

    const localizedStrings = getLocalizedStrings();

    return (
        <div class='mt-4 rounded-xl bg-secondary p-4 md:mt-6'>
            {localizedStrings.formatString(
                localizedStrings[AUTO_DELIVERY_VIEW_ORDER_SUMMARY_TEXT],
                <ButtonRaw
                    className='inline font-bold underline'
                    onClick={onLinkClick}
                    aria-label={getAriaLabel(AUTO_DELIVERY_VIEW_ORDER_SUMMARY_LINK_ARIA_LABEL)}
                >
                    {localizedStrings[AUTO_DELIVERY_VIEW_ORDER_SUMMARY_LINK]}
                </ButtonRaw>
            )}
        </div>
    );
};

export default AutoDeliveryOrderSummaryMessage;
