import React from 'react';

import {twx} from '@/utils/tailwindUtils';

const AutoDeliveryStatusTitle = ({className, title, renderAs: Heading = 'h1'}) => {
    return (
        <Heading className={twx('text-20 font-bold leading-143 tracking-02 md:text-28', className)}>{title}</Heading>
    );
};

export default AutoDeliveryStatusTitle;
