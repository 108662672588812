import React, {useEffect} from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import {MaxWidthContainer} from '@/components/Layout';
import SupportAndStoreTemplate from '@/components/SupportAndStoreTemplate/SupportAndStoreTemplate';
import * as advanceDiagnosticIconTypes from '@/consts/advanceDiagnostic/advanceDiagnosticIconTypes';
import {ADVANCED_DIAGNOSTICS_FINISH_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeADTracking from '@/services/analytics/amplitude/amplitudeADTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import appRouterService from '@/services/route/appRouterService';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners} from '@/views/WarningBanners';

import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticDescription from '../components/AdvancedDiagnosticDescription';
import AdvancedDiagnosticIcon from '../components/AdvancedDiagnosticIcon';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';

const AdvancedDiagnosticFinishScreen = () => {
    useEffect(() => {
        amplitudeADTracking.trackADInitialQuestionnaireScreen();
        googleAnalyticsService.trackAdFinishScreenView();
    }, []);

    return (
        <div className='flex w-full flex-col'>
            <AdvancedDiagnosticContainer isDeviceHero>
                <AdvancedDiagnosticIcon type={advanceDiagnosticIconTypes.SUCCESS} />
                <MaxWidthContainer className='mx-8 md:mx-0'>
                    <AdvancedDiagnosticTitle itemName='finishtitle' />
                    <AdvancedDiagnosticDescription itemName='finishdesc' />
                    <AppButton
                        primary
                        light
                        onClick={appRouterService.forwardToDevicesPage}
                        className='md:min-w-[312px]'
                        aria-label={getAriaLabel(ADVANCED_DIAGNOSTICS_FINISH_BUTTON_ARIA_LABEL)}
                    >
                        <AemComponent
                            component={EditableText}
                            pageName={routePaths.ADVANCE_DIAGNOSTIC}
                            itemName='finishbutton'
                        />
                    </AppButton>
                </MaxWidthContainer>
                <SupportAndStoreTemplate className='mt-10 md:flex-col ml:flex-row' />
            </AdvancedDiagnosticContainer>
            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.AD} />
        </div>
    );
};

export default AdvancedDiagnosticFinishScreen;
