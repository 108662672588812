import React, {useEffect, useId, useState} from 'react';

import CloseButton from '@/components/CloseButton/CloseButton';
import {StickyPageLayout} from '@/components/Layout';
import {PopupFullPage} from '@/components/Popup';
import PopupOutline from '@/components/Popup/PopupOutline';
import {FIRMWARE_AVAILABLE_POPUP_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import modalService from '@/services/modalService';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import FirmwareAvailableArticle from './FirmwareAvailableArticle/FirmwareAvailableArticle';
import FirmwareAvailableButtons from './FirmwareAvailableArticle/FirmwareAvailableButtons';

const FirmwareAvailablePopup = ({modalType, source}) => {
    const isMobile = useIsMobileLayout();
    const onClose = () => modalService.hideModal(modalType);

    useEffect(() => {
        // TODO: Add amplitude tracking
        // amplitudeInboxTracking.trackActionScreen(source);
    }, []);

    return isMobile ? <MobileFirmwareAvailable onClose={onClose} /> : <DesktopFirmwareAvailable onClose={onClose} />;
};

const MobileFirmwareAvailable = ({onClose}) => {
    const [ref, setRef] = useState();
    const headerId = useId();
    const localizedStrings = getLocalizedStrings();

    return (
        <PopupFullPage
            disablePageScrolling
            className='z-20 flex flex-col bg-romance'
            overlayRef={setRef}
            aria-labelledby={headerId}
        >
            <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.FIRMWARE_AVAILABLE_POPUP} containerEl={ref} />
            <StickyPageLayout
                className='items-center'
                headerOptions={{
                    endIcon: <CloseButton onClick={onClose} />,
                    title: localizedStrings[FIRMWARE_AVAILABLE_POPUP_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center'
                footer={<FirmwareAvailableButtons onCancel={onClose} />}
            >
                <FirmwareAvailableArticle headerId={headerId} />
                <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.FIRMWARE_AVAILABLE_POPUP} />
            </StickyPageLayout>
        </PopupFullPage>
    );
};

const DesktopFirmwareAvailable = ({onClose}) => {
    const headerId = useId();

    return (
        <PopupOutline
            className='flex w-full max-w-[457px] flex-col items-center rounded-3xl p-8 ml:max-h-[calc(100%-92px-var(--hw-h))]'
            hasNavbarOffset
            aria-labelledby={headerId}
            onClose={onClose}
        >
            <FirmwareAvailableArticle headerId={headerId} />
            <FirmwareAvailableButtons onCancel={onClose} />
        </PopupOutline>
    );
};

export default FirmwareAvailablePopup;
