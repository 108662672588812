const LayoutSizes = {
    SM: 375,
    MD: 744,
    ML: 1133,
    LG: 1280,
    XL: 1728,
    XXL: 2560,
};

export default LayoutSizes;
