import React, {useEffect, useRef} from 'react';
import {useResizeObserver} from 'usehooks-ts';

import RemoteIcon from '@/components/Icon/RemoteIcon';
import domService from '@/services/domService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import stylesUtils from '@/utils/stylesUtils';
import {twx} from '@/utils/tailwindUtils';

import {setHwHeightCssVariable} from '../hwUtils';

const HealthWarningAlternativeTop = ({className, titleKey, fontSize, fontFamily, icon, containerEl}) => {
    const localizedStrings = getLocalizedStrings();
    const title = localizedStrings[titleKey];
    const ref = useRef();

    useHwHeight({ref, containerEl});

    const styles = stylesUtils.getFontSizeStyles(fontSize);
    const fontFamilyClassName = stylesUtils.getFontFamilyClassName(fontFamily);

    return (
        <div
            ref={ref}
            className={twx('fixed left-0 right-0 top-0 z-[103] overflow-hidden bg-white pt-safe', className)}
        >
            <div className='flex h-[10vh] min-h-[68px] w-full items-center justify-center border-b border-b-french-gray'>
                <div
                    className={twx(
                        'flex max-w-2xl items-center gap-2 p-4 md:px-8 ml:gap-4 ml:px-16 ml:py-6 xl:mx-auto xl:box-content',
                        fontFamilyClassName
                    )}
                >
                    {icon && (
                        <div className='h-auto w-auto shrink-0'>
                            <RemoteIcon icon={icon} />
                        </div>
                    )}
                    {title && (
                        <p className='text-start font-bold leading-12 tracking-02 text-primary' style={styles}>
                            {localizedStrings[titleKey]}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

const useHwHeight = ({ref, containerEl}) => {
    const {height} = useResizeObserver({ref});
    const setHeight = (height) => {
        const container = containerEl || domService.getRoot();

        setHwHeightCssVariable(container, height);
    };

    useEffect(() => {
        if (!height) return;
        setHeight(height);
    }, [height]);

    useEffect(() => {
        return () => {
            setHeight(0);
        };
    }, []);
};

export default HealthWarningAlternativeTop;
