import cn from 'classnames';
import React from 'react';

import InfoBox from '@/components/InfoBox/InfoBox';

import {getAutoDeliveryOnHoldContent} from './autoDeliveryOnHoldService';

const AutoDeliveryOnHoldInfoBox = ({className, children, statusUpdateReason}) => {
    const {title, description} = getAutoDeliveryOnHoldContent(statusUpdateReason);

    if (!title && !description && !children) return;

    return (
        <InfoBox className={cn('tracking-04', className)}>
            <div>
                {title && <b className='md:text-18'>{title}</b>}
                {description && <div className='mt-2'>{description}</div>}
            </div>
            {children}
        </InfoBox>
    );
};

export default AutoDeliveryOnHoldInfoBox;
