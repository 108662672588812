import React, {useRef} from 'react';

const Icon = (props) => {
    const {'aria-label': ariaLabel, className, width, height, viewBox, children, ...rest} = props;
    const iconRef = useRef();
    const viewBoxResult = viewBox || `0 0 ${width} ${height}`;

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            viewBox={viewBoxResult}
            className={className}
            aria-label={ariaLabel}
            aria-hidden={ariaLabel ? undefined : true}
            ref={iconRef}
            {...rest}
        >
            {children}
        </svg>
    );
};

export default Icon;
