import React, {useId} from 'react';

import {
    UWM_UNAVAILABLE_POPUP_NOT_NOW_BUTTON_ARIA_LABEL,
    UWM_UNAVAILABLE_POPUP_REFRESH_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import {getAriaLabel} from '@/services/accessibilityService';
import {hideModalAction} from '@/state/slices/modalSlice';
import {dispatch} from '@/state/store';

import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../../components/Button';
import {WarningIcon} from '../../../components/Icons';
import {PopupFullPage} from '../../../components/Popup';
import ModalTypes from '../../../consts/app/modalTypes';
import appRouterService from '../../../services/route/appRouterService';

const UWMUnavailabilityPopup = ({refreshAction}) => {
    const headerId = useId();

    const onCancelClick = () => {
        dispatch(hideModalAction(ModalTypes.UWM_UNAVAILABILITY));

        appRouterService.forwardToHomePage();
    };

    return (
        <PopupFullPage aria-labelledby={headerId}>
            <div className='flex flex-col items-center justify-center px-8 pb-8 pt-18'>
                <WarningIcon className='mb-4 h-6 w-6 ml:mb-6 ml:h-8 ml:w-8' />
                <h1 id={headerId}>
                    <AemComponent
                        component={EditableText}
                        pageName={routePaths.MODALS}
                        itemName='uwmunavailabletitle'
                    />
                </h1>
                <p>
                    <span className='whitespace-pre-line'>
                        <AemComponent
                            component={EditableText}
                            pageName={routePaths.MODALS}
                            itemName='uwmunavailabledesc'
                        />
                    </span>
                </p>
                <AppButton
                    primary
                    light
                    onClick={refreshAction}
                    aria-label={getAriaLabel(UWM_UNAVAILABLE_POPUP_REFRESH_BUTTON_ARIA_LABEL)}
                >
                    <AemComponent
                        component={EditableText}
                        pageName={routePaths.MODALS}
                        itemName='uwmunavailablerefreshbutton'
                    />
                </AppButton>
                <AppButton
                    secondary
                    light
                    className={'mt-4'}
                    onClick={onCancelClick}
                    aria-label={getAriaLabel(UWM_UNAVAILABLE_POPUP_NOT_NOW_BUTTON_ARIA_LABEL)}
                >
                    <AemComponent
                        component={EditableText}
                        pageName={routePaths.MODALS}
                        itemName='uwmunavailablenotnowbutton'
                    />
                </AppButton>
            </div>
        </PopupFullPage>
    );
};

export default UWMUnavailabilityPopup;
