import React from 'react';

import routePaths from '@/consts/route/routePaths';

import AemComponent from '../../../../components/Aem/AemComponent/AemComponent';
import EditableNotificationItem from '../../../../components/Aem/EditableNotificationItem/EditableNotificationItem';
import EditableText from '../../../../components/Aem/EditableText/EditableText';
import {ButtonRaw} from '../../../../components/Button';
import * as sourceTypes from '../../../../consts/sourceTypes';
import appRouterService from '../../../../services/route/appRouterService';

const NotificationDetailsReadNext = ({notificationItem}) => {
    const {messageId, title} = notificationItem;

    const onClick = () => {
        appRouterService.forwardToNotificationDetailsPage(messageId, sourceTypes.INBOX);
    };

    return (
        <div className='px-layout mt-2 bg-romance py-2'>
            <h2 className='my-4 text-24 leading-15'>
                <AemComponent
                    component={EditableText}
                    pageName={routePaths.HOME_PAGE}
                    itemName='notificationdetailsreadnext'
                />
            </h2>
            <ButtonRaw className='w-full' onClick={onClick} aria-label={title}>
                <AemComponent
                    component={EditableNotificationItem}
                    pageName={routePaths.HOME_PAGE}
                    itemName='notificationsinbox'
                    heading='h3'
                    {...notificationItem}
                />
            </ButtonRaw>
        </div>
    );
};

export default NotificationDetailsReadNext;
