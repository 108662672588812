import objUtils from '@/utils/objUtils';

export const Text = ({text, render}) => {
    const textFormatted = text?.replaceAll('\\n', '\n');

    return typeof render === 'function' ? render(textFormatted) : textFormatted;
};

export function textIsEmpty(props) {
    return objUtils.isNullOrUndefined(props.text) || props.text.length === 0;
}
