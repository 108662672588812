import * as pathService from '@/services/route/pathService';
import routerService from '@/services/route/routerService';
import storageService from '@/services/storage/storageService';
import urlUtils from '@/utils/urlUtils';

const SUPPORTED_MARKETS = ['ch', 'pmi'];

const checkIfLocationContainsValidMarketCode = () => {
    const marketCode = getMarketCodeFromLocation();
    const isMarketCodeValid = checkIfMarketCodeIsValid(marketCode);

    return isMarketCodeValid;
};

const getMarketCodeFromLocation = () => {
    try {
        const isAem = pathService.isAemPath();
        const MARKET_CODE_INDEX = isAem ? 3 : 1;
        const marketCode = urlUtils.getCurrentPathname().split('/')[MARKET_CODE_INDEX];

        return marketCode;
    } catch (e) {
        return undefined;
    }
};

const checkIfMarketCodeIsValid = (marketCode) => {
    if (!marketCode) return false;

    const isMarketCodeValid = SUPPORTED_MARKETS.some(
        (supportedMarketCode) => supportedMarketCode === marketCode.toLowerCase()
    );

    return isMarketCodeValid;
};

const getMarketCode = () => {
    const marketCode = getMarketCodeFromLocation();

    return marketCode;
};

const validateAndInitiateMarketCode = () => {
    const isMarketCodeValid = checkIfLocationContainsValidMarketCode();

    if (isMarketCodeValid) {
        const marketCodeFromLocation = getMarketCodeFromLocation();

        storageService.setMarketCodeToLocalStorage(marketCodeFromLocation);
        return true;
    }

    const marketCodeFromStorage = storageService.getMarketCodeFromLocalStorage();

    if (!marketCodeFromStorage) return false;

    const isMarketCodeFromStorageValid = checkIfMarketCodeIsValid(marketCodeFromStorage);

    if (!isMarketCodeFromStorageValid) return false;

    // If there is no marketCode in url but it is presented in localStorage -> add it to url
    routerService.redirect(marketCodeFromStorage);
    return true;
};

export default {
    checkIfLocationContainsValidMarketCode,
    getMarketCodeFromLocation,
    getMarketCode,
    validateAndInitiateMarketCode,
};
