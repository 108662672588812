import React from 'react';

import AutoDeliveryButton from '../AutoDeliveryButton';
import {getAutoDeliveryOnHoldContent} from './autoDeliveryOnHoldService';

const AutoDeliveryOnHoldButton = ({className, statusUpdateReason, amplitudeEvent}) => {
    const {button, buttonAriaLabelKey, amplitudeTrackingValue, externalLinkConfig} =
        getAutoDeliveryOnHoldContent(statusUpdateReason);

    return (
        <AutoDeliveryButton
            sandy
            className={className}
            text={button}
            ariaLabelKey={buttonAriaLabelKey}
            amplitudeEvent={() => amplitudeEvent(amplitudeTrackingValue)}
            externalLinkConfig={externalLinkConfig}
        />
    );
};

export default AutoDeliveryOnHoldButton;
