import iccService from '@/services/icc/iccService';
import dateTimeUtils from '@/utils/dateTimeUtils';

import authDataStoreService from './authDataStoreService';

const getAuthToken = async ({isRetry, isAnonymousUserAllowed} = {}) => {
    const {accessToken, expirationDate, isAnonymous} = authDataStoreService.getAccessTokenData();
    //if token should NOT be anonymous but in state anonymous then retrieve new one
    const isTokenExists = isAnonymousUserAllowed || !isAnonymous ? accessToken : false;

    try {
        if (isTokenExists) {
            const isAuthTokenExpired = dateTimeUtils.isExpired(expirationDate);

            if (isAuthTokenExpired) {
                await iccService.refreshIccToken(isAnonymousUserAllowed);

                return await getAuthToken({isRetry: true, isAnonymousUserAllowed});
            } else {
                return accessToken;
            }
        } else if (!isRetry) {
            await iccService.fetchIccToken(isAnonymousUserAllowed);

            return await getAuthToken({isRetry: true, isAnonymousUserAllowed});
        }
    } catch (e) {
        return Promise.reject(`getAuthToken error: ${e}`);
    }

    return Promise.reject(`getAuthToken error: return undefined`);
};

export default {
    getAuthToken,
};
