import React, {useId, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import StickyHeader from '@/components/Layout/StickyHeader';
import PopupResponsive from '@/components/Popup/PopupResponsive';
import {TNC_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import useTermsOfUsePopup from '@/hooks/useTermsOfUsePopup';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import tncService from '@/services/tncService';
import {selectTncLegals} from '@/state/selectors/consumer';
import {selectTncPopupList} from '@/state/selectors/tnc';
import {removeLastTncPopup} from '@/state/slices/tncSlice';
import {twx} from '@/utils/tailwindUtils';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import TermsOfUseDescription from '../TermsOfUseDescription/TermsOfUseDescription';

const TermsOfUsePopupView = () => {
    const isMobile = useIsMobileLayout();
    const [popupRef, setPopupRef] = useState();
    const headerId = useId();
    const legals = useSelector(selectTncLegals);
    const tncPopupsList = useSelector(selectTncPopupList);
    const dispatch = useDispatch();

    const {code, className, overlayClassName} = tncPopupsList.slice(-1)[0];
    const handleClose = () => dispatch(removeLastTncPopup());
    const {openPopup} = useTermsOfUsePopup({overlayClassName});

    const localizedStrings = getLocalizedStrings();
    const legalData = tncService.getTncLegalByTermsCode(legals, code);

    if (!legalData) {
        dispatch(removeLastTncPopup());
        return;
    }
    const {text, title} = legalData;

    return (
        <PopupResponsive
            className={twx(
                'overflow-auto bg-romance ml:max-h-[calc(100vh-64px)] ml:w-[calc(100%-theme(spacing.16))] ml:max-w-[952px] ml:rounded-3xl ml:p-0',
                className
            )}
            overlayClassName={overlayClassName}
            isCloseVisible={!isMobile}
            onClose={handleClose}
            contentRef={setPopupRef}
            aria-labelledby={headerId}
        >
            {isMobile && <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.TNC_DETAILS} />}
            <div className='pt-safe-offset-sticky-header px-4 pb-4 ml:px-8 ml:pb-8 ml:pt-22' key={code}>
                <div className='pt-0-hw-offset ml:pt-0'>
                    {isMobile && (
                        <StickyHeader
                            onBack={handleClose}
                            title={localizedStrings[TNC_PAGE_HEADER_TITLE]}
                            scrollElement={popupRef}
                        />
                    )}
                    <h1
                        id={headerId}
                        className='mb-4 text-center text-24 ml:mb-8 ml:text-start ml:text-32 ml:font-bold ml:leading-[42px]'
                    >
                        {title}
                    </h1>
                    <TermsOfUseDescription
                        innerHtml={text}
                        className='mt-4 break-words text-justify [&_a]:bg-none [&_span]:text-inherit'
                        onTncLinkClick={openPopup}
                    />
                </div>
            </div>
            {isMobile && <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.TNC_DETAILS} />}
        </PopupResponsive>
    );
};

export default TermsOfUsePopupView;
