import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {RegularCleaningIcon} from '@/components/Icons';
import {StickyHeader} from '@/components/Layout';
import appConfig from '@/config/appConfig';
import {AD_REMOVE_STICK_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import amplitudeADTracking from '@/services/analytics/amplitude/amplitudeADTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import deviceTypesService from '@/services/device/deviceTypesService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import routerService from '@/services/route/routerService';
import {selectDeviceType} from '@/state/selectors/device';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners} from '@/views/WarningBanners';

import AdvancedDiagnosticCarousel from '../../AdvancedDiagnosticCarousel/AdvancedDiagnosticCarousel';
import useAdvancedDiagnosticCarousel from '../../AdvancedDiagnosticCarousel/useAdvancedDiagnosticCarousel';
import AdvancedDiagnosticContainer from '../../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticRemoveStickSlide from '../AdvancedDiagnosticRemoveStickSlide';

const AdvancedDiagnosticRemoveStick = () => {
    const isMobile = useIsMobileLayout();
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const {getSlideInertValue} = useAdvancedDiagnosticCarousel(currentSlideIndex);
    const deviceType = useSelector(selectDeviceType);
    const isDelta = deviceTypesService.isDelta(deviceType);
    const diagnosticData = appConfig.getAdConfig(deviceType, 'step4');
    const titleItemName = isDelta ? 'removestickdeltatitle' : 'removesticktitle';
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle(localizedStrings[AD_REMOVE_STICK_HEADER_TITLE]);

    const handleAfterChange = (newIndex) => {
        setCurrentSlideIndex(newIndex);

        amplitudeADTracking.trackADRemoveStickInstructionScroll();
        googleAnalyticsService.trackAdRemoveStickInstructionsScroll();
    };

    return (
        <div className='flex w-full flex-col overflow-hidden'>
            {isMobile && (
                <StickyHeader onBack={routerService.goBack} title={localizedStrings[AD_REMOVE_STICK_HEADER_TITLE]} />
            )}
            <div className='flex flex-1 flex-col gap-8'>
                <AdvancedDiagnosticContainer>
                    <RegularCleaningIcon />
                    <h1 className='mb-6 mt-4 text-24'>
                        <AemComponent
                            component={EditableText}
                            pageName={routePaths.ADVANCE_DIAGNOSTIC}
                            itemName={titleItemName}
                        />
                    </h1>
                </AdvancedDiagnosticContainer>
                <AdvancedDiagnosticCarousel
                    carouselData={diagnosticData}
                    dotsClassName='h-0' //to center arrows vertically
                    onAfterChange={handleAfterChange}
                    inert={getSlideInertValue(currentSlideIndex)}
                >
                    {diagnosticData.map((data, i) => {
                        const {title, description} = data;
                        const isLastSlide = diagnosticData.length - 1 === i;

                        return (
                            <AdvancedDiagnosticRemoveStickSlide
                                key={i}
                                title={title}
                                description={description}
                                isLastSlide={isLastSlide}
                            />
                        );
                    })}
                </AdvancedDiagnosticCarousel>
            </div>
            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.AD} />
        </div>
    );
};

export default AdvancedDiagnosticRemoveStick;
