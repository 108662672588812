import cn from 'classnames';

import loaderImage from '@/../assets/images/loading_primary.png';
import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import styles from '@/components/Button/AppButton/AppButton.module.scss';
import routePaths from '@/consts/route/routePaths';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import {getDefaultDeviceDataByType} from '@/services/device/deviceData';

const DeviceInfoBlock = ({
    node,
    nodeData,
    holderDefaultData,
    modelLabelKey,
    codentifyLabelKey,
    firmwareLabelKey,
    loading,
}) => {
    const isMobile = useIsMobileLayout();
    const {codentify, type, isHolder} = node || {};
    const {firmwareVersion} = nodeData || {};
    const defaultDeviceData = getDefaultDeviceDataByType(type);
    const productName = isHolder ? holderDefaultData?.modelName : defaultDeviceData?.name;
    const Heading = isMobile ? 'h2' : 'h3';

    return (
        <div className='border-b border-mischka first:pt-0 last:border-0'>
            <div className={cn('mb-2 text-14 leading-143 tracking-04 ml:mb-2', {'mt-4': isHolder})}>
                <AemEditableText pageName={routePaths.ABOUT_DEVICE} itemName={modelLabelKey} />
            </div>
            <Heading className='mb-8 text-18 tracking-02 md:mb-2 ml:mb-6 ml:leading-155'>{productName}</Heading>
            <ModelInfoBlock
                codentify={codentify}
                firmwareVersion={firmwareVersion}
                codentifyLabelKey={codentifyLabelKey}
                firmwareLabelKey={firmwareLabelKey}
                loading={loading}
            />
        </div>
    );
};

const ModelInfoBlock = ({codentify, firmwareVersion, codentifyLabelKey, firmwareLabelKey, loading}) => {
    const displayedCodentify = codentify ? codentify : '-';
    const displayedFirmwareVersion = firmwareVersion ? firmwareVersion : '-';

    return (
        <div className='mb-8 flex gap-6 break-words text-16 leading-15 text-black ml:mb-6 ml:gap-4'>
            <div className='flex flex-col gap-6 ml:gap-4'>
                <span>
                    <AemEditableText pageName={routePaths.ABOUT_DEVICE} itemName={codentifyLabelKey} />
                </span>
                <span>
                    <AemEditableText pageName={routePaths.ABOUT_DEVICE} itemName={firmwareLabelKey} />
                </span>
            </div>
            <div className='flex flex-col gap-6 ml:gap-4'>
                <p>{displayedCodentify}</p>
                <FirmwareVersionBlock version={displayedFirmwareVersion} loading={loading} />
            </div>
        </div>
    );
};

const FirmwareVersionBlock = ({version, loading}) => {
    return (
        <div className='relative'>
            <span className={cn({'opacity-0': loading}, 'flex-1')}>{version}</span>
            {loading && (
                <div className='absolute left-0 top-0 min-h-6 min-w-[14px]'>
                    <img alt='' className={cn([styles.Loader])} src={loaderImage} />
                </div>
            )}
        </div>
    );
};

export default DeviceInfoBlock;
