import amplitudeEventNames from '@/consts/analytics/analyticsEventNames';
import analyticsEventProperties from '@/consts/analytics/analyticsEventProperties';

import amplitude from './amplitude';

const trackHomePageScreen = () => {
    amplitude.trackWithSource(amplitudeEventNames.HOME_SCREEN_VIEWED);
};

const trackHomePageTopBannerButtonClick = (trackingValue) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(amplitudeEventNames.HOME_PAGE_TOP_BANNER_CLICKED, {
        [analyticsEventProperties.TRACKING_VALUE]: trackingValue,
    });
};

const trackLoyaltyButtonsBarClick = (trackingValue) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(amplitudeEventNames.LOYALTY_BUTTONS_BAR_CLICKED, {
        [analyticsEventProperties.TRACKING_VALUE]: trackingValue,
    });
};

const trackHighlightBannerClick = (trackingValue) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(amplitudeEventNames.HIGHLIGHT_BANNER_CLICKED, {
        [analyticsEventProperties.TRACKING_VALUE]: trackingValue,
    });
};

const trackHighlightViewAllClick = () => {
    amplitude.trackWithSource(amplitudeEventNames.HIGHLIGHT_VIEW_ALL_CLICKED);
};

const trackForYouTileOpened = (trackingValue) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(amplitudeEventNames.FOR_YOU_TILE_OPENED, {
        [analyticsEventProperties.TRACKING_VALUE]: trackingValue,
    });
};

export default {
    trackHomePageScreen,
    trackHomePageTopBannerButtonClick,
    trackLoyaltyButtonsBarClick,
    trackHighlightBannerClick,
    trackHighlightViewAllClick,
    trackForYouTileOpened,
};
