import React, {useEffect, useState} from 'react';

import {StickyPageLayout} from '@/components/Layout';
import {PopupFullPage} from '@/components/Popup';
import {LANGUAGE_SELECT_PAGE_HEADER_TITLE} from '@/consts/localization/localizationKeys';
import useIsDesktopLayout from '@/hooks/layout/useIsDesktopLayout';
import useModalRoute from '@/hooks/useModalRoute';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import modalService from '@/services/modalService';
import appRouterService from '@/services/route/appRouterService';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import {LANGUAGE_SELECT_TITLE_ID} from '../../consts/languageSelectConsts';
import useLanguageSelect from '../../hooks/useLanguageSelect';
import LanguageSelectContentSelectList from '../LanguageSelectContent/LanguageSelectContentSelectList';
import LanguageSelectContentTitle from '../LanguageSelectContent/LanguageSelectContentTitle';
import LanguageSelectPopupTrigger from './LanguageSelectPopupTrigger';

const LanguageSelectPopup = ({modalType}) => {
    const [popupEl, setPopupEl] = useState();
    const isDesktopLayout = useIsDesktopLayout();
    const {languageCode, selectListData, handleLanguageChange} = useLanguageSelect();
    const closeModal = () => modalService.hideModal(modalType);
    const {onClose} = useModalRoute({modalType, closeModal});
    const localizedStrings = getLocalizedStrings();

    //language select popup is absent for desktop design
    useEffect(() => {
        if (isDesktopLayout) {
            appRouterService.forwardToConsumerProfilePage();
        }
    }, [isDesktopLayout]);

    return (
        <PopupFullPage overlayRef={setPopupEl} className='flex flex-col' aria-labelledby={LANGUAGE_SELECT_TITLE_ID}>
            <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.LANGUAGE_SELECT} containerEl={popupEl} />
            <StickyPageLayout
                className='items-center'
                headerOptions={{
                    onBack: onClose,
                    title: localizedStrings[LANGUAGE_SELECT_PAGE_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center'
            >
                <LanguageSelectContentTitle
                    className='text-center mt-safe-offset-[calc(var(--sticky-header-height)+16px)]'
                    renderAs='h1'
                />
                <LanguageSelectContentSelectList
                    className='flex-1 py-8'
                    selectedValue={languageCode}
                    data={selectListData}
                    onChange={handleLanguageChange}
                />
                <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.LANGUAGE_SELECT} />
            </StickyPageLayout>
        </PopupFullPage>
    );
};

LanguageSelectPopup.Trigger = LanguageSelectPopupTrigger;

export default LanguageSelectPopup;
