import * as RadioGroup from '@radix-ui/react-radio-group';
import cn from 'classnames';
import React from 'react';

import {CONSUMER_FEEDBACK_POPUP_RATE_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import {getFormattedAriaLabel} from '@/services/accessibilityService';

import {StarIcon} from '../../../../components/Icons';

const EstimationRadioGroup = ({selectedValue, 'aria-labelledby': ariaLabelledBy, onChange}) => {
    const STAR_COUNT = 5;
    const getStarAriaLabel = (i) =>
        getFormattedAriaLabel(CONSUMER_FEEDBACK_POPUP_RATE_BUTTON_ARIA_LABEL, i + 1, STAR_COUNT);

    return (
        <RadioGroup.Root
            onValueChange={(value) => onChange(value + 1)}
            className='my-4 flex w-full justify-around px-2'
            aria-labelledby={ariaLabelledBy}
            required
        >
            {[...Array(STAR_COUNT)].map((star, index) => {
                return (
                    <RadioGroup.Item
                        className='p-0'
                        key={index}
                        value={index}
                        id={index}
                        aria-label={getStarAriaLabel(index)}
                    >
                        <StarIcon
                            className={cn('cursor-pointer', {
                                'fill-sandy stroke-sandy': index < selectedValue,
                            })}
                        />
                    </RadioGroup.Item>
                );
            })}
        </RadioGroup.Root>
    );
};

export default EstimationRadioGroup;
