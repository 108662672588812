import React from 'react';

import HighlightsCarousel from '../../common/components/HighlightsCarousel/HighlightsCarousel';
import HighlightsCarouselContainer from '../../common/components/HighlightsCarouselContainer';
import useHighlightsCarousels from '../hooks/useHighlightsCarousels';

const HighlightsCarouselList = ({carouselsFromConfig, visibleCount, isAutoPlayEnabled, isPlaying}) => {
    const {carousels, carouselStates, setCarouselRef, handleCarouselClick, handleSlideChange, handleCarouselEnded} =
        useHighlightsCarousels(carouselsFromConfig, visibleCount);

    return (
        <div className='flex gap-4 2xl:gap-8'>
            {carousels.map(({highlights}, i) => (
                <HighlightsCarouselContainer key={i} className='flex-1'>
                    <HighlightsCarousel
                        ref={(ref) => setCarouselRef(i, ref)}
                        highlights={highlights}
                        isAutoPlayEnabled={isAutoPlayEnabled}
                        isPlaying={isPlaying && carouselStates[i].isPlaying}
                        isEnded={carouselStates[i].isEnded}
                        currentSlide={carouselStates[i].currentSlide}
                        onSlideChange={(_, newSlide) => handleSlideChange(i, newSlide)}
                        onCarouselClick={() => handleCarouselClick(i)}
                        onCarouselEnded={() => handleCarouselEnded()}
                    />
                </HighlightsCarouselContainer>
            ))}
        </div>
    );
};

export default HighlightsCarouselList;
