import React from 'react';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableNotificationsInbox from '@/components/Aem/EditableNotificationsInbox/EditableNotificationsInbox';
import {PopoverStickyTitle} from '@/components/Popover/Popover';
import {ScrollArea} from '@/components/ScrollArea/ScrollArea';
import routePaths from '@/consts/route/routePaths';
import useInboxListView from '@/hooks/inbox/useInboxListView';

import InboxPopoverMainTitle from './InboxPopoverMainTitle';

const InboxPopoverList = ({onMessageClick, containerRef}) => {
    const {inboxMessages, sentryRef, rootRef, hasNextPage} = useInboxListView();

    return (
        <>
            <PopoverStickyTitle contentElement={containerRef.current}>
                <InboxPopoverMainTitle />
            </PopoverStickyTitle>
            <ScrollArea className='flex-1 p-6 py-0' viewportRef={rootRef}>
                <AemComponent
                    component={EditableNotificationsInbox}
                    pageName={routePaths.HOME_PAGE}
                    itemName='notificationsinbox'
                    notifications={inboxMessages}
                    sentryRef={sentryRef}
                    hasNextPage={hasNextPage}
                    onMessageClick={onMessageClick}
                />
            </ScrollArea>
        </>
    );
};

export default InboxPopoverList;
