import React, {useRef} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {useResizeObserver} from 'usehooks-ts';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {DevicesIcon, HomeIcon, MyIqosIcon} from '@/components/Icons';
import RemoteImage from '@/components/Image/RemoteImage';
import appConfig from '@/config/appConfig';
import {
    NAVIGATION_BAR_DEVICE_BUTTON_ARIA_LABEL,
    NAVIGATION_BAR_FOR_YOU_BUTTON_ARIA_LABEL,
    NAVIGATION_BAR_LOGO_BUTTON_ARIA_LABEL,
    NAVIGATION_BAR_SHOP_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import ROUTE_PATHS from '@/consts/route/routePaths';
import useWithLocale from '@/hooks/useWithLocale';
import {getAriaLabel} from '@/services/accessibilityService';
import {twx} from '@/utils/tailwindUtils';

const HomePageNavigationBar = ({activePath}) => {
    const withLocale = useWithLocale();
    const ref = useRef(null);
    const {height} = useResizeObserver({ref});

    const imageMedia = appConfig.getLogoImageMedia();

    return (
        <>
            <div style={{height}} className='ml:hidden' />
            <div ref={ref} className='fixed bottom-0 left-0 z-[2] w-full ml:top-0 ml:z-[103] ml:w-[--sidebar-width]'>
                <div
                    className={`border-input-border flex w-full border-t border-border bg-white ml:h-full ml:flex-col ml:border-none ml:px-4 ml:py-8 ml:text-center ml:text-black lg:px-6`}
                >
                    <NavLink
                        to={withLocale(ROUTE_PATHS.HOME_PAGE)}
                        className='hidden ml:block'
                        aria-label={getAriaLabel(NAVIGATION_BAR_LOGO_BUTTON_ARIA_LABEL)}
                    >
                        <RemoteImage imageMedia={imageMedia} className='m-auto' />
                    </NavLink>
                    <div className='flex w-full ml:my-auto ml:flex-col ml:gap-4 ml:pb-16 lg:mt-8 lg:pb-0'>
                        <Link
                            className='pl-2 ml:pl-0'
                            to={withLocale(ROUTE_PATHS.HOME_PAGE)}
                            activePath={withLocale(activePath)}
                            icon={HomeIcon}
                            itemName='forYouMenuItem'
                            aria-label={getAriaLabel(NAVIGATION_BAR_FOR_YOU_BUTTON_ARIA_LABEL)}
                        />
                        <Link
                            iconClassName='my-1 mr-0.5 ml-[1px] ml:m-0'
                            to={withLocale(ROUTE_PATHS.SHOP_PAGE)}
                            activePath={withLocale(activePath)}
                            icon={MyIqosIcon}
                            itemName='shopMenuItem'
                            aria-label={getAriaLabel(NAVIGATION_BAR_SHOP_BUTTON_ARIA_LABEL)}
                        />
                        <Link
                            className='pr-2 ml:pr-0'
                            to={withLocale(ROUTE_PATHS.DEVICES_PAGE)}
                            activePath={withLocale(activePath)}
                            icon={DevicesIcon}
                            itemName='deviceMenuItem'
                            aria-label={getAriaLabel(NAVIGATION_BAR_DEVICE_BUTTON_ARIA_LABEL)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

const Link = ({to, icon: Icon, className, iconClassName, itemName, activePath, 'aria-label': ariaLabel}) => {
    //expected to use useLocation instead of useLocationPathname
    const {pathname} = useLocation();
    const isActive = pathname.startsWith(to) || to === activePath;

    return (
        <NavLink
            to={to}
            className={twx('w-full pt-3 text-12 pb-safe-or-3 ml:p-0 ml:text-14 ml:tracking-04', className)}
            aria-label={ariaLabel}
        >
            <div className='flex flex-col items-center gap-1 md:flex-row md:justify-center ml:flex-col ml:gap-2'>
                <div
                    className={twx('flex h-8 w-16 items-center justify-center rounded-full md:w-6 ml:h-10 ml:w-22', {
                        'bg-tertiary-a30 md:w-16': isActive,
                    })}
                >
                    <Icon
                        className={twx('text-romance', iconClassName, {
                            'text-tertiary-a30': isActive,
                        })}
                    />
                </div>
                <div
                    className={twx(
                        'md:text-12 md:leading-13 md:tracking-04 ml:text-14 ml:leading-143 ml:tracking-02',
                        isActive && 'font-bold'
                    )}
                >
                    <AemEditableText pageName={routePaths.HOME_PAGE} itemName={itemName} />
                </div>
            </div>
        </NavLink>
    );
};

export default HomePageNavigationBar;
