import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {ButtonRaw} from '@/components/Button';
import appConfig from '@/config/appConfig';
import {AUTO_DELIVERY_ASAP_INFO_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import {getAriaLabel} from '@/services/accessibilityService';
import stringUtils from '@/utils/stringUtils';

import useDeliveryDate from '../hooks/useDeliveryDate';

const DeliveryAsapInfo = ({className}) => {
    const {startDeliveryDay, asapDeliveryDay} = useDeliveryDate();
    const externalLinkConfig = appConfig.getAutoDeliveryAsapRescheduleExternalLink();
    const redirectToExternalLink = useConfigExternalLink(externalLinkConfig);

    return (
        <div className={className}>
            <AemEditableText
                pageName={routePaths.AUTO_DELIVERY}
                itemName='deliveryAsapInfoTextPart1'
                render={(text) => {
                    const textFormatted = stringUtils.formatString(text, startDeliveryDay, asapDeliveryDay);

                    return textFormatted ? <span dangerouslySetInnerHTML={{__html: textFormatted}} /> : null;
                }}
            />{' '}
            <ButtonRaw
                renderAs='a'
                className='inline font-bold underline'
                onClick={redirectToExternalLink}
                aria-label={getAriaLabel(AUTO_DELIVERY_ASAP_INFO_BUTTON_ARIA_LABEL)}
            >
                <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='deliveryAsapInfoButton' />
            </ButtonRaw>{' '}
            <AemEditableText
                pageName={routePaths.AUTO_DELIVERY}
                itemName='deliveryAsapInfoTextPart2'
                render={(text) =>
                    text ? <span className={className} dangerouslySetInnerHTML={{__html: text}} /> : null
                }
            />
        </div>
    );
};

export default DeliveryAsapInfo;
