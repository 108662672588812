import cn from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import PopupStickedWithSwipeClose from '@/components/Popup/PopupStickedWithSwipeClose';
import routePaths from '@/consts/route/routePaths';
import DevicesSettingsSection from '@/containers/DevicesPage/components/DevicesSettingsSection/DevicesSettingsSection';
import MyDevicesManageDevice from '@/containers/DevicesPage/components/MyDevicesView/components/MyDevicesManageDevice';
import useOnConnectClick from '@/containers/DevicesPage/hooks/useOnConnectClick';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import routerService from '@/services/route/routerService';
import {selectCurrentProductInfo} from '@/state/selectors/device';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners} from '@/views/WarningBanners';

const DeviceSettingPage = () => {
    const isMobile = useIsMobileLayout();

    if (isMobile)
        return (
            <>
                <MyDevicesManageDevice />
                <PopupStickedWithSwipeClose
                    onClose={routerService.goBack}
                    className='flex flex-col items-center px-8 ml:max-w-[568px] ml:pb-8'
                    isCloseVisible
                    isSwipeDashVisible={false}
                >
                    <ConnectButton className='my-8' />
                </PopupStickedWithSwipeClose>
            </>
        );

    return (
        <DevicesSettingsSection.Container className='flex h-full w-full flex-col'>
            <div className='mt-auto'>
                <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.DEVICES} contentClassName='ml:px-8' />
                <ConnectButton className='mb-8 mt-0' />
            </div>
        </DevicesSettingsSection.Container>
    );
};

const ConnectButton = ({className}) => {
    const device = useSelector(selectCurrentProductInfo);
    const {type, deviceSerialNumber} = device;
    const {onConnectClick} = useOnConnectClick(type, deviceSerialNumber);

    return (
        <AemEditableText
            pageName={routePaths.DEVICES_PAGE}
            itemName='connectButton'
            render={(text) =>
                text && (
                    <AppButton className={cn(className, 'w-full')} primary light onClick={onConnectClick}>
                        {text}
                    </AppButton>
                )
            }
        />
    );
};

export default DeviceSettingPage;
