import React, {useState} from 'react';

import {PopupFullPage} from '@/components/Popup';
import {HIGHLIGHTS_FULLSCREEN_POPUP_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import useScrollToPreviousPosition from '@/hooks/useScrollToPreviousPosition';
import {getAriaLabel} from '@/services/accessibilityService';
import {WARNING_BANNER_SOURCE_TYPES, WarningBannersTop} from '@/views/WarningBanners';

import HighlightsCarousel from './HighlightsCarousel';

const FullscreenHighlightsCarousel = ({
    setIsFullscreen,
    currentIndex,
    highlights,
    autoplay,
    onSlideChange,
    isInfinite,
}) => {
    const [popupEl, setPopupEl] = useState();

    useScrollToPreviousPosition();

    return (
        <PopupFullPage
            contentRef={setPopupEl}
            className='z-10 bg-romance'
            isCloseVisible
            onClose={() => setIsFullscreen(false)}
            closeButtonClass='text-white top-safe-offset-[calc(var(--hw-h)+8px)] right-2'
            aria-label={getAriaLabel(HIGHLIGHTS_FULLSCREEN_POPUP_ARIA_LABEL)}
        >
            <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.HIGHLIGHTS_FULLSCREEN} containerEl={popupEl} />
            <HighlightsCarousel
                initialSlide={currentIndex}
                currentIndex={currentIndex}
                isFullscreen
                highlights={highlights}
                autoplay={autoplay}
                onSlideChange={onSlideChange}
                isInfinite={isInfinite}
                className='pt-0-hw-offset'
            />
        </PopupFullPage>
    );
};

export default FullscreenHighlightsCarousel;
