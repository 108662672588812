import cn from 'classnames';
import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {DeviceUnregisterIcon, TickCircle} from '@/components/Icons';
import appConfig from '@/config/appConfig';
import * as localizationKeys from '@/consts/localization/localizationKeys';
import {DEVICE_UNREGISTER_BUTTON_TEXT_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import DeviceImage from '@/containers/DeviceImage/DeviceImage';
import {getAriaLabel} from '@/services/accessibilityService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import productHelpers from '@/services/product/productHelpers';
import helpers from '@/utils/helpers';
import {twx} from '@/utils/tailwindUtils';

import styles from './DevicesListItem.module.scss';
import useDeviceListItemSwipe from './useDeviceListItemSwipe';

const DeviceListItem = ({device, isSelected, touchedDeviceId, onDeviceChange, onDeviceTouch, onUnregisterClick}) => {
    const isUnregistrationEnabled = appConfig.getIsUnregistrationEnabled();
    const {codentify} = device || {};
    const name = productHelpers.getProductName(device);
    const {animationStyle, onTouchStart, onTouchMove, onTouchEnd, onTransitionEnd} = useDeviceListItemSwipe({
        deviceId: codentify,
        touchedDeviceId,
        onDeviceTouch,
        isEnabled: isUnregistrationEnabled,
    });

    const handleClick = () => helpers.runFunction(onDeviceChange, codentify);

    return (
        <ButtonRaw
            className={twx('relative flex w-full cursor-pointer', styles.DeviceButton)}
            role='option'
            aria-label={name}
            aria-selected={isSelected}
            onClick={handleClick}
        >
            <div className='max-w-full flex-1 px-4 ml:p-0 ml:px-0 lg:hover:cursor-pointer'>
                <div
                    className='flex items-center gap-2 overflow-x-auto py-4 text-start text-primary'
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                >
                    <DeviceImage
                        className='h-16 w-16'
                        device={device}
                        holder={device?.holder}
                        imgClassName='absolute'
                        ignoreRestrictedMarket
                        showHolder
                    />
                    <p className={cn('flex-1 truncate text-16 font-bold leading-15', !isSelected && 'ml:pr-9')}>
                        {name}
                    </p>
                    {isSelected && <TickCircle className='shrink-0' />}
                </div>
            </div>
            {isUnregistrationEnabled && (
                <UnregisterDeviceButton
                    style={animationStyle}
                    onClick={onUnregisterClick}
                    onTransitionEnd={onTransitionEnd}
                />
            )}
        </ButtonRaw>
    );
};

const UnregisterDeviceButton = ({style, onClick, onTransitionEnd}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <ButtonRaw
            style={style}
            className={styles.DeleteContainer}
            aria-label={getAriaLabel(DEVICE_UNREGISTER_BUTTON_TEXT_ARIA_LABEL)}
            renderAs='div'
            onClick={onClick}
            onTransitionEnd={onTransitionEnd}
        >
            <div className='flex h-full w-[88px] flex-col items-center justify-center text-center text-12 tracking-02'>
                <DeviceUnregisterIcon className='h-6 w-6' />
                <span className='max-w-full truncate px-1'>
                    {localizedStrings[localizationKeys.DEVICE_UNREGISTER_BUTTON_TEXT]}
                </span>
            </div>
        </ButtonRaw>
    );
};

export default DeviceListItem;
