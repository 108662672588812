import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import DeviceHero from '@/components/DeviceHero/DeviceHero';
import {MaxWidthContainer} from '@/components/Layout';
import {ABOUT_YOUR_DEVICE_TITLE} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import DevicesSettingsSection from '@/containers/DevicesPage/components/DevicesSettingsSection/DevicesSettingsSection';
import useLayout from '@/hooks/layout/useLayout';
import useUamAssets from '@/hooks/uam/useUamAssets';
import useDocumentTitle from '@/hooks/useDocumentTitle';
import amplitudeDevicesPageTracking from '@/services/analytics/amplitude/amplitudeDevicesPageTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import deviceTypesService from '@/services/device/deviceTypesService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {selectCurrentProductInfo, selectDeviceData, selectHolderData} from '@/state/selectors/device';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import useOnConnectClick from '../DevicesPage/hooks/useOnConnectClick';
import AboutTitle from './components/AboutTitle';
import DeviceInfoBlock from './components/DeviceInfoBlock';
import FirmwareVersionWarning from './components/FirmwareVersionWarning';

const AboutDevicePage = () => {
    const {isMobile, isDesktop} = useLayout();
    const ref = useRef(null);
    const localizedStrings = getLocalizedStrings();
    const device = useSelector(selectCurrentProductInfo);
    const deviceData = useSelector(selectDeviceData) || {};
    const holderData = useSelector(selectHolderData) || {};
    const {codentify, holder, defaultHolderType, type, deviceSerialNumber} = device || {};
    const holderNode = holder || (defaultHolderType ? {type: defaultHolderType, isHolder: true} : undefined);
    const holderDefaultData = deviceTypesService.getDeviceTypesDataByType(holderNode?.type);

    const [loading, setLoading] = useState(false);
    const [isChargerError, setIsChargerError] = useState(false);
    const [isHolderError, setIsHolderError] = useState(false);
    const {onConnectClick} = useOnConnectClick(type, deviceSerialNumber);

    useDocumentTitle(localizedStrings[ABOUT_YOUR_DEVICE_TITLE]);

    useEffect(() => {
        amplitudeDevicesPageTracking.trackAboutMyDeviceScreen();
        googleAnalyticsService.trackAboutMyDeviceScreenView(type, deviceSerialNumber);
    }, []);

    useUamAssets({setLoading, setIsChargerError, setIsHolderError, codentify, holderCodentify: holder?.codentify});

    return (
        <div className='flex w-full flex-col' ref={ref}>
            {isMobile && (
                <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.ABOUT_DEVICE} containerEl={ref.current} />
            )}
            <DevicesSettingsSection.Container
                title={localizedStrings[ABOUT_YOUR_DEVICE_TITLE]}
                onClick={onConnectClick}
                pageName={routePaths.ABOUT_DEVICE}
                itemName='connectDeviceButton'
                className='pt-0-hw-offset ml:pb-2 ml:pt-0'
                isContentContainerFocusable
            >
                {isMobile && <DeviceHero containerClassName='w-full mb-8' />}
                <MaxWidthContainer className='mx-auto w-full px-4 md:max-w-[500px] ml:max-w-none ml:items-start ml:px-0'>
                    <AboutTitle device={device} />
                    <FirmwareVersionWarning className='mt-4 ml:mt-8' />
                    <div className='mt-4 w-full text-start ml:mt-8'>
                        <DeviceInfoBlock
                            modelLabelKey='chargerModel'
                            codentifyLabelKey='chargerCodentify'
                            firmwareLabelKey='chargerFirmware'
                            node={device}
                            nodeData={isChargerError ? undefined : deviceData}
                            loading={loading}
                        />
                        {holderNode && (
                            <DeviceInfoBlock
                                modelLabelKey='holderModel'
                                codentifyLabelKey='holderCodentify'
                                firmwareLabelKey='holderFirmware'
                                node={holderNode}
                                nodeData={isHolderError ? undefined : holderData}
                                holderDefaultData={holderDefaultData}
                                loading={loading}
                            />
                        )}
                    </div>
                </MaxWidthContainer>
                {isMobile && <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.ABOUT_DEVICE} />}
            </DevicesSettingsSection.Container>
            {isDesktop && <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.DEVICES} />}
        </div>
    );
};

export default AboutDevicePage;
