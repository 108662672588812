import {isInvalidToken} from '@/consts/icc/iccResponseErrorTypes';
import * as logMessageTypes from '@/consts/log/logMessageTypes';
import authService from '@/services/auth/authService';
import iccService from '@/services/icc/iccService';
import log from '@/services/logger/log';
import httpStatusTypesService from '@/services/server/httpStatusTypesService';
import {showMaintenancePopup} from '@/state/slices/modalSlice';
import {dispatch} from '@/state/store';

const iccResponseErrorCheckService = async ({isAnonymous, status, error, skipError}) => {
    const isServerUnavailable = httpStatusTypesService.isServiceUnavailable(status);
    const isInvalid = isInvalidToken(error);
    const isAuthError = httpStatusTypesService.isNotAuthorized(status);

    if (isServerUnavailable) {
        dispatch(showMaintenancePopup());
    }

    if (isAuthError) {
        await iccService.refreshIccToken(isAnonymous);
    } else if (isInvalid) {
        log.info(`iccService: gigya token is invalid. Logout`, {logMessageType: logMessageTypes.ICC});

        authService.logout();
    } else {
        if (skipError) return;
        throw error;
    }
};

export default iccResponseErrorCheckService;
