import React from 'react';

import AriaLiveContainer from '@/components/AriaLiveMessage/AriaLiveContainer';

const InputFieldWarning = ({isValid, isEntered, warningNotValid, warningRequiredField}) => {
    return (
        <>
            {(!isValid || !isEntered) && (
                <AriaLiveContainer alert visible className='text-start text-14 leading-[24px] text-error'>
                    {isEntered ? warningNotValid : warningRequiredField}
                </AriaLiveContainer>
            )}
        </>
    );
};

export default InputFieldWarning;
