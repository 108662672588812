import React from 'react';
import {useSelector} from 'react-redux';

import arianeHeroImg from '@/../assets/images/device-hero/ariane-hero.svg';
import useDevicePrimaryColor from '@/hooks/useDevicePrimaryColor';
import deviceTypesService from '@/services/device/deviceTypesService';
import stylesService from '@/services/stylesService';
import {selectDeviceType} from '@/state/selectors/device';

const DevicePrimaryColorContainer = (props) => {
    const {children, className, isBackgroundImageEnabled, deviceType: currentDeviceType, isVideoContent} = props;
    const deviceTypeFromState = useSelector(selectDeviceType);
    const devicePrimaryColor = useDevicePrimaryColor(currentDeviceType, isVideoContent);
    const deviceType = currentDeviceType || deviceTypeFromState;
    const isAriane = deviceTypesService.isAriane(deviceType);
    const isArianeBackgroundImageEnabled = isAriane && isBackgroundImageEnabled;

    const backgroundColorStyle = stylesService.getBackgroundColorStyle(devicePrimaryColor);
    const backgroundImageStyle = isArianeBackgroundImageEnabled && stylesService.getBackgroundImageStyle(arianeHeroImg);
    const styles = {...backgroundImageStyle, ...backgroundColorStyle};

    return (
        <div style={styles} className={className}>
            {children}
        </div>
    );
};

export default DevicePrimaryColorContainer;
