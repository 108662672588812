import cn from 'classnames';
import {FocusTrap} from 'focus-trap-react';
import React, {useId} from 'react';
import {useSelector} from 'react-redux';
import {RemoveScroll} from 'react-remove-scroll';

import {AppButton} from '@/components/Button';
import ModalTypes from '@/consts/app/modalTypes';
import {
    WELCOME_VIEW_BUTTON_ARIA_LABEL,
    WELCOME_VIEW_BUTTON_TEXT,
    WELCOME_VIEW_SUBTITLE,
} from '@/consts/localization/localizationKeys';
import {getAriaLabel} from '@/services/accessibilityService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import storageService from '@/services/storage/storageService';
import {makeSelectVisibleGlobalModal} from '@/state/selectors/modal';
import focusUtils from '@/utils/focusUtils';
import useGreeting from '@/views/Greeting/useGreeting';

const WelcomeContent = ({isAnimationStarted, onClick, greetingRef, greetingTranslation: {x, y}, isDarkBannerText}) => {
    const isEnableNotificationPopupVisible = useSelector(makeSelectVisibleGlobalModal(ModalTypes.ENABLE_NOTIFICATION));
    const headerId = useId();
    const descriptionId = useId();
    const {title, description} = useGreeting();
    const localizedStrings = getLocalizedStrings();
    const handleClick = () => {
        storageService.setHasSeenWelcomeScreen();
        onClick();
    };

    return (
        <FocusTrap
            active={!isEnableNotificationPopupVisible}
            focusTrapOptions={{
                escapeDeactivates: false,
                // This popup breaks focus order, so it needs to be restored to the first element after close
                setReturnFocus: () => focusUtils.getFirstFocusableElementOnPage(),
            }}
        >
            <RemoveScroll enabled={!isEnableNotificationPopupVisible}>
                <div
                    role='dialog'
                    aria-modal='true'
                    className={cn(
                        'fixed z-[3] flex h-screen w-full flex-col items-center justify-center px-8 text-center',
                        isDarkBannerText ? 'text-primary' : 'text-romance'
                    )}
                    aria-labelledby={headerId}
                    aria-describedby={descriptionId}
                >
                    <h2
                        id={headerId}
                        className={cn(
                            'mb-0 text-28 leading-[40px] tracking-04 transition-[opacity,transform] duration-500',
                            {
                                'opacity-0': isAnimationStarted,
                            }
                        )}
                        ref={greetingRef}
                        style={isAnimationStarted ? {transform: `translate(${-x}px, ${-y}px)`} : {}}
                    >
                        {title} {description}
                    </h2>
                    <div
                        className={cn('flex flex-col items-center transition-opacity duration-300', {
                            'opacity-0': isAnimationStarted,
                        })}
                    >
                        <div id={descriptionId} className='mt-4 font-normal'>
                            {localizedStrings[WELCOME_VIEW_SUBTITLE]}
                        </div>
                        <AppButton
                            primary
                            dark
                            onClick={handleClick}
                            className='mt-4 w-auto font-normal'
                            aria-label={getAriaLabel(WELCOME_VIEW_BUTTON_ARIA_LABEL)}
                        >
                            {localizedStrings[WELCOME_VIEW_BUTTON_TEXT]}
                        </AppButton>
                    </div>
                </div>
            </RemoveScroll>
        </FocusTrap>
    );
};

export default WelcomeContent;
