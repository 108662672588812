import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {AlertIcon} from '@/components/Icons';
import {INBOX_OPEN_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import {getAriaLabel} from '@/services/accessibilityService';

const InboxIconComponent = ({onClick, isUnreadExists}) => {
    return (
        <ButtonRaw onClick={onClick} className='relative' aria-label={getAriaLabel(INBOX_OPEN_BUTTON_ARIA_LABEL)}>
            <AlertIcon className='h-6 w-6' />
            {isUnreadExists && <div className='absolute right-0 top-0 h-1.5 w-1.5 rounded-full bg-bright-sun' />}
        </ButtonRaw>
    );
};

export default InboxIconComponent;
