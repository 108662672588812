import {ICC_SSO_GUIDE_DEEPLINK_TEMPLATE} from '@/consts/icc/iccSsoDeeplinkTemplates';
import useExternalLink from '@/hooks/useExternalLink';
import deviceConnectionService from '@/services/device/deviceConnectionService';
import appRouterService from '@/services/route/appRouterService';

const useOnConnectClick = (deviceType, deviceSerialNumber = undefined) => {
    const {redirect: redirectToWebAppGuidePage} = useExternalLink({
        ssoTemplate: ICC_SSO_GUIDE_DEEPLINK_TEMPLATE,
        isConnectionLink: true,
        ssoParams: {
            deviceType,
        },
        deviceType,
        deviceSerialNumber,
    });

    const onConnectClick = () => {
        if (!deviceConnectionService.isBleConnectionSupported(deviceType)) {
            appRouterService.forwardToUsbRequiredPage(deviceType);
        } else {
            redirectToWebAppGuidePage();
        }
    };

    return {onConnectClick};
};

export default useOnConnectClick;
