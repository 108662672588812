import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import ContentList from '@/components/ContentList/ContentList';
import {MaxWidthContainer} from '@/components/Layout';
import routePaths from '@/consts/route/routePaths';

const AutoDeliveryBenefits = ({className, benefitsList, itemName}) => {
    return (
        <article className={className}>
            <AemEditableText
                pageName={routePaths.HOME_PAGE}
                itemName={itemName}
                render={(title) => (title ? <h2 className='mb-0 text-24'>{title}</h2> : null)}
            />
            <MaxWidthContainer className='mt-4 flex-1 first:mt-0'>
                <ContentList list={benefitsList} />
            </MaxWidthContainer>
        </article>
    );
};

export default AutoDeliveryBenefits;
