import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import LanguageSelectPopup from '@/components/LanguageSelect/components/LanguageSelectPopup/LanguageSelectPopup';
import ModalTypes from '@/consts/app/modalTypes';
import {selectGlobalModals} from '@/state/selectors/modal';

import AutoDeliveryStatusInactiveDetailsPopup from './AutoDelivery/AutoDeliveryStatusInactiveDetailsPopup';
import AutoDeliveryStatusNoneDetailsPopup from './AutoDelivery/AutoDeliveryStatusNoneDetailsPopup';
import BrowserNotCompatiblePopup from './BrowserNotCompatible/BrowserNotCompatiblePopup';
import ConsumerFeedbackGatheringPopup from './ConsumerFeedbackGatheringPopup/ConsumerFeedbackGatheringPopup';
import EnableNotificationModal from './EnableNotificationModal/EnableNotificationModal';
import FirmwareAvailablePopup from './FirmwareAvailablePopup/FirmwareAvailablePopup';
import LeviaLearnMorePopup from './Levia/LeviaLearnMorePopup';
import MaintenancePopup from './MaintenancePopup/MaintenancePopup';
import QuickOrderPopup from './QuickOrderPopup/QuickOrderPopup';
import UWMUnavailabilityPopup from './UWMUnavailabilityPopup/UWMUnavailabilityPopup';

const MODAL_COMPONENTS = {
    [ModalTypes.LEVIA_LEAR_MORE]: LeviaLearnMorePopup,
    [ModalTypes.FIRMWARE_AVAILABLE]: FirmwareAvailablePopup,
    [ModalTypes.BROWSER_NOT_COMPATIBLE]: BrowserNotCompatiblePopup,
    [ModalTypes.UWM_UNAVAILABILITY]: UWMUnavailabilityPopup,
    [ModalTypes.CONSUMER_FEEDBACK_GATHERING]: ConsumerFeedbackGatheringPopup,
    [ModalTypes.LANGUAGE_SELECT]: LanguageSelectPopup,
    [ModalTypes.MAINTENANCE]: MaintenancePopup,
    [ModalTypes.ENABLE_NOTIFICATION]: EnableNotificationModal,
    [ModalTypes.AUTO_DELIVERY_STATUS_NONE_DETAILS]: AutoDeliveryStatusNoneDetailsPopup,
    [ModalTypes.AUTO_DELIVERY_STATUS_INACTIVE_DETAILS]: AutoDeliveryStatusInactiveDetailsPopup,
    [ModalTypes.QUICK_ORDER]: QuickOrderPopup,
};

const ModalsRoot = () => {
    const modals = useSelector(selectGlobalModals);
    const dispatch = useDispatch();

    if (!modals.length) return null;

    const alwaysDisplayModals = modals.filter((m) => m.alwaysDisplay);
    const modalsSorted = [...modals].sort((a, b) => b.order - a.order);
    const curModal = modalsSorted[modalsSorted.length - 1];

    return (
        <>
            <CurrentModal curModal={curModal} />
            {alwaysDisplayModals.map((modal) => {
                const {modalType, modalProps} = modal;
                const ComponentModal = MODAL_COMPONENTS[modalType];

                return <ComponentModal key={modalType} {...modalProps} modalType={modalType} dispatch={dispatch} />;
            })}
        </>
    );
};

const CurrentModal = ({curModal}) => {
    const dispatch = useDispatch();

    if (!curModal) return null;

    const {modalType, modalProps, alwaysDisplay} = curModal;

    if (alwaysDisplay) return null;

    const SpecificModal = MODAL_COMPONENTS[modalType];

    if (!SpecificModal) return null;

    return <SpecificModal key={modalType} {...modalProps} modalType={modalType} dispatch={dispatch} />;
};

export default ModalsRoot;
