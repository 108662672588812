import stringFormatService from '@/services/stringFormatService';
import arrayUtils from '@/utils/arrayUtils';
import urlUtils from '@/utils/urlUtils';

import {
    getBannersConfig,
    getBaseConfig,
    getConfig,
    getExternalLinksConfig,
    getLoyaltyConfig,
    getMediaConfig,
    getPagesConfig,
} from './config';

const getIsDebug = () => getConfig().logLevel !== 'SILENT';
const getLogLevel = () => getConfig().logLevel;
const getAwsRegion = () => 'eu-west-1';
const getAwsLogLevel = () => getConfig().awsLogLevel;
const getAuthLogsUrl = () => getConfig().authLogsUrl;

const getLoginEmailRegex = () => getConfig().loginEmailRegex || '.*';
const getLoginPasswordRegex = () => getConfig().loginPasswordRegex || '.*';
const getRegisterLinkCode = () => getConfig().registerLinkCode;
const getForgotPasswordLinkCode = () => getConfig().forgotPasswordLinkCode;

const getIccApiUrl = () => getConfig().iccEndpoint;
const getIccNotificationApiUrl = () => getConfig().iccNotificationApiEndpoint;
const getIccApiHeaderAuthorization = () => getConfig().iccAuthorizationHeader;
const getIccApiHeader_a = () => getConfig().iccApplicationHeader;
const getIccMarketName = () => getConfig().iccMarketHeader;
const getIccAnonymousToken = () => getConfig().iccAnonymousToken;
const getIsIccRefreshTokenEnabled = () => getConfig().isIccRefreshTokenEnabled;
const getIccTermsOfUseLegalPosition = () => getConfig().iccTermsOfUseLegalPosition;
const getIccAttributionNoticeLegalPosition = () => getConfig().iccAttributionNoticeLegalPosition;
const getIccPrivacyNoticeLegalPosition = () => getConfig().iccPrivacyNoticeLegalPosition;

const getMarketLanguages = () => getBaseConfig().marketLanguages || [];

const getEnvironment = () => getConfig().environment;
const getAppVersion = () => process.env.REACT_APP_VERSION;

const getDeviceSupport = () => getConfig().deviceSupport || [];
const getDeviceSettings = (deviceType) => {
    const deviceSettings = getConfig().deviceSettings;

    if (!deviceSettings?.length) return [];

    return deviceSettings.find((x) => x?.deviceType?.deviceType === deviceType)?.deviceSettings || [];
};

const getContentPath = () => (process.env.REACT_APP_ENV === 'dev' ? 'https://m.stg.iqos.com/assets/' : '/assets');
const getStoragePath = () => getConfig().storagePath;

const getCombinedPathAssetPath = (customPath) => {
    const storagePath = getStoragePath();

    if (storagePath && customPath) {
        const combinedPath = urlUtils.join(storagePath, customPath);

        return combinedPath;
    }
};

const getAssetsPath = () => getCombinedPathAssetPath(getConfig().assetsPath);
const getRtlLanguageCodes = () => getCombinedPathAssetPath(getConfig().rtlLanguageCodes);

const getLearnMoreUrl = () => getConfig().learnMoreUrl;
const getLearnMoreUrlFormatted = () => stringFormatService.getLanguageFormattedString(getLearnMoreUrl());

const getHomePageForYouSection = () => getBannersConfig().forYouSection || [];
const getHomePageAutoDeliveryBanner = () => getBannersConfig().homePageAutoDeliveryBanner;

const getLeviaCheckCompatibilityExternalLink = () => getExternalLinksConfig().leviaCheckCompatibilityExternalLink;
const getLeviaLearnMoreExternalLink = () => getExternalLinksConfig().leviaLearnMoreExternalLink;

const getFirmwareAvailableCheckCompatibilityExternalLink = () =>
    getExternalLinksConfig().firmwareAvailableCheckCompatibilityExternalLink;
const getFirmwareAvailableLearnMoreExternalLink = () => getExternalLinksConfig().firmwareAvailableLearnMoreExternalLink;

const getWarningBanners = () => getBannersConfig().warningBanners || [];

const getGreetings = () => getBaseConfig().greetings;
const getIsQureMarket = () => getBaseConfig().isQureMarket;
const getIsUnregistrationEnabled = () => getBaseConfig().isUnregistrationEnabled;
const getIsWelcomeScreenEnabled = () => getBaseConfig().isWelcomeScreenEnabled;
const getIsLoyaltyEnabled = () => getLoyaltyConfig().isLoyaltyEnabled;
const getIsLoyaltyApiEnabled = () => getLoyaltyConfig().isLoyaltyApiEnabled;
const getIsLoyaltyProgressBarEnabled = () => getLoyaltyConfig().isLoyaltyProgressBarEnabled;
const getLoyaltyLevels = () => getLoyaltyConfig().loyaltyLevels;
const getIsAutoDeliveryEnabled = () => getBaseConfig().isAutoDeliveryEnabled;
const getIsTradeInEnabled = (deviceType) => {
    const tradeInDeviceTypes = getBaseConfig().tradeInDeviceTypes || [];

    return tradeInDeviceTypes.some((x) => x.deviceType === deviceType);
};
const getIsRestrictedMarket = () => false;
const getIsWarningIconEnabled = () => true;

const getAdConfig = (deviceType, step) => {
    const adConfigs = getConfig().adConfigs;
    const adConfig = adConfigs?.find((x) => x.device.deviceType === deviceType);

    if (!adConfig) return [];

    if (!step) return adConfig;

    return adConfig[step] || [];
};

const getSupportExternalLink = () => getExternalLinksConfig().supportExternalLink;
const getIqosStoreExternalLink = () => getExternalLinksConfig().iqosStoreExternalLink;

const getIqosAppsLandingPageLink = () => getExternalLinksConfig().iqosAppsLandingPageLink;

const getConsumerProfileExternalLink = () => getExternalLinksConfig().consumerProfileExternalLink;
const getMarketWebSiteExternalLink = () => getExternalLinksConfig().marketWebSiteExternalLink;
const getShopDevicesExternalLink = () => getExternalLinksConfig().shopDevicesExternalLink;
const getShopAccessoriesExternalLink = () => getExternalLinksConfig().shopAccessoriesExternalLink;
const getTradeInExternalLink = () => getExternalLinksConfig().tradeInExternalLink;

const getContactUs = () => getConfig().contactUs;
const getEnableNotificationPageHeaderImageMedia = () => getMediaConfig().enableNotificationPageHeaderImageMedia;
const getEnableNotificationPageHeaderVideoSrc = () => getMediaConfig().enableNotificationPageHeaderVideoSrc;
const getEnableNotificationPageHeaderVideoThumbnailSrc = () =>
    getMediaConfig().enableNotificationPageHeaderVideoThumbnailSrc;
const getEnableNotificationPageIcons = () =>
    arrayUtils.sortArrayWithOrderField(getMediaConfig().enableNotificationPageIcons);

const getTopBanners = () => getBannersConfig().topBanners;

const getIsLoyaltyButtonsBarEnabled = () => getLoyaltyConfig().isLoyaltyButtonsBarEnabled;
const getLoyaltyButtonsBar = () => getLoyaltyConfig().loyaltyButtonsBar;

const getAutoDeliveryDashboardInactiveBenefitsIcons = () =>
    arrayUtils.sortArrayWithOrderField(getMediaConfig().autoDeliveryDashboardInactiveBenefitsIcons);
const getAutoDeliveryStatusInactiveBenefitsIcons = () =>
    arrayUtils.sortArrayWithOrderField(getMediaConfig().autoDeliveryStatusInactiveBenefitsIcons);
const getAutoDeliveryStatusInactiveHowItWorksIcons = () =>
    arrayUtils.sortArrayWithOrderField(getMediaConfig().autoDeliveryStatusInactiveHowItWorksIcons);
const getAutoDeliveryStatusNoneBenefitsIcons = () =>
    arrayUtils.sortArrayWithOrderField(getMediaConfig().autoDeliveryStatusNoneBenefitsIcons);
const getAutoDeliveryStatusNoneHowItWorksIcons = () =>
    arrayUtils.sortArrayWithOrderField(getMediaConfig().autoDeliveryStatusNoneHowItWorksIcons);
const getAutoDeliveryDetailedBannerNone = () => getBannersConfig().autoDeliveryDetailedBannerNone;
const getAutoDeliveryDetailedBannerInactive = () => getBannersConfig().autoDeliveryDetailedBannerInactive;
const getAutoDeliveryOnHoldPayFailedExternalLink = () =>
    getExternalLinksConfig().autoDeliveryOnHoldPayFailedExternalLink;
const getAutoDeliveryOnHoldOutOfStockExternalLink = () =>
    getExternalLinksConfig().autoDeliveryOnHoldOutOfStockExternalLink;
const getAutoDeliveryOnHoldDeliveryFailExternalLink = () =>
    getExternalLinksConfig().autoDeliveryOnHoldDeliveryFailExternalLink;
const getAutoDeliveryOnHoldNoneExternalLink = () => getExternalLinksConfig().autoDeliveryOnHoldNoneExternalLink;
const getAutoDeliveryInactiveExternalLink = () => getExternalLinksConfig().autoDeliveryInactiveExternalLink;
const getAutoDeliveryPaymentPendingExternalLink = () => getExternalLinksConfig().autoDeliveryPaymentPendingExternalLink;
const getAutoDeliveryEditOrderExternalLink = () => getExternalLinksConfig().autoDeliveryEditOrderExternalLink;
const getAutoDeliveryEditAddressExternalLink = () => getExternalLinksConfig().autoDeliveryEditAddressExternalLink;
const getAutoDeliveryOrderSummaryExternalLink = () => getExternalLinksConfig().autoDeliveryOrderSummaryExternalLink;
const getAutoDeliveryTncExternalLink = () => getExternalLinksConfig().autoDeliveryTncExternalLink;
const getAutoDeliveryAsapRescheduleExternalLink = () => getExternalLinksConfig().autoDeliveryAsapRescheduleExternalLink;
const getAutoDeliverySkipGoToWebsiteExternalLink = () =>
    getExternalLinksConfig().autoDeliverySkipGoToWebsiteExternalLink;

const getAmplitudeApiKey = () => getConfig().amplitudeKey;

const getGetStartedPage = () => getPagesConfig().getStarted;
const getDynamicPages = () => getPagesConfig().dynamicPages;

const getLogoImageMedia = () => getMediaConfig().logoImageMedia;
const getLeviaLearnMorePopupHeaderIcon = () => getMediaConfig().leviaLearnMorePopupHeaderIcon;
const getLeviaLearnMorePopupIcons = () => arrayUtils.sortArrayWithOrderField(getMediaConfig().leviaLearnMorePopupIcons);

const getFirmwareAvailablePopupHeaderIcon = () => getMediaConfig().firmwareAvailablePopupHeaderIcon;

const getHighlights = () => getBannersConfig().highlights;
const getHighlightsDesktop = () => getBannersConfig().highlightsDesktop;
const getHighlightsAutoplay = () => getBannersConfig().highlightsAutoplay;
const getHighlightsDesktopAutoplay = () => getBannersConfig().highlightsDesktopAutoplay;

const getMaintenancePopupIcon = () => getMediaConfig().maintenancePopupIcon;

const getYourDevicesPromotionList = () => getConfig().yourDevicesPromotionList || [];
const isYourDevicesSectionEnabled = () => getConfig().isShopYourDevicesSectionEnabled;

const getYourUsualBanners = () => getBannersConfig().yourUsualBanners;

const getShopByProductSection = () => getBannersConfig().shopByProductSection;
const getSetUpYourIqosImageMedia = () => getMediaConfig().setUpYourIqosImageMedia;

const getQuickOrderBanner = () => getBannersConfig().quickOrderBanner;
const getQuickOrderBenefitsIcons = () => arrayUtils.sortArrayWithOrderField(getMediaConfig().quickOrderBenefitsIcons);
const getQuickOrderHowItWorksIcons = () =>
    arrayUtils.sortArrayWithOrderField(getMediaConfig().quickOrderHowItWorksIcons);

const getGoogleTagManagerId = () => getConfig().googleTagManagerId;

const getIsGetStartedLanguageSelectEnabled = () => getPagesConfig().isGetStartedLanguageSelectEnabled;

export default {
    getIsDebug,
    getLogLevel,
    getAwsRegion,
    getAwsLogLevel,
    getAuthLogsUrl,

    getMarketLanguages,

    getLoginEmailRegex,
    getLoginPasswordRegex,
    getRegisterLinkCode,
    getForgotPasswordLinkCode,
    getLearnMoreUrlFormatted,
    getSupportExternalLink,
    getIqosStoreExternalLink,
    getIqosAppsLandingPageLink,

    getIccApiHeader_a,
    getIccApiUrl,
    getIccNotificationApiUrl,
    getIccApiHeaderAuthorization,
    getIccMarketName,
    getIccAnonymousToken,
    getIsIccRefreshTokenEnabled,
    getIccTermsOfUseLegalPosition,
    getIccAttributionNoticeLegalPosition,
    getIccPrivacyNoticeLegalPosition,

    getEnvironment,
    getAppVersion,

    getDeviceSupport,
    getDeviceSettings,
    getAssetsPath,
    getContentPath,
    getCombinedPathAssetPath,
    getIsWarningIconEnabled,

    getIsRestrictedMarket,
    getGreetings,
    getIsQureMarket,
    getIsUnregistrationEnabled,
    getIsLoyaltyEnabled,
    getIsWelcomeScreenEnabled,
    getIsLoyaltyApiEnabled,
    getIsLoyaltyProgressBarEnabled,
    getLoyaltyLevels,
    getIsLoyaltyButtonsBarEnabled,
    getLoyaltyButtonsBar,
    getIsAutoDeliveryEnabled,
    getIsTradeInEnabled,
    getRtlLanguageCodes,
    getHomePageForYouSection,
    getHomePageAutoDeliveryBanner,
    getLeviaCheckCompatibilityExternalLink,
    getLeviaLearnMoreExternalLink,
    getFirmwareAvailableCheckCompatibilityExternalLink,
    getFirmwareAvailableLearnMoreExternalLink,
    getWarningBanners,
    getAdConfig,
    getConsumerProfileExternalLink,
    getMarketWebSiteExternalLink,
    getShopDevicesExternalLink,
    getShopAccessoriesExternalLink,
    getTradeInExternalLink,
    getContactUs,
    getEnableNotificationPageHeaderImageMedia,
    getEnableNotificationPageHeaderVideoSrc,
    getEnableNotificationPageHeaderVideoThumbnailSrc,
    getEnableNotificationPageIcons,

    getTopBanners,

    getYourUsualBanners,

    getAutoDeliveryDashboardInactiveBenefitsIcons,
    getAutoDeliveryStatusInactiveBenefitsIcons,
    getAutoDeliveryStatusInactiveHowItWorksIcons,
    getAutoDeliveryStatusNoneBenefitsIcons,
    getAutoDeliveryStatusNoneHowItWorksIcons,
    getAutoDeliveryDetailedBannerNone,
    getAutoDeliveryDetailedBannerInactive,
    getAutoDeliveryOnHoldPayFailedExternalLink,
    getAutoDeliveryOnHoldOutOfStockExternalLink,
    getAutoDeliveryOnHoldDeliveryFailExternalLink,
    getAutoDeliveryOnHoldNoneExternalLink,
    getAutoDeliveryInactiveExternalLink,
    getAutoDeliveryPaymentPendingExternalLink,
    getAutoDeliveryEditOrderExternalLink,
    getAutoDeliveryEditAddressExternalLink,
    getAutoDeliveryOrderSummaryExternalLink,
    getAutoDeliveryTncExternalLink,
    getAutoDeliveryAsapRescheduleExternalLink,
    getAutoDeliverySkipGoToWebsiteExternalLink,

    getGetStartedPage,
    getDynamicPages,

    getAmplitudeApiKey,
    getLogoImageMedia,
    getHighlights,
    getHighlightsDesktop,
    getHighlightsAutoplay,
    getHighlightsDesktopAutoplay,
    getMaintenancePopupIcon,
    getLeviaLearnMorePopupHeaderIcon,
    getLeviaLearnMorePopupIcons,
    getFirmwareAvailablePopupHeaderIcon,
    getYourDevicesPromotionList,
    isYourDevicesSectionEnabled,
    getShopByProductSection,
    getSetUpYourIqosImageMedia,

    getQuickOrderBanner,
    getQuickOrderBenefitsIcons,
    getQuickOrderHowItWorksIcons,

    getGoogleTagManagerId,

    getIsGetStartedLanguageSelectEnabled,
};
