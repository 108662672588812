import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {MY_DEVICES_PAGE_SETTING_BUTTON_WITH_STATE_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import {getAriaLabel, getFormattedAriaLabel} from '@/services/accessibilityService';

const WithSettingsGroupButtonAriaLabel = ({ariaLabelKey, subItemName, renderContent}) => {
    const hasSubItem = !!subItemName;

    const getButtonAriaLabel = (stateText = undefined) => {
        const labelWithoutState = getAriaLabel(ariaLabelKey);

        if (labelWithoutState && stateText) {
            const labelWithState = getFormattedAriaLabel(
                MY_DEVICES_PAGE_SETTING_BUTTON_WITH_STATE_ARIA_LABEL,
                labelWithoutState,
                stateText
            );

            return labelWithState;
        }

        return labelWithoutState;
    };

    return hasSubItem ? (
        <AemEditableText
            pageName={routePaths.DEVICES_PAGE}
            itemName={subItemName}
            render={(text) => renderContent(getButtonAriaLabel(text))}
        />
    ) : (
        renderContent()
    );
};

export default WithSettingsGroupButtonAriaLabel;
