import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import appConfig from '@/config/appConfig';
import {CONSUMER_PROFILE_SHOW_PROFILE_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeProfileTracking from '@/services/analytics/amplitude/amplitudeProfileTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';

const ConsumerProfileHeader = ({userName}) => {
    const isMobile = useIsMobileLayout();
    const consumerProfileExternalLink = appConfig.getConsumerProfileExternalLink();
    const redirectToExternalLink = useConfigExternalLink(consumerProfileExternalLink);
    const UsernameComponent = isMobile ? 'h1' : 'p';

    const onClick = () => {
        amplitudeProfileTracking.trackShowProfileClick();
        googleAnalyticsService.trackShowProfileLinkClick();
        redirectToExternalLink();
    };

    return (
        <article className='w-full ml:mt-8 ml:px-4'>
            {userName && (
                <UsernameComponent className='mb-0 break-words text-32 font-bold leading-14 ml:max-w-[233px] ml:text-left ml:leading-[40px]'>
                    {userName}
                </UsernameComponent>
            )}
            <AppButton
                className='pt-2 tracking-05 ml:mt-2 ml:w-[153px] ml:rounded-none ml:p-0 ml:text-left ml:tracking-0'
                tertiary={isMobile}
                isExternalLink
                onClick={onClick}
                aria-label={getAriaLabel(CONSUMER_PROFILE_SHOW_PROFILE_BUTTON_ARIA_LABEL)}
            >
                <AemEditableText pageName={routePaths.CONSUMER_PROFILE} itemName='showProfileButton' />
            </AppButton>
        </article>
    );
};

export default ConsumerProfileHeader;
