import cn from 'classnames';
import React from 'react';

import ButtonRaw from '@/components/Button/ButtonRaw';
import {ArrowIcon} from '@/components/Icons';

import styles from './CarouselArrow.module.scss';

const CarouselArrow = (props) => {
    const {onClick, customClassName, testId, 'aria-label': ariaLabel, isVisible} = props;

    if (!isVisible) return null;

    return (
        <ButtonRaw
            aria-label={ariaLabel}
            testId={testId}
            onClick={onClick}
            className={cn(styles.Arrow, customClassName)}
        >
            <ArrowIcon />
        </ButtonRaw>
    );
};

export default CarouselArrow;
