import React from 'react';

import appConfig from '@/config/appConfig';
import {twx} from '@/utils/tailwindUtils';

import HealthWarning from './components/HealthWarning';
import HealthWarningAlternativeBottom from './components/HealthWarningAlternativeBottom';
import HealthWarningExtended from './components/HealthWarningExtended';
import WeCare from './components/WeCare';
import {WARNING_BANNERS_TYPES} from './hwConsts';

const getBannerComponent = (type) => {
    switch (type) {
        case WARNING_BANNERS_TYPES.HEALTH_WARNING:
            return HealthWarning;
        case WARNING_BANNERS_TYPES.WE_CARE:
            return WeCare;
        case WARNING_BANNERS_TYPES.HEALTH_WARNING_EXTENDED:
            return HealthWarningExtended;
        case WARNING_BANNERS_TYPES.HEALTH_WARNING_ALTERNATIVE_BOTTOM:
            return HealthWarningAlternativeBottom;
        default:
            return null;
    }
};

const getWarningBannersBySource = (source) => {
    const warningBanners = appConfig.getWarningBanners();
    const result = warningBanners?.filter(
        (b) =>
            b.type !== WARNING_BANNERS_TYPES.HEALTH_WARNING_ALTERNATIVE_TOP && b.pages?.some((page) => page === source)
    );

    return result;
};

const WarningBanners = ({source, containerClassName, className, contentClassName}) => {
    const warningBanners = getWarningBannersBySource(source);

    if (!warningBanners?.length) return;

    return (
        <div className={twx('flex w-full flex-col gap-8', containerClassName)}>
            {warningBanners.map(({type, ...rest}, index) => {
                const Component = getBannerComponent(type);

                if (!Component) return null;

                const isPreviousBannerWeCare =
                    index > 0 ? warningBanners[index - 1].type === WARNING_BANNERS_TYPES.WE_CARE : false;
                const isSeparatorVisible = index > 0 && !isPreviousBannerWeCare;

                return (
                    <Component
                        key={index}
                        className={className}
                        contentClassName={contentClassName}
                        isSeparatorVisible={isSeparatorVisible}
                        {...rest}
                    />
                );
            })}
        </div>
    );
};

export default WarningBanners;
