import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import BackButton from '@/components/BackButton/BackButton';
import {ButtonRaw} from '@/components/Button';
import domIdentifiers from '@/consts/app/domIdentifiers';
import routePaths from '@/consts/route/routePaths';
import {getAriaLabel} from '@/services/accessibilityService';
import {twx} from '@/utils/tailwindUtils';

const SidebarPageLayout = ({children}) => {
    return <div className='flex max-h-screen w-full'>{children}</div>;
};

const SidebarPageSidebar = ({children, hasBackButton}) => {
    return (
        <div className='flex max-h-screen w-[var(--profile-sidebar-width)] flex-col items-start overflow-y-auto bg-secondary p-8'>
            {hasBackButton && <BackButton />}
            {children}
        </div>
    );
};

const SidebarPageContent = ({children, className}) => {
    return (
        <div id={domIdentifiers.PROFILE_SIDEBAR_CONTENT} className={twx('flex-1 overflow-auto bg-romance', className)}>
            {children}
        </div>
    );
};

const SidebarMenuSection = ({headerItemName, className, items, activeItemKey, hasShorterGapBetweenItems}) => {
    return (
        <article className={twx('mt-8 w-full text-left', className)}>
            <SidebarMenuSectionHeader itemName={headerItemName} />
            <ul className={twx('mt-4 flex flex-col gap-4', hasShorterGapBetweenItems && 'gap-2')}>
                {items.map((item, index) => {
                    return <SidebarMenuSectionListItem key={index} activeItemKey={activeItemKey} {...item} />;
                })}
            </ul>
        </article>
    );
};

const SidebarMenuSectionHeader = ({itemName}) => {
    return (
        <h2 className='mb-0 line-clamp-2 px-4 text-16'>
            <AemEditableText pageName={routePaths.CONSUMER_PROFILE} itemName={itemName} />
        </h2>
    );
};

const SidebarMenuSectionListItem = ({icon, itemName, ariaLabelKey, onClick, itemKey, activeItemKey, disabled}) => {
    const isActive = itemKey && activeItemKey === itemKey;

    return (
        <li>
            <ButtonRaw
                className={twx('flex w-full gap-3 rounded-full px-4 py-3.5', {
                    'bg-tertiary-30': isActive,
                    'opacity-40': disabled,
                    'py-2': icon,
                })}
                renderAs='a'
                aria-label={getAriaLabel(ariaLabelKey)}
                aria-current={isActive ? 'page' : undefined}
                onClick={onClick}
                disabled={disabled}
            >
                {icon && icon}
                <span className='line-clamp-2 flex-1 break-words text-14 leading-[20px]'>
                    <AemEditableText pageName={routePaths.CONSUMER_PROFILE} itemName={itemName} />
                </span>
            </ButtonRaw>
        </li>
    );
};

SidebarPageLayout.Sidebar = SidebarPageSidebar;
SidebarPageLayout.SidebarMenuSection = SidebarMenuSection;
SidebarPageLayout.Content = SidebarPageContent;

export default SidebarPageLayout;
