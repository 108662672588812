import React from 'react';

import {PopoverClose} from '@/components/Popover/Popover';
import routePaths from '@/consts/route/routePaths';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';

const InboxPopoverMainTitle = () => {
    const isMobile = useIsMobileLayout();
    const Heading = isMobile ? 'h2' : 'h1';

    return (
        <>
            <Heading className='mb-0 text-24 leading-1'>
                <AemEditableText pageName={routePaths.HOME_PAGE} itemName='notificationsinboxtitle' />
            </Heading>
            <PopoverClose />
        </>
    );
};

export default InboxPopoverMainTitle;
