import cn from 'classnames';
import React from 'react';

import useGreeting from '@/views/Greeting/useGreeting';

const GreetingAsSeparateSection = ({className}) => {
    const {title, description} = useGreeting();

    return (
        <article
            className={cn(
                'pt-safe-offset-sticky-header max-w-2xl bg-romance px-4 pb-4 md:bg-transparent md:px-8 ml:pt-safe-offset-24 lg:px-16 xl:mx-auto xl:box-content',
                className
            )}
        >
            <h1 className='mb-2 text-32 leading-125 md:text-28 md:leading-128 lg:text-36 lg:leading-12'>{title}</h1>
            <p className='text-16 leading-15 lg:text-18 lg:leading-155 lg:tracking-02'>{description}</p>
        </article>
    );
};

export default GreetingAsSeparateSection;
