import * as httpErrorsTypes from '@/consts/app/httpErrorsTypes';
import iccService from '@/services/icc/iccService';
import {makeSelectUamData} from '@/state/selectors/uam';
import {getState} from '@/state/store';
import arrayUtils from '@/utils/arrayUtils';
import urlUtils from '@/utils/urlUtils';

import UamBaseClient from './uamBaseClient';

export default class UamClient extends UamBaseClient {
    getHeaders = async (codentify, refreshedAuthToken) => {
        const state = getState();
        const {uamApiKey, uamAuthToken} = makeSelectUamData(codentify)(state);
        let idToken = uamAuthToken;

        if (!refreshedAuthToken && !idToken) {
            const {uamAuthToken} = await iccService.fetchUamToken({codentify});

            if (!uamAuthToken) return;

            idToken = uamAuthToken;
        }

        const headers = {
            Accept: 'application/json',
            id_token: refreshedAuthToken || idToken,
        };

        if (uamApiKey) {
            headers['X-API-Key'] = uamApiKey;
        }

        return headers;
    };

    errorCheck = async ({error, onSuccess, ignoreErrorCodes = [], codentify}) => {
        const status = error?.response?.status;

        ignoreErrorCodes = arrayUtils.toArray(ignoreErrorCodes);
        if (ignoreErrorCodes.includes(status)) return null;

        switch (status) {
            case httpErrorsTypes.NOT_AUTHORIZED: {
                if (!this.isTokenRefreshInProgress(codentify)) {
                    this.setTokenRefreshInProgress(codentify, true);

                    try {
                        const {uamAuthToken} = await iccService.fetchUamToken({
                            isRetry: false,
                            codentify,
                        });

                        this.setTokenRefreshInProgress(codentify, false);
                        return onSuccess ? onSuccess({refreshedAuthToken: uamAuthToken}) : null;
                    } catch (e) {
                        this.setTokenRefreshInProgress(codentify, false);
                    }
                }
                break;
            }
            default:
                return null;
        }
    };

    getRequestParams = async ({headers, params, data, codentify, refreshedAuthToken}) => {
        const newHeaders = await this.getHeaders(codentify, refreshedAuthToken);

        if (!newHeaders) return;

        if (refreshedAuthToken) {
            newHeaders.id_token = refreshedAuthToken;
        }

        headers = {...headers, ...newHeaders};
        return {headers, params, data};
    };

    getApiUrl = (methodName, codentify) => {
        const {uamApiUrl} = makeSelectUamData(codentify)(getState());

        if (!uamApiUrl) {
            throw new Error('No Uam Api Url is provided');
        }

        return urlUtils.join(uamApiUrl, methodName);
    };
}
