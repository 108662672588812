import * as httpErrorsTypes from '@/consts/app/httpErrorsTypes';

export const REGISTERED_PRODUCT_LIMIT = {
    errorCode: httpErrorsTypes.BAD_REQUEST,
    errorMessage: 'You have reached max number of registered products',
};
export const TOO_MANY_REGISTRATION_REQUESTS = {
    errorCode: httpErrorsTypes.TOO_MANY_REQUESTS,
    errorMessage: 'Too Many Requests',
};
export const INVALID_TOKEN = {
    errorCode: httpErrorsTypes.BAD_REQUEST,
    errorMessage: 'Invalid Token',
};

export const REGISTRATION_FAILED_ERRORS_LIST = [REGISTERED_PRODUCT_LIMIT, TOO_MANY_REGISTRATION_REQUESTS];

export const isRegistrationFailedIccHttpError = (error) => {
    return REGISTRATION_FAILED_ERRORS_LIST.some(({errorCode}) => errorCode === error);
};

export const getRegistrationFailedError = (error) => {
    return REGISTRATION_FAILED_ERRORS_LIST.find(({errorCode}) => errorCode === error);
};

export const isInvalidToken = (e) => {
    const errorStatus = e?.response?.status;
    const errorMessage = e?.response?.data?.serviceError?.message?.value;

    return INVALID_TOKEN.errorCode === errorStatus && INVALID_TOKEN.errorMessage === errorMessage;
};
