import cn from 'classnames';
import React from 'react';

import dateTimeUtils from '@/utils/dateTimeUtils';

import {ButtonRaw} from '../../../../components/Button';
import AppLoaderContent from '../../../../components/Loader/AppLoader/AppLoaderContent';
import InboxNotificationItem from './InboxNotificationItem/InboxNotificationItem';

const InboxNotificationsList = ({notifications, sentryRef, hasNextPage, ...rest}) => {
    return (
        <ul>
            {notifications?.map((notification) => (
                <NotificationListItem key={notification.messageId} notification={notification} {...rest} />
            ))}
            {hasNextPage && (
                <li ref={sentryRef} className={cn(notifications === undefined ? 'mt-72 ml:mt-40' : 'mt-5')}>
                    <AppLoaderContent className='flex justify-center' />
                </li>
            )}
        </ul>
    );
};

export default InboxNotificationsList;

const NotificationListItem = ({notification, onMessageClick, ...rest}) => {
    const {messageId, title, subTitle, shortText, sendTs} = notification;
    const timeAgo = dateTimeUtils.getTimeAgo(sendTs, rest);
    const ariaLabel = `${subTitle}, ${timeAgo}, ${title}, ${shortText}`;

    const onClick = () => {
        onMessageClick(messageId);
    };

    return (
        <li>
            <ButtonRaw className='w-full' aria-label={ariaLabel} onClick={onClick} id={messageId}>
                <InboxNotificationItem {...notification} timeAgo={timeAgo} />
            </ButtonRaw>
            <hr className='h-px bg-mischka' aria-hidden='true' />
        </li>
    );
};
