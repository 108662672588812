import React from 'react';
import Slider from 'react-slick';

import {twx} from '@/utils/tailwindUtils';

import HighlightsCarouselContainer from '../../common/components/HighlightsCarouselContainer';
import HighlightsSlide from '../../common/components/HighlightsSlide/HighlightsSlide';
import useHighlightsVideoBuffering from '../../common/hooks/useHighlightsVideoBuffering';
import useHighlightsCarousel from '../hooks/useHighlightsCarousel';
import styles from '../MobileHighlights.module.scss';

const HighlightsCarousel = ({
    initialSlide,
    onSlideClick,
    isFullscreen,
    isInfinite,
    highlights,
    autoplay,
    onSlideChange,
    currentIndex,
    isPlaying = true,
    onCarouselEnded,
    className,
}) => {
    const {getIsVideoBuffering, handleVideoWaiting, handleVideoCanPlay} = useHighlightsVideoBuffering(highlights);
    const {
        sliderRef,
        options,
        getIsActiveSlide,
        setVideoRef,
        handleLoadedMetadata,
        handleVideoError,
        handleSlideEnded,
    } = useHighlightsCarousel({
        isFullscreen,
        isPlaying,
        isInfinite,
        autoplay,
        initialSlide,
        currentIndex,
        highlights,
        getIsVideoBuffering,
        onSlideChange,
        onCarouselEnded,
    });

    return (
        <HighlightsCarouselContainer
            className={twx(
                styles.Carousel,
                {
                    [styles.CarouselPreview]: !isFullscreen,
                    'rounded-none': isFullscreen,
                    [styles.ProgressAnimation]: autoplay,
                },
                className
            )}
        >
            <Slider {...options} ref={sliderRef}>
                {highlights.map((highlight, i) => {
                    return (
                        <HighlightsSlide
                            key={i}
                            onClick={onSlideClick}
                            videoRef={(ref) => setVideoRef(i, ref)}
                            loop={!autoplay}
                            isFullscreen={isFullscreen}
                            isPaused={!isPlaying}
                            isActiveSlide={getIsActiveSlide(i)}
                            onEnded={handleSlideEnded}
                            onVideoError={() => handleVideoError(i)}
                            onLoadedMetadata={(metadata) => handleLoadedMetadata(metadata, i)}
                            onVideoWaiting={() => handleVideoWaiting(i)}
                            onVideoCanPlay={() => handleVideoCanPlay(i)}
                            {...highlight}
                        />
                    );
                })}
            </Slider>
        </HighlightsCarouselContainer>
    );
};

export default HighlightsCarousel;
