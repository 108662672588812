import {amplitude} from '@/services/analytics/amplitude';
import appErrorService from '@/services/app/appErrorService';
import iccMarketService from '@/services/icc/iccMarketService';
import appRouterService from '@/services/route/appRouterService';
import {selectGigyaToken} from '@/state/selectors/auth';
import {selectAggregatedSettings, selectIsAmplitudeEnabled} from '@/state/selectors/consumerAggregatedSettings';
import {hideLoader, showLoader} from '@/state/slices/loadersSlice';
import {dispatch, getState} from '@/state/store';
import helpers from '@/utils/helpers';

const acceptTnc = (tncList) => iccMarketService.setTnc({data: tncList});
const declineTnc = (tncList) => {
    const gigyaToken = selectGigyaToken(getState());

    return iccMarketService.declineTnc({data: tncList, gigyaToken});
};

const PATTERN_LINK = '@link';
const PATTERN_WEB_LINK = '@weblink';
const PATTERN_TNC_CODE = '@termsCode';
const PATTERN_CHANGE_LIST = '@changelist';
const PATTERN_TEXT = '@TEXT';
const ATTRIBUTE_DATA_TNC_CODE = 'data-tnc-code';

const getTncData = async (onTncLoaded, onError, {disableLoader} = {}) => {
    try {
        if (!disableLoader) {
            dispatch(showLoader());
        }

        await iccMarketService.getAcceptedTnc();
        await iccMarketService.getTnc();

        helpers.runFunction(onTncLoaded);
    } catch (e) {
        helpers.runFunction(onError);
    } finally {
        if (!disableLoader) {
            dispatch(hideLoader());
        }
    }
};

const tncDataMapper = ({code, versionNumber}) => ({code, versionNumber});

const setTnc = async (legals) => {
    try {
        const acceptedTncData = legals.filter((l) => !l.isInformational).map(tncDataMapper);

        await acceptTnc(acceptedTncData);

        const settings = await iccMarketService.getConsumerAggregatedSettings();

        if (settings.allMandatoryTermsApproved) {
            if (settings.allNonMandatoryTermsReviewed) {
                appRouterService.forwardToHomePageIfNeed();
            } else {
                //auto redirect must be called inside the tnc component
                //appRouterService.forwardToTNCDataCollectionPage();
            }
        }
    } catch (e) {
        appErrorService.showGlobalError();
    }
};

const setDataCollectionTnc = async (legals, isAccepted) => {
    try {
        const tncData = legals.map(tncDataMapper);

        if (isAccepted) {
            await acceptTnc(tncData);
        } else {
            await declineTnc(tncData);
        }

        const settings = await iccMarketService.getConsumerAggregatedSettings();

        if (settings.allNonMandatoryTermsReviewed) {
            appRouterService.forwardToHomePageIfNeed();
        }
    } catch (e) {
        appErrorService.showGlobalError();
    }
};

const isAggregatedSettingEnabled = (type) => {
    const aggregatedSettings = selectAggregatedSettings(getState());

    if (!aggregatedSettings) return false;

    const {consumerAggregatedSettings} = aggregatedSettings;

    if (consumerAggregatedSettings?.length) {
        return consumerAggregatedSettings.some((item) => item.key.toLowerCase() === type.toLowerCase() && item.value);
    }

    return false;
};

const getTncCodeFromTarget = (target, className) => {
    const tncLinkElement = target.closest(`.${CSS.escape(className)}`);

    return tncLinkElement?.getAttribute(ATTRIBUTE_DATA_TNC_CODE);
};

const getStringsBetweenPattern = (str, pattern) => str.match(new RegExp(pattern + '(.*?)' + pattern, 'ig'));
const getStringBetweenPattern = (str, pattern) => {
    const match = str.match(new RegExp(pattern + '(.*?)' + pattern, 'i'));

    if (match) {
        return {
            wholeText: match[0],
            capturedText: match[1],
        };
    }
    return {};
};

const getParsedDescription = (stringFull, className, legals, tncLegals) => {
    if (!stringFull) return '';

    const stringParsed = convertPatternsToString(stringFull, className, legals);

    return getChangeListDescription(stringParsed, className, legals, tncLegals);
};

const convertPatternsToString = (stringFull, className, legals) => {
    const getWebLinkStr = (str, wholeWebLink, capturedCode, capturedText) => {
        const legalData = getTncLegalByTermsCode(legals, capturedCode);

        if (legalData) {
            const testId = `term${capturedCode}Hyperlink`;
            const linkToReplace = `<a class="${className}" target="_blank" href="${legalData.webLink}" data-testid="${testId}">${capturedText}</a>`;

            return str.replace(wholeWebLink, linkToReplace);
        }
    };

    const getLinkStr = (str, wholeLink, capturedCode, capturedText) => {
        const testId = `term${capturedCode}Hyperlink`;
        const linkToReplace = `<button class="${className}" ${ATTRIBUTE_DATA_TNC_CODE}="${capturedCode}" data-testid="${testId}">${capturedText}</button>`;

        return str.replace(wholeLink, linkToReplace);
    };

    let stringParsed = getParsedDescriptionPart(stringFull, PATTERN_WEB_LINK, getWebLinkStr);

    if (stringParsed) {
        stringParsed = getParsedDescriptionPart(stringParsed, PATTERN_LINK, getLinkStr);
    }

    return stringParsed;
};

const getTncLegalByTermsCode = (legals, termsCode) => {
    if (!legals?.length) return null;

    const legalsSorted = [...legals].sort((a, b) => a.position - b.position);

    return termsCode ? legalsSorted.find((legal) => legal.code === termsCode) : legalsSorted[0];
};

const getChangeListDescription = (str, className, legals, tncLegals) => {
    const {wholeText} = getStringBetweenPattern(str, PATTERN_CHANGE_LIST);

    if (!wholeText) return str;

    const changeText = tncLegals.length
        ? [...tncLegals]
              .sort((a, b) => a.position - b.position)
              .filter(({isInformational, changeList}) => !isInformational && changeList)
              .map(({changeList}) => changeList)
              .join('<br>')
        : '';
    const result = str.replace(wholeText, changeText);

    return convertPatternsToString(result, className, legals);
};

const getParsedDescriptionPart = (str, pattern, afterParseFunction) => {
    const matches = getStringsBetweenPattern(str, pattern);

    if (matches?.length) {
        matches.forEach((match) => {
            const capturedWebLink = match.replace(pattern, '');
            const {capturedText: capturedCode} = getStringBetweenPattern(capturedWebLink, PATTERN_TNC_CODE);
            const {capturedText} = getStringBetweenPattern(capturedWebLink, PATTERN_TEXT);

            str = afterParseFunction(str, match, capturedCode, capturedText);
        });
    }
    return str;
};

const setPreferenceCenterSettings = async (legals, legalsSettingsNotNull, acceptedSettingList, onApproved) => {
    try {
        const legalsSettingsNull = legals.filter((l) => !l.settings && !l.isInformational).map(tncDataMapper);
        const legalsSettingsNotNullAccepted = legalsSettingsNotNull
            .filter((l) => acceptedSettingList.includes(l.code) && !l.isInformational)
            .map(tncDataMapper);
        const acceptedTncData = [...legalsSettingsNull, ...legalsSettingsNotNullAccepted];

        if (acceptedTncData.length) {
            await acceptTnc(acceptedTncData);
        }

        const declinedTncData = legalsSettingsNotNull
            .filter((l) => !acceptedSettingList.includes(l.code) && !l.isInformational)
            .map(tncDataMapper);

        if (declinedTncData.length) {
            await declineTnc(declinedTncData);
        }

        const {allNonMandatoryTermsReviewed} = await iccMarketService.getConsumerAggregatedSettings();

        checkAmplitudeSettings();
        if (allNonMandatoryTermsReviewed) {
            helpers.runFunction(onApproved);
        }
    } catch (e) {
        appErrorService.showGlobalError();
    }
};

const checkAmplitudeSettings = () => {
    const isAmplitudeEnabled = selectIsAmplitudeEnabled(getState());

    amplitude.enableAmplitude(isAmplitudeEnabled);
};

const getIntToken = () => {
    const {intToken} = selectAggregatedSettings(getState()) || {};

    return intToken;
};

const getPreferenceCenterLegals = (legals, acceptedLegals) => {
    const legalsNotNull = legals?.filter((x) => !!x.settings) || [];
    const acceptedLegalsNotNull = acceptedLegals?.filter((x) => !!x.settings) || [];
    const legalsSettingsNotNull = [...legalsNotNull, ...acceptedLegalsNotNull].sort((a, b) => a.position - b.position);
    const filteredAcceptedLegals = acceptedLegalsNotNull?.filter((x) => !x.isRevoked).map(({code}) => code) || [];

    //check all aggregated settings for default value "true"
    legalsNotNull.forEach((legalNotNull) => {
        const settingsList = legalNotNull.settings.split(',');

        if (settingsList.every(isAggregatedSettingEnabled)) {
            filteredAcceptedLegals.push(legalNotNull.code);
        }
    });

    return {
        legalsSettingsNotNull,
        filteredAcceptedLegals,
    };
};

export default {
    getIntToken,
    getParsedDescription,
    getTncCodeFromTarget,
    getTncData,
    getTncLegalByTermsCode,
    isAggregatedSettingEnabled,
    setDataCollectionTnc,
    setPreferenceCenterSettings,
    setTnc,
    getPreferenceCenterLegals,
};
