import React from 'react';
import {useSelector} from 'react-redux';

import {
    AboutDeviceRestrictedIcon,
    IlumaAboutBlueIcon,
    IlumaBlueIcon,
    OtherDeviceAboutBlueIcon,
    OtherDeviceBlueIcon,
    RenameBlueIcon,
    TroubleshootDeviceRestrictedIcon,
    TroubleshootIlumaBlueIcon,
    TroubleshootOtherDeviceBlueIcon,
    TroubleshootVeevBlueIcon,
    VeevAboutBlueIcon,
    VeevDefaultBlueIcon,
} from '@/components/Icons';
import appConfig from '@/config/appConfig';
import deviceTypesService from '@/services/device/deviceTypesService';
import {selectDeviceType} from '@/state/selectors/device';

export const MODES = {
    ABOUT: 'about',
    RENAME: 'rename',
    DIAGNOSTICS: 'diagnostics',
};

const getDeviceTypes = (deviceType) => {
    return {
        isP4: deviceTypesService.isP4(deviceType),
        isP1V4: deviceTypesService.isP1V4(deviceType),
    };
};

const DeviceIcon = ({className, mode = MODES.ABOUT}) => {
    const deviceType = useSelector(selectDeviceType);
    const isRestrictedMarket = appConfig.getIsRestrictedMarket();
    const {isP4, isP1V4} = getDeviceTypes(deviceType);

    const iconsData = {
        restricted: {
            [MODES.ABOUT]: AboutDeviceRestrictedIcon,
            [MODES.RENAME]: RenameBlueIcon,
            [MODES.DIAGNOSTICS]: TroubleshootDeviceRestrictedIcon,
        },
        p4: {
            [MODES.ABOUT]: VeevAboutBlueIcon,
            [MODES.RENAME]: VeevDefaultBlueIcon,
            [MODES.DIAGNOSTICS]: TroubleshootVeevBlueIcon,
        },
        p1v4: {
            [MODES.ABOUT]: IlumaAboutBlueIcon,
            [MODES.RENAME]: IlumaBlueIcon,
            [MODES.DIAGNOSTICS]: TroubleshootIlumaBlueIcon,
        },
        other: {
            [MODES.ABOUT]: OtherDeviceAboutBlueIcon,
            [MODES.RENAME]: OtherDeviceBlueIcon,
            [MODES.DIAGNOSTICS]: TroubleshootOtherDeviceBlueIcon,
        },
    };

    const getDeviceIcon = () => {
        if (isRestrictedMarket) {
            return iconsData.restricted[mode];
        } else if (isP4) {
            return iconsData.p4[mode];
        } else if (isP1V4) {
            return iconsData.p1v4[mode];
        } else {
            return iconsData.other[mode];
        }
    };

    const Icon = getDeviceIcon();

    return <Icon className={className} />;
};

export default DeviceIcon;
