import InfoBox from '../../../../components/InfoBox/InfoBox';
import AutoDeliveryStatusTitle from '../../../../views/AutoDelivery/AutoDeliveryStatusTitle';

const EditDeliveryPopupTopSection = ({header, title, subtitle, infoText, icon}) => {
    return (
        <div className='pb-8 md:pb-0'>
            <AutoDeliveryStatusTitle title={header} className='text-28 md:text-36' />
            <div className='mt-8 flex items-center gap-2'>
                <div className='shrink-0'>{icon}</div>
                <div>
                    <p className='text-granite-gray'>{subtitle}</p>
                    <p className='mb-0 text-20 font-bold leading-13 text-primary'>{title}</p>
                </div>
            </div>
            <InfoBox className='mt-4 tracking-02 md:bg-romance'>{infoText}</InfoBox>
        </div>
    );
};

export default EditDeliveryPopupTopSection;
