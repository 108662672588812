const getFontSizeStyles = (fontSize) => {
    if (typeof fontSize !== 'number') return {};

    return {fontSize: `${fontSize}px`};
};

const FONT_FAMILY_TYPES = {
    IQOS: 'IQOS',
    HELVETICA_NEUE: 'HELVETICA_NEUE',
    IBM_PLEX_SANS: 'IBM_PLEX_SANS',
};

const getFontFamilyClassName = (fontFamily) => {
    switch (fontFamily) {
        case FONT_FAMILY_TYPES.IQOS:
        default:
            return 'font-iqos';
        case FONT_FAMILY_TYPES.IBM_PLEX_SANS:
            return 'font-ibm-plex';
        case FONT_FAMILY_TYPES.HELVETICA_NEUE:
            return 'font-helvetica';
    }
};

export default {
    getFontSizeStyles,
    getFontFamilyClassName,
};
