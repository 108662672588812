import React from 'react';

import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';

import AppImage from '../../../../components/Image/AppImage';

const FeatureItem = (props) => {
    const {subTitle, description, icon, heading: Heading} = props;

    return (
        <div className='flex min-h-18 gap-4'>
            {icon && <AppImage className='h-auto w-12 shrink-0 self-start' src={icon} />}
            <div className='flex flex-col'>
                <Heading className='mb-0 text-16 font-bold leading-15'>{subTitle}</Heading>
                <div>{description}</div>
            </div>
        </div>
    );
};

const NotificationDetailsNewFeatures = ({newFeatures, newFeaturesTitle}) => {
    const isMobile = useIsMobileLayout();
    const isTitleVisible = !!newFeaturesTitle;
    const Heading = isMobile ? 'h2' : 'h3';

    const getSubHeading = () => {
        let level = isMobile ? 3 : 4;

        if (!isTitleVisible) {
            level--;
        }

        return `h${level}`;
    };

    return (
        <div className='my-8 gap-4 ml:my-4'>
            {isTitleVisible && <Heading className='text-[1.17em] leading-15'>{newFeaturesTitle}</Heading>}
            <div className='my-8 flex flex-col gap-4 ml:my-4'>
                {newFeatures.map((feature, index) => {
                    return <FeatureItem {...feature} key={index} heading={getSubHeading()} />;
                })}
            </div>
        </div>
    );
};

export default NotificationDetailsNewFeatures;
