import cn from 'classnames';
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import Slider from 'react-slick';

import {
    CAROUSEL_NAVIGATION_CURRENT_SLIDE_ARIA_LABEL,
    CAROUSEL_NAVIGATION_SLIDE_ARIA_LABEL,
    NEXT_BUTTON_ARIA_LABEL,
    PREVIOUS_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import {getAriaLabel, getFormattedAriaLabel} from '@/services/accessibilityService';
import domService from '@/services/domService';
import log from '@/services/logger/log';
import helpers from '@/utils/helpers';

import styles from './Carousel.module.scss';
import CarouselArrow from './CarouselArrow/CarouselArrow';
import arrowStyles from './CarouselArrow/CarouselArrow.module.scss';

const afterChange = () => {
    try {
        const carousels = document.getElementsByClassName('slick-track');

        for (const carousel of carousels) {
            const sliders = carousel.querySelectorAll('.slick-slide');

            for (const slider of sliders) {
                const isSliderVisible = slider.getAttribute('aria-hidden') !== 'true';
                const buttons = slider.querySelectorAll('button, a'); // The active slide buttons are rendered as anchor

                for (const button of buttons) {
                    const requiredTabIndex = isSliderVisible ? '0' : '-1';
                    const buttonTabIndex = button.getAttribute('tabindex');

                    if (buttonTabIndex !== requiredTabIndex) {
                        button.setAttribute('tabindex', requiredTabIndex);
                    }
                }
            }
        }
    } catch (e) {
        log.debug(`Carousel: afterChange error: ${e}`);
    }
};

const Carousel = forwardRef((props, ref) => {
    const {
        arrowNextTestId,
        arrowPrevTestId,
        controlArrowClassName,
        controlArrowLeftClassName,
        controlArrowRightClassName,
        dotsClassName,
        dotsTestId,
        focusOnSelect,
        isAccessibilityEnabled = true,
        isAdaptiveHeight,
        isControlArrowVisible,
        isDotsFocusEnabled = true,
        isDotsVisible = true,
        isDraggable = true,
        isInfinite = false,
        isSwipeEnabled = true,
        onTransitionEnd,
        onAfterChange,
        options,
        children,
    } = props;
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const itemsCount = children?.length;
    const isFirstSlide = currentSlideIndex === 0;
    const isLastSlide = currentSlideIndex === itemsCount - 1;

    const sliderRef = useRef(null);

    useEffect(() => {
        if (sliderRef.current) {
            (() => onSliderInit())();
        }
    }, [sliderRef.current]);

    const onSlide = (index) => {
        setCurrentSlideIndex(index);
        helpers.runFunction(onTransitionEnd, index);
    };

    const getCustomPaging = (i) => {
        const isCurrent = i === currentSlideIndex;
        const currentDotNumber = i + 1;
        let ariaLabel = getFormattedAriaLabel(CAROUSEL_NAVIGATION_SLIDE_ARIA_LABEL, currentDotNumber, itemsCount);

        if (isCurrent) {
            ariaLabel = getFormattedAriaLabel(CAROUSEL_NAVIGATION_CURRENT_SLIDE_ARIA_LABEL, ariaLabel);
        }

        return (
            <button tabIndex={isDotsFocusEnabled ? 0 : -1} aria-label={ariaLabel}>
                {currentDotNumber}
            </button>
        );
    };

    const onSliderInit = () => {
        afterChange();
    };

    const addDotsTestIDs = () => {
        try {
            if (dotsTestId) {
                const dots = domService.getAllElementsBySelector(`.${styles.Dots} li button`);

                dots.forEach((element) => {
                    element.dataset.testid = dotsTestId;
                });
            }
        } catch (e) {
            log.debug(`Carousel: addDotsTestIDs error: ${e}`);
        }
    };

    const builtinOptions = {
        onInit: addDotsTestIDs,
        customPaging: getCustomPaging,
        speed: 300,
        waitForAnimate: true,
        focusOnSelect,
        accessibility: isAccessibilityEnabled,
        draggable: isDraggable,
        swipe: isSwipeEnabled,
        adaptiveHeight: isAdaptiveHeight,
        arrows: isControlArrowVisible,
        dots: isDotsVisible,
        infinite: isInfinite,
        dotsClass: cn(dotsClassName, styles.Dots, {[styles.DotsFocus]: isDotsFocusEnabled}),
        nextArrow: (
            <CarouselArrow
                aria-label={getAriaLabel(NEXT_BUTTON_ARIA_LABEL)}
                testId={arrowNextTestId}
                customClassName={cn(arrowStyles.RightArrow, controlArrowClassName, controlArrowRightClassName)}
                isVisible={!isLastSlide || isInfinite}
            />
        ),
        prevArrow: (
            <CarouselArrow
                aria-label={getAriaLabel(PREVIOUS_BUTTON_ARIA_LABEL)}
                customClassName={cn(arrowStyles.LeftArrow, controlArrowClassName, controlArrowLeftClassName)}
                testId={arrowPrevTestId}
                isVisible={!isFirstSlide || isInfinite}
            />
        ),
    };

    builtinOptions.beforeChange = (current, next) => onSlide(next);

    builtinOptions.afterChange = (index) => {
        helpers.runFunction(onAfterChange, index);
        onSlide(index);
        afterChange();
    };

    const mergedOptions = {...builtinOptions, ...options};

    useImperativeHandle(ref, () => ({
        slide: (index) => sliderRef.current.slickGoTo(index),
        next: () => sliderRef.current.slickNext(),
        prev: () => sliderRef.current.slickPrev(),
        slider: sliderRef.current,
    }));

    return (
        <Slider {...mergedOptions} ref={sliderRef}>
            {children}
        </Slider>
    );
});

Carousel.displayName = 'Carousel';

export default Carousel;
