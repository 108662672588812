import {AEM_REDIRECT_VIEW_IDS} from '@/consts/aem/aemRedirectViewsIds';
import {APP_SECTION_TYPES, EXTERNAL_LINK_TYPES} from '@/consts/externalLinkTypes';
import domService from '@/services/domService';
import log from '@/services/logger/log';
import appRouterService from '@/services/route/appRouterService';
import {showFirmwareAvailableModalAction, showLeviaLearnMoreModalAction} from '@/state/slices/modalSlice';
import {dispatch} from '@/state/store';
import {getHwHeight} from '@/views/WarningBanners/hwUtils';

import useExternalLink from '../useExternalLink';
import usePageSectionLinkData from './usePageSectionLinkData';

const useConfigExternalLink = (props) => {
    const {url, externalLinkCode, ssoTemplate, linkType, source, redirectTo, scrollTo, returnUrl} = props || {};
    const isSso = linkType === EXTERNAL_LINK_TYPES.SSO_LINK || linkType === EXTERNAL_LINK_TYPES.SSO_LINK_IN_APP;
    const pageSectionLinkData = usePageSectionLinkData();

    const {redirect: redirectToExternalLink, externalLink} = useExternalLink({
        url: isSso ? undefined : url,
        externalLinkCode: isSso ? undefined : externalLinkCode,
        ssoTemplate: isSso ? ssoTemplate : undefined,
        openInBluefy: linkType === EXTERNAL_LINK_TYPES.URL_IN_APP || linkType === EXTERNAL_LINK_TYPES.SSO_LINK_IN_APP,
    });

    switch (linkType) {
        case EXTERNAL_LINK_TYPES.SECTION_LINK:
            return handleSectionLink({url, source});
        case EXTERNAL_LINK_TYPES.PAGE_SECTION:
            return handlePageSectionLink({redirectTo, scrollTo, ...pageSectionLinkData});
        case EXTERNAL_LINK_TYPES.URL:
        case EXTERNAL_LINK_TYPES.URL_IN_APP:
        case EXTERNAL_LINK_TYPES.SSO_LINK:
        case EXTERNAL_LINK_TYPES.SSO_LINK_IN_APP:
            if (returnUrl) {
                return {redirectToExternalLink, externalLink};
            }
            return redirectToExternalLink;
        default:
            break;
    }
};

const handleSectionLink = ({url, source}) => {
    switch (url) {
        case APP_SECTION_TYPES.FIRMWARE_AVAILABLE:
            return () => dispatch(showFirmwareAvailableModalAction({source}));
        case APP_SECTION_TYPES.LEVIA:
            return () => dispatch(showLeviaLearnMoreModalAction({source}));
        default:
            return () => {
                log.info(`useConfigExternalLink, sectionLink is empty or not supported, actionLink: ${url}`);
            };
    }
};

const handlePageSectionLink = ({redirectTo, scrollTo, ...rest} = {}) => {
    const {id: redirectToId} = redirectTo || {};
    const {id: scrollToId} = scrollTo || {};

    return () => {
        if (redirectToId) {
            redirectToSection({id: redirectToId, ...rest});
        } else if (scrollToId) {
            scrollToElement({id: scrollToId, ...rest});
        }
    };
};

const redirectToSection = ({id, redirectToAutoDelivery}) => {
    switch (id) {
        case AEM_REDIRECT_VIEW_IDS.SHOP:
            appRouterService.forwardToShopPage();
            break;
        case AEM_REDIRECT_VIEW_IDS.DEVICES:
            appRouterService.forwardToDevicesPage();
            break;
        case AEM_REDIRECT_VIEW_IDS.SELECT_YOUR_DEVICE:
            appRouterService.forwardToSelectYourDevicePage();
            break;
        case AEM_REDIRECT_VIEW_IDS.QUICK_ORDER:
            appRouterService.forwardToQuickOrderPage();
            break;
        case AEM_REDIRECT_VIEW_IDS.AUTO_DELIVERY_DETAILS:
            redirectToAutoDelivery();
            break;
        default:
            return;
    }
};

const scrollToElement = ({id, isMobile}) => {
    const stickyHeaderHeight = domService.getStickyHeaderHeight();
    const hwHeight = getHwHeight();
    const offsetTop = isMobile ? -(stickyHeaderHeight + hwHeight) : 0;

    const element = document.querySelector(`#${id}`);

    if (element) {
        const y = window.scrollY + element.getBoundingClientRect().top + offsetTop;

        window.scrollTo({top: y, behavior: 'smooth'});
    }
};

export default useConfigExternalLink;
