import React from 'react';

import AppImage from '@/components/Image/AppImage';
import appConfig from '@/config/appConfig';
import {getAriaLabel} from '@/services/accessibilityService';
import urlUtils from '@/utils/urlUtils';

const RemoteIcon = ({icon, className}) => {
    if (!icon) return null;

    const {imagePath, altKey} = icon;

    if (!imagePath) return null;

    const imageSrc = urlUtils.join(appConfig.getContentPath(), imagePath);
    const alt = getAriaLabel(altKey);

    return <AppImage className={className} src={imageSrc} alt={alt} />;
};

export default RemoteIcon;
