import React, {useId} from 'react';

import {ButtonRaw} from '@/components/Button';
import {ErrorIcon, RoundedCancelIcon} from '@/components/Icons';
import Input from '@/components/InputNBW/InputNBW';
import {INPUT_CLEAR_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import InputFieldWarning from '@/containers/LoginPage/components/InputFieldWarning';
import {getAriaLabel} from '@/services/accessibilityService';

export const FormInput = ({
    isValid,
    isEntered,
    label,
    inputClassName,
    onInputChange,
    placeholder,
    warningNotValid,
    warningRequiredField,
    clearInput,
    type,
    value,
}) => {
    const inputId = useId();
    const iconClassName =
        'absolute right-3 top-1/2 z-[11] m-0 h-6 w-6 -translate-y-1/2 p-0 rtl:left-3 rtl:right-auto md:rtl:right-auto';

    return (
        <>
            <label htmlFor={inputId} className='mb-2 text-16 text-primary'>
                {label}
            </label>
            <div className='relative w-full'>
                <Input
                    id={inputId}
                    className={inputClassName}
                    onChange={onInputChange}
                    placeholder={placeholder}
                    type={type}
                    maxLength={50}
                    minLength={3}
                    value={value}
                />
                {value && isValid && (
                    <ButtonRaw
                        onClick={clearInput}
                        className={iconClassName}
                        aria-label={getAriaLabel(INPUT_CLEAR_BUTTON_ARIA_LABEL)}
                    >
                        <RoundedCancelIcon className='h-full w-full cursor-pointer' />
                    </ButtonRaw>
                )}
                {!isValid && <ErrorIcon className={iconClassName} />}
            </div>
            <div className='mb-6 mt-1 min-h-2 md:mb-0 ml:empty:hidden'>
                <InputFieldWarning
                    isValid={isValid}
                    isEntered={isEntered}
                    warningNotValid={warningNotValid}
                    warningRequiredField={warningRequiredField}
                />
            </div>
        </>
    );
};
