import cn from 'classnames';
import React from 'react';

import Banner from '@/components/Banner/Banner';
import {AppButton} from '@/components/Button';
import useLayout from '@/hooks/layout/useLayout';
import {getAriaLabel} from '@/services/accessibilityService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import {twx} from '@/utils/tailwindUtils';

const YourUsualSectionBanner = ({isAdditionalInfoVisible, banner, onBannerClick}) => {
    const {isTabletAndDesktop} = useLayout();
    const {
        imageMedia,
        videoPath,
        isGradient,
        titleKey,
        descriptionKey,
        isDarkBannerText,
        buttonActionKey,
        actionAriaLabelKey,
    } = banner || {};
    const localizedStrings = getLocalizedStrings();

    const isBannerAButton = !isTabletAndDesktop;
    const Heading = isBannerAButton ? 'p' : 'h2'; // buttons can't have h tags inside
    const buttonText = buttonActionKey && localizedStrings[buttonActionKey];
    const buttonAriaLabel = getAriaLabel(actionAriaLabelKey);

    return (
        <Banner
            className={twx(
                'h-60 overflow-hidden rounded-xl text-romance md:h-[370px] md:rounded-3xl ml:h-[370px] lg:h-[406px]',
                isAdditionalInfoVisible && 'lg:h-[370px]'
            )}
            imageMedia={imageMedia}
            videoPath={videoPath}
            isGradient={isGradient}
            isDarkBannerText={isDarkBannerText}
            gradient='linear-gradient(180deg, rgba(52, 48, 61, 0) 39.99%, #1C1A21 100%)'
            onClick={isBannerAButton ? onBannerClick : undefined}
            aria-label={buttonAriaLabel}
        >
            <div className='relative flex h-full flex-col justify-end p-4 md:p-8'>
                <Heading
                    className={twx(
                        'mb-0 overflow-hidden text-ellipsis whitespace-nowrap text-20 font-bold leading-normal md:text-24 md:leading-13 ml:text-42',
                        isAdditionalInfoVisible && 'lg:text-24'
                    )}
                >
                    {localizedStrings[titleKey]}
                </Heading>
                <p
                    className={twx(
                        'mt-2 line-clamp-2 whitespace-pre-line text-16 font-normal leading-125 ml:text-18 ml:font-bold ml:leading-13',
                        isAdditionalInfoVisible && 'lg:text-16 lg:font-normal lg:leading-125'
                    )}
                >
                    {localizedStrings[descriptionKey]}
                </p>
                {buttonText && !isBannerAButton && (
                    <AppButton
                        primary
                        dark
                        className={cn(
                            'mt-2 whitespace-nowrap ml:mt-6 ml:self-start [&>*]:overflow-hidden [&>*]:text-ellipsis',
                            isAdditionalInfoVisible && 'lg:mt-2 lg:self-stretch'
                        )}
                        onClick={onBannerClick}
                        aria-label={buttonAriaLabel}
                    >
                        {buttonText}
                    </AppButton>
                )}
            </div>
        </Banner>
    );
};

export default YourUsualSectionBanner;
