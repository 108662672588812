import {createApi} from '@reduxjs/toolkit/query/react';

import appConfig from '@/config/appConfig';

import {getBaseQuery} from './apiUtils';

export const iccApi = createApi({
    reducerPath: 'iccApi',
    baseQuery: getBaseQuery(() => appConfig.getIccApiUrl()),
    endpoints: (builder) => ({}),
});
