import cn from 'classnames';
import React, {useId, useState} from 'react';

import {ButtonRaw} from '@/components/Button';
import {AppButton} from '@/components/Button';
import {InputInvisibleEyeIcon, InputVisibleEyeIcon} from '@/components/Icons';
import Input from '@/components/InputNBW/InputNBW';
import {
    INPUT_PASSWORD_HIDE_BUTTON_ARIA_LABEL,
    INPUT_PASSWORD_SHOW_BUTTON_ARIA_LABEL,
    LOGIN_PAGE_FORGOT_PASSWORD_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import InputFieldWarning from '@/containers/LoginPage/components/InputFieldWarning';
import {getAriaLabel} from '@/services/accessibilityService';

export const FormInputPassword = ({
    isValid,
    isEntered,
    label,
    inputClassName,
    onInputChange,
    placeholder,
    customIconClassName,
    warningNotValid,
    warningRequiredField,
    forgotPasswordLinkCode,
    onClick,
    forgotPassword,
    value,
}) => {
    const inputId = useId();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    return (
        <>
            <label htmlFor={inputId} className='mb-2 text-16 text-primary ml:mt-6'>
                {label}
            </label>

            <div className='relative flex'>
                <Input
                    id={inputId}
                    className={inputClassName}
                    onChange={onInputChange}
                    placeholder={placeholder}
                    type={isPasswordVisible ? 'text' : 'password'}
                    maxLength={50}
                    minLength={3}
                    value={value}
                />
                <PasswordVisibleToggle
                    isPasswordVisible={isPasswordVisible}
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    customIconClassName={customIconClassName}
                />
            </div>
            <div className='mt-1 min-h-2'>
                <InputFieldWarning
                    isValid={isValid}
                    isEntered={isEntered}
                    warningNotValid={warningNotValid}
                    warningRequiredField={warningRequiredField}
                />
                {forgotPasswordLinkCode && (
                    <AppButton
                        className='m-0 block p-0 text-left text-14 leading-[24px] tracking-0 text-primary'
                        onClick={onClick}
                        aria-label={getAriaLabel(LOGIN_PAGE_FORGOT_PASSWORD_BUTTON_ARIA_LABEL)}
                    >
                        {forgotPassword}
                    </AppButton>
                )}
            </div>
        </>
    );
};

const PasswordVisibleToggle = ({isPasswordVisible, onClick, customIconClassName}) => {
    const iconClassName = cn(
        'absolute top-1/2 right-0 rtl:right-auto rtl:left-0 translate-x-[-50%] rtl:translate-x-[50%] translate-y-[-50%]',
        customIconClassName
    );
    const ariaLabelKey = isPasswordVisible
        ? INPUT_PASSWORD_HIDE_BUTTON_ARIA_LABEL
        : INPUT_PASSWORD_SHOW_BUTTON_ARIA_LABEL;
    const ariaLabel = getAriaLabel(ariaLabelKey);

    return (
        <ButtonRaw onClick={onClick} className={iconClassName} aria-label={ariaLabel}>
            {isPasswordVisible ? <InputInvisibleEyeIcon /> : <InputVisibleEyeIcon />}
        </ButtonRaw>
    );
};
