import React from 'react';

import {getLocalizedStrings} from '@/services/localization/localizationService';
import stylesUtils from '@/utils/stylesUtils';
import {twx} from '@/utils/tailwindUtils';

import HwContainer from './HwContainer';

const HealthWarningAlternativeBottom = ({
    titleKey,
    className,
    contentClassName,
    isSeparatorVisible,
    fontSize,
    fontFamily,
}) => {
    const localizedStrings = getLocalizedStrings();
    const title = localizedStrings[titleKey];

    if (!title) return;

    const styles = stylesUtils.getFontSizeStyles(fontSize);
    const fontFamilyClassName = stylesUtils.getFontFamilyClassName(fontFamily);

    return (
        <HwContainer className={className} isSeparatorVisible={isSeparatorVisible}>
            <div
                className={twx(
                    'max-w-2xl px-4 py-8 text-16 md:px-8 ml:px-16 xl:mx-auto xl:box-content',
                    contentClassName,
                    fontFamilyClassName
                )}
            >
                {title && (
                    <p className='leading-15 text-black' style={styles}>
                        {localizedStrings[titleKey]}
                    </p>
                )}
            </div>
        </HwContainer>
    );
};

export default HealthWarningAlternativeBottom;
