import marketConfigService from '@/services/marketConfigService';
import uaUtils from '@/utils/uaUtils';

const isBleConnectionSupported = (deviceType) => {
    const isIos = uaUtils.getIsIos();
    const {isBleDisabled} = marketConfigService.getDeviceData(deviceType) || {};

    // For now, this check should only include ios devices, but this will be changed in the future
    const isBleConnectionSupported = !isIos || (isIos && !isBleDisabled);

    return isBleConnectionSupported;
};

export default {
    isBleConnectionSupported,
};
