import cn from 'classnames';
import React, {useState} from 'react';

import {ButtonRaw} from '@/components/Button';
import {ExpansionPanelArrowIcon} from '@/components/Icons';
import {twx} from '@/utils/tailwindUtils';

import TermsOfUseDescription from '../../../../TermsOfUseDescription/TermsOfUseDescription';

const ExpansionPanel = ({titleId, title, innerTextId, innerText, children, onTncLinkClick}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const onExpansionPanelClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div
            className={cn(
                'relative w-full cursor-pointer select-none md:min-h-18',
                'after:absolute after:bottom-0 after:right-1/2 after:h-px after:w-screen after:translate-x-1/2 after:bg-alto-second after:content-[""] md:after:w-full'
            )}
        >
            <div className='flex items-center justify-between'>
                <h2 className='mb-0 flex-1 text-16 font-normal leading-15'>
                    <ButtonRaw
                        className='flex w-full items-center pb-6 pt-4 ml:pl-4 ml:pt-6'
                        onClick={onExpansionPanelClick}
                        aria-controls={innerTextId}
                        aria-expanded={isExpanded}
                        aria-label={title}
                        tabIndex={0}
                        role='button'
                    >
                        <ExpansionPanelArrowIcon
                            className={cn('w-3 min-w-[12px] duration-200 ease-in-out', {'rotate-180': isExpanded})}
                            onClick={onExpansionPanelClick}
                        />
                        <span id={titleId} className='w-full px-4 text-start'>
                            {title}
                        </span>
                    </ButtonRaw>
                </h2>
                {children}
            </div>
            {innerText && (
                <TermsOfUseDescription
                    id={innerTextId}
                    className={twx('hidden pb-6 text-start ml:pl-4', isExpanded && 'block')}
                    innerHtml={innerText}
                    aria-hidden={!isExpanded}
                    onTncLinkClick={onTncLinkClick}
                />
            )}
        </div>
    );
};

export default ExpansionPanel;
