import React from 'react';

import routePaths from '@/consts/route/routePaths';
import {twx} from '@/utils/tailwindUtils';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import AutoDeliveryEditOrderButton from './AutoDeliveryEditOrderButton';

const AutoDeliveryOrderHeader = ({titleClassName, isEditAllowed, renderAs}) => {
    const HeadingComponent = renderAs || 'h1';

    return (
        <div className='flex items-center justify-between gap-2'>
            <HeadingComponent
                className={twx(
                    'mb-0 overflow-hidden text-ellipsis whitespace-nowrap text-24 font-bold leading-13 md:text-36 md:leading-12 md:text-black',
                    titleClassName
                )}
            >
                <AemEditableText pageName={routePaths.AUTO_DELIVERY} itemName='yourOrderTitle' />
            </HeadingComponent>
            {isEditAllowed && <AutoDeliveryEditOrderButton />}
        </div>
    );
};

export default AutoDeliveryOrderHeader;
