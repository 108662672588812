import React from 'react';

import {NEXT_BUTTON_ARIA_LABEL, PREVIOUS_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import {getAriaLabel} from '@/services/accessibilityService';
import {twx} from '@/utils/tailwindUtils';

import SwiperNavigationButton from './SwiperNavigationButton';

const SwiperNavigation = ({prevRef, nextRef, isBeginning, isEnd, className}) => {
    return (
        <div className={twx('hidden shrink-0 gap-4 lg:flex', className)}>
            <SwiperNavigationButton
                ref={prevRef}
                disabled={isBeginning}
                className='rotate-180'
                aria-label={getAriaLabel(PREVIOUS_BUTTON_ARIA_LABEL)}
            />
            <SwiperNavigationButton ref={nextRef} disabled={isEnd} aria-label={getAriaLabel(NEXT_BUTTON_ARIA_LABEL)} />
        </div>
    );
};

export default SwiperNavigation;
