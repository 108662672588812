import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import appConfig from '@/config/appConfig';
import {properties} from '@/services/analytics/ga/googleAnalytics';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {selectIsGaEnabled} from '@/state/selectors/consumerAggregatedSettings';

const useGoogleAnalytics = () => {
    const googleTagManagerId = appConfig.getGoogleTagManagerId();
    const isGaEnabled = useSelector(selectIsGaEnabled);

    useEffect(() => {
        googleAnalyticsService.toggleAnalytics(isGaEnabled);
    }, [isGaEnabled]);

    const setDefaultProperties = (dataLayer) => {
        dataLayer.push('config', googleTagManagerId, {
            ...properties.source(),
            ...properties.market(),
            ...properties.deviceType(),
        });
    };

    const trackInitialEvent = (dataLayer) => {
        dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });
    };

    useEffect(() => {
        if (!googleTagManagerId) return;

        window.dataLayer = window.dataLayer || [];
        setDefaultProperties(window.dataLayer);
        trackInitialEvent(window.dataLayer);

        const firstScript = document.getElementsByTagName('script')[0];
        const scriptToAdd = document.createElement('script');

        scriptToAdd.async = true;
        scriptToAdd.src = `https://www.googletagmanager.com/gtm.js?id=${googleTagManagerId}`;

        firstScript.parentNode.insertBefore(scriptToAdd, firstScript);
    }, []);
};

export default useGoogleAnalytics;
