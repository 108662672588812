import React from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '@/components/Button';
import appConfig from '@/config/appConfig';
import {
    NO_DEVICES_PAGE_ADD_IQOS_BUTTON_ARIA_LABEL,
    NO_DEVICES_PAGE_TRY_YOUR_FIRST_IQOS_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import useConfigExternalLink from '@/hooks/externalLink/useConfigExternalLink';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeDevicesPageTracking from '@/services/analytics/amplitude/amplitudeDevicesPageTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import appRouterService from '@/services/route/appRouterService';

const DevicePageButtons = () => {
    const marketWebSiteExternalLink = appConfig.getMarketWebSiteExternalLink();

    const onAddDeviceClick = () => {
        appRouterService.forwardToSelectYourDevicePage();
    };

    return (
        <div className='flex w-full flex-col md:w-auto md:max-w-[398px]'>
            <AppButton
                primary
                light
                className='mt-4 items-stretch md:min-w-[361px]'
                onClick={onAddDeviceClick}
                aria-label={getAriaLabel(NO_DEVICES_PAGE_ADD_IQOS_BUTTON_ARIA_LABEL)}
            >
                <AemEditableText pageName={routePaths.DEVICES_PAGE} itemName='addyouriqos' />
            </AppButton>
            {marketWebSiteExternalLink && <TryYourFirstIqosButton externalLink={marketWebSiteExternalLink} />}
        </div>
    );
};

const TryYourFirstIqosButton = ({externalLink}) => {
    const redirectToExternalLink = useConfigExternalLink(externalLink);

    const onTryYourFirstIqosClick = () => {
        amplitudeDevicesPageTracking.trackTryYourFirstIqosButtonClick();
        googleAnalyticsService.trackTryYourFirstIqosButtonClick();
        redirectToExternalLink();
    };

    return (
        <AppButton
            secondary
            light
            className='mt-4 md:min-w-[361px]'
            onClick={onTryYourFirstIqosClick}
            isExternalLink
            aria-label={getAriaLabel(NO_DEVICES_PAGE_TRY_YOUR_FIRST_IQOS_BUTTON_ARIA_LABEL)}
        >
            <AemEditableText pageName={routePaths.DEVICES_PAGE} itemName='tryyourfirstiqos' />
        </AppButton>
    );
};

export default DevicePageButtons;
