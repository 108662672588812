import React, {useRef} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import {NEXT_BUTTON_ARIA_LABEL, PREVIOUS_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import {getAriaLabel} from '@/services/accessibilityService';

import HighlightsCarousel from '../../common/components/HighlightsCarousel/HighlightsCarousel';
import styles from '../FullscreenDesktopHighlights.module.scss';
import useHighlightsInfiniteCarousels from '../hooks/useHighlightsInfiniteCarousels';
import useHighlightsSwiper from '../hooks/useHighlightsSwiper';
import HighlightsAnimatedContainer from './HighlightsAnimatedContainer';
import HighlightsArrowButton from './HighlightsArrowButton';

const HighlightsCarouselList = ({carouselsFromConfig, isAutoPlayEnabled, onCarouselChange, onSlideChange}) => {
    const CAROUSELS_TO_SHOW = 3;

    const sliderRef = useRef(null);
    const {
        setCarouselRef,
        carousels,
        carouselStates,
        handleCarouselLeave,
        handleCarouselEnter,
        handleCarouselEnded,
        handleSlideChange,
    } = useHighlightsInfiniteCarousels({
        sliderRef,
        carouselsFromConfig,
        visibleCount: CAROUSELS_TO_SHOW,
        onCarouselChange,
        onSlideChange,
    });
    const {options, currentSlide, handlePreviousClick, handleNextClick, handleOverlayClick} = useHighlightsSwiper({
        sliderRef,
        visibleCount: CAROUSELS_TO_SHOW,
        handleCarouselLeave,
        handleCarouselEnter,
    });

    return (
        <Swiper ref={sliderRef} className={styles.FullscreenCarousel} {...options}>
            {carousels.map(({highlights}, i) => (
                <SwiperSlide key={i}>
                    <HighlightsAnimatedContainer
                        isBigger={i === currentSlide}
                        hasOverlay={i !== currentSlide}
                        onOverlayClick={() => handleOverlayClick(i)}
                    >
                        <HighlightsCarousel
                            ref={(ref) => setCarouselRef(i, ref)}
                            highlights={highlights}
                            isAutoPlayEnabled={isAutoPlayEnabled}
                            isFullscreen
                            isPlaying={carouselStates[i].isPlaying}
                            isEnded={carouselStates[i].isEnded}
                            currentSlide={carouselStates[i].currentSlide}
                            onSlideChange={(_, newSlide) => handleSlideChange(i, newSlide)}
                            onCarouselEnded={() => handleCarouselEnded(i)}
                        />
                    </HighlightsAnimatedContainer>
                </SwiperSlide>
            ))}
            <HighlightsArrowButton onClick={handleNextClick} aria-label={getAriaLabel(NEXT_BUTTON_ARIA_LABEL)} />
            <HighlightsArrowButton
                isFlipped
                onClick={handlePreviousClick}
                aria-label={getAriaLabel(PREVIOUS_BUTTON_ARIA_LABEL)}
            />
        </Swiper>
    );
};

export default HighlightsCarouselList;
