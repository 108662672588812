import appConfig from '@/config/appConfig';
import queryParameters from '@/consts/route/queryParameters';
import iccMarketService from '@/services/icc/iccMarketService';
import marketService from '@/services/marketService';
import storageService from '@/services/storage/storageService';
import {selectConsumerLanguageCode, selectIsConsumerDataFetched} from '@/state/selectors/consumer';
import {selectLanguageCode} from '@/state/selectors/global';
import {setLanguageCode} from '@/state/slices/globalSlice';
import {dispatch, getState} from '@/state/store';
import urlUtils from '@/utils/urlUtils';

import {initLocalizations} from './localizationService';

const initLanguage = async () => {
    const [languageCodeFromQuery] = urlUtils.getDataFromUrl(queryParameters.LANGUAGE);
    const initialLanguageCode = await getLanguageCode(languageCodeFromQuery);

    await initLocalizations(marketService.getMarketCode(), initialLanguageCode);
    return true;
};

const getLanguageCode = async (languageCodeFromQuery) => {
    const marketLanguages = appConfig.getMarketLanguages();
    const languageCode = storageService.getLanguageCodeFromLocalStorage();

    if (isLanguageCodeSupported(marketLanguages, languageCode)) return formatLanguageCode(languageCode);

    if (isLanguageCodeSupported(marketLanguages, languageCodeFromQuery))
        return formatLanguageCode(languageCodeFromQuery);

    const systemLanguageCode = navigator.language?.split('-')[0];

    if (isLanguageCodeSupported(marketLanguages, systemLanguageCode)) return formatLanguageCode(systemLanguageCode);

    const defaultLanguageCode = marketLanguages.find((x) => x.isDefault)?.languageCode;

    if (defaultLanguageCode) return formatLanguageCode(defaultLanguageCode);

    return getDefaultLanguageCode();
};

const getDefaultLanguageCode = () => formatLanguageCode('en');

const isLanguageCodeSupported = (marketLanguages, languageCode) => {
    return !!getMarketLanguageByLanguageCode(marketLanguages, languageCode);
};

const getMarketLanguageByLanguageCode = (marketLanguages, languageCode) => {
    if (!languageCode) return;

    return marketLanguages.find((x) => x.languageCode === languageCode);
};

const setLanguageCodeToStores = (languageCode) => {
    const newLanguageCode = formatLanguageCode(languageCode);

    storageService.setLanguageCodeToLocalStorage(newLanguageCode);

    dispatch(setLanguageCode(newLanguageCode));
};

const formatLanguageCode = (languageCode) => {
    return languageCode?.toLowerCase();
};

const checkAndSetConsumerLanguageCode = async () => {
    const state = getState();
    const isConsumerDataFetched = selectIsConsumerDataFetched(state);
    const consumerLanguageCode = formatLanguageCode(selectConsumerLanguageCode(state));
    const languageCode = formatLanguageCode(selectLanguageCode(state));

    if (isConsumerDataFetched && consumerLanguageCode !== languageCode) {
        await iccMarketService.setConsumerLanguageCode({languageCode});
    }
};

export default {
    initLanguage,
    getDefaultLanguageCode,
    getMarketLanguageByLanguageCode,
    setLanguageCodeToStores,
    checkAndSetConsumerLanguageCode,
};
