import imgAlpha1 from '@/../assets/images/select-device/alpha-desktop.png';
import imgAlpha2 from '@/../assets/images/select-device/alpha-desktop@2x.png';
import imgAlpha3 from '@/../assets/images/select-device/alpha-desktop@3x.png';
import imgAriane1 from '@/../assets/images/select-device/ariane-desktop.png';
import imgAriane2 from '@/../assets/images/select-device/ariane-desktop@2x.png';
import imgAriane3 from '@/../assets/images/select-device/ariane-desktop@3x.png';
import imgCortez1 from '@/../assets/images/select-device/cortez-desktop.png';
import imgCortez2 from '@/../assets/images/select-device/cortez-desktop@2x.png';
import imgCortez3 from '@/../assets/images/select-device/cortez-desktop@3x.png';
import imgCosmos1 from '@/../assets/images/select-device/cosmos-desktop.png';
import imgCosmos2 from '@/../assets/images/select-device/cosmos-desktop@2x.png';
import imgCosmos3 from '@/../assets/images/select-device/cosmos-desktop@3x.png';
import imgDelta1 from '@/../assets/images/select-device/delta-desktop.png';
import imgDelta2 from '@/../assets/images/select-device/delta-desktop@2x.png';
import imgDelta3 from '@/../assets/images/select-device/delta-desktop@3x.png';
import imgHermesMid1 from '@/../assets/images/select-device/hermes-mid-desktop.png';
import imgHermesMid2 from '@/../assets/images/select-device/hermes-mid-desktop@2x.png';
import imgHermesMid3 from '@/../assets/images/select-device/hermes-mid-desktop@3x.png';
import imgHermesPrime1 from '@/../assets/images/select-device/hermes-prime-desktop.png';
import imgHermesPrime2 from '@/../assets/images/select-device/hermes-prime-desktop@2x.png';
import imgHermesPrime3 from '@/../assets/images/select-device/hermes-prime-desktop@3x.png';
import imgJuno1 from '@/../assets/images/select-device/juno-desktop.png';
import imgJuno2 from '@/../assets/images/select-device/juno-desktop@2x.png';
import imgJuno3 from '@/../assets/images/select-device/juno-desktop@3x.png';
import imgMulti1 from '@/../assets/images/select-device/multi-desktop.png';
import imgMulti2 from '@/../assets/images/select-device/multi-desktop@2x.png';
import imgMulti3 from '@/../assets/images/select-device/multi-desktop@3x.png';
import imgOrdinary1 from '@/../assets/images/select-device/ordinary-desktop.png';
import imgOrdinary2 from '@/../assets/images/select-device/ordinary-desktop@2x.png';
import imgOrdinary3 from '@/../assets/images/select-device/ordinary-desktop@3x.png';
import imgOriginalOne1 from '@/../assets/images/select-device/original-one-desktop.png';
import imgOriginalOne2 from '@/../assets/images/select-device/original-one-desktop@2x.png';
import imgOriginalOne3 from '@/../assets/images/select-device/original-one-desktop@3x.png';
import imgOriginalDuo1 from '@/../assets/images/select-device/originals-duo-desktop.png';
import imgOriginalDuo2 from '@/../assets/images/select-device/originals-duo-desktop@2x.png';
import imgOriginalDuo3 from '@/../assets/images/select-device/originals-duo-desktop@3x.png';
import imgSoyuz1 from '@/../assets/images/select-device/soyuz-desktop.png';
import imgSoyuz2 from '@/../assets/images/select-device/soyuz-desktop@2x.png';
import imgSoyuz3 from '@/../assets/images/select-device/soyuz-desktop@3x.png';
import imgP4IqosTitle1 from '@/../assets/images/select-device/veev-title-iqos-desktop.png';
import imgP4IqosTitle2 from '@/../assets/images/select-device/veev-title-iqos-desktop@2x.png';
import imgP4IqosTitle3 from '@/../assets/images/select-device/veev-title-iqos-desktop@3x.png';
import imgVoyager1 from '@/../assets/images/select-device/voyager-desktop.png';
import imgVoyager2 from '@/../assets/images/select-device/voyager-desktop@2x.png';
import imgVoyager3 from '@/../assets/images/select-device/voyager-desktop@3x.png';
import {DEVICE_TYPES} from '@/consts/device/deviceTypes';
import * as localizationKeys from '@/consts/localization/localizationKeys';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import marketConfigService from '@/services/marketConfigService';

const getDefaultDeviceDataByType = (type) => {
    const defaultDeviceDataList = getDefaultDeviceData();
    const deviceDefaultData = defaultDeviceDataList.find((x) => x.type === type);

    return deviceDefaultData;
};

const getDefaultDeviceData = () => {
    const localizedStrings = getLocalizedStrings();

    return [
        {
            type: DEVICE_TYPES.DEV_TYPE_P1V41A_CONTROL,
            images: [imgAlpha1, imgAlpha2, imgAlpha3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V41A_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_P1V41A_CONTROL),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_P1V41A_CONTROL),
        },
        {
            type: DEVICE_TYPES.DEV_TYPE_P1V41J_CONTROL,
            images: [imgJuno1, imgJuno2, imgJuno3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V41J_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_P1V41J_CONTROL),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_P1V41J_CONTROL),
        },
        {
            type: DEVICE_TYPES.DEV_TYPE_P1V41P_CHARGER,
            images: [imgHermesPrime1, imgHermesPrime2, imgHermesPrime3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V41P_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_P1V41P_CHARGER),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_P1V41P_CHARGER),
        },
        {
            type: DEVICE_TYPES.DEV_TYPE_P1V41M_CHARGER,
            images: [imgHermesMid1, imgHermesMid2, imgHermesMid3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V41M_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_P1V41M_CHARGER),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_P1V41M_CHARGER),
        },
        {
            type: DEVICE_TYPES.DEV_TYPE_P1_ARIANE,
            images: [imgAriane1, imgAriane2, imgAriane3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_ARIANE_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_P1_ARIANE),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_P1_ARIANE),
        },
        {
            type: DEVICE_TYPES.DEV_TYPE_P1V31_ORIGINALS_CHARGER,
            images: [imgOriginalDuo1, imgOriginalDuo2, imgOriginalDuo3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_ORIGINALS_DUO_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_P1V31_ORIGINALS_CHARGER),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_P1V31_ORIGINALS_CHARGER),
        },
        {
            type: DEVICE_TYPES.DEV_TYPE_P1V31M,
            images: [imgOriginalOne1, imgOriginalOne2, imgOriginalOne3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_ORIGINALS_ONE_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_P1V31M),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_P1V31M),
        },
        {
            type: DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL,
            images: [imgDelta1, imgDelta2, imgDelta3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V40D_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL),
        },
        {
            type: DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER,
            images: [imgCosmos1, imgCosmos2, imgCosmos3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V40M_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER),
        },
        {
            type: DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER,
            images: [imgVoyager1, imgVoyager2, imgVoyager3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V40_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER),
        },
        {
            type: DEVICE_TYPES.DEV_TYPE_P4_M3_G2,
            images: [imgP4IqosTitle1, imgP4IqosTitle2, imgP4IqosTitle3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_TITAN_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_P4_M3_G2),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_P4_M3_G2),
        },
        {
            type: DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER,
            images: [imgSoyuz1, imgSoyuz2, imgSoyuz3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_SOYUZ_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER),
        },
        {
            type: DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER,
            images: [imgCortez1, imgCortez2, imgCortez3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_CORTEZ_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER),
        },
        {
            type: DEVICE_TYPES.DEV_TYPE_P1V30M,
            images: [imgMulti1, imgMulti2, imgMulti3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_VESPUCCI_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_P1V30M),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_P1V30M),
        },
        {
            type: DEVICE_TYPES.DEV_TYPE_V24P_CHARGER,
            images: [imgOrdinary1, imgOrdinary2, imgOrdinary3],
            name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_ORDINARY_NAME],
            isSupported: marketConfigService.isDeviceSupported(DEVICE_TYPES.DEV_TYPE_V24P_CHARGER),
            order: marketConfigService.deviceOrder(DEVICE_TYPES.DEV_TYPE_V24P_CHARGER),
        },
    ];
};

export {getDefaultDeviceData, getDefaultDeviceDataByType};
