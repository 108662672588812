import React from 'react';

import {AUTO_DELIVERY_ORDER_STATUS_TYPES, AUTO_DELIVERY_STATUS_TYPES} from '@/consts/icc/iccAutoDeliveryStatusTypes';
import {
    AUTO_DELIVERY_PROGRESS_DELIVERED,
    AUTO_DELIVERY_PROGRESS_ON_HOLD,
    AUTO_DELIVERY_PROGRESS_ON_ITS_WAY,
    AUTO_DELIVERY_PROGRESS_PROCESSED,
} from '@/consts/localization/localizationKeys';

import AutoDeliveryProgress from '../AutoDeliveryProgress';

const getProgressColor = (status) => {
    switch (status?.toUpperCase()) {
        case AUTO_DELIVERY_STATUS_TYPES.ACTIVE:
            return 'bg-tertiary';
        case AUTO_DELIVERY_STATUS_TYPES.ON_HOLD:
            return 'bg-sandy';
        default:
            return '';
    }
};

const getProgress = (status, orderStatus) => {
    if (status === AUTO_DELIVERY_STATUS_TYPES.ON_HOLD) return 50;
    if (status !== AUTO_DELIVERY_STATUS_TYPES.ACTIVE) return 0;

    switch (orderStatus?.toUpperCase()) {
        case AUTO_DELIVERY_ORDER_STATUS_TYPES.EXPECTED:
        case AUTO_DELIVERY_ORDER_STATUS_TYPES.PROCESSED:
            return 20;
        case AUTO_DELIVERY_ORDER_STATUS_TYPES.ON_ITS_WAY:
            return 50;
        default:
            return 0;
    }
};

const getProgressLabel = (progress) => {
    switch (progress) {
        case 0:
        default:
            return AUTO_DELIVERY_PROGRESS_PROCESSED;
        case 20:
            return AUTO_DELIVERY_PROGRESS_ON_ITS_WAY;
        case 50:
            return AUTO_DELIVERY_PROGRESS_DELIVERED;
    }
};

const getProgressSteps = (status) => {
    switch (status?.toUpperCase()) {
        case AUTO_DELIVERY_STATUS_TYPES.ACTIVE:
        case AUTO_DELIVERY_STATUS_TYPES.PAYMENT_PENDING:
            return [
                AUTO_DELIVERY_PROGRESS_PROCESSED,
                AUTO_DELIVERY_PROGRESS_ON_ITS_WAY,
                AUTO_DELIVERY_PROGRESS_DELIVERED,
            ];
        case AUTO_DELIVERY_STATUS_TYPES.ON_HOLD:
            return [AUTO_DELIVERY_PROGRESS_ON_HOLD];
        default:
            return undefined;
    }
};

const AutoDeliveryActiveProgress = ({className, status, orderStatus}) => {
    const colorClassName = getProgressColor(status);
    const progress = getProgress(status, orderStatus);
    const progressLabel = getProgressLabel(progress);
    const progressSteps = getProgressSteps(status);

    return (
        <AutoDeliveryProgress
            className={className}
            progressClassName={colorClassName}
            progress={progress}
            progressLabel={progressLabel}
            steps={progressSteps}
        />
    );
};

export default AutoDeliveryActiveProgress;
