import React from 'react';
import {useSelector} from 'react-redux';

import AemComponent from '@/components/Aem/AemComponent/AemComponent';
import EditableText from '@/components/Aem/EditableText/EditableText';
import {AppButton} from '@/components/Button';
import * as advanceDiagnosticIconTypes from '@/consts/advanceDiagnostic/advanceDiagnosticIconTypes';
import * as advanceDiagnosticScreenTypes from '@/consts/advanceDiagnostic/advanceDiagnosticScreenTypes';
import {
    ADVANCED_DIAGNOSTICS_CHARGING_ISSUE_BUTTON_ARIA_LABEL,
    ADVANCED_DIAGNOSTICS_CHARGING_ISSUE_NOT_NOW_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import routePaths from '@/consts/route/routePaths';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeADTracking from '@/services/analytics/amplitude/amplitudeADTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import appRouterService from '@/services/route/appRouterService';
import {WARNING_BANNER_SOURCE_TYPES, WarningBanners} from '@/views/WarningBanners';

import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticDescription from '../components/AdvancedDiagnosticDescription';
import AdvancedDiagnosticIcon from '../components/AdvancedDiagnosticIcon';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';
import NotNowButtonAD from '../components/NotNowButtonAD';
import {selectDiagnosticFlowDataResult} from '../state/advancedDiagnosticSelectors';

const AdvancedDiagnosticChargingIssue = () => {
    const diagnosticResult = useSelector(selectDiagnosticFlowDataResult);
    const {deviceInferenceCode, holderInferenceCode} = diagnosticResult || {};
    const inferenceCode = deviceInferenceCode ? deviceInferenceCode : holderInferenceCode;
    const isHolder = inferenceCode === holderInferenceCode;
    const onCleanMyDeviceButtonClick = () => {
        amplitudeADTracking.trackADCleanMyDeviceClick();
        googleAnalyticsService.trackAdCleanMyDeviceClick();
        appRouterService.forwardToCleaningContactsScreenAD();
    };

    return (
        <div className='flex w-full flex-col'>
            <AdvancedDiagnosticContainer isDeviceHero>
                <AdvancedDiagnosticIcon type={advanceDiagnosticIconTypes.ERROR} />
                <div className='px-8'>
                    <AdvancedDiagnosticTitle itemName='chargingissuetitle' />
                    <AdvancedDiagnosticDescription itemName='chargingissuedesc' />
                    <div className='mt-10 grid w-full grid-cols-1 justify-items-center gap-4'>
                        <AppButton
                            primary
                            light
                            className='mt-0 min-w-[312px]'
                            onClick={onCleanMyDeviceButtonClick}
                            aria-label={getAriaLabel(ADVANCED_DIAGNOSTICS_CHARGING_ISSUE_BUTTON_ARIA_LABEL)}
                        >
                            <AemComponent
                                component={EditableText}
                                pageName={routePaths.ADVANCE_DIAGNOSTIC}
                                itemName='chargingissuebutton'
                            />
                        </AppButton>
                        <NotNowButtonAD
                            isHolder={isHolder}
                            inferenceCode={inferenceCode}
                            screenName={advanceDiagnosticScreenTypes.CHARGING_ISSUE_SCREEN}
                            aria-label={getAriaLabel(ADVANCED_DIAGNOSTICS_CHARGING_ISSUE_NOT_NOW_BUTTON_ARIA_LABEL)}
                        >
                            <AemComponent
                                component={EditableText}
                                pageName={routePaths.ADVANCE_DIAGNOSTIC}
                                itemName='chargingissuenotnowbutton'
                            />
                        </NotNowButtonAD>
                    </div>
                </div>
            </AdvancedDiagnosticContainer>
            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.AD} />
        </div>
    );
};

export default AdvancedDiagnosticChargingIssue;
