import React, {forwardRef} from 'react';
import {Link as ReactLink} from 'react-router-dom';

import {makeButtonAccessible} from '@/components/Link/linkService';

const Link = forwardRef((props, ref) => {
    const {
        id,
        className,
        isExternalLink,
        type,
        value,
        to,
        href,
        onClick,
        children,
        isTargetBlank,
        testId,
        disabled,
        'aria-label': ariaLabel,
        'aria-hidden': ariaHidden,
        'aria-checked': ariaChecked,
        'aria-selected': ariaSelected,
        'aria-current': ariaCurrent,
        'aria-expanded': ariaExpanded,
        'aria-controls': ariaControls,
        'aria-labelledby': ariaLabelledBy,
        role,
        tabIndex,
        dangerouslySetInnerHTML,
        renderAs,
        style,
        onFocus,
    } = props;
    const Component = getRenderComponent({isExternalLink, to, renderAs});
    const target = isTargetBlank ? '_blank' : null;
    const {buttonRole, buttonTabIndex, buttonOnKeyDown, buttonOnKeyUp} = makeButtonAccessible({
        Component,
        isExternalLink,
        role,
        tabIndex,
        type,
        href: href || to,
        disabled,
        onClick,
    });

    return isExternalLink ? (
        <a
            id={id}
            ref={ref}
            className={className}
            href={to}
            onClick={onClick}
            rel={isTargetBlank ? 'noopener noreferrer' : null}
            target={target}
            data-testid={testId}
            aria-label={ariaLabel}
            aria-hidden={ariaHidden}
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            style={style}
            onFocus={onFocus}
            role={buttonRole}
            tabIndex={buttonTabIndex}
            onKeyDown={buttonOnKeyDown}
            onKeyUp={buttonOnKeyUp}
        >
            {children || value}
        </a>
    ) : (
        <Component
            id={id}
            ref={ref}
            className={className}
            onClick={onClick}
            rel={isTargetBlank ? 'noopener noreferrer' : null}
            target={target}
            to={to}
            data-testid={testId}
            role={buttonRole}
            aria-label={ariaLabel}
            aria-hidden={ariaHidden}
            aria-disabled={disabled}
            aria-checked={ariaChecked}
            aria-selected={ariaSelected}
            aria-current={ariaCurrent}
            aria-expanded={ariaExpanded}
            aria-controls={ariaControls}
            aria-labelledby={ariaLabelledBy}
            tabIndex={buttonTabIndex}
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            onKeyDown={buttonOnKeyDown}
            onKeyUp={buttonOnKeyUp}
            style={style}
            onFocus={onFocus}
        >
            {children || value}
        </Component>
    );
});

const getRenderComponent = ({isExternalLink, to, renderAs}) => {
    if (isExternalLink) return 'a';

    if (to) return ReactLink;

    return renderAs || 'button';
};

Link.displayName = 'Link';

Link.defaultProps = {
    className: '',
    isExternalLink: false,
    isTargetBlank: false,
};

export default Link;
