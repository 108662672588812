import storageService from '@/services/storage/storageService';
import {selectAccessToken, selectAccessTokenData, selectRefreshToken} from '@/state/selectors/auth';
import {setAuthData} from '@/state/slices/authSlice';
import {dispatch, getState} from '@/state/store';

const getAccessToken = () => selectAccessToken(getState());
const getAccessTokenData = () => selectAccessTokenData(getState());
const getRefreshToken = () => selectRefreshToken(getState());

const setIccTokenData = (data) => {
    dispatch(setAuthData(data));
    storageService.setAuthDataToStorage(data);
};

let _username;
let _password;

const setCredentials = ({username, password}) => {
    _username = username;
    _password = password;
};

const getCredentials = () => ({
    username: _username,
    password: _password,
});

const clearCredentials = () => {
    _username = undefined;
    _password = undefined;
};

export default {
    getAccessToken,
    getAccessTokenData,
    getRefreshToken,
    setIccTokenData,
    setCredentials,
    getCredentials,
    clearCredentials,
};
