import React, {useId, useState} from 'react';

import {AppButton, ButtonRaw} from '@/components/Button';
import CloseButton from '@/components/CloseButton/CloseButton';
import {ArrowIcon} from '@/components/Icons';
import {StickyHeader} from '@/components/Layout';
import PopupSideBar from '@/components/Popup/PopupSideBar/PopupSideBar';
import PopupStickedWithSwipeClose from '@/components/Popup/PopupStickedWithSwipeClose';
import * as localizationKeys from '@/consts/localization/localizationKeys';
import {CLOSE_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import useIsMobileLayout from '@/hooks/layout/useIsMobileLayout';
import {getAriaLabel} from '@/services/accessibilityService';
import amplitudeDeviceTracking from '@/services/analytics/amplitude/amplitudeDeviceTracking';
import googleAnalyticsService from '@/services/analytics/ga/googleAnalyticsService';
import {getLocalizedStrings} from '@/services/localization/localizationService';
import appRouterService from '@/services/route/appRouterService';
import {twx} from '@/utils/tailwindUtils';

import DevicesList from './DevicesList';
import MissingDeviceNotification from './MissingDeviceNotification';

const DevicesListPopup = ({onClose, ...rest}) => {
    const isMobile = useIsMobileLayout();
    const localizedStrings = getLocalizedStrings();

    const onAddDeviceClick = () => {
        amplitudeDeviceTracking.trackAddNewDeviceClick();
        googleAnalyticsService.trackAddNewDeviceClick();
        appRouterService.forwardToSelectYourDevicePage();
    };

    const props = {
        title: localizedStrings[localizationKeys.MY_DEVICES_LIST_TITLE],
        button: localizedStrings[localizationKeys.MY_DEVICES_LIST_ADD_NEW_BUTTON_TEXT],
        buttonAriaLabel: getAriaLabel(localizationKeys.MY_DEVICES_LIST_ADD_NEW_BUTTON_TEXT_ARIA_LABEL),
        onAddDeviceClick,
        onPopupClose: onClose,
        ...rest,
    };

    return isMobile ? <MobilePopup {...props} /> : <DesktopPopup {...props} />;
};

const YourDevicesHeader = ({id, children, className}) => {
    return (
        <h1 id={id} className={twx('mb-0 text-24', className)}>
            {children}
        </h1>
    );
};

const AddDeviceButton = ({className, ...props}) => {
    return <AppButton primary light className={twx('shrink-0', className)} {...props} />;
};

const MobilePopup = ({title, button, buttonAriaLabel, onAddDeviceClick, onPopupClose, ...rest}) => {
    const headerId = useId();

    return (
        <PopupStickedWithSwipeClose
            className='flex max-h-[calc(100vh-56px-var(--hw-h))] flex-col overflow-hidden px-4 pb-safe-offset-8'
            swipeAreaClassName='py-4'
            onClose={onPopupClose}
            isSwipeDashVisible={false}
            aria-labelledby={headerId}
            {...rest}
        >
            <div className='flex items-center justify-between'>
                <YourDevicesHeader id={headerId}>{title}</YourDevicesHeader>
                <ButtonRaw
                    onClick={onPopupClose}
                    className='shrink-0'
                    aria-label={getAriaLabel(CLOSE_BUTTON_ARIA_LABEL)}
                >
                    <ArrowIcon className='rotate-90' />
                </ButtonRaw>
            </div>
            <MissingDeviceNotification className='mt-8' />
            <DevicesList className='-mx-4 mt-4' aria-labelledby={headerId} onClose={onPopupClose} />
            <AddDeviceButton className='mx-auto mt-8' aria-label={buttonAriaLabel} onClick={onAddDeviceClick}>
                {button}
            </AddDeviceButton>
        </PopupStickedWithSwipeClose>
    );
};

const DesktopPopup = ({title, button, buttonAriaLabel, onAddDeviceClick, onPopupClose, ...rest}) => {
    const headerId = useId();
    const [scrollContainer, setScrollContainer] = useState(null);

    return (
        <PopupSideBar
            className='w-[var(--devices-popup-sidebar-width)]'
            overlayClassName='left-[var(--sidebar-width)] w-[calc(100%-var(--sidebar-width))]'
            onClose={onPopupClose}
            aria-labelledby={headerId}
            {...rest}
        >
            <div
                ref={(ref) => setScrollContainer(ref)}
                className='hw-h-0 flex max-h-[calc(100%-98px)] flex-col overflow-y-auto'
            >
                <StickyHeader
                    title={title}
                    scrollElement={scrollContainer}
                    endIcon={<CloseButton onClick={onPopupClose} />}
                    renderAs='div'
                />
                <div className='flex flex-col px-8 pt-[calc(var(--sticky-header-height)+8px)]'>
                    <YourDevicesHeader id={headerId} className='text-left leading-13'>
                        {title}
                    </YourDevicesHeader>
                    <MissingDeviceNotification className='mt-8' />
                    <DevicesList className='mt-4' aria-labelledby={headerId} onClose={onPopupClose} />
                </div>
            </div>
            <div className='mt-auto px-4 pb-[34px]'>
                <AddDeviceButton
                    className='m-0 w-full min-w-[312px]'
                    aria-label={buttonAriaLabel}
                    onClick={onAddDeviceClick}
                >
                    {button}
                </AddDeviceButton>
            </div>
        </PopupSideBar>
    );
};

export default DevicesListPopup;
