import React from 'react';

import {BellIcon} from '../../../../components/Icons';

const InboxEmpty = ({emptyInboxText}) => {
    return (
        <div className='flex flex-col items-center gap-4 py-36 ml:absolute ml:left-0 ml:top-0 ml:h-full ml:w-full ml:justify-center ml:px-6 ml:py-0'>
            <BellIcon />
            <strong className='mb-0 text-center leading-13 ml:text-20'>{emptyInboxText}</strong>
        </div>
    );
};

export default InboxEmpty;
