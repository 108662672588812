const formatByName = (str, values) => {
    if (!str) return str;

    return str.replace(/\{\{|\}\}|\{(\w+)\}/g, (m, n) => {
        if (m === '{{') {
            return '{';
        }
        if (m === '}}') {
            return '}';
        }
        return values[n];
    });
};

const formatString = (string, ...values) => {
    if (!string) return '';

    for (let i = 0; i < values.length; i++) {
        string = string.replace(`{${i}}`, values[i]);
    }

    return string;
};

const capitalize = (str) => {
    if (!str) return;

    return str.charAt(0).toUpperCase() + str.slice(1);
};

export default {
    formatString,
    formatByName,
    capitalize,
};
