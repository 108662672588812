import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import {WARNING_BANNER_SOURCE_TYPES, WarningBanners, WarningBannersTop} from '@/views/WarningBanners';

import ROUTE_PARAMS from '../../consts/route/routeParams';
import useIsDesktopLayout from '../../hooks/layout/useIsDesktopLayout';
import appRouterService from '../../services/route/appRouterService';
import InboxViewPage from './InboxView/InboxViewPage';
import NotificationDetailsPage from './NotificationDetails/NotificationDetailsPage';

const InboxPage = () => {
    const {[ROUTE_PARAMS.MESSAGE_ID]: messageId} = useParams();
    const isDesktopLayout = useIsDesktopLayout();

    useEffect(() => {
        if (isDesktopLayout) appRouterService.forwardToHomePage();
    }, [isDesktopLayout]);

    return (
        <div className='flex w-full flex-col pt-0-hw-offset'>
            <WarningBannersTop source={WARNING_BANNER_SOURCE_TYPES.INBOX} />
            {messageId ? <NotificationDetailsPage messageId={messageId} /> : <InboxViewPage className='flex-1' />}
            <WarningBanners source={WARNING_BANNER_SOURCE_TYPES.INBOX} />
        </div>
    );
};

export default InboxPage;
