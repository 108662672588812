import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {EllipsisMenuIcon, RenameIcon} from '@/components/Icons';
import {
    MY_DEVICES_PAGE_DEVICE_LIST_BUTTON_ARIA_LABEL,
    MY_DEVICES_PAGE_RENAME_DEVICE_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import useIsDesktopLayout from '@/hooks/layout/useIsDesktopLayout';
import {getAriaLabel} from '@/services/accessibilityService';

import useDevicesListPopup from '../hooks/useDevicesListPopup';
import useOnRenameClick from '../hooks/useOnRenameClick';
import DevicesListPopup from './DevicesListPopup/DevicesListPopup';

const MyDevicesDeviceHeader = ({deviceName}) => {
    const isDesktop = useIsDesktopLayout();
    const onRenameClick = useOnRenameClick();
    const {isPopupOpened, openPopup, closePopup} = useDevicesListPopup();

    const onMoreDevicesClick = () => {
        openPopup();
    };

    return (
        <>
            <div className='flex w-full items-center'>
                <h1 className='mb-0 overflow-hidden text-ellipsis text-center text-28 leading-[40px]'>{deviceName}</h1>
                {isDesktop && (
                    <ButtonRaw
                        className='h-full p-2'
                        onClick={onRenameClick}
                        aria-label={getAriaLabel(MY_DEVICES_PAGE_RENAME_DEVICE_BUTTON_ARIA_LABEL)}
                    >
                        <RenameIcon />
                    </ButtonRaw>
                )}
                <div className='flex flex-1 justify-end'>
                    <ButtonRaw
                        className='shrink-0'
                        onClick={onMoreDevicesClick}
                        aria-label={getAriaLabel(MY_DEVICES_PAGE_DEVICE_LIST_BUTTON_ARIA_LABEL)}
                    >
                        <EllipsisMenuIcon />
                    </ButtonRaw>
                </div>
            </div>
            <DevicesListPopup isOpen={isPopupOpened} onClose={closePopup} />
        </>
    );
};

export default MyDevicesDeviceHeader;
