import React from 'react';

import DeviceImage from '@/containers/DeviceImage/DeviceImage';
import useIsDesktopOnlyLayout from '@/hooks/layout/useIsDesktopOnlyLayout';

import MyDevicesConnectButton from './MyDevicesConnectButton';
import MyDevicesDeviceHeader from './MyDevicesDeviceHeader';

const MyDevicesImageSection = ({device, deviceName}) => {
    const isDesktopOnlyLayout = useIsDesktopOnlyLayout();

    return (
        <section className='flex w-full flex-col px-4 md:px-8 ml:mx-auto ml:max-w-[572px] ml:flex-1 ml:px-0 ml:pt-8'>
            <MyDevicesDeviceHeader deviceName={deviceName} />
            {isDesktopOnlyLayout && <MyDevicesConnectButton device={device} />}
            <DeviceImage
                className='relative mx-auto mt-8 aspect-square w-full max-w-[298px] md:max-w-[398px] ml:max-w-none'
                device={device}
                holder={device?.holder}
                imgClassName='max-h-[298px] md:max-h-[398px] even:top-0 even:absolute ml:max-h-none ml:w-full ml:h-auto'
                ignoreRestrictedMarket
                showHolder
            />
        </section>
    );
};

export default MyDevicesImageSection;
