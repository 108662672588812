import React, {forwardRef} from 'react';

import {ButtonRaw} from '@/components/Button/index.js';
import {ArrowLeftIcon} from '@/components/Icons/index.js';
import {BACK_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import {getAriaLabel} from '@/services/accessibilityService';
import routerService from '@/services/route/routerService.js';
import {twx} from '@/utils/tailwindUtils.js';

const BackButton = forwardRef(
    ({className, onClick = routerService.goBack, 'aria-label': propsAriaLabel, ...restProps}, ref) => {
        const ariaLabel = getAriaLabel(BACK_BUTTON_ARIA_LABEL, propsAriaLabel);

        return (
            <ButtonRaw
                onClick={onClick}
                className={twx('flex h-12 w-12 items-center justify-center ml:h-6 ml:w-6', className)}
                aria-label={ariaLabel}
                {...restProps}
                ref={ref}
            >
                <ArrowLeftIcon className='rtl:rotate-180' />
            </ButtonRaw>
        );
    }
);

export default BackButton;
