import cn from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';

import tncService from '@/services/tncService';
import {makeSelectTncLegalsByType, selectTncLegals} from '@/state/selectors/consumer';

import styles from './TermsOfUseDescription.module.scss';

const TermsOfUseDescription = ({id, innerHtml, className, tncType, 'aria-hidden': ariaHidden, onTncLinkClick}) => {
    const legals = useSelector(selectTncLegals);
    const tncLegals = useSelector(makeSelectTncLegalsByType(tncType));
    const tncLinkClass = styles.TncLink;

    const handleReadButtonClick = (e) => {
        const {target} = e;
        const tncCode = tncService.getTncCodeFromTarget(target, tncLinkClass);

        if (tncCode) {
            onTncLinkClick(tncCode);
        }
    };

    const textParsed = tncService.getParsedDescription(innerHtml, tncLinkClass, legals, tncLegals);

    return (
        <div
            id={id}
            className={cn('whitespace-pre-line', className)}
            dangerouslySetInnerHTML={{__html: textParsed}}
            aria-hidden={ariaHidden}
            onClick={handleReadButtonClick}
        />
    );
};

export default TermsOfUseDescription;
