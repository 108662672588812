import {useSelector} from 'react-redux';

import {
    MY_DEVICES_PAGE_AUTO_START_BUTTON_ARIA_LABEL,
    MY_DEVICES_PAGE_FLEX_BATTERY_BUTTON_ARIA_LABEL,
    MY_DEVICES_PAGE_FLEX_PUFF_BUTTON_ARIA_LABEL,
    MY_DEVICES_PAGE_ILLUMINATION_MODE_BUTTON_ARIA_LABEL,
    MY_DEVICES_PAGE_SMART_GESTURES_BUTTON_ARIA_LABEL,
    MY_DEVICES_PAGE_VIBRATIONS_BUTTON_ARIA_LABEL,
} from '@/consts/localization/localizationKeys';
import useAemFlexBatteryData from '@/containers/DevicesPage/components/MyDevicesView/hooks/DeviceSettings/useAemFlexBatteryData';
import useAutoStartData from '@/containers/DevicesPage/components/MyDevicesView/hooks/DeviceSettings/useAutoStartData';
import useDeviceSettingsEnabled from '@/containers/DevicesPage/components/MyDevicesView/hooks/DeviceSettings/useDeviceSettingsEnabled';
import useFlexPuffData from '@/containers/DevicesPage/components/MyDevicesView/hooks/DeviceSettings/useFlexPuffData';
import useGestureModeData from '@/containers/DevicesPage/components/MyDevicesView/hooks/DeviceSettings/useGestureModeData';
import useLedData from '@/containers/DevicesPage/components/MyDevicesView/hooks/DeviceSettings/useLedData';
import useVibrationData from '@/containers/DevicesPage/components/MyDevicesView/hooks/DeviceSettings/useVibrationData';
import useIsDesktopLayout from '@/hooks/layout/useIsDesktopLayout';
import appRouterService from '@/services/route/appRouterService';
import {selectDeviceType} from '@/state/selectors/device';

const useDeviceSettings = () => {
    const type = useSelector(selectDeviceType);
    const isDesktopLayout = useIsDesktopLayout();
    const aemFlexBatteryData = useAemFlexBatteryData();
    const flexPuffData = useFlexPuffData();
    const autoStartData = useAutoStartData();
    const gestureModeData = useGestureModeData();
    const ledData = useLedData();
    const vibrationData = useVibrationData();
    const {
        isAnySettingEnabled,
        isAemFlexBatteryEnabled,
        isAutoStartEnabled,
        isFlexPuffEnabled,
        isSmartGesturesEnabled,
        isIlluminationModeEnabled,
        isVibrationEnabled,
    } = useDeviceSettingsEnabled({
        type,
    });

    const onAemFlexBatteryClick = () => {
        appRouterService.forwardToAemFlexBatteryPage();
    };

    const onFlexPuffClick = () => {
        appRouterService.forwardToFlexPuffPage();
    };

    const onAutoStartClick = () => {
        appRouterService.forwardToAutoStartPage();
    };

    const onSmartGesturesClick = () => {
        appRouterService.forwardToSmartGesturesPage();
    };

    const onVibrationsClick = () => {
        appRouterService.forwardToVibrationsPage();
    };

    const onIlluminationModeClick = () => {
        appRouterService.forwardToIlluminationModePage();
    };

    const settings = [];
    const addDeviceSetting = (settingData, itemName, ariaLabelKey, onClick) => {
        if (!settingData) return;

        const {itemName: subItemName, icon} = settingData;

        const setting = {
            itemName,
            subItemName: isDesktopLayout ? subItemName : undefined,
            icon,
            onClick,
            ariaLabelKey,
            hideArrow: isDesktopLayout,
            hasSeparator: !isDesktopLayout,
        };

        settings.push(setting);
    };

    if (isAemFlexBatteryEnabled) {
        addDeviceSetting(
            aemFlexBatteryData,
            'aemFlexBatterySetting',
            MY_DEVICES_PAGE_FLEX_BATTERY_BUTTON_ARIA_LABEL,
            onAemFlexBatteryClick
        );
    }

    if (isFlexPuffEnabled) {
        addDeviceSetting(flexPuffData, 'flexPuffSetting', MY_DEVICES_PAGE_FLEX_PUFF_BUTTON_ARIA_LABEL, onFlexPuffClick);
    }

    if (isAutoStartEnabled && autoStartData) {
        addDeviceSetting(
            autoStartData,
            'autoStartSetting',
            MY_DEVICES_PAGE_AUTO_START_BUTTON_ARIA_LABEL,
            onAutoStartClick
        );
    }

    if (isSmartGesturesEnabled) {
        addDeviceSetting(
            gestureModeData,
            'smartGesturesSetting',
            MY_DEVICES_PAGE_SMART_GESTURES_BUTTON_ARIA_LABEL,
            onSmartGesturesClick
        );
    }

    if (isVibrationEnabled) {
        addDeviceSetting(
            vibrationData,
            'vibrationsSetting',
            MY_DEVICES_PAGE_VIBRATIONS_BUTTON_ARIA_LABEL,
            onVibrationsClick
        );
    }

    if (isIlluminationModeEnabled) {
        addDeviceSetting(
            ledData,
            'illuminationModeSetting',
            MY_DEVICES_PAGE_ILLUMINATION_MODE_BUTTON_ARIA_LABEL,
            onIlluminationModeClick
        );
    }

    if (settings.length) {
        settings[settings.length - 1].hasSeparator = false;
    }

    return {isAnySettingEnabled, settings};
};

export default useDeviceSettings;
