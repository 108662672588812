import cn from 'classnames';
import React from 'react';
import {SwiperSlide} from 'swiper/react';

import PageSection from '@/components/Layout/PageSection';
import SwiperCarousel from '@/components/Swiper/SwiperCarousel';
import SwiperNavigation from '@/components/Swiper/SwiperNavigation';
import useSwiperNavigation from '@/components/Swiper/useSwiperNavigation';
import usePageSectionTitle from '@/hooks/usePageSectionTitle';

import useGetForYouSectionList from './hooks/useGetForYouSectionList';

const HomePageForYouSection = ({id, className}) => {
    const forYouSectionList = useGetForYouSectionList({id});
    const {prevRef, nextRef, isBeginning, isEnd, swiperOptions} = useSwiperNavigation();
    const titleText = usePageSectionTitle(id);

    if (!forYouSectionList?.length) return null;

    return (
        <PageSection id={id} className={cn('overflow-hidden px-0', className)}>
            <div className='mb-4 px-4 text-start md:mb-8 lg:flex lg:justify-between'>
                <PageSection.Title text={titleText} />
                <SwiperNavigation prevRef={prevRef} nextRef={nextRef} isBeginning={isBeginning} isEnd={isEnd} />
            </div>
            <SwiperCarousel options={swiperOptions}>
                {forYouSectionList.map((slide, index) => (
                    <SwiperSlide key={index}>{slide}</SwiperSlide>
                ))}
            </SwiperCarousel>
        </PageSection>
    );
};

export default HomePageForYouSection;
