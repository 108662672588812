import React from 'react';

import {CLOSE_BUTTON_ARIA_LABEL} from '@/consts/localization/localizationKeys';
import {getAriaLabel} from '@/services/accessibilityService';
import {twx} from '@/utils/tailwindUtils';

import {CancelIcon, CancelIcon12} from '../Icons';

const CloseButton = ({onClick, className, size, 'aria-label': propsAriaLabel}) => {
    const ariaLabel = getAriaLabel(CLOSE_BUTTON_ARIA_LABEL, propsAriaLabel);
    const isSmall = size === 'small';

    return (
        <button
            className={twx('cursor-pointer p-2.5 text-primary ml:p-3', className)}
            onClick={onClick}
            aria-label={ariaLabel}
        >
            {isSmall ? <CancelIcon12 /> : <CancelIcon />}
        </button>
    );
};

export default CloseButton;
