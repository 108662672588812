import cn from 'classnames';
import React from 'react';

import {ButtonRaw} from '@/components/Button';
import {CheckmarkIcon} from '@/components/Icons';
import helpers from '@/utils/helpers';
import {twx} from '@/utils/tailwindUtils';

import styles from './SwitchToggle.module.scss';

const SwitchToggle = (props) => {
    const {
        onChange,
        className,
        isActive,
        testId,
        'aria-labelledby': ariaLabelledBy,
        isDisabled,
        onDisableClick,
        ...rest
    } = props;
    const onSwitchToggleClick = () => {
        if (isDisabled) {
            onDisableClick();
            return;
        }
        helpers.runFunction(onChange, !isActive);
    };

    return (
        <ButtonRaw
            className={twx(
                'flex items-center justify-center py-2 hover:cursor-pointer md:block md:p-0 md:pr-4',
                className
            )}
            data-testid={testId}
            onClick={onSwitchToggleClick}
            role='switch'
            aria-labelledby={ariaLabelledBy}
            aria-checked={!!isActive}
            tabIndex={isDisabled ? -1 : 0}
            {...rest}
        >
            <div className={cn(styles.Toggle, {[styles.Active]: isActive})}>
                <div className={styles.Slider}>
                    <CheckmarkIcon />
                </div>
            </div>
        </ButtonRaw>
    );
};

export default SwitchToggle;
