import {twx} from '@/utils/tailwindUtils';

import useHighlightsSlideText from '../hooks/useHighlightsSlideText';
import HighlightsContentContainer from './HighlightsContentContainer';

const HighlightsContentTextBottom = ({isFullscreen, gradient, title, subTitle, description}) => {
    const {isTextLarger, TitleComponent} = useHighlightsSlideText(isFullscreen);

    return (
        <HighlightsContentContainer isFullscreen={isFullscreen} gradient={gradient}>
            <p
                className={twx(
                    'mb-auto line-clamp-1 text-14 font-bold leading-[10px] md:leading-normal',
                    isFullscreen && 'pr-12 md:pr-0',
                    isTextLarger && 'text-24 leading-[18px]'
                )}
            >
                {subTitle}
            </p>
            <TitleComponent className={twx('mb-0 text-24', !isFullscreen && 'line-clamp-2')}>{title}</TitleComponent>
            <p className={twx('md:mt-2 md:leading-[20px]', !isFullscreen && 'line-clamp-3')}>{description}</p>
        </HighlightsContentContainer>
    );
};

export default HighlightsContentTextBottom;
