import cn from 'classnames';
import React, {forwardRef} from 'react';

import AemEditableText from '@/components/Aem/AemEditableText/AemEditableText';
import BackButton from '@/components/BackButton/BackButton';
import {AppButton, ButtonRaw} from '@/components/Button';
import DeviceIcon from '@/components/DeviceIcon/DeviceIcon';
import {ArrowSmallIcon} from '@/components/Icons';
import {StickyPageLayout} from '@/components/Layout';
import routePaths from '@/consts/route/routePaths';
import useIsDesktopLayout from '@/hooks/layout/useIsDesktopLayout';
import routerService from '@/services/route/routerService';
import {twx} from '@/utils/tailwindUtils';

import WithSettingsGroupButtonAriaLabel from './WithGroupButtonAriaLabel/WithSettingsGroupButtonAriaLabel';

const DevicesSettingsSection = ({className, children}) => {
    return (
        <section
            className={twx(
                'flex w-full flex-col overflow-hidden rounded-xl bg-secondary ml:max-w-[700px] ml:flex-[1.36] ml:rounded-b-none ml:rounded-t-[30px] ml:bg-white lg:flex-[1.47]',
                className
            )}
        >
            {children}
        </section>
    );
};

const Container = forwardRef(({children, className, title, onClick, pageName, itemName, ...rest}, ref) => {
    const isDesktop = useIsDesktopLayout();

    if (isDesktop) {
        return (
            <div ref={ref} className={cn(className, 'ml:px-8 ml:pt-8')} {...rest}>
                <BackButton />
                {children}
            </div>
        );
    }

    return (
        <StickyPageLayout
            ref={ref}
            className='items-center'
            contentClassName='flex flex-1 flex-col items-center'
            headerOptions={{
                onBack: routerService.goBack,
                title,
            }}
            footer={
                <AppButton className='m-auto' primary light onClick={onClick}>
                    <AemEditableText pageName={pageName} itemName={itemName} />
                </AppButton>
            }
            {...rest}
        >
            {children}
        </StickyPageLayout>
    );
});

const Group = ({children, className}) => {
    return <div className={twx('px-4 md:px-8', className)}>{children}</div>;
};

const GroupTitle = ({itemName}) => {
    return (
        <AemEditableText
            pageName={routePaths.DEVICES_PAGE}
            itemName={itemName}
            render={(text) =>
                text && (
                    <h2 className='mb-4 text-20 leading-12 tracking-02 md:text-20 ml:mb-6 ml:text-24 ml:leading-15 ml:tracking-normal'>
                        {text}
                    </h2>
                )
            }
        />
    );
};

const GroupButtons = ({className, children}) => {
    return <div className={twx('rounded-xl bg-romance ml:bg-secondary', className)}>{children}</div>;
};

const GroupButton = ({
    className,
    textClassName,
    mode,
    icon: Icon,
    itemName,
    subItemName,
    hasSeparator,
    onClick,
    hideArrow,
    ariaLabelKey,
}) => {
    return (
        <WithSettingsGroupButtonAriaLabel
            ariaLabelKey={ariaLabelKey}
            subItemName={subItemName}
            renderContent={(ariaLabel) => (
                <ButtonRaw
                    className={cn(className, 'flex w-full flex-col justify-between px-4 pt-4')}
                    aria-label={ariaLabel}
                    onClick={onClick}
                >
                    <div className='flex w-full items-center justify-between gap-2 pb-4'>
                        {mode && <DeviceIcon className='shrink-0' mode={mode} />}
                        {Icon && <Icon className='h-12 w-12 shrink-0' />}
                        <div className='mr-auto flex flex-col pr-1'>
                            <span
                                className={twx(
                                    'text-14 font-bold leading-143 tracking-02 ml:font-normal ml:tracking-04',
                                    textClassName
                                )}
                            >
                                <AemEditableText pageName={routePaths.DEVICES_PAGE} itemName={itemName} />
                            </span>
                            {subItemName && (
                                <span className='text-14 font-bold tracking-02'>
                                    <AemEditableText pageName={routePaths.DEVICES_PAGE} itemName={subItemName} />
                                </span>
                            )}
                        </div>
                        {!hideArrow && <ArrowSmallIcon className='shrink-0' />}
                    </div>
                    {hasSeparator && <ButtonSeparator />}
                </ButtonRaw>
            )}
        />
    );
};

const ButtonSeparator = () => {
    return <hr className='h-[1px] w-full bg-mischka ml:bg-primary-tins-slate/15' />;
};

DevicesSettingsSection.Container = Container;
DevicesSettingsSection.Group = Group;
DevicesSettingsSection.Title = GroupTitle;
DevicesSettingsSection.Buttons = GroupButtons;
DevicesSettingsSection.Button = GroupButton;

export default DevicesSettingsSection;
