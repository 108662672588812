import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {useGetInboxStatus} from '@/api/iccNotificationApi';
import {ICC_INBOX_MESSAGE_STATUS_TYPES} from '@/consts/icc/iccInboxMessageStatusTypes';
import inboxMessageService from '@/services/inboxMessageService';
import pushNotificationService from '@/services/pushNotificationService';
import {makeSelectInboxMessage, makeSelectNextInboxMessage} from '@/state/selectors/inbox';

import useInboxMessages from './useInboxMessages';

const useNotificationDetails = ({messageId}) => {
    const message = useSelector(makeSelectInboxMessage(messageId));
    const nextMessage = useSelector(makeSelectNextInboxMessage(messageId));
    const {refetch: refetchInboxStatus} = useGetInboxStatus();

    useEffect(() => {
        const run = async () => {
            if (message?.status === ICC_INBOX_MESSAGE_STATUS_TYPES.UNREAD) {
                pushNotificationService.removeNotification(messageId);
                await inboxMessageService.readInboxMessage(messageId);
                refetchInboxStatus();
            }
        };

        run();
    }, [message]);

    const {inboxMessages, loadMore, isLoading, hasNextPage} = useInboxMessages();

    useEffect(() => {
        if (nextMessage) return;
        if (isLoading) return;

        if (hasNextPage || inboxMessages === undefined) {
            loadMore();
        }
    }, [hasNextPage, nextMessage, isLoading]);

    return {message, nextMessage, isLoaderActive: isLoading};
};

export default useNotificationDetails;
